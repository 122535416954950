import { Card, Stack, Typography, CircularProgress } from "@mui/material";
import LoansAndAdvancesListViewer from "./LoanAndAdvancesList";
import ClearPaymentModel from "./ClearPaymentModel";

const AdvanceViewerList = ({
  advances,
  isLoading,
  sClearer,
  onChangeClearer,
  onClearPayment,
  isClearing,
}) => {
  return (
    <>
      <Card
        elevation={3}
        sx={{
          flexDirection: "column",
          justifyContent: "center",
          flexGrow: "1",
          // width: "50%",
          padding: "15px 10px 10px 15px",
          margin: "40px 20px",
          borderRadius: "10px",
          marginTop: "100px",
        }}
      >
        <Stack
          direction="row"
          sx={{
            flexDirection: "row",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <Typography variant="h1" fontSize="22px">
            Approveds
          </Typography>
        </Stack>

        {isLoading ? (
          <CircularProgress
            sx={{
              minHeight: "200px",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              margin: "auto",
            }}
            color="primary"
            size={25}
          />
        ) : (
          <Stack
            style={{
              marginLeft: "10px",
              maxHeight: "400px",
              overflowY: "scroll",
              margin: "12px 0px 15px 15px",
            }}
            spacing={0}
          >
            <LoansAndAdvancesListViewer
              expenses={advances}
              onSelect={(advance) => {
                onChangeClearer(advance.expenseId);
              }}
            />

            {Boolean(sClearer) && (
              <ClearPaymentModel
                isLoading={isClearing}
                isOpen={Boolean(sClearer)}
                onClose={() => {
                  onChangeClearer("");
                }}
                onClearPayment={(data) => {
                  onClearPayment(data);
                }}
                expenseId={sClearer}
              />
            )}
          </Stack>
        )}
      </Card>
    </>
  );
};

export default AdvanceViewerList;
