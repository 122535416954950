import CustomDateRangePicker from "../../analytics/components/DateRangePicker";
import LoadingBookings from "../../common/LoadingBookings";
import EntitiesRevenueGraph from "../../graph/entitiesRevenue/entitiesRevenueGraph";
import SingleEntityRevenueViewer from "./components/SingleEntityRevenueViewer";

const ExpenseRevenueScreen = ({
  revenue,
  isLoading,
  startDay,
  days,
  onDaysUpdate,
}) => {
  let allEntities = Object.keys(revenue);

  const dateContent = (
    <div style={{ position: "absolute", top: "15px", left: "30px", zIndex: 4 }}>
      <CustomDateRangePicker
        startDay={startDay}
        days={days}
        onDateChange={(date, days) => {
          onDaysUpdate(date, days);
        }}
      />
    </div>
  );

  if (isLoading) {
    return <LoadingBookings />;
  }

  return (
    <>
      {dateContent}
      <EntitiesRevenueGraph
        entitiesRevenue={revenue}
        title={"Entity Revenue"}
      />
      {allEntities.map((ent) => {
        return (
          <SingleEntityRevenueViewer
            entityName={ent}
            allDateData={revenue[ent]}
            key={ent}
          />
        );
      })}
    </>
  );
};

export default ExpenseRevenueScreen;
