import axios from "axios";

const TaskRequester = async (route, token, data, method) => {
  try {
    const response = await axios({
      method: method,
      url: route,
      headers: {
        Authorization: `Bearer ${token}`,
      },
      data: {
        ...data,
      },
      params: {
        ...(method.toLowerCase() === "get" ? data : {}),
      },
    });

    return response.data;
  } catch (err) {
    console.log(err);
    if (err.response && err.response.data.message)
      throw new Error(err.response.data.message);
    throw new Error(err.message);
  }
};

export default TaskRequester;
