import { useState } from "react";
import RevenueReconcilationAllDatesGraph from "./RevenueReconcilationAllDates";
import RevenueReconcileSingleDateGraph from "./RevenueReconcileSingleDate";

const RevenueReconcilationGraph = ({ revenueReconcilation }) => {
  const [summed, setSummed] = useState({
    Hotel: false,
    Date: false,
    Bar: false,
    View: false,
  });

  return summed.View ? (
    <RevenueReconcilationAllDatesGraph
      revenueReconcilation={revenueReconcilation}
      summed={summed}
      onSumChange={(st) => {
        setSummed(st);
      }}
    />
  ) : (
    <RevenueReconcileSingleDateGraph
      revenueReconcilation={revenueReconcilation}
      summed={summed}
      onSumChange={(st) => {
        setSummed(st);
      }}
    />
  );
};

export default RevenueReconcilationGraph;
