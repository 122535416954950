import React, { useState, useEffect } from "react";
import ChartDataLabels from "chartjs-plugin-datalabels";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  BarElement,
} from "chart.js";
import { Line, Bar } from "react-chartjs-2";
import Graph from "../util/graph";
import HotelChooser from "../components/HotelChooser";
import { Stack } from "@mui/material";
import HotelSwitch from "../components/HotelSwitch";
import labelFinder from "../util/labelFinder";
import EntitiesRevenueFinder from "./entitiesRevenueFinder";

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  BarElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  ChartDataLabels
);

const EntitiesRevenueGraph = ({ entitiesRevenue, title }) => {
  const [sEntities, setSEntities] = useState([]);
  const entities = Object.keys(entitiesRevenue);
  let allDates = Object.keys(entitiesRevenue[entities[0]]);
  const allOptions = Graph.ExpenseAnalytics.REV;
  const [options, setOptions] = useState([]);

  const [summed, setSummed] = useState({
    entity: false,
    date: false,
    isBar: false,
  });
  const [data, setData] = useState({});

  useEffect(() => {
    const entities = Object.keys(entitiesRevenue);
    let allDates = Object.keys(entitiesRevenue[entities[0]]);

    let datasets = EntitiesRevenueFinder(
      entitiesRevenue,
      allDates,
      summed.entity,
      summed.date,
      sEntities,
      summed.isBar,
      options
    );
    let labels = labelFinder(allDates, summed.entity, summed.date, sEntities);
    setData({ labels, datasets });
  }, [entitiesRevenue, summed.entity, summed.date, sEntities, summed.isBar,options]);

  useEffect(() => {
    setSEntities(Object.keys(entitiesRevenue));
    setOptions(Graph.ExpenseAnalytics.REV)
  }, [entitiesRevenue,allOptions]);

  return (
    <div style={{ margin: "20px", paddingTop: "20px", position: "relative" }}>
      <Stack
        style={{
          position: "absolute",
          top: "-10px",
          right: "10px",
        }}
        direction="row"
        spacing={1}
      >
        <HotelSwitch
          isChecked={summed.isBar}
          label={"Bar"}
          onChange={(v) => {
            setSummed((p) => {
              return { ...p, isBar: v };
            });
          }}
        />

        {entities.length > 1 ? (
          <HotelSwitch
            isChecked={summed.entity}
            label={"Entity"}
            onChange={(v) => {
              setSummed((p) => {
                return { entity: v, date: p.date, isBar: p.isBar };
              });
            }}
          />
        ) : (
          <></>
        )}

        {allDates.length > 1 ? (
          <HotelSwitch
            isChecked={summed.date}
            label={"Date"}
            onChange={(v) => {
              setSummed((p) => {
                return { date: v, entity: p.entity, isBar: p.isBar };
              });
            }}
          />
        ) : (
          <></>
        )}

        <HotelChooser
          hotels={allOptions}
          selectedHotels={options}
          onHotelsChange={(o) => {
            setOptions(o);
          }}
          width="80px"
        />

        <HotelChooser
          hotels={entities}
          selectedHotels={sEntities}
          onHotelsChange={(s) => {
            setSEntities(s);
          }}
          width="200px"
        />
      </Stack>

      {data &&
        data.datasets &&
        data.datasets.length > 0 &&
        (summed.isBar ? (
          <Bar
            style={{ marginTop: "20px" }}
            options={Graph.BarOptions(
              title,
              true,
              data.datasets.reduce((acc, cur) => {
                if (!acc[cur.stack]) {
                  let nAcc = { ...acc };
                  nAcc[cur.stack] = [cur];
                  return nAcc;
                } else {
                  let nAcc = { ...acc };
                  nAcc[cur.stack] = [...nAcc[cur.stack], cur];
                  return nAcc;
                }
              }, {})
            )}
            data={data}
          />
        ) : (
          <Line
            style={{ marginTop: "20px" }}
            options={Graph.Options(title)}
            data={data}
          />
        ))}
    </div>
  );
};

export default EntitiesRevenueGraph;
