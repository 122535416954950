import { useContext } from "react";
import { AnalyticsContext } from "../../../store/analyticsS";
import { HotelContext } from "../../../store/hotel";
import CustomDateRangePicker from "../../analytics/components/DateRangePicker";
import LoadingBookings from "../../common/LoadingBookings";
import UserAnalyticsGraph from "../../graph/userAnalytics/UserAnalyticsGraph";
import Constant from "../../../constant/Constant";
import SingleDateUserAnalyticsViewer from "../component/userAnalytics/SingleDateUserAnalyticsViewer";

const DepUserAnalyticsScreen = () => {
  const analyticsContext = useContext(AnalyticsContext);
  const hotelContext = useContext(HotelContext);
  let bookingAnalytics = analyticsContext.depUserAnalytics;
  let allDates = Object.keys(bookingAnalytics);

  const dateContent = (
    <div style={{ position: "absolute", top: "15px", left: "30px", zIndex: 4 }}>
      <CustomDateRangePicker
        startDay={hotelContext.startDay}
        days={hotelContext.days}
        onDateChange={(date, days) => {
          hotelContext.onUpdateDate(date, days);
        }}
      />
    </div>
  );

  if (
    analyticsContext.isDepUserAnalyticsLoading ||
    !analyticsContext.isDepUserAnalyticsLoaded
  ) {
    return (
      <>
        {dateContent}
        <LoadingBookings />
      </>
    );
  }

  return (
    <>
      {dateContent}
      {allDates.length > 0 && (
        <UserAnalyticsGraph
          bookingAnalytics={bookingAnalytics}
          title="User Analytics"
          allOptions={Constant.UserAnalyticsShort}
          isTablularData={analyticsContext.isTabularData}
          onToggleTabularData={() => {
            analyticsContext.onToggleTabularData();
          }}
          isBar={analyticsContext.isBar}
          isHotel={analyticsContext.isHotel}
          isDate={analyticsContext.isDate}
          isPercentage={analyticsContext.isPercentage}
          onTogglePercentage={() => {
            analyticsContext.onTogglePercentage();
          }}
          onToggleBar={() => {
            analyticsContext.onToggleBar();
          }}
          onToggleHotel={() => {
            analyticsContext.onToggleHotel();
          }}
          onToggleDate={() => {
            analyticsContext.onToggleDate();
          }}
        />
      )}
      {!analyticsContext.isTabularData &&
        allDates.map((date) => {
          return (
            <SingleDateUserAnalyticsViewer
              key={date}
              date={date}
              dateData={bookingAnalytics[date]}
            />
          );
        })}
    </>
  );
};

export default DepUserAnalyticsScreen;
