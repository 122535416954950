import { useState } from "react";
import { CircularProgress, Stack } from "@mui/material";
import ChatTopBar from "./ChatTopBar";
import MessageContainer from "./MessageContainer";
import ChatFooter from "./ChatFooter";
import { useEffect, useRef } from "react";
import { useQuery } from "@tanstack/react-query";
import { getAllActions } from "../../../utils/task/TaskActions";
import CustomSnackBar from "../../common/CustomSnackBar";

const ChatContainer = ({ task, token }) => {
  const chatContainerRef = useRef(null);
  const [error, setError] = useState("");

  const taskId = task.id;

  const {
    data: actions,
    isLoading: isActionsLoading,
    error: actionsError,
  } = useQuery({
    queryFn: () => getAllActions(taskId, token),
    queryKey: ["actions", taskId],
    retry: 1,
  });

  useEffect(() => {
    if (actionsError) setError(actionsError.message);
  }, [actionsError]);

  const scrollToBottom = () => {
    if (chatContainerRef.current)
      chatContainerRef.current.scrollTop =
        chatContainerRef.current.scrollHeight;
  };

  useEffect(() => {
    scrollToBottom();
  }, [chatContainerRef, actions]);

  if (isActionsLoading) {
    return (
      <Stack justifyContent={"center"} alignItems={"center"} height={"100%"}>
        <CircularProgress />
      </Stack>
    );
  }

  return (
    <Stack
      direction={"column"}
      sx={{
        border: "1px solid #ccc",
        borderRadius: "5px",
        padding: "10px",
        margin: "10px",
        flexGrow: 1,
        height: "calc(100% - 40px)",
      }}
    >
      <ChatTopBar
        taskId={taskId}
        title={task.title}
        token={token}
        dueDate={task.dueDate}
      />

      <Stack
        ref={chatContainerRef}
        sx={{
          padding: "10px",
          overflowY: "scroll",
          flexGrow: 1,
        }}
        gap={2}
      >
        {actions?.map((action) => (
          <MessageContainer
            key={action._id}
            action={action}
            token={token}
            taskId={taskId}
          />
        ))}
      </Stack>

      <ChatFooter
        taskId={task.id}
        action={actions?.length === 1 ? "ASSIGN" : "FORWARD"}
        token={token}
      />
      <CustomSnackBar
        isOpen={Boolean(error)}
        message={error}
        onClose={() => setError(null)}
      />
    </Stack>
  );
};
export default ChatContainer;
