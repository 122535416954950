import { Stack, Collapse } from "@mui/material";
import SummaryStageResult from "./SummaryStageResult";
import { useState } from "react";
import SummaryThirdStageFilter from "./SummaryThirdStageFilter";
import roundToDecimalPlace from "../../../../../utils/expense/roundToDecimalPlace";

const SummarySecondStageFilter = ({
  summary,
  dTransId,
  onDeleteTransaction,
}) => {
  const [isOpen, setIsOpen] = useState({});

  return (
    <Stack
      direction={"column"}
      sx={{
        paddingLeft: "30px",
      }}
    >
      {Object.keys(summary).map((stageKey) => {
        const allTrans = Object.values(summary[stageKey])
          .map((a) => Object.values(a))
          .flat()
          .flat();
        return (
          <>
            <div>
              <SummaryStageResult
                key={stageKey}
                name={stageKey}
                amount={allTrans
                  .filter((a) => !a.type.startsWith("Incoming"))
                  .reduce((acc, cur) => {
                    return roundToDecimalPlace(
                      roundToDecimalPlace(acc, 2) + roundToDecimalPlace(cur.amount, 2),2
                    );
                  }, 0)}
                pAmount={allTrans
                  .filter((a) => a.type.startsWith("Incoming"))
                  .reduce((acc, cur) => {
                    return roundToDecimalPlace(
                      roundToDecimalPlace(acc, 2) + roundToDecimalPlace(cur.amount, 2),2
                    );
                  }, 0)}
                isOpen={Boolean(isOpen[stageKey])}
                onToggle={() => {
                  setIsOpen((p) => {
                    let a = { ...p };
                    a[stageKey] = !Boolean(a[stageKey]);
                    console.log(a);
                    return a;
                  });
                }}
              />
              <Collapse unmountOnExit timeout={100} in={isOpen[stageKey]}>
                <SummaryThirdStageFilter
                  summary={summary[stageKey]}
                  dTransId={dTransId}
                  onDeleteTransaction={(transId) => {
                    onDeleteTransaction(transId);
                  }}
                />
              </Collapse>
            </div>
          </>
        );
      })}
    </Stack>
  );
};

export default SummarySecondStageFilter;
