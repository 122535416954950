import ExpenseViewer from "../../components/common/expenseViewer";

const LoansAndAdvancesListViewer = ({ expenses, onSelect}) => {
  return (
    <>
      {expenses.map((expense, index) => {
        return (
          <ExpenseViewer
            key={index + Math.random() * 100}
            expense={expense}
            onClick={() => {
              onSelect(expense);
            }}
          />
        );
      })}
    </>
  );
};

export default LoansAndAdvancesListViewer;
