import styled from "@emotion/styled";
import {
  AccountCircleOutlined,
  AttachFile,
  Bolt,
  EmojiEmotionsOutlined,
} from "@mui/icons-material";
import {
  Box,
  Button,
  Checkbox,
  CircularProgress,
  IconButton,
  InputBase,
  Modal,
  Stack,
  Typography,
} from "@mui/material";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { useEffect, useState } from "react";
import {
  assignTask,
  getUsers,
  sendingMessage,
} from "../../../utils/task/TaskActions";
import CustomSnackBar from "../../common/CustomSnackBar";

const ChatBox = styled("div")({
  flexGrow: 1,
  fontSize: "50%",
});

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  height: 500,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};

const ChatFooter = ({ taskId, action, token }) => {
  const [open, setOpen] = useState(false);
  const [error, setError] = useState("");

  const [selectedUsers, setSelectedUsers] = useState([]);
  const [message, setMessage] = useState("");

  const queryClient = useQueryClient();

  const {
    data: usersData,
    isLoading: isUsersLoading,
    error: usersError,
    refetch: refetchUsers,
  } = useQuery({
    queryFn: () => getUsers(taskId, token),
    queryKey: ["users", taskId],
    retry: false,
  });

  useEffect(() => {
    setError(usersError?.message);
  }, [usersError]);

  useEffect(() => {
    refetchUsers();
  }, [refetchUsers, taskId]);

  const {
    mutate: addUsers,
    isPending: isAddUsersPending,
    isError: isAddUsersError,
  } = useMutation({
    mutationFn: ({ taskId, to, action, token }) => {
      return assignTask(taskId, to, action, token);
    },
    retry: 1,
    mutationKey: ["addUser"],
    onSuccess: () => {
      setOpen(false);
      queryClient.invalidateQueries(["actions", taskId]);
      setSelectedUsers([]);
    },
    onError: (error) => {
      setError(error?.message);
    },
  });

  const {
    mutate: sendMessage,
    isPending: isSendMessagePending,
    isError: isSendMessageError,
  } = useMutation({
    mutationFn: ({ taskId, message, token }) => {
      return sendingMessage(taskId, message, token);
    },
    retry: 1,
    mutationKey: ["addUser"],
    onSuccess: () => {
      setMessage("");
      queryClient.invalidateQueries(["actions", taskId]);
    },
    onError: (error) => {
      setError(error?.message);
    },
  });

  useEffect(() => {
    setMessage("");
  }, [taskId]);

  useEffect(() => {
    refetchUsers();
    setSelectedUsers([]);
  }, [open]);

  const handleSelectUser = (id) => {
    setSelectedUsers((prev) => {
      const isSelected = prev.includes(id);

      if (isSelected) {
        return prev.filter((userId) => userId !== id);
      } else {
        return [...prev, id];
      }
    });
  };

  return (
    <Stack
      bgcolor={"#F4F6FA"}
      flexDirection={"row"}
      alignItems={"center"}
      justifyContent={"space-between"}
      gap={1}
      padding={"5px"}
      borderRadius={"10px"}
    >
      <ChatBox>
        <InputBase
          placeholder="Type your message..."
          sx={{ paddingX: "8px", paddingY: "8px", width: "100%" }}
          value={message}
          onChange={(e) => setMessage(e.target.value)}
          onKeyDown={(e) => {
            if (e.key === "Enter") {
              sendMessage({ taskId, message, token });
            }
          }}
        />
      </ChatBox>
      <Stack
        flexDirection={"row"}
        alignItems={"center"}
        justifyContent={"space-between"}
        gap={1}
      >
        <IconButton>
          <AttachFile />
        </IconButton>
        <IconButton>
          <EmojiEmotionsOutlined />
        </IconButton>
        <IconButton>
          <Bolt />
        </IconButton>
        <IconButton onClick={() => setOpen(true)}>
          <AccountCircleOutlined />
        </IconButton>

        <Modal open={open} onClose={() => setOpen(false)}>
          <Box sx={style}>
            <Typography variant="h1" component="h3" textAlign={"center"}>
              {action} TASK
            </Typography>
            <Stack
              marginY={"20px"}
              gap={"10px"}
              height={"350px"}
              overflow={"auto"}
            >
              {isUsersLoading ? (
                <CircularProgress />
              ) : (
                usersData &&
                usersData.length > 0 &&
                usersData?.map((user) => (
                  <Stack
                    flexDirection={"row"}
                    key={user?.id}
                    sx={{ border: "1px solid", padding: "10px" }}
                  >
                    <Checkbox
                      value={user?.id}
                      onChange={(e) => handleSelectUser(e.target.value)}
                    />
                    <Stack>
                      <Typography variant="body1">{user?.name}</Typography>
                      <Typography variant="body2">{user?.email}</Typography>
                    </Stack>
                  </Stack>
                ))
              )}
            </Stack>
            {selectedUsers && selectedUsers.length > 0 && (
              <Button
                variant="contained"
                sx={{ height: "60px", width: "100%" }}
                onClick={() =>
                  addUsers({ taskId, to: selectedUsers, action, token })
                }
              >
                CONFIRM
              </Button>
            )}
          </Box>
        </Modal>
      </Stack>
      <CustomSnackBar
        isOpen={Boolean(error)}
        message={error}
        onClose={() => setError(null)}
      />
    </Stack>
  );
};
export default ChatFooter;
