import {
  Button,
  Card,
  CircularProgress,
  Modal,
  Typography,
} from "@mui/material";
import ExpenseDateAmountPicker from "../common/ExpenseDateAmountPicker";
import AddExpenserHotelSelect from "../common/AddExpenserHotelSelect";
import AddExpenseBooleanInput from "../common/AddExpenseBooleanInput";
import AddExpenseDatePicker from "../common/AddExpenseDatePicker";
import OxyOsTheme from "../../../../theme/theme";
import { useState } from "react";
import moment from "moment";

const AddPartnerModal = ({ isOpen, onClose, onAdd, hotels, isLoading }) => {
  const [partnerData, setPartnerData] = useState({
    id: "",
    partnerId: "",
    XBR: 0,
    XECR: 0,
    OBR: 0,
    OECR: 0,
    CFEE: 0,
    GST:0,
    isProfitSharing: false,
    isRelative: false,
    sDate: [moment(new Date()).format("DD-MM-YYYY")],
    eDate: [[moment(new Date()).add(1, "year").format("DD-MM-YYYY")]],
  });

  return (
    <Modal
      open={isOpen}
      onClose={onClose}
      sx={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <Card
        sx={{
          flexDirection: "row",
          minHeight: "500px",
          maxHeight: "600px",
          maxWidth: "700px",
          minWidth: "500px",
          background: "#FFF",
          borderRadius: "10px",
          padding: "20px 30px",
          overflowY: "scroll",
          "::-webkit-scrollbar": {
            display: "none",
          },
          border: "none",
        }}
      >
        <Typography
          variant="h1"
          sx={{
            width: "100%",
            textAlign: "center",
            marginBottom: "40px",
            marginTop: "10px",
          }}
          style={{
            textShadow: "0px 1px 1px rgba(0,0,0,0.24)",
          }}
          fontSize={"25px"}
        >
          Add Partner
        </Typography>

        <AddExpenserHotelSelect
          value={partnerData.id}
          onValueChange={(val) => {
            setPartnerData((p) => {
              return { ...p, id: val };
            });
          }}
          required={true}
          hotels={hotels}
        />

        {partnerData.id && (
          <AddExpenserHotelSelect
            value={partnerData.partnerId}
            onValueChange={(val) => {
              setPartnerData((p) => {
                return { ...p, partnerId: val };
              });
            }}
            required={true}
            hotels={hotels.filter((h) => h._id !== partnerData.id)}
          />
        )}

        <ExpenseDateAmountPicker
          amount={partnerData.XBR}
          onAmountChange={(val) => {
            setPartnerData((p) => {
              return {
                ...p,
                XBR: Number(val),
              };
            });
          }}
          label={"XBR"}
        />

        <ExpenseDateAmountPicker
          amount={partnerData.XECR}
          onAmountChange={(val) => {
            setPartnerData((p) => {
              return {
                ...p,
                XECR: Number(val),
              };
            });
          }}
          label={"XECR"}
        />

        <ExpenseDateAmountPicker
          amount={partnerData.OBR}
          onAmountChange={(val) => {
            setPartnerData((p) => {
              return {
                ...p,
                OBR: Number(val),
              };
            });
          }}
          label={"OBR"}
        />

        <ExpenseDateAmountPicker
          amount={partnerData.OECR}
          onAmountChange={(val) => {
            setPartnerData((p) => {
              return {
                ...p,
                OECR: Number(val),
              };
            });
          }}
          label={"OECR"}
        />
        <ExpenseDateAmountPicker
          amount={partnerData.CFEE}
          onAmountChange={(val) => {
            setPartnerData((p) => {
              return {
                ...p,
                CFEE: Number(val),
              };
            });
          }}
          label={"CFEE"}
        />

        <ExpenseDateAmountPicker
          amount={partnerData.GST}
          onAmountChange={(val) => {
            setPartnerData((p) => {
              return {
                ...p,
                GST: Number(val),
              };
            });
          }}
          label={"GST"}
        />

        <AddExpenseDatePicker
          date={partnerData.sDate}
          onDateChange={(date) => {
            setPartnerData((p) => {
              return {
                ...p,
                sDate: date,
              };
            });
          }}
        />

        <AddExpenseDatePicker
          date={partnerData.eDate}
          onDateChange={(date) => {
            setPartnerData((p) => {
              return {
                ...p,
                eDate: date,
              };
            });
          }}
        />

        <AddExpenseBooleanInput
          value={partnerData.isProfitSharing}
          onToggle={() => {
            setPartnerData((e) => {
              return {
                ...e,
                isProfitSharing: !e.isProfitSharing,
              };
            });
          }}
          label={"Is Profit Sharing"}
        />

        <AddExpenseBooleanInput
          value={partnerData.isRelative}
          onToggle={() => {
            setPartnerData((e) => {
              return {
                ...e,
                isRelative: !e.isRelative,
              };
            });
          }}
          label={"Is Relative"}
        />

        <Button
          variant="outlined"
          size="large"
          sx={{
            backgroundColor: "#262626",
            borderRadius: "30px",
            padding: "10px 15px",
            margin: "20px auto",
            display: "block",
            color: OxyOsTheme.palette.background.main,
            "&:hover": {
              backgroundColor: OxyOsTheme.palette.primary.main,
              color: OxyOsTheme.palette.background.main,
              opacity: 0.6,
            },
          }}
          onClick={() => {
            if (!isLoading) {
              onAdd(partnerData);
            }
          }}
        >
          {isLoading ? (
            <CircularProgress color="background" />
          ) : (
            <Typography variant="h6">Add Partner</Typography>
          )}
        </Button>
      </Card>
    </Modal>
  );
};

export default AddPartnerModal;
