import { Stack, Typography } from "@mui/material";

const SingleNameDateViewer = ({ name, amount }) => {
  return (
    <Stack
      direction={"row"}
      sx={{
        width: "400px",
        justifyContent: "space-between",
        marginTop:"7px",
        marginLeft:"20px"
      }}
    >
      <Typography variant="h6" color="primary.main" sx={{ fontSize: "12px" }}>
        {name}
      </Typography>

      <Typography variant="h6" color="primary.main" sx={{ fontSize: "12px" }}>
        {amount}
      </Typography>
    </Stack>
  );
};

export default SingleNameDateViewer;
