import { Stack, Typography, IconButton } from "@mui/material";
// import TaskStringInput from "./TaskStringInput";
import AddIcon from "@mui/icons-material/Add";
import DeleteIcon from "@mui/icons-material/Delete";
import StringSelectInput from "./StringSelectInput";

const UserPosition = ({ positions, onUpdatePosition, companies }) => {
  return (
    <Stack direction={"column"}>
      <Stack
        direction="row"
        sx={{
          flexDirection: "row",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <Typography variant="h1" fontSize="22px">
          Positions
        </Typography>
        <IconButton
          onClick={() => {
            let nPosition = { ...positions };
            nPosition[Math.random()] = {
              id: "",
              position: "",
            };
            onUpdatePosition(nPosition);
          }}
        >
          <AddIcon fontSize="20px" color="primary.main" />
        </IconButton>
      </Stack>

      {Object.keys(positions).map((uid) => {
        return (
          <Stack
            key={uid}
            direction={"row"}
            spacing={6}
            sx={{
              marginTop: "15px",
              alignItems: "center",
            }}
          >
            <StringSelectInput
              value={positions[uid].id}
              onValueChange={(value) => {
                let nPosition = { ...positions };
                nPosition[uid].id = value;
                onUpdatePosition(nPosition);
              }}
              label={"Company"}
              title={"Company"}
              options={companies
                .filter(
                  (a) =>
                    !Object.keys(positions)
                      .filter((a) => a !== uid)
                      .map((a) => positions[a])
                      .map((a) => a.id)
                      .includes(a._id)
                )
                .map((cc) => {
                  return { value: cc._id, title: cc.name };
                })}
              required={true}
              fullwidth={true}
            />

            <StringSelectInput
              value={positions[uid].position}
              onValueChange={(value) => {
                let nPosition = { ...positions };
                nPosition[uid].position = value;
                onUpdatePosition(nPosition);
              }}
              label={"Position"}
              title={"Position"}
              options={
                positions[uid].id &&
                companies.find((cc) => cc._id === positions[uid].id)
                  ? Object.keys(
                      companies.find((cc) => cc._id === positions[uid].id)
                        .positions
                    ).map((pos) => {
                      return { title: pos, value: pos };
                    })
                  : []
              }
              required={true}
              fullwidth={true}
            />

            <IconButton
              onClick={() => {
                let nPosition = { ...positions };
                nPosition = Object.fromEntries(
                  Object.entries(nPosition).filter((a) => a[0] !== uid)
                );
                onUpdatePosition(nPosition);
              }}
            >
              <DeleteIcon />
            </IconButton>
          </Stack>
        );
      })}
    </Stack>
  );
};

export default UserPosition;
