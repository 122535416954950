import { useState } from "react";
import CollectionConcilationAllDatesGraph from "./collectionConcilationAllDates";
import CollectionConcilationSingleDateGraph from "./collectionConcilationSingleDate";
// import CollectionConcilationSingleDateGraph from "./collectionConcilationSingleDate";

const CollectionConcilationGraph = ({ collectionConcilation }) => {
  const [summed, setSummed] = useState({
    Hotel: false,
    Date: false,
    Bar: false,
    View: false,
  });


  return summed.View ? (
    <CollectionConcilationAllDatesGraph
      collectionConcilation={collectionConcilation}
      summed={summed}
      onSumChange={(st) => {
        setSummed(st);
      }}
    />
  ) : (
    <CollectionConcilationSingleDateGraph
      collectionConcilation={collectionConcilation}
      summed={summed}
      onSumChange={(st) => {
        setSummed(st);
      }}
    />
  );
};

export default CollectionConcilationGraph;
