import { Container, Typography } from "@mui/material"

const NoBookings = () => {

    return (
        <Container
        sx={{
            display:"flex",
            justifyContent:"center",
            alignItems:"center",
            height:"100%",
            width:"100%",
            flexGrow:1
        }}
        >
            <Typography variant="h6" color="primary.main">
                No Bookings
            </Typography>
        </Container>
    )

}

export default NoBookings