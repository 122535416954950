import { useContext } from "react";
import { ExpenseContext } from "../../../../store/expensesS";
import {
  Card,
  CircularProgress,
  IconButton,
  Stack,
  Typography,
} from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import Partner from "./Partner";
import AddPartnerModal from "./AddPartnerModel";
import { HotelContext } from "../../../../store/hotel";

const PartnerListWrapper = () => {
  const expenseContext = useContext(ExpenseContext);
  const hotelContext = useContext(HotelContext);

  return (
    <Card
      elevation={3}
      sx={{
        flexDirection: "column",
        justifyContent: "center",
        flexGrow: "1",
        // width: "50%",
        padding: "15px 10px 10px 15px",
        margin: "40px 20px",
        borderRadius: "10px",
      }}
    >
      <Stack
        direction="row"
        sx={{
          flexDirection: "row",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <Typography variant="h1" fontSize="22px">
          Partners
        </Typography>
        <IconButton
          onClick={() => {
            expenseContext.onTogglePartnerAdder()
          }}
        >
          <AddIcon fontSize="20px" color="primary.main" />
        </IconButton>
      </Stack>

      {expenseContext.isPartnersLoading || !expenseContext.isPartnersLoaded ? (
        <CircularProgress
          sx={{
            minHeight: "200px",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            margin: "auto",
          }}
          color="primary"
          size={25}
        />
      ) : (
        <Stack
          style={{
            marginLeft: "10px",
            maxHeight: "400px",
            overflowY: "scroll",
            margin: "12px 0px 15px 15px",
          }}
          spacing={0}
        >
          {expenseContext.partners.map((partner, index) => {
            return <Partner key={index} partner={partner} />;
          })}
        </Stack>
      )}

      {expenseContext.isPartnerAdderOpen && (
        <AddPartnerModal
          hotels={hotelContext.hotels}
          onClose={() => {
            expenseContext.onTogglePartnerAdder();
          }}
          isOpen={expenseContext.isPartnerAdderOpen}
          onAdd={(data) => {
            expenseContext.onAddPartner(data)
          }}
          isLoading={expenseContext.isPartnerAdding}
        />
      )}
    </Card>
  );
};

export default PartnerListWrapper;
