import { Button, List, ListItem, Typography } from "@mui/material";
import allRoutes from "../../pages/routes";

const SideNav = ({ selectedRoute, onRouteChange,isOpen }) => {

  if(!isOpen){
    return
  }
  
  return (
    <List sx={{
      background:"#282828",
    }}>
      {allRoutes.map((route) => {
        return (
          <ListItem
            key={route}
            sx={{
              backgroundColor: selectedRoute === route ? "#111111" : "#282828",
              transition:"background-color 700ms"
            }}
            onClick={() => {
              onRouteChange(route);
            }}
          >
            <Button
              sx={{
                textTransform: "none",
              }}
              onClick={() => {
                onRouteChange(route);
              }}
            >
              <Typography
                variant="body1"
                style={{
                  color: "white",
                  fontSize:"17px"
                }}
              >
                {route}
              </Typography>
            </Button>
          </ListItem>
        );
      })}
    </List>
  );
};

export default SideNav;
