import { KeyboardArrowDown, KeyboardArrowUp } from "@mui/icons-material";
import { Button, Collapse, Typography } from "@mui/material";
import { useState } from "react";
import RevenueReconcilationBookingPreview from "./RevenueReconcilationBookingPreview";

const SingleDateRevenueReconcilationViewer = ({ date, bookings }) => {
  const [isOpen, setIsOpen] = useState(bookings.length <= 4);

  const onToggle = () => {
    setIsOpen((p) => !p);
  };

  return (
    <>
      <Button
        onClick={onToggle}
        disableRipple
        sx={{
          justifyContent: "space-between",
          display: "flex",
          alignItems: "center",
          flexDirection: "row",
          textTransform: "none",
        }}
      >
        <Typography variant="h6" color="primary.main" sx={{ fontSize: "14px" }}>
          {date} - {bookings.length}
        </Typography>

        {isOpen ? (
          <KeyboardArrowUp sx={{ marginLeft: "20px" }} />
        ) : (
          <KeyboardArrowDown sx={{ marginLeft: "20px" }} />
        )}
      </Button>

      <Collapse in={isOpen} timeout="auto" unmountOnExit>
        <div style={{ marginLeft: "10px" }}>
          {bookings
            .sort((a, b) => {
              return Number(a.roomStayed[0]) - Number(b.roomStayed[0]);
            })
            .map((book) => {
              return (
                <RevenueReconcilationBookingPreview
                  key={book.bookingId}
                  booking={book}
                />
              );
            })}
        </div>
      </Collapse>
    </>
  );
};

export default SingleDateRevenueReconcilationViewer;
