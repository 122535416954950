import { Modal, Card, Typography, Button,CircularProgress } from "@mui/material";
import AddExpenserInput from "../../components/common/AddExpenserInput";
import AddExpenseSelector from "../../components/common/AddExpenseSelector";
import AddExpenseDatePicker from "../../components/common/AddExpenseDatePicker";
import { useState } from "react";
// import UnSyncCheckBox from "../../components/colSync/UnSyncCheckBox";
import OxyOsTheme from "../../../../theme/theme";

const Methods = ["Cash", "Bank"];

const ClearPaymentModel = ({ isLoading, onClearPayment, isOpen, onClose,expenseId }) => {
  const [clearData, setClearData] = useState({
    amount: "0",
    method: "Cash",
    date: "",
    note: "",
    isManaged: false,
    expenseId
  });

  return (
    <>
      <Modal
        open={isOpen}
        onClose={onClose}
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Card
          sx={{
            flexDirection: "row",
            minHeight: "500px",
            maxHeight: "600px",
            maxWidth: "700px",
            minWidth: "500px",
            background: "#FFF",
            borderRadius: "10px",
            padding: "20px 30px",
            overflowY: "scroll",
            "::-webkit-scrollbar": {
              display: "none",
            },
            border: "none",
          }}
        >
          <Typography
            variant="h1"
            sx={{
              width: "100%",
              textAlign: "center",
              marginBottom: "40px",
              marginTop: "10px",
            }}
            style={{
              textShadow: "0px 1px 1px rgba(0,0,0,0.24)",
            }}
            fontSize={"25px"}
          >
            Clear Payment
          </Typography>

          <AddExpenserInput
            value={clearData.amount}
            onValueChange={(val) => {
              setClearData((p) => {
                return { ...p, amount: val };
              });
            }}
            label="Amount"
            type="text"
            required={true}
          />

          <AddExpenseSelector
            options={Methods}
            value={clearData.method}
            onValueChange={(val) => {
              setClearData((p) => {
                return { ...p, method: val };
              });
            }}
            required={true}
            label={"Choose Payment Type"}
          />

          <AddExpenseDatePicker
            date={clearData.date}
            onDateChange={(date) => {
              setClearData((p) => {
                return {
                  ...p,
                  date: date,
                };
              });
            }}
          />

          <AddExpenserInput
            value={clearData.note}
            onValueChange={(val) => {
              setClearData((p) => {
                return { ...p, note: val };
              });
            }}
            label="Note"
            type="text"
            required={true}
          />

          {/* <UnSyncCheckBox
            isChecked={clearData.isManaged}
            onToggle={() => {
              setClearData((c) => {
                return {
                  ...c,
                  isManaged: !c.isManaged,
                };
              });
            }}
            label={"Managed"}
          /> */}

          <Button
            variant="outlined"
            size="large"
            sx={{
              backgroundColor: "#262626",
              borderRadius: "30px",
              padding: "10px 15px",
              margin: "20px auto",
              display: "block",
              color: OxyOsTheme.palette.background.main,
              "&:hover": {
                backgroundColor: OxyOsTheme.palette.primary.main,
                color: OxyOsTheme.palette.background.main,
                opacity: 0.6,
              },
            }}
            onClick={() => {
              if (!isLoading) {
                onClearPayment(clearData);
              }
            }}
          >
            {isLoading ? (
              <CircularProgress color="background" />
            ) : (
              <Typography variant="h6">Clear Payment</Typography>
            )}
          </Button>
        </Card>
      </Modal>
    </>
  );
};

export default ClearPaymentModel;
