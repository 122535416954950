import {
  InputLabel,
  MenuItem,
  Select,
  Typography,
} from "@mui/material";

const AddExpenserHotelSelect = ({ value, onValueChange, required,hotels,title }) => {



  return (
    <>
      <InputLabel
        variant="standard"
        sx={{
          width: "100%",
          margin: "3px 0px",
        }}
      >
        <Typography variant="h6" color="primary" fontSize="15px">
          {title ? title : "Choose Hotel"}
        </Typography>
      </InputLabel>
      <Select
        fullWidth
        value={value}
        onChange={(e) => {
          onValueChange(e.target.value);
        }}
        variant="outlined"
        margin="none"
        label="Hotel"
        sx={{
          margin: "8px 0px",
        }}
        required={Boolean(required)}
        style={{
          marginLeft: "1px",
        }}
      >
        {hotels.map((perm) => {
          return <MenuItem key={perm._id} value={perm._id}>{perm.hotelName}</MenuItem>;
        })}
      </Select>
    </>
  );
};

export default AddExpenserHotelSelect;
