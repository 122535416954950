import React, { useState, useEffect } from "react";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  BarElement,
} from "chart.js";
import { Line, Bar } from "react-chartjs-2";
import Graph from "../util/graph";
import HotelChooser from "../components/HotelChooser";
import { Stack } from "@mui/material";
import HotelSwitch from "../components/HotelSwitch";
import labelFinder from "../util/labelFinder";
import HotelGroupSwitchContent from "../components/HotelGroupSwitchContent";
import moment from "moment-timezone";
import feedbackGraphDataFinder from "./feedbackGraphDataFinder";

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  BarElement,
  Title,
  Tooltip,
  Legend
);

const FeedBackGraph = ({
  feedbackAnalytics,
  title,
  allOptions,
  isTablularData,
  onToggleTabularData,
  isBar,
  isHotel,
  isDate,
  isAvg,
  onToggleBar,
  onToggleHotel,
  onToggleDate,
  onToggleAverage
}) => {

  const [sHotels, setSHotels] = useState([]);
  const hotels = Object.keys(feedbackAnalytics);
  let allDates = Object.keys(feedbackAnalytics[hotels[0]]);
  const [options, setOptions] = useState(allOptions);

  const [data, setData] = useState({});

  useEffect(() => {
    let hotels = Object.keys(feedbackAnalytics);
    setSHotels(hotels);
    setOptions(allOptions);
  }, [feedbackAnalytics, allOptions]);

  useEffect(() => {
    const hotels = Object.keys(feedbackAnalytics);
    let allDates = Object.keys(feedbackAnalytics[hotels[0]]);
    let datasets = feedbackGraphDataFinder(
      feedbackAnalytics,
      allDates,
      isHotel,
      isDate,
      sHotels,
      options,
      undefined,
      undefined,
      isBar,
      isTablularData,
      isAvg
    );
    let labels = labelFinder(
      allDates.map((dt) => moment(dt, "DD-MM-YYYY").format("DD-MMM")),
      isHotel,
      isDate,
      sHotels
    );
    setData({ labels, datasets });
  }, [
    feedbackAnalytics,
    isDate,
    isHotel,
    sHotels,
    options,
    isBar,
    isTablularData,
    isAvg
  ]);

  return (
    <div style={{ margin: "20px", paddingTop: "30px", position: "relative" }}>
      <Stack
        style={{
          position: "absolute",
          top: "-10px",
          right: "10px",
        }}
        direction="row"
        spacing={1}
      >
        <HotelGroupSwitchContent
          content={
            <>
              <HotelSwitch
                isChecked={!isTablularData}
                label={"Data"}
                onChange={(v) => {
                  onToggleTabularData();
                }}
              />

              <HotelSwitch
                isChecked={isBar}
                label={"Bar"}
                onChange={(v) => {
                  onToggleBar();
                }}
              />

              {hotels.length > 1 ? (
                <HotelSwitch
                  isChecked={isHotel}
                  label={"Hotel"}
                  onChange={(v) => {
                    onToggleHotel();
                  }}
                />
              ) : (
                <></>
              )}

              {allDates.length > 1 ? (
                <HotelSwitch
                  isChecked={isDate}
                  label={"Date"}
                  onChange={(v) => {
                    onToggleDate();
                  }}
                />
              ) : (
                <></>
              )}

              <HotelSwitch
                isChecked={isAvg}
                label={"Avg"}
                onChange={(v) => {
                  onToggleAverage();
                }}
              />
            </>
          }
        />

        <HotelChooser
          hotels={allOptions}
          selectedHotels={options}
          onHotelsChange={(o) => {
            setOptions(o);
          }}
          width="80px"
        />

        <HotelChooser
          hotels={hotels}
          selectedHotels={sHotels}
          onHotelsChange={(s) => {
            setSHotels(s);
          }}
          width="200px"
        />
      </Stack>

      {data &&
        data.datasets &&
        data.datasets.length > 0 &&
        (isBar ? (
          <Bar
            style={{ marginTop: "20px" }}
            options={Graph.BarOptions(
              title,
              true,
              data.datasets.reduce((acc, cur) => {
                if (!acc[cur.stack]) {
                  let nAcc = { ...acc };
                  nAcc[cur.stack] = [cur];
                  return nAcc;
                } else {
                  let nAcc = { ...acc };
                  nAcc[cur.stack] = [...nAcc[cur.stack], cur];
                  return nAcc;
                }
              }, {})
            )}
            data={data}
          />
        ) : (
          <Line
            style={{ marginTop: "20px" }}
            options={Graph.Options(title)}
            data={data}
          />
        ))}
    </div>
  );
};

export default FeedBackGraph;
