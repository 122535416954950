import './App.css';
import LoginPage from './pages/LoginPage';
import MobilePage from './pages/MobilePage';
import AuthContextProvider from './store/auth';
import { useMediaQuery } from "@mui/material"

function App() {

  const isValid = useMediaQuery(`(min-width:1000px)`)

  return isValid === true ? (<AuthContextProvider> <LoginPage /> </AuthContextProvider>) : <MobilePage />
}

export default App;
