import {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";
import { HotelContext } from "./hotel";
import { AuthContext } from "./auth";
import Constant from "../constant/Constant";
import axios from "axios";
import GetExpenser from "../utils/expense/GetExpenser";
import GetExpenserWithToken from "../utils/expense/GetExpenserWithToken";
import moment from "moment";
import allRoutes from "../pages/routes";

const expenseStartIndex = 6;

export const ExpenseContext = createContext({
  partners: [],
  isPartnersLoading: false,
  isPartnersLoaded: false,
  partnersConf: "",
  isPartnerAdding: false,
  isPartnerAdderOpen: false,
  onTogglePartnerAdder: () => {},
  onAddPartner: async () => {},

  isPreviousFinancialYear: false,


  expensers: [],
  isExpensersLoading: false,
  isExpensersLoaded: false,
  expenserConf: "",

  amounts: [],
  isAmountsLoading: false,
  isAmountsLoaded: false,
  amountConf: "",

  approvals: [],
  sApprovalId: undefined,
  isApprovalApproving: false,
  isApprovalsLoading: false,
  isApprovalsLoaded: false,
  approvalsConf: "",

  approveds: [],
  sApprovedId: undefined,
  isPaymentAdding: false,
  isPaymentAdderOpen: false,
  isApprovedsLoading: false,
  isApprovedsLoaded: false,
  approvedsConf: "",

  advances: [],
  sAdvanceId: undefined,
  isAdvanceClearing: false,
  isAdvanceClearerOpen: false,
  isAdvancesLoading: false,
  isAdvancessLoaded: false,
  advancesConf: "",

  onSelectAdvanceId: (id) => {},
  onToggleAdvanceClearer: () => {},

  isAmountAdderOpen: false,
  isAmountAdding: false,
  onAddAmount: async (data) => {},
  onToggleAmountAdder: () => {},

  isExpenserAdderOpen: false,
  isExpenserAdding: false,
  onAddExpenser: async (data) => {},
  onToggleExpenserAdder: () => {},

  isExpenseAdderOpen: false,
  isExpenseAdding: false,
  onAddExpense: async (data) => {},
  onToggleExpenseAdder: () => {},
  onSelectApproval: (id) => {},
  onApproveApproval: async (data) => {},
  onTogglePaymentAdder: () => {},
  onAddPayment: async (data) => {},
  onClearPayment: async (data) => {},

  onSelectApprovedId: (id) => {},

  isError: false,
  errorMessage: "",
  onClearMessage: () => {},

  isTransactionsAnalyticsLoaded: false,
  isTransactionsAnalyticsLoading: false,
  transactionAnalytics: {},
  transactionAnalyticsConf: "",

  isProfitAndLossLoaded: false,
  isProfitAndLossLoading: false,
  profitAndLossAnalytics: {},
  profitAndLossAnalyticsConf: "",

  startDay: "01-02-2024",
  days: 18,
  isTable: false,
  onDaysUpdate: (date, days) => {},

  deletingTransactionId: undefined,
  onDeleteTransaction: async (data) => {},

  isBar: false,
  isHotel: false,
  isDate: false,
  isPercentage: false,
  isAverage: false,

  onToggleTabularData: () => {},
  onToggleBar: () => {},
  onToggleHotel: () => {},
  onToggleDate: () => {},
  onTogglePercentage: () => {},
  onToggleAverage: () => {},

  balanceSheetData: {
    entity: "",
    hotelId: "",
    balances: {},
    opening: {},
  },
  onBalanceSheetDataUpdate: () => {},
  onBalanceSelectedEntityChange: (entity, transctions) => {},

  isBalanceSheetUpdating: false,
  onBalanceSheetUpdate: async (data) => {},

  onToggleFinancialYear: () => {},
});

const ExpenseContextProvider = ({ children, selRoute }) => {
  const hotelContext = useContext(HotelContext);
  const authContext = useContext(AuthContext);

  const [data, setData] = useState({
    partners: [],
    isPartnersLoading: false,
    isPartnersLoaded: false,
    partnersConf: "",
    isPartnerAdding: false,
    isPartnerAdderOpen: false,

    expensers: [],
    isExpensersLoading: false,
    isExpensersLoaded: false,
    expenserConf: "",

    amounts: [],
    isAmountsLoading: false,
    isAmountsLoaded: false,
    amountConf: "",

    approvals: [],
    sApprovalId: undefined,
    isApprovalApproving: false,
    isApprovalsLoading: false,
    isApprovalsLoaded: false,
    approvalsConf: "",

    approveds: [],
    sApprovedId: undefined,
    isPaymentAdding: false,
    isPaymentAdderOpen: false,
    isApprovedsLoading: false,
    isApprovedsLoaded: false,
    approvedsConf: "",

    isAmountAdderOpen: false,
    isAmountAdding: false,

    isExpenserAdderOpen: false,
    isExpenserAdding: false,

    isExpenseAdderOpen: false,
    isExpenseAdding: false,

    isError: false,
    errorMessage: "",

    advances: [],
    sAdvanceId: undefined,
    isAdvanceClearing: false,
    isAdvanceClearerOpen: false,
    isAdvancesLoading: false,
    isAdvancessLoaded: false,
    advancesConf: "",

    isProfitAndLossLoaded: false,
    isProfitAndLossLoading: false,
    profitAndLossAnalytics: {},
    profitAndLossAnalyticsConf: "",

    isTransactionsAnalyticsLoaded: false,
    isTransactionsAnalyticsLoading: false,
    transactionAnalytics: {},
    transactionAnalyticsConf: "",

    isBar: false,
    isHotel: false,
    isDate: false,
    isPercentage: false,
    isAverage: false,

    deletingTransactionId: undefined,

    balanceSheetData: {
      hotelId: "",
      entity: "",
      balances: {},
      opening: {},
    },
    isBalanceSheetUpdating: false,

    isPreviousFinancialYear: false,

    startDay: "01-04-2024",
    days: 30,
    isTable: false,
  });

  const partnersCancelToken = useRef(null);
  const expensersCancelToken = useRef(null);
  const amountsCancelToken = useRef(null);
  const approvalsCancelToken = useRef(null);
  const approvedsCancelToken = useRef(null);
  const transactionAnalyticsToken = useRef(null);
  const profitAndLossAnalyticsToken = useRef(null);
  const advancesCancelToken = useRef(null);

  const loadPartners = useCallback(
    async (conf) => {
      if (conf === `${hotelContext.selctedHotelId}`) {
        return;
      }

      setData((e) => {
        return {
          ...e,
          isPartnersLoaded: false,
          isPartnersLoading: true,
          partners: [],
        };
      });

      if (partnersCancelToken.current && partnersCancelToken.current.token) {
        partnersCancelToken.current.cancel();
      }

      partnersCancelToken.current = axios.CancelToken.source();

      const data = await GetExpenserWithToken(
        Constant.getHotelPartnersRoute,
        hotelContext.selctedHotelId,
        authContext.authToken,
        {},
        partnersCancelToken.current.token
      );
      if (!data.status) {
        setData((e) => {
          return {
            ...e,
            isPartnersLoaded: false,
            isPartnersLoading: false,
            errorMessage: data.message,
            isError: true,
          };
        });
      } else {
        setData((e) => {
          return {
            ...e,
            isPartnersLoaded: true,
            isPartnersLoading: false,
            errorMessage: "Partners Updated Successfully.",
            isError: true,
            partners: data.data,
            partnersConf: `${hotelContext.selctedHotelId}`,
          };
        });
      }
    },
    [hotelContext.selctedHotelId, authContext.authToken]
  );

  const loadExpensers = useCallback(
    async (conf) => {
      if (conf === `${hotelContext.selctedHotelId}`) {
        return;
      }

      setData((e) => {
        return {
          ...e,
          isExpensersLoaded: false,
          isExpensersLoading: true,
          expensrs: [],
        };
      });

      if (expensersCancelToken.current && expensersCancelToken.current.token) {
        expensersCancelToken.current.cancel();
      }

      expensersCancelToken.current = axios.CancelToken.source();

      const data = await GetExpenserWithToken(
        Constant.getExpensersRoute,
        hotelContext.selctedHotelId,
        authContext.authToken,
        {},
        expensersCancelToken.current.token
      );
      if (!data.status) {
        setData((e) => {
          return {
            ...e,
            isExpensersLoaded: false,
            isExpensersLoading: false,
            errorMessage: data.message,
            isError: true,
          };
        });
      } else {
        setData((e) => {
          return {
            ...e,
            isExpensersLoaded: true,
            isExpensersLoading: false,
            errorMessage: "Expensers Updated Successfully.",
            isError: true,
            expensers: data.data,
            expenserConf: `${hotelContext.selctedHotelId}`,
          };
        });
      }
    },
    [hotelContext.selctedHotelId, authContext.authToken]
  );

  const loadExpenses = useCallback(
    async (conf) => {
      if (conf === `${hotelContext.selctedHotelId}`) {
        return;
      }

      setData((e) => {
        return {
          ...e,
          isApprovalsLoaded: false,
          isApprovalsLoading: true,
          approvals: [],
        };
      });

      if (approvalsCancelToken.current && approvalsCancelToken.current.token) {
        approvalsCancelToken.current.cancel();
      }

      approvalsCancelToken.current = axios.CancelToken.source();

      const data = await GetExpenserWithToken(
        Constant.getApprovalsRoute,
        hotelContext.selctedHotelId,
        authContext.authToken,
        {},
        approvalsCancelToken.current.token
      );
      if (!data.status) {
        setData((e) => {
          return {
            ...e,
            isApprovalsLoaded: false,
            isApprovalsLoading: false,
            errorMessage: data.message,
            isError: true,
          };
        });
      } else {
        setData((e) => {
          return {
            ...e,
            isApprovalsLoaded: true,
            isApprovalsLoading: false,
            errorMessage: "Approvals Updated Successfully.",
            isError: true,
            approvals: data.data,
            approvalsConf: `${hotelContext.selctedHotelId}`,
          };
        });
      }
    },
    [hotelContext.selctedHotelId, authContext.authToken]
  );

  const loadAdvances = useCallback(
    async (conf) => {
      if (conf === `${hotelContext.selctedHotelId}`) {
        return;
      }

      setData((e) => {
        return {
          ...e,
          isAdvancessLoaded: false,
          isAdvancesLoading: true,
          advances: [],
        };
      });

      if (advancesCancelToken.current && advancesCancelToken.current.token) {
        advancesCancelToken.current.cancel();
      }

      advancesCancelToken.current = axios.CancelToken.source();

      const data = await GetExpenserWithToken(
        Constant.getPendingAdvancesRoute,
        hotelContext.selctedHotelId,
        authContext.authToken,
        {},
        advancesCancelToken.current.token
      );
      if (!data.status) {
        setData((e) => {
          return {
            ...e,
            isAdvancessLoaded: false,
            isAdvancesLoading: false,
            errorMessage: data.message,
            isError: true,
          };
        });
      } else {
        setData((e) => {
          return {
            ...e,
            isAdvancessLoaded: true,
            isAdvancesLoading: false,
            errorMessage: "Advances Updated Successfully.",
            isError: true,
            advances: data.data,
            advancesConf: `${hotelContext.selctedHotelId}`,
          };
        });
      }
    },
    [hotelContext.selctedHotelId, authContext.authToken]
  );

  const onToggleAmountAdder = useCallback(() => {
    setData((e) => {
      return {
        ...e,
        isAmountAdderOpen: !e.isAmountAdderOpen,
      };
    });
  }, []);

  const onToggleExpenserAdder = useCallback(() => {
    setData((e) => {
      return {
        ...e,
        isExpenserAdderOpen: !e.isExpenserAdderOpen,
      };
    });
  }, []);

  const onTogglePartnerAdder = useCallback(() => {
    setData((e) => {
      return {
        ...e,
        isPartnerAdderOpen: !e.isPartnerAdderOpen,
      };
    });
  }, []);

  const onToggleExpenseAdder = useCallback(() => {
    setData((e) => {
      return {
        ...e,
        isExpenseAdderOpen: !e.isExpenseAdderOpen,
      };
    });
  }, []);

  const onAddPartner = useCallback(
    async (rData) => {
      setData((e) => {
        return { ...e, isPartnerAdding: true };
      });
      const data = await GetExpenser(
        Constant.addHotelPartnerRoute,
        hotelContext.selctedHotelId,
        authContext.authToken,
        rData
      );
      if (!data.status) {
        setData((e) => {
          return {
            ...e,
            isError: true,
            errorMessage: data.message,
            isPartnerAdding: false,
          };
        });
      } else {
        setData((e) => {
          return {
            ...e,
            isError: true,
            errorMessage: "Partner Added Successfully.",
            isPartnerAdding: false,
            isPartnerAdderOpen: false,
            partners: [...e.partners, data.data],
          };
        });
      }
    },
    [authContext.authToken, hotelContext.selctedHotelId]
  );

  const onAddExpenser = useCallback(
    async (rData) => {
      setData((e) => {
        return { ...e, isExpenserAdding: true };
      });
      const data = await GetExpenser(
        Constant.addExpenserRoute,
        hotelContext.selctedHotelId,
        authContext.authToken,
        rData
      );
      if (!data.status) {
        setData((e) => {
          return {
            ...e,
            isError: true,
            errorMessage: data.message,
            isExpenserAdding: false,
          };
        });
      } else {
        setData((e) => {
          return {
            ...e,
            isError: true,
            errorMessage: "Expenser Added Successfully.",
            isExpenserAdding: false,
            isExpenserAdderOpen: false,
            expensers: [...e.expensers, data.data],
          };
        });
      }
    },
    [authContext.authToken, hotelContext.selctedHotelId]
  );

  const onAddAmount = useCallback(
    async (rData) => {
      setData((e) => {
        return { ...e, isAmountAdding: true };
      });
      const data = await GetExpenser(
        Constant.addSourceRoute,
        hotelContext.selctedHotelId,
        authContext.authToken,
        rData,
        Constant.FINANCIAL_YEAR
      );
      if (!data.status) {
        setData((e) => {
          return {
            ...e,
            isError: true,
            errorMessage: data.message,
            isAmountAdding: false,
          };
        });
      } else {
        setData((e) => {
          return {
            ...e,
            isError: true,
            errorMessage: "Source Added Successfully.",
            isAmountAdding: false,
            isAmountAdderOpen: false,
          };
        });
      }
    },
    [authContext.authToken, hotelContext.selctedHotelId]
  );

  const onAddExpense = useCallback(
    async (rData) => {
      setData((e) => {
        return { ...e, isExpenseAdding: true };
      });
      const data = await GetExpenser(
        Constant.addExpenseRoute,
        hotelContext.selctedHotelId,
        authContext.authToken,
        rData
      );
      if (!data.status) {
        setData((e) => {
          return {
            ...e,
            isError: true,
            errorMessage: data.message,
            isExpenseAdding: false,
          };
        });
      } else {
        setData((e) => {
          return {
            ...e,
            isError: true,
            errorMessage: "Expense Added Successfully.",
            isExpenseAdding: false,
            isExpenseAdderOpen: false,
            approvals: [...e.approvals, data.data],
          };
        });
      }
    },
    [authContext.authToken, hotelContext.selctedHotelId]
  );

  const onAddPayment = useCallback(
    async (rData) => {
      setData((e) => {
        return { ...e, isPaymentAdding: true };
      });
      const data = await GetExpenser(
        Constant.addPaymentToExpenseRoute,
        hotelContext.selctedHotelId,
        authContext.authToken,
        rData
      );
      if (!data.status) {
        setData((e) => {
          return {
            ...e,
            isError: true,
            errorMessage: data.message,
            isPaymentAdding: false,
          };
        });
      } else {
        setData((e) => {
          return {
            ...e,
            isError: true,
            errorMessage: "Payment Added Successfully.",
            isPaymentAdding: false,
            isPaymentAdderOpen: false,
          };
        });
      }
    },
    [authContext.authToken, hotelContext.selctedHotelId]
  );

  const onDeleteTransaction = useCallback(
    async (transId) => {
      setData((e) => {
        return { ...e, deletingTransactionId: transId };
      });
      const data = await GetExpenser(
        Constant.deleteTransaction,
        hotelContext.selctedHotelId,
        authContext.authToken,
        {
          transId,
        }
      );
      if (!data.status) {
        setData((e) => {
          return {
            ...e,
            isError: true,
            errorMessage: data.message,
            deletingTransactionId: undefined,
          };
        });
      } else {
        setData((e) => {
          return {
            ...e,
            isError: true,
            errorMessage: "Transaction deleted Successfully.",
            deletingTransactionId: undefined,
          };
        });
      }
    },
    [authContext.authToken, hotelContext.selctedHotelId]
  );

  const onClearPayment = useCallback(
    async (rData) => {
      setData((e) => {
        return { ...e, isAdvanceClearing: true };
      });
      const data = await GetExpenser(
        Constant.clearPendingAdvancesRoute,
        hotelContext.selctedHotelId,
        authContext.authToken,
        rData
      );
      if (!data.status) {
        setData((e) => {
          return {
            ...e,
            isError: true,
            errorMessage: data.message,
            isAdvanceClearing: false,
          };
        });
      } else {
        setData((e) => {
          return {
            ...e,
            isError: true,
            errorMessage: "Advance Cleared Successfully.",
            isAdvanceClearing: false,
            isAdvanceClearerOpen: false,
          };
        });
      }
    },
    [authContext.authToken, hotelContext.selctedHotelId]
  );

  const onBalanceSheetUpdate = useCallback(
    async (rData) => {
      setData((e) => {
        return { ...e, isBalanceSheetUpdating: true };
      });
      const data = await GetExpenser(
        Constant.balanceSheetBuilderRoute,
        hotelContext.selctedHotelId,
        authContext.authToken,
        rData
      );
      if (!data.status) {
        setData((e) => {
          return {
            ...e,
            isError: true,
            errorMessage: data.message,
            isBalanceSheetUpdating: false,
          };
        });
      } else {
        setData((e) => {
          return {
            ...e,
            isError: true,
            errorMessage: "Balance Sheet Added Successfully.",
            isBalanceSheetUpdating: false,
          };
        });
      }
    },
    [authContext.authToken, hotelContext.selctedHotelId]
  );

  const onApproveApproval = useCallback(
    async (rData) => {
      setData((e) => {
        return { ...e, isApprovalApproving: true };
      });
      const data = await GetExpenser(
        Constant.approveExpenseRoute,
        hotelContext.selctedHotelId,
        authContext.authToken,
        rData
      );
      if (!data.status) {
        setData((e) => {
          return {
            ...e,
            isError: true,
            errorMessage: data.message,
            isApprovalApproving: false,
          };
        });
      } else {
        setData((e) => {
          return {
            ...e,
            isError: true,
            errorMessage: "Approval Updated Successfully.",
            isApprovalApproving: false,
            sApprovalId: null,
            approvals: e.approvals.filter((ex) => ex.id !== rData.id),
            //approved pass
          };
        });
      }
    },
    [authContext.authToken, hotelContext.selctedHotelId]
  );

  const loadAmounts = useCallback(
    async (conf) => {
      if (conf === `${hotelContext.selctedHotelId}`) {
        return;
      }

      setData((e) => {
        return {
          ...e,
          isAmountsLoaded: false,
          isAmountsLoading: true,
          amounts: [],
        };
      });

      if (amountsCancelToken.current && amountsCancelToken.current.token) {
        amountsCancelToken.current.cancel();
      }

      amountsCancelToken.current = axios.CancelToken.source();

      const data = await GetExpenserWithToken(
        Constant.getAmountsRoute,
        hotelContext.selctedHotelId,
        authContext.authToken,
        {},
        amountsCancelToken.current.token,
        Constant.FINANCIAL_YEAR
      );
      if (!data.status) {
        setData((e) => {
          return {
            ...e,
            isAmountsLoaded: false,
            isAmountsLoading: false,
            errorMessage: data.message,
            isError: true,
          };
        });
      } else {
        setData((e) => {
          return {
            ...e,
            isAmountsLoaded: true,
            isAmountsLoading: false,
            errorMessage: "Amounts Updated Successfully.",
            isError: true,
            amounts: data.data,
            amountConf: `${hotelContext.selctedHotelId}`,
          };
        });
      }
    },
    [hotelContext.selctedHotelId, authContext.authToken]
  );

  const loadApproveds = useCallback(
    async (conf) => {
      if (conf === `${hotelContext.selctedHotelId}`) {
        return;
      }

      setData((e) => {
        return {
          ...e,
          isApprovedsLoaded: false,
          isApprovedsLoading: true,
          approveds: [],
        };
      });

      if (approvedsCancelToken.current && approvedsCancelToken.current.token) {
        approvedsCancelToken.current.cancel();
      }

      approvedsCancelToken.current = axios.CancelToken.source();

      const data = await GetExpenserWithToken(
        Constant.getApprovedExpensesRoute,
        hotelContext.selctedHotelId,
        authContext.authToken,
        {},
        approvedsCancelToken.current.token
      );
      if (!data.status) {
        setData((e) => {
          return {
            ...e,
            isApprovedsLoaded: false,
            isApprovedsLoading: false,
            errorMessage: data.message,
            isError: true,
          };
        });
      } else {
        setData((e) => {
          return {
            ...e,
            isApprovedsLoaded: true,
            isApprovedsLoading: false,
            errorMessage: "Approveds Updated Successfully.",
            isError: true,
            approveds: data.data,
            approvedsConf: `${hotelContext.selctedHotelId}`,
          };
        });
      }
    },
    [hotelContext.selctedHotelId, authContext.authToken]
  );

  const loadTransactionsAnalytics = useCallback(
    async (startDay, days, isTable, conf, identifier) => {
      if (
        conf ===
        `${hotelContext.selctedHotelId}${startDay}${days}${isTable}${identifier}`
      ) {
        return;
      }

      setData((e) => {
        return {
          ...e,
          isTransactionsAnalyticsLoaded: false,
          isTransactionsAnalyticsLoading: true,
          transactionAnalytics: {},
        };
      });

      if (
        transactionAnalyticsToken.current &&
        transactionAnalyticsToken.current.token
      ) {
        transactionAnalyticsToken.current.cancel();
      }

      transactionAnalyticsToken.current = axios.CancelToken.source();

      const data = await GetExpenserWithToken(
        Constant.getTrasactionsAnalyticsRoute,
        hotelContext.selctedHotelId,
        authContext.authToken,
        {
          startDay,
          days,
          isTable,
        },
        transactionAnalyticsToken.current.token,
        identifier
      );
      if (!data.status) {
        setData((e) => {
          return {
            ...e,
            isTransactionsAnalyticsLoaded: false,
            isTransactionsAnalyticsLoading: false,
            errorMessage: data.message,
            isError: true,
          };
        });
      } else {
        setData((e) => {
          return {
            ...e,
            isTransactionsAnalyticsLoaded: true,
            isTransactionsAnalyticsLoading: false,
            errorMessage: "Transaction Analytics Updated Successfully.",
            isError: true,
            transactionAnalytics: data.data,
            transactionAnalyticsConf: `${hotelContext.selctedHotelId}${startDay}${days}${isTable}`,
          };
        });
      }
    },
    [hotelContext.selctedHotelId, authContext.authToken]
  );

  const loadProfitAndLossAnalytics = useCallback(
    async (startDay, days, isTable, conf) => {
      if (
        conf === `${hotelContext.selctedHotelId}${startDay}${days}${isTable}`
      ) {
        return;
      }

      setData((e) => {
        return {
          ...e,
          isProfitAndLossLoaded: false,
          isProfitAndLossLoading: true,
          profitAndLossAnalytics: {},
        };
      });

      if (
        profitAndLossAnalyticsToken.current &&
        profitAndLossAnalyticsToken.current.token
      ) {
        profitAndLossAnalyticsToken.current.cancel();
      }

      profitAndLossAnalyticsToken.current = axios.CancelToken.source();

      const data = await GetExpenserWithToken(
        Constant.getProfitAndLossAnalyticsRoute,
        hotelContext.selctedHotelId,
        authContext.authToken,
        {
          startDay,
          days,
          isTable,
        },
        profitAndLossAnalyticsToken.current.token
      );
      if (!data.status) {
        setData((e) => {
          return {
            ...e,
            isProfitAndLossLoaded: false,
            isProfitAndLossLoading: false,
            errorMessage: data.message,
            isError: true,
          };
        });
      } else {
        console.log(data.data);
        setData((e) => {
          return {
            ...e,
            isProfitAndLossLoaded: true,
            isProfitAndLossLoading: false,
            errorMessage: "Profit And Loss Analytics Updated Successfully.",
            isError: true,
            profitAndLossAnalytics: data.data,
            profitAndLossAnalyticsConf: `${hotelContext.selctedHotelId}${startDay}${days}${isTable}`,
          };
        });
      }
    },
    [hotelContext.selctedHotelId, authContext.authToken]
  );

  const onDaysUpdate = useCallback((date, days) => {
    setData((e) => {
      return {
        ...e,
        startDay: date,
        days: days,
        balanceSheetData: {
          entity: "",
          balances: {},
          opening: {},
          hotelId: "",
        },
      };
    });
  }, []);

  useEffect(() => {
    if (selRoute === allRoutes[expenseStartIndex]) {
      loadAmounts(data.amountConf);
    }
  }, [selRoute, data.amountConf, loadAmounts]);

  // useEffect(() => {
  //   if (selRoute === allRoutes[expenseStartIndex]) {
  //     loadAdvances(data.advancesConf);
  //   }
  // }, [selRoute, data.advancesConf, loadAdvances]);

  useEffect(() => {
    if (selRoute === allRoutes[expenseStartIndex]) {
      loadExpensers(data.expenserConf);
    }
  }, [selRoute, data.expenserConf, loadExpensers]);

  useEffect(() => {
    if (selRoute === allRoutes[expenseStartIndex]) {
      loadPartners(data.partnersConf);
    }
  }, [selRoute, data.partnersConf, loadPartners]);

  useEffect(() => {
    if (selRoute === allRoutes[expenseStartIndex]) {
      loadApproveds(data.approvedsConf);
    }
  }, [selRoute, data.approvedsConf, loadApproveds]);

  useEffect(() => {
    if (selRoute === allRoutes[expenseStartIndex]) {
      loadExpenses(data.approvalsConf);
    }
  }, [selRoute, data.approvalsConf, loadExpenses]);

  useEffect(() => {
    if (selRoute === allRoutes[expenseStartIndex + 5]) {
      loadProfitAndLossAnalytics(
        data.startDay,
        data.days,
        data.isTable,
        data.profitAndLossAnalyticsConf
      );
    }
  }, [
    selRoute,
    data.profitAndLossAnalyticsConf,
    loadProfitAndLossAnalytics,
    data.startDay,
    data.days,
    data.isTable,
  ]);

  useEffect(() => {
    if (
      selRoute === allRoutes[expenseStartIndex + 2] ||
      selRoute === allRoutes[expenseStartIndex + 3]
    ) {
      loadTransactionsAnalytics(
        data.startDay,
        data.days,
        data.isTable,
        data.transactionAnalyticsConf,
        "2024"
      );
    }
  }, [
    selRoute,
    data.transactionAnalyticsConf,
    loadTransactionsAnalytics,
    data.startDay,
    data.days,
    data.isTable,
  ]);

  const onClearMessage = useCallback(() => {
    setData((e) => {
      return {
        ...e,
        isError: false,
        errorMessage: "",
      };
    });
  }, []);

  const onSelectApproval = useCallback((id) => {
    setData((e) => {
      return {
        ...e,
        sApprovalId: id,
      };
    });
  }, []);

  const onSelectApprovedId = useCallback((id) => {
    setData((e) => {
      return {
        ...e,
        sApprovedId: id,
      };
    });
  }, []);

  const onSelectAdvanceId = useCallback((id) => {
    setData((e) => {
      return {
        ...e,
        sAdvanceId: id,
      };
    });
  }, []);

  const onTogglePaymentAdder = useCallback(() => {
    setData((e) => {
      return {
        ...e,
        isPaymentAdderOpen: !e.isPaymentAdderOpen && Boolean(e.sApprovedId),
      };
    });
  }, []);

  const onToggleAdvanceClearer = useCallback(() => {
    setData((e) => {
      return {
        ...e,
        isAdvanceClearerOpen: !e.isAdvanceClearerOpen && Boolean(e.sAdvanceId),
      };
    });
  }, []);

  const onBalanceSelectedEntityChange = useCallback(
    (entity, transactions) => {
      if (entity) {
        let values = Object.keys(transactions[entity].transctions).map(
          (date) => {
            return [
              date,
              {
                cashLoanToOxyCorporations: "0",
                bankLoanToOxyCorporations: "0",
                revenueToKotakBank: "0",
                expenses: [],
              },
            ];
          }
        );

        let fDate = Object.keys(transactions[entity].transctions)
          .map((dt) => {
            return moment.tz(dt, "DD-MMM", "Asia/Kolkata");
          })
          .sort((a, b) => {
            return a.unix() - b.unix();
          })[0]
          .format("DD-MMM");

        const open =
          transactions[entity].transctions[fDate].balances.OpeningBalance;
        const opening = open ? open : {};

        setData((exp) => {
          return {
            ...exp,
            balanceSheetData: {
              balances: Object.fromEntries(values),
              opening,
              entity,
              hotelId: hotelContext.hotels.find(
                (hot) => hot.hotelName === entity
              )._id,
            },
          };
        });
      }
    },
    [hotelContext.hotels]
  );

  const onBalanceSheetDataUpdate = useCallback((data) => {
    setData((p) => {
      return {
        ...p,
        balanceSheetData: data,
      };
    });
  }, []);

  const onToggleTabularData = useCallback(() => {
    setData((p) => {
      window.localStorage.setItem("isTabularData", !p.isTabularData);
      return {
        ...p,
        isTable: !p.isTable,
        isProfitAndLossLoaded: false,
      };
    });
  }, []);

  const onToggleBar = useCallback(() => {
    setData((p) => {
      window.localStorage.setItem("isBar", !p.isBar);
      return {
        ...p,
        isBar: !p.isBar,
      };
    });
  }, []);

  const onToggleHotel = useCallback(() => {
    setData((p) => {
      window.localStorage.setItem("isHotel", !p.isHotel);
      return {
        ...p,
        isHotel: !p.isHotel,
      };
    });
  }, []);

  const onToggleDate = useCallback(() => {
    setData((p) => {
      window.localStorage.setItem("isDate", !p.isDate);
      return {
        ...p,
        isDate: !p.isDate,
      };
    });
  }, []);

  const onTogglePercentage = useCallback(() => {
    setData((p) => {
      window.localStorage.setItem("isPercentage", !p.isPercentage);
      return {
        ...p,
        isPercentage: !p.isPercentage,
      };
    });
  }, []);

  const onToggleAverage = useCallback(() => {
    setData((p) => {
      window.localStorage.setItem("isAverage", !p.isAverage);
      return {
        ...p,
        isAverage: !p.isAverage,
      };
    });
  }, []);

  const onToggleFinancialYear = useCallback(() => {
    // setData((p) => {
    //   return {
    //     ...p,
    //     isPreviousFinancialYear: !p.isPreviousFinancialYear,
    //     days:7,
    //     startDay:p.isPreviousFinancialYear ? "01-04-2024" : "01-04-2023"
    //   };
    // });
  }, []);

  const value = {
    ...data,
    onAddExpenser,
    onAddAmount,
    onAddPartner,
    onAddExpense,
    onToggleExpenserAdder,
    onToggleExpenseAdder,
    onToggleAmountAdder,
    onClearMessage,
    onSelectApproval,
    onApproveApproval,
    onSelectApprovedId,
    onTogglePaymentAdder,
    onAddPayment,
    onDaysUpdate,
    onBalanceSelectedEntityChange,
    onBalanceSheetDataUpdate,
    onBalanceSheetUpdate,
    onTogglePartnerAdder,
    onToggleTabularData,
    onToggleAverage,
    onTogglePercentage,
    onToggleHotel,
    onToggleBar,
    onToggleDate,
    onSelectAdvanceId,
    onToggleAdvanceClearer,
    onClearPayment,
    onDeleteTransaction,
    onToggleFinancialYear,
  };

  return (
    <ExpenseContext.Provider value={value}>{children}</ExpenseContext.Provider>
  );
};
export default ExpenseContextProvider;
