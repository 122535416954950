import {
  Card,
  CircularProgress,
  IconButton,
  Stack,
  Typography,
} from "@mui/material";
import AddIcon from "@mui/icons-material/Add";

import { useContext } from "react";
import { TaskContext } from "../../../store/task";
import UserViewer from "./UserViewer";
import AddUserModel from "./AddUserModel";

const UsersListViewer = () => {
  const taskContext = useContext(TaskContext);

  return (
    <Card
      elevation={3}
      sx={{
        flexDirection: "column",
        justifyContent: "center",
        flexGrow: "1",
        width: "50%",
        padding: "15px 10px 10px 15px",
        margin: "40px 20px",
        borderRadius: "10px",
      }}
    >
      <Stack
        direction="row"
        sx={{
          flexDirection: "row",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <Typography variant="h1" fontSize="22px">
          Users
        </Typography>
        <IconButton
          onClick={() => {
            taskContext.onToggleUserAdder();
          }}
        >
          <AddIcon fontSize="20px" color="primary.main" />
        </IconButton>
      </Stack>

      {!taskContext.users[taskContext.selectedComapnyId] ? (
        <CircularProgress
          sx={{
            minHeight: "200px",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            margin: "auto",
          }}
          color="primary"
          size={25}
        />
      ) : (
        <Stack
          style={{
            marginLeft: "10px",
            maxHeight: "400px",
            overflowY: "scroll",
            margin: "12px 0px 15px 15px",
          }}
          spacing={0}
        >
          {taskContext.users[taskContext.selectedComapnyId].map((user) => {
            return (
              <UserViewer
                key={user._id}
                user={user}
                onClick={() => {
                    taskContext.onSetPUserData(
                      {
                        name:user.name,
                        canAddNewTask:user.canAddNewTask,
                        email:user.email,
                        phoneNo:user.phoneNo,
                        password:"",
                        company: Object.fromEntries(user.company.map(cc => [Math.random(),{id:cc.id,position:cc.position}]))
                      }
                    )
                }}
              />
            );
          })}
        </Stack>
      )}

      {taskContext.isUserAdderOpen && (
        <AddUserModel
          isOpen={taskContext.isUserAdderOpen}
          onClose={() => {
            taskContext.onToggleUserAdder();
          }}
          isLoading={taskContext.isUserAdding}
          onSubmit={(data) => {
            console.log(data)
            taskContext.onAddUser(data);
          }}
          companies={taskContext.companies}
          pData={taskContext.pUserData}
        />
      )}
    </Card>
  );
};

export default UsersListViewer;
