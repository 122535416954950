import { Stack, Collapse } from "@mui/material";
import SummaryStageResult from "./SummaryStageResult";
import SingleTransctionViewer from "../SingleTransctionViewer";
import { useState } from "react";
import roundToDecimalPlace from "../../../../../utils/expense/roundToDecimalPlace";

const SummaryThirdStageValue = ({ summary, dTransId, onDeleteTransaction }) => {
  const [isOpen, setIsOpen] = useState({});

  return (
    <Stack
      direction={"column"}
      sx={{
        paddingLeft: "30px",
      }}
    >
      {Object.keys(summary).map((stageKey) => {
        let allTrans = Object.values(summary[stageKey]);
        return (
          <>
            <div>
              <SummaryStageResult
                key={stageKey}
                name={stageKey}
                amount={allTrans
                  .filter((a) => !a.type.startsWith("Incoming"))
                  .reduce((acc, cur) => {
                    return roundToDecimalPlace(acc, 2) + roundToDecimalPlace(cur.amount, 2)
                    
                  }, 0)}
                pAmount={allTrans
                  .filter((a) => a.type.startsWith("Incoming"))
                  .reduce((acc, cur) => {
                    return roundToDecimalPlace(acc, 2) + roundToDecimalPlace(cur.amount, 2)
                  }, 0.00)}
                isOpen={Boolean(isOpen[stageKey])}
                onToggle={() => {
                  setIsOpen((p) => {
                    let a = { ...p };
                    a[stageKey] = !Boolean(a[stageKey]);
                    console.log(a);
                    return a;
                  });
                }}
              />
              <Collapse
                unmountOnExit
                timeout={100}
                in={isOpen[stageKey]}
                sx={{
                  paddingLeft: "30px",
                }}
              >
                {summary[stageKey].map((tras) => {
                  return (
                    <SingleTransctionViewer
                      Transction={tras}
                      key={tras._id}
                      dTransId={dTransId}
                      onDeleteTransaction={(transId) => {
                        onDeleteTransaction(transId);
                      }}
                      isShort={true}
                    />
                  );
                })}
              </Collapse>
            </div>
          </>
        );
      })}
    </Stack>
  );
};

export default SummaryThirdStageValue;
