import {  MenuItem, Select } from "@mui/material";

const BalanceRemoteEntitySelector = ({
  hotels,
  onValueChange,
  required,
  label,
  hotel
}) => {
  return (
    <>
      <Select
        value={hotel}
        onChange={(e) => {
          onValueChange(e.target.value);
        }}
        variant="outlined"
        margin="none"
        label="Hotel"
        sx={{
          margin: "8px 0px",
        }}
        required={Boolean(required)}
        style={{
          marginLeft: "1px",
        }}
        renderValue={(val) => {
          return val
        }}
      >
        {hotels
          .map((hotel) => {
            return (
              <MenuItem
                key={hotel}
                value={hotel}
              >
                {hotel}
              </MenuItem>
            );
          })}
      </Select>
    </>
  );
};

export default BalanceRemoteEntitySelector;
