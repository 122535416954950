import {
  Card,
  CircularProgress,
  IconButton,
  Stack,
  Typography,
} from "@mui/material";
import ExpenserList from "./ExpenerList";
import AddIcon from "@mui/icons-material/Add";
import AddExpenserModal from "./AddExpenserModal";
import { useContext } from "react";
import { ExpenseContext } from "../../../../store/expensesS";
import { HotelContext } from "../../../../store/hotel";

const ExpenserListWrapper = () => {
  const expenseContext = useContext(ExpenseContext);
  const hotelContext = useContext(HotelContext);

  return (
    <Card
      elevation={3}
      sx={{
        flexDirection: "column",
        justifyContent: "center",
        flexGrow: "1",
        width: "50%",
        padding: "15px 10px 10px 15px",
        margin: "40px 20px",
        borderRadius: "10px",
        alignItems: "center",
      }}
    >
      <Stack
        direction="row"
        sx={{
          flexDirection: "row",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <Typography variant="h1" fontSize="22px">
          Expeners
        </Typography>

        <IconButton
          onClick={() => {
            expenseContext.onToggleExpenserAdder();
          }}
        >
          <AddIcon fontSize="20px" color="primary.main" />
        </IconButton>
      </Stack>

      {expenseContext.isExpenserAdderOpen && (
        <AddExpenserModal
          isOpen={expenseContext.isExpenserAdderOpen}
          onClose={() => {
            expenseContext.onToggleExpenserAdder();
          }}
          onAdd={(data) => {
            expenseContext.onAddExpenser(data);
          }}
          isAdding={expenseContext.isExpenserAdding}
          hotels={hotelContext.hotels}
        />
      )}
      {expenseContext.isExpensersLoading ||
      !expenseContext.isExpensersLoaded ? (
        <CircularProgress
          sx={{
            minHeight: "200px",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            margin: "auto",
          }}
          color="primary"
          size={25}
        />
      ) : (
        <Stack
          style={{
            marginLeft: "10px",
            maxHeight: "400px",
            overflowY: "scroll",
            margin: "12px 0px 15px 15px",
          }}
          spacing={0}
        >
          <ExpenserList expensers={expenseContext.expensers} />
        </Stack>
      )}
    </Card>
  );
};

export default ExpenserListWrapper;
