import {
  Card,
  CircularProgress,
  IconButton,
  Stack,
  Typography,
} from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import AddExpenseModel from "./AddExpenseModel";
import EditExpenseModel from "./EditExpenseModel";
import { useContext } from "react";
import { ExpenseContext } from "../../../../store/expensesS";
import { HotelContext } from "../../../../store/hotel";
import ExpenseViewer from "../common/expenseViewer";

const ExpenseListWrapper = ({ category }) => {
  const expenseContext = useContext(ExpenseContext);
  const hotelContext = useContext(HotelContext);

  return (
    <Card
      elevation={3}
      sx={{
        flexDirection: "column",
        justifyContent: "center",
        flexGrow: "1",
        // width: "50%",
        padding: "15px 10px 10px 15px",
        margin: "40px 20px",
        borderRadius: "10px",
      }}
    >
      <Stack
        direction="row"
        sx={{
          flexDirection: "row",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <Typography variant="h1" fontSize="22px">
          Approvals
        </Typography>
        <IconButton
          onClick={() => {
            expenseContext.onToggleExpenseAdder();
          }}
        >
          <AddIcon fontSize="20px" color="primary.main" />
        </IconButton>
      </Stack>

      {expenseContext.isApprovalsLoading ||
      !expenseContext.isApprovalsLoaded ? (
        <CircularProgress
          sx={{
            minHeight: "200px",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            margin: "auto",
          }}
          color="primary"
          size={25}
        />
      ) : (
        <Stack
          style={{
            marginLeft: "10px",
            maxHeight: "400px",
            overflowY: "scroll",
            margin: "12px 0px 15px 15px",
          }}
          spacing={0}
        >
          {expenseContext.approvals.map((approval, index) => {
            return (
              <ExpenseViewer
                key={index}
                expense={approval}
                onClick={() => {
                  expenseContext.onSelectApproval(approval.id);
                }}
              />
            );
          })}
        </Stack>
      )}

      {expenseContext.isExpenseAdderOpen && (
        <AddExpenseModel
          isOpen={expenseContext.isExpenseAdderOpen}
          onClose={() => {
            expenseContext.onToggleExpenseAdder();
          }}
          isLoading={expenseContext.isExpenseAdding}
          onSubmit={(data) => {
            expenseContext.onAddExpense(data);
          }}
          expensers={expenseContext.expensers}
          category={category}
          hotels={hotelContext.hotels}
          hotelId={hotelContext.selctedHotelId}
        />
      )}

      {expenseContext.sApprovalId && (
        <EditExpenseModel
          expense={expenseContext.approvals.find(
            (exp) => exp.id === expenseContext.sApprovalId
          )}
          onClose={() => {
            expenseContext.onSelectApproval(null);
          }}
          onApprove={(id, status, note) => {
            expenseContext.onApproveApproval({ id: id, status, note });
          }}
          isApproving={expenseContext.isApprovalApproving}
          naote={
            expenseContext.approvals.find(
              (exp) => exp.id === expenseContext.sApprovalId
            ).note
          }
        />
      )}
    </Card>
  );
};

export default ExpenseListWrapper;
