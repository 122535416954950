import { useContext } from "react";
import { TaskContext } from "../../../store/task";
import CustomSnackBar from "../../common/CustomSnackBar";
import CompanyListViewer from "../components/CompanyListViewer";
import UsersListViewer from "../components/UsersListViewer";
import {Stack} from "@mui/material"

const TaskManagerScreen = ({ selRoute }) => {
  const taskContext = useContext(TaskContext);

  return (
    <>
      <CustomSnackBar
        isOpen={taskContext.isError}
        message={taskContext.errorMessage}
        onClose={() => {
          taskContext.clearMessage();
        }}
      />

      <Stack direction={"row"}> 
        <CompanyListViewer />
        <UsersListViewer />
      </Stack>
    </>
  );
};

export default TaskManagerScreen;
