import Constant from "../../../constant/Constant";
import Graph from "../util/graph";

const roomDataFinder = (
    bookingSummaryAnalytics,
    allDates,
    isHotelSummed,
    isDateSummed,
    sHotels,
    isBar,
    isArr,
    bs
) => {

    const bSource = Constant.BookingSourcesShort.filter((a) => bs.includes(a));

    if (!isHotelSummed && !isDateSummed) {
        if (isBar) {
            return bSource.map((option, oIndex) => {
                return sHotels.map((hotel, index) => {
                    return {
                        label: `${hotel} - ${option}`,
                        data: allDates.map((date) => {
                            const all = [option]
                                .map((bs) =>
                                    [date]
                                        .map((dt) => {
                                            return [hotel].map(
                                                (hot) => bookingSummaryAnalytics[hot].data[dt][bs]
                                            );
                                        })
                                        .flat()
                                )
                                .flat();

                            const actualAll = bSource
                                .map((bs) =>
                                    [date]
                                        .map((dt) => {
                                            return [hotel].map(
                                                (hot) => bookingSummaryAnalytics[hot].data[dt][bs]
                                            );
                                        })
                                        .flat()
                                )
                                .flat();
                            const rev = all.reduce((acc, cur) => {
                                return acc + cur.rooms;
                            }, 0) * 100;

                            const rooms = isArr
                                ? actualAll.reduce((ac, cu) => ac + cu.rooms, 0)
                                : bookingSummaryAnalytics[hotel].rooms;
                            return Math.round((rev / rooms) * 100) / 100;
                        }),
                        borderColor: Graph.Colors[oIndex],
                        backgroundColor: Graph.Colors[oIndex],
                        ...Graph.ExtraOptions,
                        stack: `Stack - ${hotel}`,
                    };
                });
            }).flat();
        }

        return sHotels.map((hotel, index) => {
            return {
                label: hotel,
                data: allDates.map((date) => {
                    const all = bSource
                        .map((bs) =>
                            [date]
                                .map((dt) => {
                                    return [hotel].map(
                                        (hot) => bookingSummaryAnalytics[hot].data[dt][bs]
                                    );
                                })
                                .flat()
                        )
                        .flat();

                    const rev = all.reduce((acc, cur) => {
                        return acc + cur.rooms
                    }, 0) * 100;

                    const rooms = isArr
                        ? all.reduce((ac, cu) => ac + cu.rooms, 0)
                        : bookingSummaryAnalytics[hotel].rooms;
                    return Math.round((rev / rooms) * 100) / 100;
                }),
                borderColor: Graph.Colors[index],
                backgroundColor: Graph.Colors[index],
                ...Graph.ExtraOptions,
                stack: `${hotel}`,
            };
        });
    } else if (isHotelSummed && !isDateSummed) {
        if (isBar) {
            return bSource.map((option, oIndex) => {
                return {
                    label: `All Hotels ${option}`,
                    data: allDates.map((date) => {
                        const all = [option]
                            .map((bs) =>
                                [date]
                                    .map((dt) => {
                                        return sHotels.map(
                                            (hot) => bookingSummaryAnalytics[hot].data[dt][bs]
                                        );
                                    })
                                    .flat()
                            )
                            .flat();

                        const actualAll = bSource
                            .map((bs) =>
                                [date]
                                    .map((dt) => {
                                        return sHotels.map(
                                            (hot) => bookingSummaryAnalytics[hot].data[dt][bs]
                                        );
                                    })
                                    .flat()
                            )
                            .flat();

                        const rev = all.reduce((ac, cu) => ac + cu.rooms, 0) * 100;

                        const rooms = isArr
                            ? actualAll.reduce((ac, cu) => ac + cu.rooms, 0)
                            : sHotels.reduce((acc, cur) => {
                                return acc + bookingSummaryAnalytics[cur].rooms;
                            }, 0);

                        return Math.round((rev / rooms) * 100) / 100;
                    }),
                    borderColor: Graph.Colors[oIndex],
                    backgroundColor: Graph.Colors[oIndex],
                    ...Graph.ExtraOptions,
                    stack: `All Hotels`,
                };
            });
        }

        return [
            {
                label: "All Hotels",
                data: allDates.map((date) => {
                    const all = bSource
                        .map((bs) =>
                            [date]
                                .map((dt) => {
                                    return sHotels.map(
                                        (hot) => bookingSummaryAnalytics[hot].data[dt][bs]
                                    );
                                })
                                .flat()
                        )
                        .flat();

                    const rev = all.reduce((acc, cur) => {
                        return (
                            acc + cur.rooms
                        );
                    }, 0) * 100;

                    const rooms = isArr
                        ? all.reduce((ac, cu) => ac + cu.rooms, 0)
                        : sHotels.reduce((acc, cur) => {
                            return acc + bookingSummaryAnalytics[cur].rooms;
                        }, 0);

                    return Math.round((rev / rooms) * 100) / 100;
                }),
                borderColor: Graph.Colors[0],
                backgroundColor: Graph.Colors[0],
                ...Graph.ExtraOptions,
                stack: `All Hotels`,
            },
        ];
    } else if (!isHotelSummed && isDateSummed) {
        if (isBar) {
            return bSource.map((option, oIndex) => {
                return {
                    label: `All Dates ${option}`,
                    data: sHotels.map((hotel) => {
                        const all = [option]
                            .map((bs) =>
                                allDates
                                    .map((dt) => {
                                        return [hotel].map(
                                            (hot) => bookingSummaryAnalytics[hot].data[dt][bs]
                                        );
                                    })
                                    .flat()
                            )
                            .flat();

                        const actualAll = bSource
                            .map((bs) =>
                                allDates
                                    .map((dt) => {
                                        return [hotel].map(
                                            (hot) => bookingSummaryAnalytics[hot].data[dt][bs]
                                        );
                                    })
                                    .flat()
                            )
                            .flat();

                        const rev = all.reduce((acc, cur) => {
                            return acc + cur.rooms;
                        }, 0) * 100;

                        const rooms = isArr
                            ? actualAll.reduce((ac, cu) => ac + cu.rooms, 0)
                            : bookingSummaryAnalytics[hotel].rooms * allDates.length;
                        return Math.round((rev / rooms) * 100) / 100;
                    }),
                    borderColor: Graph.Colors[oIndex],
                    backgroundColor: Graph.Colors[oIndex],
                    ...Graph.ExtraOptions,
                    stack: "All Dates",
                };
            });
        }
        return [
            {
                label: "All Dates",
                data: sHotels.map((hotel) => {
                    const all = bSource
                        .map((bs) =>
                            allDates
                                .map((dt) => {
                                    return [hotel].map(
                                        (hot) => bookingSummaryAnalytics[hot].data[dt][bs]
                                    );
                                })
                                .flat()
                        )
                        .flat();

                    const rev = all.reduce((acc, cur) => {
                        return (
                            acc + cur.rooms
                        );
                    }, 0) * 100;

                    const rooms = isArr
                        ? all.reduce((ac, cu) => ac + cu.rooms, 0)
                        : bookingSummaryAnalytics[hotel].rooms * allDates.length;

                    return Math.round((rev / rooms) * 100) / 100;
                }),
                borderColor: Graph.Colors[0],
                backgroundColor: Graph.Colors[0],
                ...Graph.ExtraOptions,
                stack: "All Dates",
            },
        ];
    } else if (isHotelSummed && isDateSummed) {
        const allRooms =
            sHotels.reduce((acc, cur) => {
                return acc + bookingSummaryAnalytics[cur].rooms;
            }, 0) * allDates.length;

        if (isBar) {
            return bSource.map((option, oIndex) => {
                const all = [option]
                    .map((bs) =>
                        allDates
                            .map((dt) => {
                                return sHotels.map(
                                    (hot) => bookingSummaryAnalytics[hot].data[dt][bs]
                                );
                            })
                            .flat()
                    )
                    .flat();

                const actualAll = [option]
                    .map((bs) =>
                        allDates
                            .map((dt) => {
                                return sHotels.map(
                                    (hot) => bookingSummaryAnalytics[hot].data[dt][bs]
                                );
                            })
                            .flat()
                    )
                    .flat();

                const rev = all.reduce((acc, cur) => {
                    return acc + cur.rooms;
                }, 0) * 100;

                const rooms = isArr
                    ? actualAll.reduce((ac, cu) => ac + cu.rooms, 0)
                    : allRooms;

                return {
                    label: `All Dates And Hotels ${option}`,
                    data: [Math.round((rev / rooms) * 100) / 100],
                    borderColor: Graph.Colors[oIndex],
                    backgroundColor: Graph.Colors[oIndex],
                    ...Graph.ExtraOptions,
                    stack: "All Dates And Hotels",
                };
            });
        }

        const all = bSource
            .map((bs) =>
                allDates
                    .map((dt) => {
                        return sHotels.map(
                            (hot) => bookingSummaryAnalytics[hot].data[dt][bs]
                        );
                    })
                    .flat()
            )
            .flat();

        const rev = all.reduce((acc, cur) => {
            return (
                acc + cur.rooms
            );
        }, 0) * 100;

        const rooms = isArr ? all.reduce((ac, cu) => ac + cu.rooms, 0) : allRooms;

        return [
            {
                label: "All Dates And Hotels",
                data: [Math.round((rev / rooms) * 100) / 100],
                borderColor: Graph.Colors[0],
                backgroundColor: Graph.Colors[0],
                ...Graph.ExtraOptions,
                stack: "All Dates And Hotels",
            },
        ];
    }

}

export default roomDataFinder