import { Checkbox, Stack, Typography } from "@mui/material";

const BooleanTaskInput = ({ value, onToggle, label }) => {
  return (
    <Stack direction={"row"} spacing={4} sx={{ alignItems: "center" }}>
      <Checkbox
        checked={value}
        onChange={(e) => {
          onToggle();
        }}
      />
      <Typography variant="h1" fontSize={"20px"}>
        {label}
      </Typography>
    </Stack>
  );
};

export default BooleanTaskInput;
