import {
  Button,
  Collapse,
  Container,
  Typography,
} from "@mui/material";
import { useState } from "react";
import BookingPreview from "./BookingPreview";
import { KeyboardArrowDown, KeyboardArrowUp } from "@mui/icons-material";

const BookingList = ({ name, bookings }) => {
  const [isOpen, setIsOpen] = useState(bookings.length <= 4);

  const onToggle = () => {
    setIsOpen((p) => !p);
  };
  if (bookings.length === 0) {
    return <></>;
  }

  return (
    <Container
      sx={{
        margin: "15px 0px",
        flexGrow: 1,
      }}
    >
      <Button
        onClick={onToggle}
        disableRipple
        sx={{
          justifyContent: "space-between",
          display: "flex",
          alignItems: "center",
          flexDirection: "row",
          textTransform: "none",
        }}
      >
        <Typography variant="h6" color="primry.main">
          {`${name} (${bookings.length})`}
        </Typography>

        {isOpen ? (
          <KeyboardArrowUp sx={{ marginLeft: "20px" }} />
        ) : (
          <KeyboardArrowDown sx={{ marginLeft: "20px" }} />
        )}
      </Button>

      <Collapse in={isOpen} timeout="auto" unmountOnExit>
        {bookings.map((book) => {
          return <BookingPreview key={book.bookingId} booking={book} />;
        })}
      </Collapse>
    </Container>
  );
};

export default BookingList;
