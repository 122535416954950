import React, { useState, useEffect } from "react";
import ChartDataLabels from "chartjs-plugin-datalabels";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  BarElement,
} from "chart.js";
import { Line, Bar } from "react-chartjs-2";
import Graph from "../util/graph";
import HotelChooser from "../components/HotelChooser";
import { Stack } from "@mui/material";
import HotelGroupSwitch from "../components/HotelGroupSwitch";
import CollectionConcilationSingleDateFinder from "./collectionConcilationSingleDateFinder";

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  BarElement,
  Title,
  Tooltip,
  Legend,
  ChartDataLabels
);

const CollectionConcilationSingleDateGraph = ({ collectionConcilation,summed,onSumChange }) => {
  let title = "Collection Concilation";
  const [sHotels, setSHotels] = useState([]);
  const hotels = Object.keys(collectionConcilation);
  let allDates = Object.keys(collectionConcilation[hotels[0]]);
  const allOptions = ["ADV", "REV", "DUE"];

  const [options, setOptions] = useState([]);
  const [dates, setDates] = useState(allDates);
  const setSummed = (st) => {
    onSumChange(st)
  }

  const [data, setData] = useState({});

  useEffect(() => {
    let hotels = Object.keys(collectionConcilation);
    setSHotels(hotels);
    setOptions(["ADV", "REV", "DUE"]);
  }, [collectionConcilation]);

  useEffect(() => {
    const hotels = Object.keys(collectionConcilation);
    let allDates = Object.keys(collectionConcilation[hotels[0]]);
    let datasets = CollectionConcilationSingleDateFinder(
      collectionConcilation,
      allDates,
      dates,
      summed.Hotel,
      summed.Date,
      sHotels,
      options,
      summed.Bar
    );
    datasets.datasets = datasets.datasets.map(dataset => {
      return {
        ...dataset,
        data:dataset.data.map(value => {
          return value === 0 ? null : value
        })
      }
    })
    setData(datasets);

  }, [
    collectionConcilation,
    summed.Date,
    dates,
    sHotels,
    options,
    summed.Bar,
    summed.Hotel,
  ]);

  return (
    <div style={{ margin: "20px", paddingTop: "30px", position: "relative" }}>
      <Stack
        style={{
          position: "absolute",
          top: "-10px",
          right: "10px",
        }}
        direction="row"
        spacing={1}
      >
         <HotelGroupSwitch
          state={summed}
          onStateChange={(st) => {
            setSummed(st);
          }}
          width={"70px"}
        />

        <HotelChooser
          hotels={allOptions}
          selectedHotels={options}
          onHotelsChange={(o) => {
            setOptions(o);
          }}
          width="80px"
        />

        <HotelChooser
          hotels={allDates}
          selectedHotels={dates}
          onHotelsChange={(o) => {
            setDates(o);
          }}
          width="80px"
        />

        <HotelChooser
          hotels={hotels}
          selectedHotels={sHotels}
          onHotelsChange={(s) => {
            setSHotels(s);
          }}
          width="160px"
        />
      </Stack>

      {data &&
        data.datasets &&
        data.datasets.length > 0 &&
        (summed.Bar ? (
          <Bar
            style={{ marginTop: "20px" }}
            options={Graph.BarOptions(
              title,
              true,
              data.datasets.reduce((acc, cur) => {
                if (!acc[cur.stack]) {
                  let nAcc = { ...acc };
                  nAcc[cur.stack] = [cur];
                  return nAcc;
                } else {
                  let nAcc = { ...acc };
                  nAcc[cur.stack] = [...nAcc[cur.stack], cur];
                  return nAcc;
                }
              }, {})
            )}
            data={data}
          />
        ) : (
          <Line
            style={{ marginTop: "20px" }}
            options={Graph.Options(title)}
            data={data}
          />
        ))}
    </div>
  );
};

export default CollectionConcilationSingleDateGraph;
