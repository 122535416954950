import { useContext } from "react";
import { TaskContext } from "../../../store/task";
import allRoutes from "../../../pages/routes";
import TaskManagerScreen from "../screens/TaskManagerScreen";

const TaskManagerStack = ({ selRoute }) => {
  const taskContext = useContext(TaskContext);

  if (selRoute === allRoutes[25]) {
    return <TaskManagerScreen />;
  } else if (selRoute === allRoutes[26]) {
    return (
     <>

     </>
    ) ;
  }

  return <></>;
};

export default TaskManagerStack;
