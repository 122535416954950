import { useState } from "react";
import {
  Button,
  Card,
  CircularProgress,
  Modal,
  Typography,
} from "@mui/material";
import OxyOsTheme from "../../../theme/theme";
import TaskStringInput from "./TaskStringInput";
import UserPosition from "./UserPosition";
import BooleanTaskInput from "./BooleanTaskInput";

const AddUserModel = ({
  isOpen,
  onClose,
  isLoading,
  companies,
  onSubmit,
  pData,
}) => {
  const [data, setData] = useState(
    pData
      ? pData
      : {
          name: "",
          email: "",
          phoneNo: "",
          password: "",
          company: {
            1: {
              id: "",
              position: "",
            },
          },
          canAddNewTask: false,
        }
  );

  return (
    <Modal
      open={isOpen}
      onClose={onClose}
      sx={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <Card
        sx={{
          flexDirection: "row",
          minHeight: "500px",
          maxHeight: "600px",
          maxWidth: "700px",
          minWidth: "500px",
          background: "#FFF",
          borderRadius: "10px",
          padding: "20px 30px",
          overflowY: "scroll",
          "::-webkit-scrollbar": {
            display: "none",
          },
          border: "none",
        }}
      >
        <Typography
          variant="h1"
          sx={{
            width: "100%",
            textAlign: "center",
            marginBottom: "40px",
            marginTop: "10px",
          }}
          style={{
            textShadow: "0px 1px 1px rgba(0,0,0,0.24)",
          }}
          fontSize={"25px"}
        >
          Add User
        </Typography>

        <TaskStringInput
          value={data.name}
          onValueChange={(value) => {
            setData((d) => {
              return {
                ...d,
                name: value,
              };
            });
          }}
          label={"Name"}
          type={"text"}
          required={true}
        />

        <TaskStringInput
          value={data.email}
          onValueChange={(value) => {
            setData((d) => {
              return {
                ...d,
                email: value,
              };
            });
          }}
          label={"Email"}
          type={"text"}
          required={true}
        />

        <TaskStringInput
          value={data.phoneNo}
          onValueChange={(value) => {
            setData((d) => {
              return {
                ...d,
                phoneNo: value,
              };
            });
          }}
          label={"Phone No"}
          type={"number"}
          required={true}
        />

        <TaskStringInput
          value={data.password}
          onValueChange={(value) => {
            setData((d) => {
              return {
                ...d,
                password: value,
              };
            });
          }}
          label={"Password"}
          type={"text"}
          required={true}
        />

        <UserPosition
          positions={data.company}
          companies={companies}
          onUpdatePosition={(value) => {
            setData((d) => {
              return {
                ...d,
                company: value,
              };
            });
          }}
        />

        <BooleanTaskInput
          value={data.canAddNewTask}
          onToggle={() => {
            setData((p) => {
              return {
                ...p,
                canAddNewTask: !p.canAddNewTask,
              };
            });
          }}
          label={"Can Add New Task"}
        />

        <Button
          variant="outlined"
          size="large"
          sx={{
            backgroundColor: "#262626",
            borderRadius: "30px",
            padding: "10px 15px",
            margin: "20px auto",
            display: "block",
            color: OxyOsTheme.palette.background.main,
            "&:hover": {
              backgroundColor: OxyOsTheme.palette.primary.main,
              color: OxyOsTheme.palette.background.main,
              opacity: 0.6,
            },
          }}
          onClick={() => {
            if (!isLoading) {
              onSubmit({
                ...data,
                company:Object.values(data.company)
              });
            }
          }}
        >
          {isLoading ? (
            <CircularProgress color="background" />
          ) : (
            <Typography variant="h6">Add User</Typography>
          )}
        </Button>
      </Card>
    </Modal>
  );
};

export default AddUserModel;
