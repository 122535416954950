import {  Stack, Typography } from "@mui/material";

const SingleItemRevenueViewer = ({ item }) => {
  let datas = Object.keys(item.itemData);
  return (
    <Stack
      sx={{
        borderRadius: "5px",
        border: "1px solid #262626",
        margin: "15px 0px",
        padding: "7px 7px",
        transition: "border 1s",
        ":hover": {
          boxShadow: "0px 10px 15px -3px rgba(0,0,0,0.1)",
        },
        width: "min-content",
      }}
      direction="row"
      spacing={2}
    >
      <Stack direction="column" spacing={0} sx={{ width: "150px" }}>
        <Typography
          variant="h6"
          color="primary.main"
          sx={{ fontSize: "13px", textOverflow: "ellipsis" }}
        >
          {`${item.itemConsumerName[0].toUpperCase()}${item.itemConsumerName.slice(
            1
          )}`}
        </Typography>
        <Typography variant="h6" color="primary.main" sx={{ fontSize: "11px" }}>
          #{item.itemId}
        </Typography>
      </Stack>

      {datas.map((type) => {
        return (
          <Stack
            key={type}
            direction="column"
            spacing={0}
            sx={{ width: "100px" }}
          >
            <Typography
              variant="h6"
              color="primary.main"
              sx={{ fontSize: "13px", textOverflow: "ellipsis" }}
            >
              {type}
            </Typography>
            <Typography
              variant="h6"
              color="primary.main"
              sx={{ fontSize: "11px" }}
            >
              ₹{item.itemData[type]} - ₹{item.itemEffectiveAmount[type]}
            </Typography>
          </Stack>
        );
      })}

      <Stack sx={{ width: "100px" }}>
        <Typography variant="h6" color="primary.main" sx={{ fontSize: "11px" }}>
          #{item.itemRemoteId}
        </Typography>
      </Stack>


      <Stack sx={{ width: "60px" }}>
        <Typography variant="h6" color="primary.main" sx={{ fontSize: "13px" }}>
          ₹{item.itemAmount}
        </Typography>
      </Stack>
    </Stack>
  );
};


export default SingleItemRevenueViewer