import { useState } from "react";
import {
  Button,
  Card,
  CircularProgress,
  Modal,
  Typography,
} from "@mui/material";
import OxyOsTheme from "../../../theme/theme";
import TaskStringInput from "./TaskStringInput";
import CompanyChooser from "./CompanyChooser";
import CompanyPosition from "./CompanyPosition";
import MultiDepartmentSelectInput from "./MultiDepartmentSelect";

const TaskDepartment = {
  Marketing: "Marketing",
  Maintenance: "Maintenance",
  IT: "IT",
  Recrusion: "Recrusion",
  Finance: "Finance",
  Others: "Others",
  Messaging: "Messaging",
};

const AddCompanyModel = ({
  isOpen,
  onClose,
  isLoading,
  companies,
  onSubmit,
  pData,
}) => {
  const [data, setData] = useState(
    pData
      ? pData
      : {
          name: "",
          positions: {
            1: {
              position: "ADMIN",
              priority: "100",
              departments: [],
            },
          },
          parentCompany: "",
          departments: [],
        }
  );

  return (
    <Modal
      open={isOpen}
      onClose={onClose}
      sx={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <Card
        sx={{
          flexDirection: "row",
          minHeight: "500px",
          maxHeight: "600px",
          maxWidth: "700px",
          minWidth: "500px",
          background: "#FFF",
          borderRadius: "10px",
          padding: "20px 30px",
          overflowY: "scroll",
          "::-webkit-scrollbar": {
            display: "none",
          },
          border: "none",
        }}
      >
        <Typography
          variant="h1"
          sx={{
            width: "100%",
            textAlign: "center",
            marginBottom: "40px",
            marginTop: "10px",
          }}
          style={{
            textShadow: "0px 1px 1px rgba(0,0,0,0.24)",
          }}
          fontSize={"25px"}
        >
          Add Company
        </Typography>

        <TaskStringInput
          value={data.name}
          onValueChange={(value) => {
            setData((d) => {
              return {
                ...d,
                name: value,
              };
            });
          }}
          label={"Company Name"}
          type={"text"}
          required={true}
        />

        <MultiDepartmentSelectInput
          value={data.departments}
          onValueChange={(va) => {
            setData((d) => {
              return {
                ...d,
                departments: va,
              };
            });
          }}
          required={true}
          options={Object.values(TaskDepartment)}
        />

        <CompanyChooser
          value={data.parentCompany}
          onValueChange={(value) => {
            setData((d) => {
              return {
                ...d,
                parentCompany: value,
              };
            });
          }}
          title={"Choose Parent Company"}
          companies={companies}
          required={false}
        />

        <CompanyPosition
          positions={data.positions}
          options={data.departments}
          onUpdatePosition={(positions) => {
            setData((d) => {
              return {
                ...d,
                positions: positions,
              };
            });
          }}
        />

        <Button
          variant="outlined"
          size="large"
          sx={{
            backgroundColor: "#262626",
            borderRadius: "30px",
            padding: "10px 15px",
            margin: "20px auto",
            display: "block",
            color: OxyOsTheme.palette.background.main,
            "&:hover": {
              backgroundColor: OxyOsTheme.palette.primary.main,
              color: OxyOsTheme.palette.background.main,
              opacity: 0.6,
            },
          }}
          onClick={() => {
            if (!isLoading) {
              onSubmit({
                companyId: data.companyId ? data.companyId : undefined,
                companyName: data.name,
                parentCompany: data.parentCompany,
                departments: data.departments,
                positions: Object.fromEntries(
                  Object.values(data.positions).map((a) => [
                    a.position,
                    {
                      departments: a.departments,
                      priority: a.priority,
                    },
                  ])
                ),
              });
            }
          }}
        >
          {isLoading ? (
            <CircularProgress color="background" />
          ) : (
            <Typography variant="h6">Add Company</Typography>
          )}
        </Button>
      </Card>
    </Modal>
  );
};

export default AddCompanyModel;
