import { createTheme } from "@mui/material";

const OxyOsTheme = createTheme({
    typography:{
        h1:{
            fontFamily:"'M PLUS Rounded 1c', sans-serif",
            fontWeight:"bold",
            fontSize:"35px",
            textShadow:"0px 1px 1px rgba(0,0,0,0.24)"
        },
        h6:{
            fontFamily:"'M PLUS Rounded 1c', sans-serif",
            fontWeight:"bold",
            fontSize:"18px",
            textShadow:"0px 1px 1px rgba(0,0,0,0.24)"
        },
        body1:{
            fontFamily:"'M PLUS Rounded 1c', sans-serif",
            fontWeight:"medium",
            fontSize:"16px",
            textShadow:"0px 1px 1px rgba(0,0,0,0.24)"
        },
        body2:{
            fontFamily:"'Roboto Mono', monospace",
            fontWeight:"medium",
            fontSize:"12px",
            textShadow:"0px 1px 1px rgba(0,0,0,0.24)"
        }
    },
    palette:{
        primary:{
            main:"#262626"
        },
        secondary:{
            main:"#181818"
        },
        background:{
            main:"#f6f6f6"
        }
    }
})

export default OxyOsTheme