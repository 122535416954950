import { useEffect, useState } from "react";
import OxyOsTheme from "../../../../theme/theme";
import { Modal, Card, Typography, Button } from "@mui/material";
import AddExpenserSelector from "../../components/common/AddExpenserSelector";
import AddExpenserInput from "../../components/common/AddExpenserInput";
import AddExpenseCategoryInput from "../../components/common/AddExpenseCategoryInput";
import findCategory from "../../../../utils/expense/findCategory";
import AddExpenseSelector from "../../components/common/AddExpenseSelector";
import ExpenseDateRangePicker from "../../components/common/ExpenseDateRangePicker";
import ExpenseDateAmountPicker from "../../components/common/ExpenseDateAmountPicker";
import AddExpenseDatePicker from "../../components/common/AddExpenseDatePicker";
import AddExpenseBooleanInput from "../../components/common/AddExpenseBooleanInput";
import AddExpenserHotelSelect from "../../components/common/AddExpenserHotelSelect";
import AddBalancePaymentInput from "./AddBalancePaymentInput";
import PaymentViewer from "./PaymentViewer";
import moment from "moment";

const Sources = ["Cash", "Bank", "Ota"];

const AddBalanceExpense = ({
  isOpen,
  onClose,
  onAddExpense,
  expensers,
  category,
  sDate,
  hotels,
  hotelId,
}) => {
  const [isPayment, setIsPayment] = useState(false);

  const [expenseData, setExpenseData] = useState({
    hotelId: hotelId,
    expenserId: "",
    category: [""],
    aDates: [sDate],
    dates: {},
    note: "",
    cDate: sDate,
    isAdvance: false,
    isEntity: false,
    isExpenserToBusinessLoan: false,
    isParentCompanyPayment: false,
    payments: [],
  });

  const [error, setError] = useState({
    isError: false,
    errorMessage: "",
  });

  useEffect(() => {
    setExpenseData((p) => {
      let a = {};
      for (let i = 0; i < p.aDates.length; i++) {
        a[p.aDates[i]] = "0";
      }
      return {
        ...p,
        dates: a,
      };
    });
  }, [expenseData.aDates]);

  return (
    <>
      <Modal
        open={isOpen}
        onClose={onClose}
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Card
          sx={{
            flexDirection: "row",
            minHeight: "500px",
            maxHeight: "600px",
            maxWidth: "700px",
            minWidth: "500px",
            background: "#FFF",
            borderRadius: "10px",
            padding: "20px 30px",
            overflowY: "scroll",
            "::-webkit-scrollbar": {
              display: "none",
            },
            border: "none",
          }}
        >
          <Typography
            variant="h1"
            sx={{
              width: "100%",
              textAlign: "center",
              marginBottom: "40px",
              marginTop: "10px",
            }}
            style={{
              textShadow: "0px 1px 1px rgba(0,0,0,0.24)",
            }}
            fontSize={"25px"}
          >
            Add Expense
          </Typography>

          {!expenseData.isExpenserToBusinessLoan && (
            <AddExpenserHotelSelect
              value={expenseData.hotelId}
              onValueChange={(val) => {
                setExpenseData((p) => {
                  return { ...p, hotelId: hotelId };
                });
              }}
              required={true}
              hotels={hotels}
            />
          )}

          {!expenseData.isExpenserToBusinessLoan &&
            expenseData.hotelId &&
            !expenseData.isEntity && (
              <AddExpenserSelector
                value={expenseData.expenserId}
                onValueChange={(val) => {
                  setExpenseData((p) => {
                    return { ...p, expenserId: val };
                  });
                }}
                hotels={hotels}
                required={true}
                expensers={expensers}
              />
            )}

          {!expenseData.isExpenserToBusinessLoan &&
            expenseData.hotelId &&
            expenseData.isEntity && (
              <AddExpenserHotelSelect
                value={expenseData.expenserId}
                onValueChange={(val) => {
                  setExpenseData((p) => {
                    return { ...p, expenserId: val };
                  });
                }}
                required={true}
                hotels={hotels}
              />
            )}

          {expenseData.isExpenserToBusinessLoan && (
            <AddExpenserHotelSelect
              value={expenseData.hotelId}
              onValueChange={(val) => {
                setExpenseData((p) => {
                  return { ...p, hotelId: val };
                });
              }}
              required={true}
              hotels={hotels}
              title={"Choose Loan Taker"}
            />
          )}

          {expenseData.isExpenserToBusinessLoan && expenseData.hotelId && (
            <AddExpenserSelector
              value={expenseData.expenserId}
              onValueChange={(val) => {
                setExpenseData((p) => {
                  return { ...p, expenserId: val };
                });
              }}
              hotels={hotels}
              required={true}
              expensers={expensers.filter((exp) => {
                return exp.entities.includes(expenseData.hotelId);
              })}
              title={"Choose Loan Provider"}
            />
          )}

          {expenseData.hotelId && expenseData.expenserId && (
            <>
              {
                <AddExpenseCategoryInput
                  category={
                    expenseData.category.length > 0
                      ? expenseData.category[0]
                      : undefined
                  }
                  onChange={(ncat) => {
                    setExpenseData((p) => {
                      return {
                        ...p,
                        category: [ncat],
                      };
                    });
                  }}
                  categories={category.map((cat) => cat.type)}
                />
              }

              {expenseData.category.map((cat, index) => {
                let categorie = findCategory(
                  expenseData.category,
                  index,
                  category
                );

                return categorie &&
                  categorie.categories &&
                  categorie.categories.length > 0 ? (
                  <AddExpenseSelector
                    key={index}
                    value={expenseData.category[index + 1]}
                    onValueChange={(val) => {
                      let a = categorie.categories.find(
                        (ca) => ca.type === val
                      );
                      setExpenseData((p) => {
                        return {
                          ...p,
                          category: [...p.category.slice(0, index + 1), val],
                          expenseType:
                            a && a.expenseType ? a.expenseType : undefined,
                        };
                      });
                    }}
                    required={true}
                    label={`Choose ${
                      expenseData.category[index]
                        ? expenseData.category[index]
                        : ""
                    } Category`}
                    options={categorie.categories.map((ca) => ca.type)}
                  />
                ) : (
                  <></>
                );
              })}
            </>
          )}

          {expenseData.hotelId &&
            expenseData.expenserId &&
            expenseData.category.length > 0 && (
              <ExpenseDateRangePicker
                dateRange={expenseData.aDates}
                onDateRangeChange={(range) => {
                  setExpenseData((p) => {
                    return {
                      ...p,
                      aDates: range,
                    };
                  });
                }}
              />
            )}

          {expenseData.hotelId && expenseData.expenserId && (
            <>
              <ExpenseDateAmountPicker
                amount={
                  Object.values(expenseData.dates).length > 0
                    ? Object.values(expenseData.dates)[0]
                    : 0
                }
                onAmountChange={(val) => {
                  setExpenseData((p) => {
                    let a = p.dates;
                    let allDates = Object.keys(a);
                    for (let i = 0; i < allDates.length; i++) {
                      a[allDates[i]] = val;
                    }
                    return {
                      ...p,
                      dates: a,
                    };
                  });
                }}
                label={"Equal Amount"}
              />
              {expenseData.aDates.map((date) => {
                return (
                  <ExpenseDateAmountPicker
                    amount={expenseData.dates[date]}
                    onAmountChange={(val) => {
                      setExpenseData((p) => {
                        let a = p.dates;
                        a[date] = val;
                        return {
                          ...p,
                          dates: a,
                        };
                      });
                    }}
                    label={date}
                  />
                );
              })}
            </>
          )}

          {expenseData.hotelId && expenseData.expenserId && (
            <AddExpenserInput
              value={expenseData.note}
              onValueChange={(val) => {
                setExpenseData((p) => {
                  return { ...p, note: val };
                });
              }}
              label={"Note"}
              type={"text"}
              required={true}
            />
          )}

          {expenseData.hotelId &&
            expenseData.expenserId &&
            expenseData.category.length > 0 && (
              <AddExpenseBooleanInput
                value={expenseData.isAdvance}
                onToggle={() => {
                  setExpenseData((e) => {
                    return {
                      ...e,
                      isAdvance: !e.isAdvance,
                    };
                  });
                }}
                label={"Is Advance Payment"}
              />
            )}

          <AddExpenseBooleanInput
            value={expenseData.isEntity}
            onToggle={() => {
              setExpenseData((e) => {
                return {
                  ...e,
                  isEntity: !e.isEntity,
                  isExpenserToBusinessLoan: !e.isEntity
                    ? false
                    : e.isExpenserToBusinessLoan,
                  expenserId: "",
                };
              });
            }}
            label={"Business Payment"}
          />

          <AddExpenseBooleanInput
            value={expenseData.isParentCompanyPayment}
            onToggle={() => {
              setExpenseData((e) => {
                return {
                  ...e,
                  isParentCompanyPayment: !e.isParentCompanyPayment,
                  isEntity: !e.isParentCompanyPayment ? true : false,
                  expenserId: "",
                };
              });
            }}
            label={"Parent Company"}
          />

          <AddExpenseBooleanInput
            value={expenseData.isExpenserToBusinessLoan}
            onToggle={() => {
              setExpenseData((e) => {
                return {
                  ...e,
                  isExpenserToBusinessLoan: !e.isExpenserToBusinessLoan,
                  isEntity: !e.isExpenserToBusinessLoan ? false : e.isEntity,
                  expenserId: "",
                };
              });
            }}
            label={"Personal Loan"}
          />

          <AddExpenseDatePicker
            date={expenseData.cDate}
            onDateChange={(date) => {
              setExpenseData((p) => {
                return {
                  ...p,
                  cDate: date,
                };
              });
            }}
          />

          {expenseData.payments.map((payment, index) => {
            return (
              <PaymentViewer
                payment={payment}
                onDelete={() => {
                  setExpenseData((e) => {
                    let paym = [...e.payments];
                    paym.splice(index, 1);
                    return {
                      ...e,
                      payments: paym,
                    };
                  });
                }}
              />
            );
          })}

          {isPayment && (
            <AddBalancePaymentInput
              isOpen={isPayment}
              onClose={() => {
                setIsPayment((p) => !p);
              }}
              onAdd={(data) => {
                setExpenseData((e) => {
                  return {
                    ...e,
                    payments: [...e.payments, data],
                  };
                });
                setIsPayment(false);
              }}
              isEntity={expenseData.isEntity}
              sources={Sources}
              source={
                expenseData.isEntity
                  ? expenseData.expenserId === "65c760ef06ed72293c85ed4b"
                    ? "Bank"
                    : "Cash"
                  : "Cash"
              }
              pSource={
                expenseData.expenserId === "65c760ef06ed72293c85ed4b"
                  ? "Bank"
                  : "Cash"
              }
              amount={Object.values(expenseData.dates).reduce((ac, cu) => {
                return ac + Number(cu);
              }, 0)}
              note={expenseData.note}
              sDate={
                expenseData.isEntity && expenseData.expenserId !== hotelId
                  ? moment
                      .tz(sDate, "DD-MM-YYYY", "Asia/Kolkata")
                      .add(1, "day")
                      .format("DD-MM-YYYY")
                  : sDate
              }
            />
          )}

          <Button
            variant="outlined"
            size="large"
            sx={{
              backgroundColor: "#262626",
              borderRadius: "30px",
              padding: "10px 15px",
              margin: "20px auto",
              display: "block",
              color: OxyOsTheme.palette.background.main,
              "&:hover": {
                backgroundColor: OxyOsTheme.palette.primary.main,
                color: OxyOsTheme.palette.background.main,
                opacity: 0.6,
              },
            }}
            onClick={() => {
              if (!isPayment) {
                setIsPayment((p) => !p);
              }
            }}
          >
            <Typography variant="h6">Add Payment</Typography>
          </Button>

          <Button
            variant="outlined"
            size="large"
            sx={{
              backgroundColor: "#262626",
              borderRadius: "30px",
              padding: "10px 15px",
              margin: "20px auto",
              display: "block",
              color: OxyOsTheme.palette.background.main,
              "&:hover": {
                backgroundColor: OxyOsTheme.palette.primary.main,
                color: OxyOsTheme.palette.background.main,
                opacity: 0.6,
              },
            }}
            onClick={() => {
              onAddExpense(expenseData);
            }}
          >
            <Typography variant="h6">Add Expense</Typography>
          </Button>
        </Card>
      </Modal>
    </>
  );
};

export default AddBalanceExpense;
