import LoadingBookings from "../../common/LoadingBookings";
import CollectionConcilationGraph from "../../graph/collectionConcilation/collectionConcilationGraph";
import SingleHotelCollectionConcilationViewer from "../component/SingleHotelCollectionConcilationViewer";


const CollectionConcilationScreen = ({ collectionConcilation, isLoading }) => {
  let allHotels = Object.keys(collectionConcilation);

  if (isLoading) {
    return <LoadingBookings />;
  }

  return (
    <>
    <CollectionConcilationGraph collectionConcilation={collectionConcilation} />
      {allHotels.map((hotel) => {
        return (
          <SingleHotelCollectionConcilationViewer
            key={hotel}
            hotelName={hotel}
            allDateData={collectionConcilation[hotel]}
          />
        );
      })}
    </>
  );
};

export default CollectionConcilationScreen;
