import { InputLabel, MenuItem, Select, Typography } from "@mui/material";

const AddExpenserSourceSelector = ({
  value,
  onValueChange,
  required,
  sources,
  label,
}) => {
  return (
    <>
      <InputLabel
        variant="standard"
        sx={{
          width: "100%",
          margin: "3px 0px",
        }}
      >
        <Typography variant="h6" color="primary" fontSize="15px">
          {label}
        </Typography>
      </InputLabel>
      <Select
        fullWidth
        value={value}
        onChange={(e) => {
          onValueChange(e.target.value);
        }}
        variant="outlined"
        margin="none"
        label="Hotel"
        sx={{
          margin: "8px 0px",
        }}
        required={Boolean(required)}
        style={{
          marginLeft: "1px",
        }}
      >
        {sources.map((source) => {
          return (
            <MenuItem key={source.id} value={source.id}>
              {source.name}
            </MenuItem>
          );
        })}
      </Select>
    </>
  );
};

export default AddExpenserSourceSelector;
