import {
  Box,
  Button,
  FormHelperText,
  MenuItem,
  Select,
  Stack,
  TextField,
  Typography,
  styled,
} from "@mui/material";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { useState } from "react";
import dayjs from "dayjs";
import "dayjs/locale/en-gb";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { createTask } from "../../../utils/task/TaskActions";

const TaskBox = styled(Box)({
  width: "100%",
  height: "auto",
});
const SubmitButton = styled(Button)({
  backgroundColor: "black",
  padding: "14px",
});

const CreateTask = ({ token, companiesData }) => {
  const [priority, setPriority] = useState("MEDIUM");
  const [dueDate, setDueDate] = useState(dayjs().format("DD-MM-YYYY"));
  const [selectedCompany, setSelectedCompany] = useState("");
  const [selectedDepartment, setSelectedDepartment] = useState("");

  const queryClient = useQueryClient();

  const {
    mutate: mutateCreateTask,
    isPending: isTaskCreatePending,
    isError: isTaskCreateError,
  } = useMutation({
    mutationFn: ({
      title,
      description,
      dueDate,
      companyId,
      priority,
      department,
    }) => {
      return createTask(
        title,
        description,
        dueDate,
        companyId,
        priority,
        department,
        token
      );
    },
    onError: (error) => {
      console.log(error);
    },
    mutationKey: ["createTask"],
    onSuccess: (data) => {
      queryClient.invalidateQueries({ queryKey: ["tasks"] });
    },
  });

  const handleSubmit = (e) => {
    e.preventDefault();
    const formData = new FormData(e.target);
    const taskName = formData.get("title");
    const description = formData.get("description");

    if (
      !taskName ||
      !description ||
      !dueDate ||
      !selectedCompany ||
      !selectedDepartment
    ) {
      return alert("Enter all fields!");
    }

    if (taskName.length < 5) {
      return alert("Task name should be atleast 5 characters long");
    }
    if (description.length < 10) {
      return alert("Description should be atleast 10 characters long");
    }

    mutateCreateTask({
      title: taskName,
      description,
      dueDate,
      companyId: selectedCompany,
      priority,
      department: selectedDepartment,
    });

    e.target.reset();
  };
  console.log(selectedCompany)

  return (
    <TaskBox>
      <form onSubmit={handleSubmit}>
        <Stack spacing={2} marginY={2}>
          <TextField
            name="title"
            required
            label="Task Name"
            variant="outlined"
          />
          <TextField
            name="description"
            label="Description"
            variant="outlined"
            multiline
            required
          />

          <LocalizationProvider
            dateAdapter={AdapterDayjs}
            adapterLocale="en-gb"
          >
            <DemoContainer components={["DatePicker"]}>
              <DatePicker
                label="Due Date"
                fullWidth
                sx={{ width: "100%", marginTop: 2 }}
                value={dayjs(dueDate, "DD-MM-YYYY")}
                onChange={(date) => {
                  setDueDate(dayjs(date).format("DD-MM-YYYY"));
                }}
              />
            </DemoContainer>
          </LocalizationProvider>

          {console.log(dueDate)}
          <FormHelperText sx={{ fontStyle: "italic", fontWeight: "600" }}>
            Select Company
          </FormHelperText>
          <Select
            labelId="company"
            id="company"
            label="company"
            value={selectedCompany}
            onChange={(e) => setSelectedCompany(e.target.value)}
            placeholder="Select Company"
          >
            {companiesData &&
              companiesData.map((company) => (
                <MenuItem key={company._id} value={company._id}>
                  {company.name}
                </MenuItem>
              ))}
          </Select>
         
          <FormHelperText sx={{ fontStyle: "italic", fontWeight: "600" }}>
            Select Department
          </FormHelperText>
          <Select
            labelId="department"
            id="department"
            label="department"
            value={selectedDepartment}
            onChange={(e) => setSelectedDepartment(e.target.value)}
            placeholder="Select Department"
          >
            {selectedCompany &&
              companiesData.find(cc => cc._id == selectedCompany).departments.map((department, index) => (
                <MenuItem key={index} value={department}>
                  {department}
                </MenuItem>
              ))}
          </Select>
         
          <FormHelperText sx={{ fontStyle: "italic", fontWeight: "600" }}>
            Select priority
          </FormHelperText>
          <Select
            labelId="priority"
            id="priority"
            label="Priority"
            value={priority}
            onChange={(e) => setPriority(e.target.value)}
            placeholder="Priority"
          >
            <MenuItem value={"HIGH"}>HIGH</MenuItem>
            <MenuItem value={"MEDIUM"}>MEDIUM</MenuItem>
            <MenuItem value={"LOW"}>LOW</MenuItem>
          </Select>
          

          <Button variant="contained" sx={{ padding: "14px" }} type="submit">
            <Typography>Create Task</Typography>
          </Button>
        </Stack>
      </form>
    </TaskBox>
  );
};
export default CreateTask;
