const DOMAIN_NAME = "https://api.oxyhotels.com";
// const DOMAIN_NAME = `http://${window.location.hostname}:4000`;

const TASK_DOMAIN_NAME = `https://task.oxyhotels.com`;

const Constant = {
  COMPANY_NAME: "Oxy",
  DOMAIN_NAME: DOMAIN_NAME,
  authRoute: `${DOMAIN_NAME}/manager/auth`,
  getHotelsRoute: `${DOMAIN_NAME}/manager/getPermissions`,
  getUpcomingBookings: `${DOMAIN_NAME}/manager/getUpcomingBookings`,
  getInHouseBookings: `${DOMAIN_NAME}/manager/getInHouseBookings`,
  getCompletedBookings: `${DOMAIN_NAME}/manager/getCompleteBookings`,
  getNeedActionBookings: `${DOMAIN_NAME}/manager/getActionNeededBookings`,
  getPendingPaymentBookings: `${DOMAIN_NAME}/manager/getPendingPaymentBookings`,

  getBookingAnalyticsRoute: `${DOMAIN_NAME}/manager/getBookingAnalytics`,
  getAccountingAnalyticsRoute: `${DOMAIN_NAME}/manager/getAccountingAnalytics`,
  getRevenueReconcilationRoute: `${DOMAIN_NAME}/manager/getRevenueReconcilation`,
  getCollectionConcilationRoute: `${DOMAIN_NAME}/manager/getCollectionConcilation`,

  getCategoryRoute: `${DOMAIN_NAME}/manager/expense/getCategory`,
  updateCategoryRoute: `${DOMAIN_NAME}/manager/expense/updateCategory`,

  getHotelPartnersRoute: `${DOMAIN_NAME}/manager/getHotelPartners`,
  addHotelPartnerRoute: `${DOMAIN_NAME}/manager/addHotelPartner`,

  getExpensersRoute: `${DOMAIN_NAME}/manager/getExpensers`,
  addExpenserRoute: `${DOMAIN_NAME}/manager/addExpenser`,

  getAmountsRoute: `${DOMAIN_NAME}/manager/getHotelAmounts`,
  addSourceRoute: `${DOMAIN_NAME}/manager/addHotelSource`,

  getApprovalsRoute: `${DOMAIN_NAME}/manager/getApprovals`,
  addExpenseRoute: `${DOMAIN_NAME}/manager/addExpense`,
  approveExpenseRoute: `${DOMAIN_NAME}/manager/approveApproval`,
  getApprovedExpensesRoute: `${DOMAIN_NAME}/manager/getApproveds`,
  addPaymentToExpenseRoute: `${DOMAIN_NAME}/manager/addPayment`,

  getPendingAdvancesRoute: `${DOMAIN_NAME}/manager/getPendingAdvances`,
  clearPendingAdvancesRoute: `${DOMAIN_NAME}/manager/clearPendingAdvances`,
  deleteTransaction: `${DOMAIN_NAME}/manager/deleteTransaction`,

  getTrasactionsAnalyticsRoute: `${DOMAIN_NAME}/manager/getTrasactionsAnalytics`,
  balanceSheetBuilderRoute: `${DOMAIN_NAME}/manager/balanceSheetBuilder`,
  getProfitAndLossAnalyticsRoute: `${DOMAIN_NAME}/manager/getProfitAndLossAnalytics`,

  getBookingSalesAnalytics: `${DOMAIN_NAME}/manager/getBookingSalesAnalytics`,
  getCheckInAnalyticsRoutes: `${DOMAIN_NAME}/manager/getBookingCheckInAnalytics`,
  getCheckOutAnalyticsRoutes: `${DOMAIN_NAME}/manager/getBookingCheckOutAnalytics`,
  getFeedbackCountAnalyticsRoutes: `${DOMAIN_NAME}/manager/getFeedbackCountAnalytics`,
  getUserBookingAnalyticsRoute: `${DOMAIN_NAME}/manager/getUserBookingAnalytics`,
  getUserAnalyticsRoute: `${DOMAIN_NAME}/manager/getUserAnalytics`,

  getRevenueAnalyticsRoutes: `${DOMAIN_NAME}/manager/getBookingRevenueAnalytics`,
  getCollectionAnalyticsRoutes: `${DOMAIN_NAME}/manager/getBookingCollectionAnalytics`,
  getAccountSalesAnalyticsRoutes: `${DOMAIN_NAME}/manager/getAccountSalesAnalytics`,

  getBookingSummaryAnalyticsRoutes: `${DOMAIN_NAME}/manager/getBookingSummaryAnalytics`,

  getFeedbackAnalyticsRoutes: `${DOMAIN_NAME}/manager/getFeedbackAnalytics`,

  isTimeUnBoundedMarking: true,
  isMeOpening: false,

  BookingSourcesShort: ["OX", "WH", "WA", "OT"],
  CheckInSourcesShort: ["QCI", "CCI"],
  CheckOutSourcesShort: ["QCO", "CCO", "NCO"],
  FeedbackCountAnalyticsShort: ["FD", "NFD", "NCO"],
  UserBookingsSourcesShort: ["OOXY", "OUSER", "USER", "OXY"],

  UserAnalyticsShort: ["USER", "BOUNCE", "OXY"],

  RevenueAnalyticsShort: ["XBR", "XECR", "OBR", "OECR", "CFEE", "GST"],

  CollectionAnalyticsShort: ["Cash", "Bank", "Ota","TidCARD","TidUPI"],
  FeedbackAnalyticsShort: ["SF", "CHE", "RCN", "RMC", "AP"],

  // Task Manager Routes

  companiesRoute: `${DOMAIN_NAME}/task/company`,
  usersRoute: `${DOMAIN_NAME}/task/users`,

  getComapnyRoute: `${TASK_DOMAIN_NAME}/task/company`,
  getTaskRoute: `${TASK_DOMAIN_NAME}/task`,

  ActionsTaskRoute: `${TASK_DOMAIN_NAME}/task/actions`,
  UsersTaskRoute: `${TASK_DOMAIN_NAME}/task/users`,
  AssignTaskRoute: `${TASK_DOMAIN_NAME}/action/assign`,
  ForwardTaskRoute: `${TASK_DOMAIN_NAME}/action/forward`,
  CommonActionRoute: `${TASK_DOMAIN_NAME}/action/takeAction`,
  DueDateChangeRoute: `${TASK_DOMAIN_NAME}/action/dueDateChange`,
  CompletionRequestRoute: `${TASK_DOMAIN_NAME}/action/complete`,
  TextMessageRoute: `${TASK_DOMAIN_NAME}/action/sendTextMessage`,

  
  FINANCIAL_YEAR: "2023"
};

export default Constant;
