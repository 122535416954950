import {
  ArrowDownward,
  CopyAll,
  KeyboardArrowDown,
  KeyboardArrowUp,
} from "@mui/icons-material";
import {
  Button,
  Collapse,
  IconButton,
  Stack,
  Tooltip,
  Typography,
} from "@mui/material";
import { useState } from "react";
import SingleDateRevenueViewer from "./SingleDateRevenueViewer";

const SingleEntityRevenueViewer = ({ entityName, allDateData }) => {
  let allDates = Object.keys(allDateData);
  const [isOpen, setIsOpen] = useState(false);
  const [copyText, setCopyText] = useState("Copy");
  const [downloadText, setDownloadText] = useState("Download");

  const onCopyText = (isDownload) => {
    isDownload ? setDownloadText("Downloaded") : setCopyText("Copied");
    setTimeout(() => {
      isDownload ? setDownloadText("Download") : setCopyText("Copy");
    }, 2000);
  };

  const onToggle = () => {
    setIsOpen((p) => !p);
  };

  return (
    <>
      <Stack
        direction="row"
        sx={{
          flexGrow: 1,
          justifyContent: "space-between",
          display: "flex",
          margin: "10px 10px",
        }}
      >
        <Button
          onClick={onToggle}
          disableRipple
          sx={{
            justifyContent: "space-between",
            display: "flex",
            alignItems: "center",
            flexDirection: "row",
            textTransform: "none",
          }}
        >
          <Typography
            variant="h6"
            color="primary.main"
            sx={{ fontSize: "15px" }}
          >
            {entityName}
          </Typography>

          {isOpen ? (
            <KeyboardArrowUp sx={{ marginLeft: "20px" }} />
          ) : (
            <KeyboardArrowDown sx={{ marginLeft: "20px" }} />
          )}
        </Button>

        <div
          style={{
            marginLeft: "100px",
            marginRight: "10px",
          }}
        >
          <Tooltip title={downloadText} placement="left">
            <IconButton
              sx={{
                marginRight: "20px",
              }}
              onClick={() => {
                onCopyText(true);
              }}
            >
              <ArrowDownward />
            </IconButton>
          </Tooltip>
          <Tooltip title={copyText} placement="right">
            <IconButton
              onClick={() => {
                onCopyText(false);
              }}
            >
              <CopyAll />
            </IconButton>
          </Tooltip>
        </div>
      </Stack>

      <Collapse in={isOpen} timeout="auto" unmountOnExit>
        <div style={{ marginLeft: "30px" }}>
          {allDates.map((date) => {
            return (
              <SingleDateRevenueViewer
                date={date}
                items={allDateData[date]}
                file={`${entityName}-${date}`}
                key={entityName + date}
              />
            );
          })}
        </div>
      </Collapse>
    </>
  );
};

export default SingleEntityRevenueViewer;
