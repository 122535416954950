import Graph from "../util/graph";

const SalesDataFinder = (
    bookingAnalytics,
    allDates,
    isHotelSummed,
    isDateSummed,
    sHotels,
    options,
    index,
    title,
    isBar,
    isDataTabular
) => {

    if (!isHotelSummed && !isDateSummed) {
        if (isBar) {
            return options.map((option, oIndex) => {
                return sHotels.map((hotel, index) => {
                    return {
                        label: `${hotel} ${option}`,
                        data: allDates.map((date) => {
                            return isDataTabular ? (
                                bookingAnalytics[hotel][date][option]
                            ) : bookingAnalytics[hotel][date][option].reduce((acc, cur) => {
                                return acc + cur.rooms;
                            }, 0);
                        }),
                        borderColor: Graph.Colors[oIndex],
                        backgroundColor: Graph.Colors[oIndex],
                        ...Graph.ExtraOptions,
                        stack: `Stack - ${hotel}`
                    };
                })
            }).flat()
        }
        return sHotels.map((hotel, index) => {
            return {
                label: hotel,
                data: allDates.map((date) => {
                    let data = Object.keys(bookingAnalytics[hotel][date])
                        .filter((dT) => {
                            if (!options) {
                                return true
                            }
                            return options.includes(dT);
                        })
                        .map((dT) => bookingAnalytics[hotel][date][dT])
                        .flat();
                    return data.reduce((acc, cur) => {
                        return acc + (isDataTabular ? cur : cur.rooms);
                    }, 0)
                }),
                borderColor: Graph.Colors[index],
                backgroundColor: Graph.Colors[index],
                ...Graph.ExtraOptions,
            };
        })
    } else if (isHotelSummed && !isDateSummed) {


        if (isBar) {
            return options.map((option, oIndex) => {
                return {
                    label: `All Hotels ${title ? title : ""} ${option}`,
                    data: allDates.map((date) => {
                        return Object.keys(bookingAnalytics).filter(hot => sHotels.includes(hot)).map(hot => bookingAnalytics[hot]).map(dateData => {
                            return dateData[date][option]
                        }).flat().reduce((acc, cur) => { return acc + (isDataTabular ? cur : cur.rooms) }, 0)
                    }),
                    borderColor: Graph.Colors[oIndex],
                    backgroundColor: Graph.Colors[oIndex],
                    ...Graph.ExtraOptions,
                    stack: "All Hotels"
                }
            }).flat()
        }

        return [
            {
                label: `All Hotels ${title ? title : ""}`,
                data: allDates.map((date) => {
                    return Object.keys(bookingAnalytics).filter(hot => sHotels.includes(hot)).map(hot => bookingAnalytics[hot]).map(dateData => {
                        return dateData[date]
                    }).map(typeData => {
                        return Object.keys(typeData).filter(v => {
                            if (!options) {
                                return true
                            }
                            return options.includes(v)
                        }).map(typee => {
                            return typeData[typee]
                        }).flat()
                    }).flat().reduce((acc, cur) => { return acc + (isDataTabular ? cur : cur.rooms) }, 0)
                }),
                borderColor: Graph.Colors[index ? index : 0],
                backgroundColor: Graph.Colors[index ? index : 0],
                ...Graph.ExtraOptions,
            }
        ]
    } else if (!isHotelSummed && isDateSummed) {

        if (isBar) {
            return options.map((option, oIndex) => {
                return {
                    label: `All Dates ${title ? title : ""} ${option}`,
                    data: sHotels.map(hotel => {
                        let allData = Object.keys(bookingAnalytics[hotel]).map(date => {
                            return isDataTabular ? bookingAnalytics[hotel][date][option] : (
                                bookingAnalytics[hotel][date][option].reduce((acc, cur) => {
                                    return acc + cur.rooms
                                }, 0)
                            )
                        }).reduce((acc, cur) => {
                            return acc + cur
                        }, 0)
                        return allData
                    }),
                    borderColor: Graph.Colors[oIndex],
                    backgroundColor: Graph.Colors[oIndex],
                    ...Graph.ExtraOptions,
                    stack: "All Dates"
                }
            }).flat()
        }

        return [
            {
                label: `All Dates ${title ? title : ""}`,
                data: sHotels.map(hotel => {
                    let allData = Object.keys(bookingAnalytics[hotel]).map(date => {
                        let dateData = options ? (
                            options.map((opt) => {
                                return bookingAnalytics[hotel][date][opt]
                            }).flat()
                        ) : Object.values(bookingAnalytics[hotel][date]).flat()
                        return dateData.reduce((acc, cur) => {
                            return acc + (isDataTabular ? cur : cur.rooms)
                        }, 0)
                    }).reduce((acc, cur) => {
                        return acc + cur
                    }, 0)
                    return allData
                }),
                borderColor: Graph.Colors[index ? index : 0],
                backgroundColor: Graph.Colors[index ? index : 0],
                ...Graph.ExtraOptions,
            }
        ]
    } else if (isHotelSummed && isDateSummed) {

        if (isBar) {
            return options.map((option, oIndex) => {
                return {
                    label: `All ${title ? title : ""} ${option}`,
                    data: [
                        sHotels.map(hotel => {
                            let allData = Object.keys(bookingAnalytics[hotel]).map(date => {
                                return isDataTabular ? bookingAnalytics[hotel][date][option] : bookingAnalytics[hotel][date][option].reduce((acc, cur) => {
                                    return acc + cur.rooms
                                }, 0)
                            }).reduce((acc, cur) => {
                                return acc + cur
                            }, 0)
                            return allData
                        }).reduce((acc, cur) => {
                            return acc + cur
                        }, 0)
                    ],
                    borderColor: Graph.Colors[oIndex],
                    backgroundColor: Graph.Colors[oIndex],
                    ...Graph.ExtraOptions,
                    stack: "All"
                }
            }).flat()
        }

        return [
            {
                label: `All ${title ? title : ""}`,
                data: [
                    sHotels.map(hotel => {
                        let allData = Object.keys(bookingAnalytics[hotel]).map(date => {
                            let dateData = options ? (
                                options.map((opt) => {
                                    return bookingAnalytics[hotel][date][opt]
                                }).flat()
                            ) : Object.values(bookingAnalytics[hotel][date]).flat()
                            return dateData.reduce((acc, cur) => {
                                return acc + (isDataTabular ? cur : cur.rooms)
                            }, 0)
                        }).reduce((acc, cur) => {
                            return acc + cur
                        }, 0)
                        return allData
                    }).reduce((acc, cur) => {
                        return acc + cur
                    }, 0)
                ],
                borderColor: Graph.Colors[index ? index : 0],
                backgroundColor: Graph.Colors[index ? index : 0],
                ...Graph.ExtraOptions,
            }
        ]
    }
}

export default SalesDataFinder
