const labelFinder = (allDates, isHotelSummed, isDateSummed,sHotels) => {
    if (!isHotelSummed && !isDateSummed) {
        return allDates
    } else if(isHotelSummed && !isDateSummed){
        return allDates
    }else if(!isHotelSummed && isDateSummed){
        return sHotels
    }else {
        return "All Hotels"
    }
}

export default labelFinder