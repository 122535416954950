import { useContext } from "react";
import { AnalyticsContext } from "../../store/analyticsS";
import { HotelContext } from "../../store/hotel";
import CustomDateRangePicker from "../analytics/components/DateRangePicker";
import LoadingBookings from "../common/LoadingBookings";
import FeedBackGraph from "../graph/feedback/FeedBackGraph";
import Constant from "../../constant/Constant";
import SingleHotelFeedbackViewer from "./components/SingleHotelFeedbackViewer";

const FeedbackScreen = () => {
  const analyticsContext = useContext(AnalyticsContext);
  const hotelContext = useContext(HotelContext);
  let feedbackAnalytics = analyticsContext.feedbackAnalytics;
  let allHotels = Object.keys(feedbackAnalytics);

  const dateContent = (
    <div style={{ position: "absolute", top: "15px", left: "30px", zIndex: 4 }}>
      <CustomDateRangePicker
        startDay={hotelContext.startDay}
        days={hotelContext.days}
        onDateChange={(date, days) => {
          hotelContext.onUpdateDate(date, days);
        }}
      />
    </div>
  );

  if (
    analyticsContext.isFeedbackAnalyticsLoading ||
    !analyticsContext.isFeedbackAnalyticsLoaded
  ) {
    return (
      <>
        {dateContent}
        <LoadingBookings />
      </>
    );
  }

  return (
    <>
      {dateContent}
      {allHotels.length > 0 && (
        <FeedBackGraph
          feedbackAnalytics={feedbackAnalytics}
          title="Feedback Analytics"
          allOptions={Constant.FeedbackAnalyticsShort}
          isTablularData={analyticsContext.isTabularData}
          onToggleTabularData={() => {
            analyticsContext.onToggleTabularData();
          }}
          isBar={analyticsContext.isBar}
          isHotel={analyticsContext.isHotel}
          isDate={analyticsContext.isDate}
          isAvg={analyticsContext.isAverage}
          onToggleAverage={() => {
            analyticsContext.onToggleAverage();
          }}
          onToggleBar={() => {
            analyticsContext.onToggleBar();
          }}
          onToggleHotel={() => {
            analyticsContext.onToggleHotel();
          }}
          onToggleDate={() => {
            analyticsContext.onToggleDate();
          }}
        />
      )}
      {!analyticsContext.isTabularData &&
        allHotels.map((hotel) => {
          return (
            <SingleHotelFeedbackViewer
              key={hotel}
              hotelName={hotel}
              allDateData={feedbackAnalytics[hotel]}
            />
          );
        })}
    </>
  );
};

export default FeedbackScreen;
