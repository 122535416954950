function splitArrayIntoPairs(arr) {
    if (!Array.isArray(arr)) {
        throw new Error("Input is not an array");
    }

    const result = [];
    for (let i = 0; i < arr.length - 1; i += 2) {
        result.push([arr[i], arr[i + 1]]);
    }

    if (arr.length % 2 !== 0) {
        result.push([arr[arr.length - 1]]);
    }

    return result;
}

export default splitArrayIntoPairs