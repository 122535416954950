import {
  Card,
  CircularProgress,
  IconButton,
  Stack,
  Typography,
} from "@mui/material";
import AddIcon from "@mui/icons-material/Add";

import { useContext } from "react";
import { TaskContext } from "../../../store/task";
import CompanyViewer from "./CompanyViewer";
import AddCompanyModel from "./AddCompanyModel";

const CompanyListViewer = () => {
  const taskContext = useContext(TaskContext);

  return (
    <Card
      elevation={3}
      sx={{
        flexDirection: "column",
        justifyContent: "center",
        flexGrow: "1",
        width: "50%",
        padding: "15px 10px 10px 15px",
        margin: "40px 20px",
        borderRadius: "10px",
      }}
    >
      <Stack
        direction="row"
        sx={{
          flexDirection: "row",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <Typography variant="h1" fontSize="22px">
          Companies
        </Typography>
        <IconButton
          onClick={() => {
            taskContext.onToggleCompanyAdder();
          }}
        >
          <AddIcon fontSize="20px" color="primary.main" />
        </IconButton>
      </Stack>

      {taskContext.isCompanyLoading || !taskContext.isCompanyLoaded ? (
        <CircularProgress
          sx={{
            minHeight: "200px",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            margin: "auto",
          }}
          color="primary"
          size={25}
        />
      ) : (
        <Stack
          style={{
            marginLeft: "10px",
            maxHeight: "400px",
            overflowY: "scroll",
            margin: "12px 0px 15px 15px",
          }}
          spacing={0}
        >
          {taskContext.companies.map((company) => {
            return (
              <CompanyViewer
                key={company._id}
                company={company}
                onEditClick={() => {
                  taskContext.onSetCompanyPData({
                    name: company.name,
                    parentCompany: company.parentCompany?._id,
                    positions: Object.fromEntries(
                      Object.keys(company.positions).map((pos) => [
                        Math.random(),
                        {
                          position: pos,
                          priority: company.positions[pos].priority,
                          departments: company.positions[pos].departments,
                        },
                      ])
                    ),
                    companyId: company._id,
                    departments:company.departments
                  });
                }}
                isEdit={true}
                onClick={() => {
                  taskContext.onSelectCompany(company._id);
                }}
                isSelected={company._id === taskContext.selectedComapnyId}
              />
            );
          })}
        </Stack>
      )}

      {taskContext.isCompanyAdderOpen && (
        <AddCompanyModel
          isOpen={taskContext.isCompanyAdderOpen}
          onClose={() => {
            taskContext.onToggleCompanyAdder();
          }}
          isLoading={taskContext.isCompanyAdding}
          onSubmit={(data) => {
            taskContext.onAddCompany(data);
          }}
          companies={taskContext.companies}
          pData={taskContext.pData}
        />
      )}
    </Card>
  );
};

export default CompanyListViewer;
