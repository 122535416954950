import axios from "axios";

const GetBookingsWithoutDate =async (route,token,hotelId,cancelToken) => {

    try{
        const response = await axios({
            method:"POST",
            url:route,
            headers:{
                "Authorization":`Bearer ${token}`
            },
            params:{
                hotelId
            },
            cancelToken:cancelToken
        })
        return response.data
    }catch(err){
        console.log(err)
        return err.response ? (err.response.data ? err.response.data : {}) : {}
    }

}

export default GetBookingsWithoutDate