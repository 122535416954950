import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers"
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import dayjs from 'dayjs';
const CustomDatePicker = ({date,onDateChange}) => {

    return (
        <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DatePicker format="DD-MM-YYYY" value={dayjs(date)} onChange={onDateChange} />
        </LocalizationProvider>
    )

}

export default CustomDatePicker