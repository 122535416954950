import { Button, Collapse, Typography } from "@mui/material";
import { useState } from "react";
import { KeyboardArrowDown, KeyboardArrowUp } from "@mui/icons-material";
import FeedbackAnalyticsBookingPreview from "./FeedbackAnalyticsBookingPreview";

const SingleDateFeedbackCountViewer = ({ date, bookings }) => {
  let allTypes = Object.keys(bookings);

  const [isOpen, setIsOpen] = useState(bookings.length <= 4);

  const onToggle = () => {
    setIsOpen((p) => !p);
  };

  return (
    <>
      <Button
        onClick={onToggle}
        disableRipple
        sx={{
          justifyContent: "space-between",
          display: "flex",
          alignItems: "center",
          flexDirection: "row",
          textTransform: "none",
        }}
      >
        <Typography variant="h6" color="primary.main" sx={{ fontSize: "14px" }}>
          {date}
        </Typography>

        {isOpen ? (
          <KeyboardArrowUp sx={{ marginLeft: "20px" }} />
        ) : (
          <KeyboardArrowDown sx={{ marginLeft: "20px" }} />
        )}
      </Button>

      <Collapse in={isOpen} timeout="auto" unmountOnExit>
        <div style={{ marginLeft: "10px" }}>
          {allTypes.map((type) => {
            return bookings[type].map((book) => {
              return (
                <FeedbackAnalyticsBookingPreview key={book.bookingId} booking={book} type={type} />
              );
            });
          })}
        </div>
      </Collapse>
    </>
  );
};

export default SingleDateFeedbackCountViewer;
