import moment from "moment";
import Graph from "../util/graph";

let SampleR = { XBR: 0, XECR: 0, OBR: 914, OECR: 0, CFEE: 60 }

const RevenueReconcilationSingleDateFinder = (
    revenueReconcilation,
    allDates,
    selectedDates,
    isHotelSummed,
    isDateSummed,
    sHotels,
    options,
    isBar
) => {

    let labels = [...selectedDates]
    let methods = []
    sHotels.forEach((sHotel) => {
        selectedDates.forEach(sDate => {
            revenueReconcilation[sHotel][sDate].forEach(cur => {
                let types = Object.keys(cur.data).filter((t) =>
                    options.includes(t)
                );
                types.forEach((curr) => {
                    cur.data[curr].forEach((currr) => {
                        if (!labels.includes(currr.date)) {
                            labels.push(currr.date)
                        }
                        if (!methods.includes(currr.method)) {
                            methods.push(currr.method)
                        }
                    });
                })
            });
        })
    })

    labels = labels.sort((a, b) => {
        let aTime = moment(a, "DD-MMMM").unix()
        let bTime = moment(b, "DD-MMM").unix()
        return aTime - bTime
    })

    if (!isHotelSummed && !isDateSummed) {

        if (isBar) {
            let rData = sHotels.map((sHotel, hIndex) => {
                return selectedDates.map((sDate, dIndex) => {
                    let rData = Object.keys(SampleR).map((option, oIndex) => {
                        return {
                            label: `${sHotel} ${option} ${sDate} Revenue`,
                            data: labels.map((date) => {
                                if (date !== sDate) {
                                    return 0
                                }
                                let data = revenueReconcilation[sHotel][sDate]
                                return data.reduce((acc, cur) => {
                                    return acc + cur.REV[option]
                                }, 0);
                            }),
                            borderColor: Graph.Colors[hIndex + oIndex + dIndex],
                            backgroundColor: Graph.Colors[hIndex + oIndex + dIndex],
                            ...Graph.ExtraOptions,
                            stack: `${sHotel} ${sDate} Revenue`
                        }
                    })
                    let cData = options.map((option, oIndex) => {
                        return methods.map((method, mIndex) => {
                            return {
                                label: `${sHotel} ${option} ${method} ${sDate} Collection`,
                                data: labels.map((date) => {
                                    let data = revenueReconcilation[sHotel][sDate];
                                    return data.reduce((acc, cur) => {
                                        return (
                                            acc + cur.data[option].reduce((acccc, currr) => {
                                                if (date !== currr.date || currr.method !== method) {
                                                    return acccc
                                                }
                                                return acccc + currr.effectiveValue
                                            }, 0)
                                        );
                                    }, 0);
                                }),
                                borderColor: Graph.Colors[hIndex + mIndex + oIndex + 5 + dIndex],
                                backgroundColor: Graph.Colors[hIndex + mIndex + oIndex + 5 + dIndex],
                                ...Graph.ExtraOptions,
                                stack: `${sHotel} ${sDate} Collection`
                            };
                        })
                    }).flat()

                    let pData = {
                        label: `${sHotel} ${sDate} Pending`,
                        data: labels.map((date) => {
                            if (date !== sDate) {
                                return 0
                            }
                            let data = revenueReconcilation[sHotel][sDate]
                            return data.reduce((acc, cur) => {
                                return acc + cur.PEND
                            }, 0);
                        }),
                        borderColor: Graph.Colors[hIndex + sHotels.length + options.length + methods.length + dIndex],
                        backgroundColor: Graph.Colors[hIndex + sHotels.length + options.length + methods.length + dIndex],
                        ...Graph.ExtraOptions,
                        stack: `${sHotel} ${sDate} Pending`
                    }

                    return [rData, cData, pData].flat()
                }).flat()
            }).flat();

            return {
                labels: labels,
                datasets: rData
            }
        }

        let rData = sHotels.map((sHotel, hIndex) => {
            return selectedDates.map((sDate, dIndex) => {
                return {
                    label: `${sHotel} ${sDate} Revenue`,
                    data: labels.map((date) => {
                        if (date !== sDate) {
                            return 0
                        }
                        let data = revenueReconcilation[sHotel][sDate]
                        return data.reduce((acc, cur) => {
                            let types = Object.keys(cur.REV)
                            return (
                                acc +
                                types.reduce((accc, curr) => {
                                    return accc + cur.REV[curr];
                                }, 0)
                            );
                        }, 0);
                    }),
                    borderColor: Graph.Colors[hIndex + dIndex],
                    backgroundColor: Graph.Colors[hIndex + dIndex],
                    ...Graph.ExtraOptions,
                }
            })
        }).flat();

        let cData = sHotels.map((sHotel, hIndex) => {
            return selectedDates.map((sDate, dIndex) => {
                return {
                    label: `${sHotel} ${sDate} Collection`,
                    data: labels.map((date) => {
                        let data = revenueReconcilation[sHotel][sDate];
                        return data.reduce((acc, cur) => {
                            let types = Object.keys(cur.data).filter((t) =>
                                options.includes(t)
                            );
                            return (
                                acc +
                                types.reduce((accc, curr) => {
                                    return accc + cur.data[curr].reduce((acccc, currr) => {
                                        if (date !== currr.date) {
                                            return acccc
                                        }
                                        return acccc + currr.effectiveValue
                                    }, 0);
                                }, 0)
                            );
                        }, 0);
                    }),
                    borderColor: Graph.Colors[hIndex + dIndex + sHotel.length + selectedDates.length],
                    backgroundColor: Graph.Colors[hIndex + dIndex + sHotel.length + selectedDates.length],
                    ...Graph.ExtraOptions,
                };
            })
        }).flat()

        let pData = sHotels.map((sHotel, hIndex) => {
            return selectedDates.map((sDate, dIndex) => {
                return {
                    label: `${sHotel} ${sDate} Pending`,
                    data: labels.map((date) => {
                        if (date !== sDate) {
                            return 0
                        }
                        let data = revenueReconcilation[sHotel][sDate]
                        return data.reduce((acc, cur) => {
                            return acc + cur.PEND
                        }, 0);
                    }),
                    borderColor: Graph.Colors[hIndex + sHotel.length * 2 + dIndex],
                    backgroundColor: Graph.Colors[hIndex + sHotel.length * 2 + dIndex],
                    ...Graph.ExtraOptions,
                }
            })
        }).flat();

        return {
            labels: labels,
            datasets: [rData, cData, pData].flat()
        }

    } else if (isHotelSummed && !isDateSummed) {

        if (isBar) {
            let rData = selectedDates.map((sDate, dIndex) => {
                return Object.keys(SampleR).map((option, oIndex) => {
                    return {
                        label: `All Hotels ${sDate} ${option} Revenue`,
                        data: labels.map((date) => {
                            if (date !== sDate) {
                                return 0
                            }
                            return sHotels.reduce((hAcc, sHotel) => {
                                return hAcc + revenueReconcilation[sHotel][sDate].reduce((acc, cur) => {
                                    return acc + cur.REV[option]
                                }, 0)
                            }, 0)
                        }),
                        borderColor: Graph.Colors[oIndex + dIndex],
                        backgroundColor: Graph.Colors[oIndex + dIndex],
                        ...Graph.ExtraOptions,
                        stack: `All Hotels ${sDate} Revenue`
                    }
                })
            }).flat()

            let cData = selectedDates.map((sDate, dIndex) => {
                return options.map((option, oIndex) => {
                    return methods.map((method, mIndex) => {
                        return {
                            label: `All Hotels ${sDate} ${option} ${method} Collection`,
                            data: labels.map((date) => {
                                return sHotels.reduce((hAcc, sHotel) => {
                                    return hAcc + revenueReconcilation[sHotel][sDate].reduce((acc, cur) => {
                                        return (
                                            acc + cur.data[option].reduce((acccc, currr) => {
                                                if (date !== currr.date || currr.method !== method) {
                                                    return acccc
                                                }
                                                return acccc + currr.effectiveValue
                                            }, 0)
                                        );
                                    }, 0);
                                }, 0)
                            }),
                            borderColor: Graph.Colors[Object.keys(SampleR).length + oIndex + mIndex + dIndex],
                            backgroundColor: Graph.Colors[Object.keys(SampleR).length + oIndex + mIndex + dIndex],
                            ...Graph.ExtraOptions,
                            stack: `All Hotels ${sDate} collection`
                        };
                    })
                }).flat()
            }).flat()

            let pData = selectedDates.map((sDate, dIndex) => {
                return {
                    label: `All Hotels ${sDate} Pending`,
                    data: labels.map((date) => {
                        if (date !== sDate) {
                            return 0
                        }
                        return sHotels.reduce((hAcc, sHotel) => {
                            return hAcc + revenueReconcilation[sHotel][sDate].reduce((acc, cur) => {
                                return acc + cur.PEND
                            }, 0)
                        }, 0)
                    }),
                    borderColor: Graph.Colors[Object.keys(SampleR).length + options.length + methods.length + 1 + dIndex],
                    backgroundColor: Graph.Colors[Object.keys(SampleR).length + options.length + methods.length + 1 + dIndex],
                    ...Graph.ExtraOptions,
                    stack: `All Hotels ${sDate} Pending`
                }
            })


            return {
                labels: labels,
                datasets: [rData, cData, pData].flat()
            }
        }

        let rData = selectedDates.map((sDate, dIndex) => {
            return {
                label: `All Hotels ${sDate} Revenue`,
                data: labels.map((date) => {
                    if (date !== sDate) {
                        return 0
                    }
                    return sHotels.reduce((hAcc, sHotel) => {
                        return hAcc + revenueReconcilation[sHotel][sDate].reduce((acc, cur) => {
                            let types = Object.keys(cur.REV)
                            return (
                                acc +
                                types.reduce((accc, curr) => {
                                    return accc + cur.REV[curr];
                                }, 0)
                            );
                        }, 0)
                    }, 0)
                }),
                borderColor: Graph.Colors[dIndex],
                backgroundColor: Graph.Colors[dIndex],
                ...Graph.ExtraOptions,
            }
        })

        let cData = selectedDates.map((sDate, dIndex) => {
            return {
                label: `All Hotels ${sDate} Collection`,
                data: labels.map((date) => {
                    return sHotels.reduce((hAcc, sHotel) => {
                        return hAcc + revenueReconcilation[sHotel][sDate].reduce((acc, cur) => {
                            let types = Object.keys(cur.data).filter((t) =>
                                options.includes(t)
                            );
                            return (
                                acc +
                                types.reduce((accc, curr) => {
                                    return accc + cur.data[curr].reduce((acccc, currr) => {
                                        if (date !== currr.date) {
                                            return acccc
                                        }
                                        return acccc + currr.effectiveValue
                                    }, 0);
                                }, 0)
                            );
                        }, 0);
                    }, 0)
                }),
                borderColor: Graph.Colors[selectedDates.length + dIndex],
                backgroundColor: Graph.Colors[selectedDates.length + dIndex],
                ...Graph.ExtraOptions,
            };
        })

        let pData = selectedDates.map((sDate, dIndex) => {
            return {
                label: `All Hotels ${sDate} Pending`,
                data: labels.map((date) => {
                    if (date !== sDate) {
                        return 0
                    }
                    return sHotels.reduce((hAcc, sHotel) => {
                        return hAcc + revenueReconcilation[sHotel][sDate].reduce((acc, cur) => {
                            return acc + cur.PEND
                        }, 0)
                    }, 0)
                }),
                borderColor: Graph.Colors[selectedDates.length * 2 + dIndex],
                backgroundColor: Graph.Colors[selectedDates.length * 2 + dIndex],
                ...Graph.ExtraOptions,
            }
        })

        return {
            labels: labels,
            datasets: [rData, cData, pData].flat()
        }

    } else if (!isHotelSummed && isDateSummed) {

        if (isBar) {
            let rData = sHotels.map((sHotel, hIndex) => {
                return selectedDates.map((sDate, dIndex) => {
                    let cData = options.map((option, oIndex) => {
                        return methods.map((method, mIndex) => {
                            return {
                                label: `${sHotel} ${sDate} ${option} ${method} Collection`,
                                data: [
                                    revenueReconcilation[sHotel][sDate].reduce((acc, cur) => {
                                        return (
                                            acc + cur.data[option].reduce((acccc, currr) => {
                                                if (currr.method !== method) {
                                                    return acccc
                                                }
                                                return acccc + currr.effectiveValue
                                            }, 0)
                                        );
                                    }, 0)
                                ],
                                borderColor: Graph.Colors[hIndex * 2 + mIndex + oIndex + dIndex],
                                backgroundColor: Graph.Colors[hIndex * 2 + mIndex + oIndex + dIndex],
                                ...Graph.ExtraOptions,
                                stack: `${sHotel} ${sDate} Collection`
                            };
                        })
                    }).flat()
                    let rData = Object.keys(SampleR).map((option, oIndex) => {
                        return {
                            label: `${sHotel} ${sDate} ${option} Revenue`,
                            data: [
                                revenueReconcilation[sHotel][sDate].reduce((acc, cur) => {
                                    return acc + cur.REV[option]
                                }, 0)
                            ],
                            borderColor: Graph.Colors[hIndex + oIndex + dIndex],
                            backgroundColor: Graph.Colors[hIndex + oIndex + dIndex],
                            ...Graph.ExtraOptions,
                            stack: `${sHotel} ${sDate} Revenue`
                        }
                    })

                    let pData = {
                        label: `${sHotel} ${sDate} Pending`,
                        data: [
                            revenueReconcilation[sHotel][sDate].reduce((acc, cur) => {
                                return acc + cur.PEND
                            }, 0)
                        ],
                        borderColor: Graph.Colors[hIndex + options.length + methods.length + sHotels.length],
                        backgroundColor: Graph.Colors[hIndex + options.length + methods.length + sHotel.length],
                        ...Graph.ExtraOptions,
                        stack: `${sHotel} ${sDate} Pending`
                    }
                    return [rData, cData, pData].flat()
                }).flat()
            }).flat();


            return {
                labels: ["Revenue And Collection"],
                datasets: rData
            }
        }

        let rData = sHotels.map((sHotel, hIndex) => {
            return selectedDates.map((sDate, dIndex) => {
                return {
                    label: `${sHotel} ${sDate} Revenue`,
                    data: [
                        revenueReconcilation[sHotel][sDate].reduce((acc, cur) => {
                            let types = Object.keys(cur.REV)
                            return (
                                acc +
                                types.reduce((accc, curr) => {
                                    return accc + cur.REV[curr];
                                }, 0)
                            );
                        }, 0)
                    ],
                    borderColor: Graph.Colors[hIndex + dIndex],
                    backgroundColor: Graph.Colors[hIndex + dIndex],
                    ...Graph.ExtraOptions,
                }
            })
        }).flat();

        let cData = sHotels.map((sHotel, hIndex) => {
            return selectedDates.map((sDate, dIndex) => {
                return {
                    label: `${sHotel} ${sDate} Collection`,
                    data: [
                        revenueReconcilation[sHotel][sDate].reduce((acc, cur) => {
                            let types = Object.keys(cur.data).filter((t) =>
                                options.includes(t)
                            );
                            return (
                                acc +
                                types.reduce((accc, curr) => {
                                    return accc + cur.data[curr].reduce((acccc, currr) => {
                                        return acccc + currr.effectiveValue
                                    }, 0);
                                }, 0)
                            );
                        }, 0)
                    ],
                    borderColor: Graph.Colors[hIndex + dIndex],
                    backgroundColor: Graph.Colors[hIndex + dIndex],
                    ...Graph.ExtraOptions,
                };
            })
        }).flat()

        let pData = sHotels.map((sHotel, hIndex) => {
            return selectedDates.map((sDate, dIndex) => {
                return {
                    label: `${sHotel} ${sDate} Pending`,
                    data: [
                        revenueReconcilation[sHotel][sDate].reduce((acc, cur) => {
                            return acc + cur.PEND
                        }, 0)
                    ],
                    borderColor: Graph.Colors[hIndex + dIndex],
                    backgroundColor: Graph.Colors[hIndex + dIndex],
                    ...Graph.ExtraOptions,
                }
            })
        }).flat();

        return {
            labels: ["Revenue And Collection"],
            datasets: [rData, cData, pData].flat()
        }

    } else if (isHotelSummed && isDateSummed) {

        if (isBar) {

            let cData = selectedDates.map((sDate, dIndex) => {
                return options.map((option, oIndex) => {
                    return methods.map((method, mIndex) => {
                        return {
                            label: `All Hotels ${sDate} ${option} ${method} Collection`,
                            data: [
                                sHotels.reduce((hAcc, sHotel) => {
                                    return hAcc + revenueReconcilation[sHotel][sDate].reduce((acc, cur) => {
                                        return (
                                            acc + cur.data[option].reduce((acccc, currr) => {
                                                if (currr.method !== method) {
                                                    return acccc
                                                }
                                                return acccc + currr.effectiveValue
                                            }, 0)
                                        );
                                    }, 0)
                                }, 0)
                            ],
                            borderColor: Graph.Colors[oIndex + Object.keys(SampleR).length + mIndex + dIndex],
                            backgroundColor: Graph.Colors[oIndex + Object.keys(SampleR).length + mIndex + dIndex],
                            ...Graph.ExtraOptions,
                            stack: `All Hotels ${sDate} Collection`
                        };
                    })
                }).flat()
            }).flat()

            let rData = selectedDates.map((sDate, dIndex) => {
                return Object.keys(SampleR).map((option, oIndex) => {
                    return {
                        label: `All Hotels ${sDate} ${option} Revenue`,
                        data: [
                            sHotels.reduce((hAcc, sHotel) => {
                                return hAcc + revenueReconcilation[sHotel][sDate].reduce((acc, cur) => {
                                    return acc + cur.REV[option]
                                }, 0)
                            }, 0)
                        ],
                        borderColor: Graph.Colors[oIndex + dIndex],
                        backgroundColor: Graph.Colors[oIndex + dIndex],
                        ...Graph.ExtraOptions,
                        stack: `All ${sDate} Hotels Revenue`
                    }
                })
            }).flat()

            let pData = selectedDates.map((sDate, dIndex) => {
                return {
                    label: `All Hotels ${sDate} Pending`,
                    data: [
                        sHotels.reduce((hAcc, sHotel) => {
                            return hAcc + revenueReconcilation[sHotel][sDate].reduce((acc, cur) => {
                                return acc + cur.PEND
                            }, 0)
                        }, 0)
                    ],
                    borderColor: Graph.Colors[options.length + Object.keys(SampleR).length + 1 + dIndex],
                    backgroundColor: Graph.Colors[options.length + Object.keys(SampleR).length + 1 + dIndex],
                    ...Graph.ExtraOptions,
                    stack: `All Hotels ${sDate} Pending`
                }
            })

            return {
                labels: ["Revenue And Collection"],
                datasets: [rData, cData, pData].flat()
            }
        }

        let rData = selectedDates.map((sDate, dIndex) => {
            return {
                label: `All Hotels ${sDate} Revenue`,
                data: [
                    sHotels.reduce((hAcc, sHotel) => {
                        return hAcc + revenueReconcilation[sHotel][sDate].reduce((acc, cur) => {
                            let types = Object.keys(cur.REV)
                            return (
                                acc +
                                types.reduce((accc, curr) => {
                                    return accc + cur.REV[curr];
                                }, 0)
                            );
                        }, 0)
                    }, 0)
                ],
                borderColor: Graph.Colors[dIndex],
                backgroundColor: Graph.Colors[dIndex],
                ...Graph.ExtraOptions,
                stack: `All Hotels ${sDate} Revenue`
            }
        })

        let cData = selectedDates.map((sDate, dIndex) => {
            return {
                label: `All Hotels ${sDate} Collection`,
                data: [
                    sHotels.reduce((hAcc, sHotel) => {
                        return hAcc + revenueReconcilation[sHotel][sDate].reduce((acc, cur) => {
                            let types = Object.keys(cur.data).filter((t) =>
                                options.includes(t)
                            );
                            return (
                                acc +
                                types.reduce((accc, curr) => {
                                    return accc + cur.data[curr].reduce((acccc, currr) => {
                                        return acccc + currr.effectiveValue
                                    }, 0);
                                }, 0)
                            );
                        }, 0)
                    }, 0)
                ],
                borderColor: Graph.Colors[dIndex],
                backgroundColor: Graph.Colors[dIndex],
                ...Graph.ExtraOptions,
                stack: `All Hotels ${sDate} Collection`
            };
        })

        let pData = selectedDates.map((sDate, dIndex) => {
            return {
                label: `All Hotels ${sDate} Pending`,
                data: [
                    sHotels.reduce((hAcc, sHotel) => {
                        return hAcc + revenueReconcilation[sHotel][selectedDates].reduce((acc, cur) => {
                            return acc + cur.PEND
                        }, 0)
                    }, 0)
                ],
                borderColor: Graph.Colors[dIndex],
                backgroundColor: Graph.Colors[dIndex],
                ...Graph.ExtraOptions,
                stack: `All Hotels ${sDate} Pending`
            }
        })

        return {
            labels: ["Revenue And Collection"],
            datasets: [rData, cData, pData].flat()
        }
    }
}

export default RevenueReconcilationSingleDateFinder