import { useEffect, useState } from "react";
import { ExpandMore } from "@mui/icons-material";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  MenuItem,
  Select,
  Stack,
  Typography,
} from "@mui/material";
import { useQuery } from "@tanstack/react-query";
import allRoutes from "../../../pages/routes";
import { getAllCompanies, getAllTasks } from "../../../utils/task/TaskActions";
import ChatContainer from "../component/ChatContainer";
import ChatSideBar from "../component/ChatSideBar";
import CreateTask from "../component/CreateTask";
import { TaskStatus } from "../../../utils/task/ActionTypes";

const TaskUserScreen = ({ selRoute }) => {
  const { taskToken } = JSON.parse(localStorage.getItem("authToken"));
  const [selectedTask, setSelectedTask] = useState(null);

  const [selectedCompany, setSelectedCompany] = useState("ALL");
  const [selectedDepartment, setSelectedDepartment] = useState("ALL");
  const [selectedStatus, setSelectedStatus] = useState("ALL");

  const {
    data: companiesData,
    isLoading: isCompaniesLoading,
    error: companiesError,
    isError: isCompaniesError,
    refetch: companiesRefetch,
  } = useQuery({
    queryFn: () => getAllCompanies(taskToken),
    queryKey: ["companies"],
    retry: 1,
  });

  const {
    data: tasksData,
    isLoading: isTasksLoading,
    error: tasksError,
    isError: isTasksError,
    refetch: refetchTask,
  } = useQuery({
    queryFn: () => getAllTasks(taskToken, selectedCompany, selectedDepartment),
    queryKey: ["tasks"],
    retry: 1,
  });

  useEffect(() => {
    refetchTask();
  }, [refetchTask, selectedCompany, selectedDepartment]);

  if (selRoute !== allRoutes[26]) {
    return;
  }

  return (
    <Stack
      sx={{
        height: "100%",
      }}
      direction={"row"}
    >
      <Stack
        gap={"20px"}
        sx={{
          width: "30%",
          margin: "10px",
          height: "calc(100% - 20px)",
          overflowY: "scroll",
          "::-webkit-scrollbar": {
            display: "none",
          },
        }}
      >
        <Stack sx={{ border: "1px solid #ccc", borderRadius: "5px" }}>
          <Accordion sx={{ height: "auto" }}>
            <AccordionSummary
              expandIcon={<ExpandMore />}
              aria-controls="panel1-content"
              id="panel1-header"
            >
              <Typography variant="h6">Create Task</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <CreateTask
                token={taskToken}
                companiesData={companiesData?.companies}
                departmentsData={companiesData?.departments}
              />
            </AccordionDetails>
          </Accordion>
        </Stack>

        <Stack
          sx={{
            border: "1px solid #ccc",
            borderRadius: "5px",
          }}
        >
          <Accordion defaultExpanded={true}>
            <Stack
              flexDirection={"row"}
              gap={3}
              alignItems={"center"}
              justifyContent={"space-between"}
              paddingY={1}
              paddingX={2}
            >
              <Typography variant="h6">Tasks</Typography>
              <Select
                labelId="status"
                id="status"
                label="status"
                value={selectedStatus}
                placeholder="Select Status"
                onChange={(e) => setSelectedStatus(e.target.value)}
                sx={{
                  width: "182px",
                  height: "40px",
                }}
              >
                <MenuItem value="ALL">All</MenuItem>
                {Object.entries(TaskStatus).map(([key, value]) => (
                  <MenuItem key={key} value={key}>
                    {value.status}
                  </MenuItem>
                ))}
              </Select>
            </Stack>
            <AccordionDetails>
              <Stack
                gap={1}
                sx={{
                  paddingBottom: "30px",
                  maxHeight: "460px",
                  overflowY: "scroll",
                  "::-webkit-scrollbar": {
                    display: "none",
                  },
                }}
              >
                <Stack flexDirection={"row"} gap={1}>
                  <Select
                    labelId="company"
                    id="company"
                    label="company"
                    value={selectedCompany}
                    onChange={(e) => setSelectedCompany(e.target.value)}
                    placeholder="Select Company"
                    fullWidth
                  >
                    <MenuItem value="ALL">All Companies</MenuItem>
                    {companiesData &&
                      companiesData?.companies?.map((company) => (
                        <MenuItem key={company._id} value={company._id}>
                          {company.name}
                        </MenuItem>
                      ))}
                  </Select>
                  <Select
                    labelId="department"
                    id="department"
                    label="department"
                    value={selectedDepartment}
                    onChange={(e) => setSelectedDepartment(e.target.value)}
                    placeholder="Select Department"
                    fullWidth
                  >
                    <MenuItem value="ALL">All Departments</MenuItem>
                    {console.log(companiesData)}
                    {companiesData &&
                      selectedCompany &&
                      companiesData.companies
                        .find((cc) => cc._id == selectedCompany)
                        ?.departments?.map((department, index) => (
                          <MenuItem key={index} value={department}>
                            {department}
                          </MenuItem>
                        ))}
                  </Select>
                </Stack>
                {tasksData &&
                  tasksData.length > 0 &&
                  tasksData
                    .filter((task) =>
                      selectedStatus === "ALL"
                        ? true
                        : task.status === selectedStatus
                    )
                    .map((task) => (
                      <ChatSideBar
                        key={task?.id}
                        task={task}
                        token={taskToken}
                        selectedTask={selectedTask}
                        setSelectedTask={setSelectedTask}
                      />
                    ))}
              </Stack>
            </AccordionDetails>
          </Accordion>
        </Stack>
      </Stack>

      <Stack
        sx={{
          flexGrow: 1,
          height: "100%",
        }}
      >
        {tasksData && tasksData.length > 0 && selectedTask && (
          <ChatContainer task={selectedTask} token={taskToken} />
        )}
      </Stack>
    </Stack>
  );
};

export default TaskUserScreen;
