import Constant from "../../../constant/Constant"
import Graph from "../util/graph"



const opt = ["Revenue", "Expense", "Profit"]

const profitAndLossDataFinder = (
    profitAndLossAnalytics,
    allDates,
    isHotelSummed,
    isDateSummed,
    sHotels,
    isBar,
    isTablularData,
    eoptions,
    options
) => {


    const allOptions = Constant.RevenueAnalyticsShort
    const sOptions = [...opt].filter(e => eoptions.includes(e))

    if (!isHotelSummed && !isDateSummed) {
        return sOptions.map((option, oIndex) => {
            return sHotels.map((hotel, hIndex) => {
                return {
                    label: `${hotel} ${option}`,
                    data: allDates.map((date) => {
                        let data = profitAndLossAnalytics[option][hotel][date]
                        if (option === opt[0]) {
                            return data.reduce((ac, cu) => {
                                return ac + allOptions.reduce((acc, cur) => {
                                    return acc + cu[cur]
                                }, 0)
                            }, 0)
                        } else if (option === opt[1]) {
                            return isTablularData ? data : (
                                data.reduce((acc, cur) => {
                                    return acc + cur.amount
                                }, 0)
                            )
                        } else {
                            return data
                        }
                    }),
                    borderColor: Graph.Colors[hIndex + oIndex],
                    backgroundColor: Graph.Colors[hIndex + oIndex],
                    ...Graph.ExtraOptions,
                    stack: `${hotel} ${option}`
                }
            })
        }).flat()
    } else if (isHotelSummed && !isDateSummed) {

        return sOptions.map((option, oIndex) => {
            return {
                label: `All Hotels ${option}`,
                data: allDates.map((date) => {
                    let data = sHotels.map(hotel => profitAndLossAnalytics[option][hotel][date]).flat()
                    if (oIndex === 0) {
                        return data.reduce((ac, cu) => {
                            return ac + allOptions.reduce((acc, cur) => {
                                return acc + cu[cur]
                            }, 0)
                        }, 0)
                    } else if (oIndex === 1) {
                        return isTablularData ? data : (
                            data.reduce((acc, cur) => {
                                return acc + cur.amount
                            }, 0)
                        )
                    } else {
                        return data.reduce((ac, cur) => {
                            return ac + cur
                        }, 0)
                    }
                }),
                borderColor: Graph.Colors[oIndex],
                backgroundColor: Graph.Colors[oIndex],
                ...Graph.ExtraOptions,
                stack: `All Hotels ${option}`
            }
        }).flat()

    } else if (!isHotelSummed && isDateSummed) {

        return sOptions.map((option, oIndex) => {
            return {
                label: `All Dates ${option}`,
                data: sHotels.map((hotel) => {
                    let data = allDates.map(date => profitAndLossAnalytics[option][hotel][date]).flat()
                    if (oIndex === 0) {
                        return data.reduce((ac, cu) => {
                            return ac + allOptions.reduce((acc, cur) => {
                                return acc + cu[cur]
                            }, 0)
                        }, 0)
                    } else if (oIndex === 1) {
                        return isTablularData ? data : (
                            data.reduce((acc, cur) => {
                                return acc + cur.amount
                            }, 0)
                        )
                    } else {
                        return data.reduce((ac, cur) => {
                            return ac + cur
                        }, 0)
                    }
                }),
                borderColor: Graph.Colors[oIndex],
                backgroundColor: Graph.Colors[oIndex],
                ...Graph.ExtraOptions,
                stack: `All Dates ${option}`
            }
        }).flat()

    } else if (isHotelSummed && isDateSummed) {


        return sOptions.map((option, oIndex) => {

            let data = sHotels.map(hotel => {
                return allDates.map(date => profitAndLossAnalytics[option][hotel][date]).flat()
            }).flat()

            return {
                label: `All Dates and Hotels ${option}`,
                data: [
                    (() => {
                        if (oIndex === 0) {
                            return data.reduce((ac, cu) => {
                                return ac + allOptions.reduce((acc, cur) => {
                                    return acc + cu[cur]
                                }, 0)
                            }, 0)
                        } else if (oIndex === 1) {
                            return isTablularData ? data : (
                                data.reduce((acc, cur) => {
                                    return acc + cur.amount
                                }, 0)
                            )
                        } else {
                            return data.reduce((ac, cur) => {
                                return ac + cur
                            }, 0)
                        }
                    })()
                ],
                borderColor: Graph.Colors[oIndex],
                backgroundColor: Graph.Colors[oIndex],
                ...Graph.ExtraOptions,
                stack: `All Dates and Hotels ${option}`
            }
        }).flat()


    }








}


export default profitAndLossDataFinder