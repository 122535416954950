import Graph from "../util/graph";

const EntitiesRevenueFinder = (
    entitiesRevenue,
    allDates,
    isEntitySummed,
    isDateSummed,
    sEntities,
    isBar,
    options
) => {

    if (!isEntitySummed && !isDateSummed) {

        if (isBar) {
            return options.map((option, oIndex) => {
                return sEntities.map((entity, index) => {
                    return {
                        label: `${entity} ${option}`,
                        data: allDates.map((date) => {
                            let data = entitiesRevenue[entity][date];
                            return data.reduce((acc, cur) => {
                                return (
                                    acc + cur.itemEffectiveAmount[option]
                                );
                            }, 0);
                        }),
                        borderColor: Graph.Colors[index + ((oIndex > 3 ? (oIndex - 3) : oIndex) * 10)],
                        backgroundColor: Graph.Colors[index + ((oIndex > 3 ? (oIndex - 3) : oIndex) * 10)],
                        tension: 0.2,
                        pointBorderWidth: 7,
                        hoverBorderWidth: 10,
                        stack: `Stack - ${entity}`
                    };
                })
            }).flat()
        }

        return sEntities.map((entity, index) => {
            return {
                label: entity,
                data: allDates.map((date) => {
                    let data = entitiesRevenue[entity][date];
                    return data.reduce((acc, cur) => {
                        let types = Object.keys(cur.itemEffectiveAmount).filter((t) =>
                            options.includes(t)
                        );
                        return (
                            acc +
                            types.reduce((accc, curr) => {
                                return accc + cur.itemEffectiveAmount[curr];
                            }, 0)
                        );
                    }, 0);
                }),
                borderColor: Graph.Colors[index],
                backgroundColor: Graph.Colors[index],
                tension: 0.2,
                pointBorderWidth: 7,
                hoverBorderWidth: 10,
            };
        })


    } else if (isEntitySummed && !isDateSummed) {

        if (isBar) {
            return options.map((option, oIndex) => {
                return {
                    label: `All Entities ${option}`,
                    data: allDates.map((date) => {
                        return Object.keys(entitiesRevenue).filter(ent => sEntities.includes(ent)).map(ent => entitiesRevenue[ent]).map(dateData => {
                            return dateData[date]
                        }).flat().reduce((acc, cur) => {
                            return acc + cur.itemEffectiveAmount[option]
                        }, 0)
                    }),
                    borderColor: Graph.Colors[oIndex],
                    backgroundColor: Graph.Colors[oIndex],
                    tension: 0.2,
                    pointBorderWidth: 7,
                    hoverBorderWidth: 10,
                    stack: `All Entities`
                }
            }).flat()
        }

        return [
            {
                label: "All Entities",
                data: allDates.map((date) => {
                    return Object.keys(entitiesRevenue).filter(ent => sEntities.includes(ent)).map(ent => entitiesRevenue[ent]).map(dateData => {
                        return dateData[date]
                    }).flat().reduce((acc, cur) => {
                        let types = Object.keys(cur.itemEffectiveAmount).filter(opt => options.includes(opt))
                        return acc + types.reduce((accc, curr) => {
                            return accc + cur.itemEffectiveAmount[curr]
                        }, 0)
                    }, 0)
                }),
                borderColor: Graph.Colors[0],
                backgroundColor: Graph.Colors[0],
                tension: 0.2,
                pointBorderWidth: 7,
                hoverBorderWidth: 10,
            }
        ]
    } else if (!isEntitySummed && isDateSummed) {
        let entites = Object.keys(entitiesRevenue).filter(ent => sEntities.includes(ent))

        if (isBar) {
            return options.map((option, oIndex) => {
                return {
                    label: `All Dates ${option}`,
                    data: entites.map(entity => {
                        let allData = Object.keys(entitiesRevenue[entity]).map(date => {
                            let dateData = entitiesRevenue[entity][date]
                            return dateData.reduce((acc, cur) => {
                                return acc + cur.itemEffectiveAmount[option]
                            }, 0)
                        }).reduce((acc, cur) => {
                            return acc + cur
                        }, 0)
                        return allData
                    }),
                    borderColor: Graph.Colors[oIndex],
                    backgroundColor: Graph.Colors[oIndex],
                    tension: 0.2,
                    pointBorderWidth: 7,
                    hoverBorderWidth: 10,
                    stack: `All Dates`
                }
            })
        }
        return [
            {
                label: "All Dates",
                data: entites.map(entity => {
                    let allData = Object.keys(entitiesRevenue[entity]).map(date => {
                        let dateData = entitiesRevenue[entity][date]
                        return dateData.reduce((acc, cur) => {
                            let types = Object.keys(cur.itemEffectiveAmount).filter(opt => options.includes(opt))
                            return acc + types.reduce((accc, curr) => {
                                return accc + cur.itemEffectiveAmount[curr]
                            }, 0)
                        }, 0)
                    }).reduce((acc, cur) => {
                        return acc + cur
                    }, 0)
                    return allData
                }),
                borderColor: Graph.Colors[0],
                backgroundColor: Graph.Colors[0],
                tension: 0.2,
                pointBorderWidth: 7,
                hoverBorderWidth: 10,
            }
        ]
    } else if (isEntitySummed && isDateSummed) {
        let entities = Object.keys(entitiesRevenue).filter(ent => sEntities.includes(ent))
        if (isBar) {
            return options.map((option, oIndex) => {
                return {
                    label: `All Dates ${option}`,
                    data: [
                        entities.map(entity => {
                            let allData = Object.keys(entitiesRevenue[entity]).map(date => {
                                let dateData = entitiesRevenue[entity][date]
                                return dateData.reduce((acc, cur) => {
                                    return acc + cur.itemEffectiveAmount[option]
                                }, 0)
                            }).reduce((acc, cur) => {
                                return acc + cur
                            }, 0)
                            return allData
                        }).reduce((acc, cur) => {
                            return acc + cur
                        }, 0)
                    ],
                    borderColor: Graph.Colors[oIndex],
                    backgroundColor: Graph.Colors[oIndex],
                    tension: 0.2,
                    pointBorderWidth: 7,
                    hoverBorderWidth: 10,
                    stack: "All"
                }
            })
        }
        return [
            {
                label: "All Dates",
                data: [
                    entities.map(entity => {
                        let allData = Object.keys(entitiesRevenue[entity]).map(date => {
                            let dateData = entitiesRevenue[entity][date]
                            return dateData.reduce((acc, cur) => {
                                let types = Object.keys(cur.itemEffectiveAmount).filter(opt => options.includes(opt))
                                return acc + types.reduce((accc, curr) => {
                                    return accc + cur.itemEffectiveAmount[curr]
                                }, 0)
                            }, 0)
                        }).reduce((acc, cur) => {
                            return acc + cur
                        }, 0)
                        return allData
                    }).reduce((acc, cur) => {
                        return acc + cur
                    }, 0)
                ],
                borderColor: Graph.Colors[0],
                backgroundColor: Graph.Colors[0],
                tension: 0.2,
                pointBorderWidth: 7,
                hoverBorderWidth: 10,
            }
        ]
    }
}

export default EntitiesRevenueFinder