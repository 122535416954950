import { Stack, Typography } from "@mui/material";
import { useSortable } from "@dnd-kit/sortable";
import { CSS } from "@dnd-kit/utilities";
import { useRef } from "react";

const DraggableComponent = ({ title, id }) => {
  const elem = useRef(null);

  const { attributes, listeners, setNodeRef, transform, transition } =
    useSortable({ id });

  const style = {
    transform: CSS.Transform.toString(transform),
    transition,
  };

  return (
    <Stack sx={style} {...attributes} {...listeners} ref={setNodeRef} m={3}>
      <Stack
        ref={elem}
        direction={"row"}
        sx={{
          cursor: "pointer",

          borderRadius: "3px",
          width: "100px",
          justifyContent: "center",
          position: "relative",
          "::before": {
            content: "''",
            height: 0,
            width: 0,

            borderBottom: "20px solid #262626",
            borderLeft: "10px solid #fff",
            borderTop: "20px solid #262626",

            position: "absolute",
            top: 0,
            bottom: 0,
            left: 0,
          },
          "::after": {
            content: "''",
            height: 0,
            width: 0,

            borderBottom: "20px solid transparent",
            borderLeft: "10px solid #262626",
            borderTop: "20px solid transparent",

            position: "absolute",
            top: 0,
            bottom: 0,
            right: 0,
            // "-webkit-transform": "translateX(0.667em)",
            transform: "translateX(8.8px)",
          },
          background: "#262626",
        }}
        id={id}
      >
        <Typography
          variant="h6"
          sx={{
            fontSize: "16px",
            lineHeight: "24px",
            textShadow: "none",
            padding: "8px 0px",
            width: "100%",
            textAlign: "center",
            // border: "1px solid #262626",
            color: "#fff",
            transform: "translateX(8.8px)",
          }}
        >
          {title}
        </Typography>
      </Stack>
    </Stack>
  );
};
export default DraggableComponent;
