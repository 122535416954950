import Constant from "../../../constant/Constant";
import Graph from "../util/graph";

const feedbackGraphDataFinder = (
    feedbackAnalytics,
    allDates,
    isHotelSummed,
    isDateSummed,
    sHotels,
    options,
    index,
    title,
    isBar,
    isDataTabular,
    isAvg
) => {

    const sOptions = Constant.FeedbackAnalyticsShort.filter(p => options.includes(p))
    const allOptionsLength = isAvg ? Constant.FeedbackAnalyticsShort.length : 1
    if (!isHotelSummed && !isDateSummed) {

        if (isBar) {

            return sOptions.map((option, oIndex) => {
                return sHotels.map((hotel, index) => {
                    return {
                        label: `${hotel}  ${option}`,
                        data: allDates.map((date) => {
                            let data = feedbackAnalytics[hotel][date].flat();
                            if (data.length === 0) return 0
                            return (data.reduce((acc, cur) => {
                                return acc + cur[option];
                            }, 0) / (data.length * allOptionsLength))
                        }),
                        borderColor: Graph.Colors[oIndex],
                        backgroundColor: Graph.Colors[oIndex],
                        ...Graph.ExtraOptions,
                        stack: `${hotel} ${!isAvg ? option : ""}`
                    };
                })
            }).flat()

        }

        return sOptions.map((option, oIndex) => {
            return sHotels.map((hotel, index) => {
                return {
                    label: `${hotel}  ${option}`,
                    data: allDates.map((date) => {
                        let data = feedbackAnalytics[hotel][date].flat();
                        if (data.length === 0) return 0
                        return (data.reduce((acc, cur) => {
                            return acc + cur[option];
                        }, 0) / data.length)
                    }),
                    borderColor: Graph.Colors[index + oIndex],
                    backgroundColor: Graph.Colors[index + oIndex],
                    ...Graph.ExtraOptions,
                };
            })
        }).flat()

    } else if (isHotelSummed && !isDateSummed) {

        if (isBar) {
            return sOptions.map((option, oIndex) => {
                return {
                    label: `All Hotels ${option} ${title ? title : ""}`,
                    data: allDates.map((date) => {
                        let data = Object.keys(feedbackAnalytics).filter(hot => sHotels.includes(hot)).map(hot => feedbackAnalytics[hot]).map(dateData => {
                            return dateData[date]
                        }).flat()
                        if (data.length === 0) return 0
                        return (data.reduce((acc, cur) => {
                            return acc + cur[option];
                        }, 0) / (data.length * allOptionsLength))
                    }),
                    borderColor: Graph.Colors[oIndex],
                    backgroundColor: Graph.Colors[oIndex],
                    ...Graph.ExtraOptions,
                    stack: `All Hotels ${!isAvg ? option : ""}`
                }
            })
        }

        return sOptions.map((option, oIndex) => {
            return {
                label: `All Hotels ${option} ${title ? title : ""}`,
                data: allDates.map((date) => {
                    let data = Object.keys(feedbackAnalytics).filter(hot => sHotels.includes(hot)).map(hot => feedbackAnalytics[hot]).map(dateData => {
                        return dateData[date]
                    }).flat()
                    if (data.length === 0) return 0
                    return (data.reduce((acc, cur) => {
                        return acc + cur[option];
                    }, 0) / data.length)
                }),
                borderColor: Graph.Colors[oIndex],
                backgroundColor: Graph.Colors[oIndex],
                ...Graph.ExtraOptions,
            }
        })

    } else if (!isHotelSummed && isDateSummed) {

        if (isBar) {
            return sOptions.map((option, oIndex) => {
                return {
                    label: `All Dates ${option} ${title ? title : ""}`,
                    data: sHotels.map(hotel => {
                        let data = Object.values(feedbackAnalytics[hotel]).flat()
                        if (data.length === 0) return 0
                        return (data.reduce((acc, cur) => {
                            return acc + cur[option];
                        }, 0) / (data.length * allOptionsLength))
                    }),
                    borderColor: Graph.Colors[oIndex],
                    backgroundColor: Graph.Colors[oIndex],
                    ...Graph.ExtraOptions,
                    stack: `All Dates ${!isAvg ? option : ""}`
                }
            })
        }

        return sOptions.map((option, oIndex) => {
            return {
                label: `All Dates ${option} ${title ? title : ""}`,
                data: sHotels.map(hotel => {
                    let data = Object.values(feedbackAnalytics[hotel]).flat()
                    if (data.length === 0) return 0
                    return (data.reduce((acc, cur) => {
                        return acc + cur[option];
                    }, 0) / data.length)
                }),
                borderColor: Graph.Colors[oIndex],
                backgroundColor: Graph.Colors[oIndex],
                ...Graph.ExtraOptions,
            }
        })
    } else if (isHotelSummed && isDateSummed) {

        let data = Object.keys(feedbackAnalytics).map(hot => {
            return Object.values(feedbackAnalytics[hot]).flat()
        }).flat()

        if(isBar){
            return sOptions.map((option, oIndex) => {
                return {
                    label: `All ${option} ${title ? title : ""}`,
                    data: [
                        (data.length === 0 ? 0 : (
                            data.reduce((acc, cur) => {
                                return acc + cur[option];
                            }, 0) / (data.length * allOptionsLength)
                        ))
                    ],
                    borderColor: Graph.Colors[oIndex],
                    backgroundColor: Graph.Colors[oIndex],
                    ...Graph.ExtraOptions,
                    stack:`All ${!isAvg ? option : 0}`
                }
            })
        }

        return sOptions.map((option, oIndex) => {
            return {
                label: `All ${option} ${title ? title : ""}`,
                data: [
                    (data.length === 0 ? 0 : (
                        data.reduce((acc, cur) => {
                            return acc + cur[option];
                        }, 0) / data.length
                    ))
                ],
                borderColor: Graph.Colors[oIndex],
                backgroundColor: Graph.Colors[oIndex],
                ...Graph.ExtraOptions,
            }
        })
    }


}

export default feedbackGraphDataFinder