import Graph from "../util/graph";

const AccountDataFinder = (
    accountAnalytics,
    allDates,
    isHotelSummed,
    isDateSummed,
    allOptions,
    sHotels,
    options,
    isBar,
    isTablularData,
    partner,
    source
) => {

    const types = allOptions.filter(al => options.includes(al))

    if (!isHotelSummed && !isDateSummed) {

        if (isBar) {
            return types.map((option, oIndex) => {
                return sHotels.map((hotel, index) => {
                    return {
                        label: `${hotel} ${option}`,
                        data: allDates.map((date) => {
                            let data = accountAnalytics[hotel][date].filter(p => {
                                return partner.includes(p.partners) && source.includes(p.sources)
                            });;
                            return data.reduce((acc, cur) => {
                                return (
                                    acc + cur[option]
                                );
                            }, 0)
                        }),
                        borderColor: Graph.Colors[oIndex],
                        backgroundColor: Graph.Colors[oIndex],
                        ...Graph.ExtraOptions,
                        stack: `Stack - ${hotel}`
                    };
                })
            }).flat()
        }

        return sHotels.map((hotel, index) => {
            return {
                label: hotel,
                data: allDates.map((date) => {
                    let data = accountAnalytics[hotel][date].filter(p => {
                        return partner.includes(p.partners) && source.includes(p.sources)
                    });
                    return data.reduce((acc, cur) => {
                        return (
                            acc +
                            types.reduce((accc, curr) => {
                                return accc + cur[curr];
                            }, 0)
                        );
                    }, 0)
                }),
                borderColor: Graph.Colors[index],
                backgroundColor: Graph.Colors[index],
                ...Graph.ExtraOptions,
            };
        })

    } else if (isHotelSummed && !isDateSummed) {

        if (isBar) {
            return types.map((option, oIndex) => {
                return {
                    label: `All Hotels ${option}`,
                    data: allDates.map((date) => {
                        return Object.keys(accountAnalytics).filter(hot => sHotels.includes(hot)).map(hot => accountAnalytics[hot]).map(dateData => {
                            return dateData[date]
                        }).flat().filter(p => {
                            return partner.includes(p.partners) && source.includes(p.sources)
                        }).reduce((acc, cur) => {
                            return acc + cur[option]
                        }, 0)
                    }),
                    borderColor: Graph.Colors[oIndex],
                    backgroundColor: Graph.Colors[oIndex],
                    ...Graph.ExtraOptions,
                    stack: `All Hotels`
                }
            }).flat()
        }
        return [
            {
                label: "All Hotels",
                data: allDates.map((date) => {
                    return Object.keys(accountAnalytics).filter(hot => sHotels.includes(hot)).map(hot => accountAnalytics[hot]).map(dateData => {
                        return dateData[date]
                    }).flat().filter(p => {
                        return partner.includes(p.partners) && source.includes(p.sources)
                    }).reduce((acc, cur) => {
                        return acc + types.reduce((accc, curr) => {
                            return accc + cur[curr]
                        }, 0)
                    }, 0)
                }),
                borderColor: Graph.Colors[0],
                backgroundColor: Graph.Colors[0],
                ...Graph.ExtraOptions,
            }
        ]
    } else if (!isHotelSummed && isDateSummed) {
        let hotels = Object.keys(accountAnalytics).filter(hot => sHotels.includes(hot))

        if (isBar) {
            return types.map((option, oIndex) => {
                return {
                    label: `All Dates ${option}`,
                    data: hotels.map(hotel => {
                        let allData = Object.keys(accountAnalytics[hotel]).map(date => {
                            let dateData = accountAnalytics[hotel][date].filter(p => {
                                return partner.includes(p.partners) && source.includes(p.sources)
                            })
                            return dateData.reduce((acc, cur) => {
                                return acc + cur[option]
                            }, 0)
                        }).reduce((acc, cur) => {
                            return acc + cur
                        }, 0)
                        return allData
                    }),
                    borderColor: Graph.Colors[oIndex],
                    backgroundColor: Graph.Colors[oIndex],
                    ...Graph.ExtraOptions,
                    stack: `All Dates`
                }
            })
        }
        return [
            {
                label: "All Dates",
                data: hotels.map(hotel => {
                    let allData = Object.keys(accountAnalytics[hotel]).map(date => {
                        let dateData = accountAnalytics[hotel][date].filter(p => {
                            return partner.includes(p.partners) && source.includes(p.sources)
                        })
                        return dateData.reduce((acc, cur) => {
                            return acc + types.reduce((accc, curr) => {
                                return accc + cur[curr]
                            }, 0)
                        }, 0)
                    }).reduce((acc, cur) => {
                        return acc + cur
                    }, 0)
                    return allData
                }),
                borderColor: Graph.Colors[0],
                backgroundColor: Graph.Colors[0],
                ...Graph.ExtraOptions,
            }
        ]
    } else if (isHotelSummed && isDateSummed) {
        let hotels = Object.keys(accountAnalytics).filter(hot => sHotels.includes(hot))
        if (isBar) {
            return types.map((option, oIndex) => {
                return {
                    label: `All Dates ${option}`,
                    data: [
                        hotels.map(hotel => {
                            let allData = Object.keys(accountAnalytics[hotel]).map(date => {
                                let dateData = accountAnalytics[hotel][date].filter(p => {
                                    return partner.includes(p.partners) && source.includes(p.sources)
                                })
                                return dateData.reduce((acc, cur) => {
                                    return acc + cur[option]
                                }, 0)
                            }).reduce((acc, cur) => {
                                return acc + cur
                            }, 0)
                            return allData
                        }).reduce((acc, cur) => {
                            return acc + cur
                        }, 0)
                    ],
                    borderColor: Graph.Colors[oIndex],
                    backgroundColor: Graph.Colors[oIndex],
                    ...Graph.ExtraOptions,
                    stack: "All"
                }
            })
        }
        return [
            {
                label: "All Dates",
                data: [
                    hotels.map(hotel => {
                        let allData = Object.keys(accountAnalytics[hotel]).map(date => {
                            let dateData = accountAnalytics[hotel][date].filter(p => {
                                return partner.includes(p.partners) && source.includes(p.sources)
                            })
                            return dateData.reduce((acc, cur) => {
                                return acc + types.reduce((accc, curr) => {
                                    return accc + cur[curr]
                                }, 0)
                            }, 0)
                        }).reduce((acc, cur) => {
                            return acc + cur
                        }, 0)
                        return allData
                    }).reduce((acc, cur) => {
                        return acc + cur
                    }, 0)
                ],
                borderColor: Graph.Colors[0],
                backgroundColor: Graph.Colors[0],
                ...Graph.ExtraOptions,
            }
        ]
    }
}

export default AccountDataFinder