import axios from "axios"

const GetExpenserWithToken = async (route, hotelId, token, data, cancelToken,identifier = undefined) => {
    try {
        const response = await axios({
            method: "POST",
            url: route,
            headers: {
                "Authorization": `Bearer ${token}`
            },
            params: {
                hotelId,
                identifier:identifier
            },
            data: data,
            cancelToken: cancelToken
        })
        return response.data
    } catch (err) {
        console.log(err)
        return err.response ? (err.response.data ? err.response.data : {}) : {}
    }
}

export default GetExpenserWithToken