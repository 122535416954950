import { Stack, Typography } from "@mui/material";
import moment from "moment";

const ExpenseViewer = ({ expense, onClick }) => {


  let partner = `${expense.partnerRefId[0].toUpperCase()}${expense.partnerRefId.slice(
    1
  )}`;

  let entity = expense.entityRefId;

  return (
    <Stack
      sx={{
        borderRadius: "5px",
        border: "1px solid #262626",
        margin: "10px 0px",
        padding: "7px 10px",
        transition: "border 1s",
        ":hover": {
          boxShadow: "0px 10px 15px -3px rgba(0,0,0,0.1)",
        },
        cursor: "pointer",
      }}
      direction="row"
      spacing={2}
      onClick={() => {
        onClick();
      }}
    >
      <Stack direction="column" spacing={0} sx={{ width: "120px" }}>
        <Typography
          variant="h6"
          color="primary.main"
          sx={{ fontSize: "13px", textOverflow: "ellipsis" }}
        >
          {expense.isExpenserToBusinessLoan ? entity : partner}
        </Typography>
        <Typography variant="h6" color="primary.main" sx={{ fontSize: "11px" }}>
          #{expense.id}
        </Typography>
      </Stack>

      <Stack sx={{ width: "100px" }}>
        <Typography variant="h6" color="primary.main" sx={{ fontSize: "13px" }}>
        {expense.isExpenserToBusinessLoan ? partner : entity}
        </Typography>
      </Stack>

      <Stack sx={{ width: "190px" }}>
        <Typography variant="h6" color="primary.main" sx={{ fontSize: "13px" }}>
          {expense.category}
        </Typography>
      </Stack>

      <Stack sx={{ width: "100px" }}>
        <Typography variant="h6" color="primary.main" sx={{ fontSize: "13px" }}>
          {expense.note}
        </Typography>
      </Stack>

      {expense.createdAt && (
        <Stack sx={{ width: "100px" }}>
          <Typography
            variant="h6"
            color="primary.main"
            sx={{ fontSize: "13px" }}
          >
            {moment(expense.createdAt).format("DD-MM-YYYY")}
          </Typography>
        </Stack>
      )}

      {expense.amount && (
        <Stack sx={{ width: "100px" }}>
          <Typography
            variant="h6"
            color="primary.main"
            sx={{ fontSize: "13px" }}
          >
            ₹{expense.amount}
          </Typography>
        </Stack>
      )}

      {expense.payment && (
        <Stack sx={{ width: "100px" }}>
          <Typography
            variant="h6"
            color="primary.main"
            sx={{ fontSize: "13px" }}
          >
            Paid - ₹
            {expense.payment.reduce((acc, cur) => {
              return acc + cur.value;
            }, 0)}
          </Typography>
        </Stack>
      )}

      {expense.cPayment && expense.isAdvance && (
        <Stack sx={{ width: "100px" }}>
          <Typography
            variant="h6"
            color="primary.main"
            sx={{ fontSize: "13px" }}
          >
            Cleared - ₹
            {expense.cPayment.reduce((acc, cur) => {
              return acc + cur.value;
            }, 0)}
          </Typography>
        </Stack>
      )}
    </Stack>
  );
};

export default ExpenseViewer;
