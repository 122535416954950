import { Stack, Typography } from "@mui/material";

const BalanceStackViewer = ({ opening, value, label }) => {
  return (
    <Stack direction={"column"} spacing={1} sx={{
        alignItems:"center",
        margin:"20px 13px"
    }}>
      <Typography variant="h1" fontSize={"18px"}>
        {label}
      </Typography>
      <Typography variant="h6" fontSize={"15px"}>
        ₹{opening}
      </Typography>
      <Typography variant="h6" fontSize={"15px"}>
        ₹{value}
      </Typography>
      <Typography variant="h6" fontSize={"15px"}>
        ₹{Number(opening) + Number(value)}
      </Typography>
    </Stack>
  );
};

export default BalanceStackViewer;
