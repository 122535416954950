import { DndContext, closestCenter } from "@dnd-kit/core";
import {
  SortableContext,
  arrayMove,
  horizontalListSortingStrategy,

} from "@dnd-kit/sortable";

import DraggableComponent from "./DraggableComponent";
import { Stack } from "@mui/material";

const KanbanContainer = ({
  elements,
  setElements
}) => {
  

  const handleDragEnd = (event) => {
    const { active, over } = event;
    if (over && active.id !== over.id) {
      setElements((prevElements) => {
        const activeIndex = prevElements.findIndex(
          (element) => element.id === active.id
        );
        const overIndex = prevElements.findIndex(
          (element) => element.id === over.id
        );

        const reorderedElements = arrayMove(
          prevElements,
          activeIndex,
          overIndex
        );
        return [...reorderedElements];
      });
    }
  };

  return (
    <DndContext collisionDetection={closestCenter} onDragEnd={handleDragEnd}>
      <Stack direction={"row"} spacing={"9px"}>
        <SortableContext
          items={elements}
          strategy={horizontalListSortingStrategy}
        >
          {elements?.map((element, index) => (
            <DraggableComponent
              key={element.id}
              title={element.title}
              id={element.id}
              index={index}
            />
          ))}
        </SortableContext>
      </Stack>
    </DndContext>
  );
};
export default KanbanContainer;
