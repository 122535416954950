import {
  InputLabel,
  MenuItem,
  Select,
  Typography,
  Stack,
  Checkbox,
  ListItemText
} from "@mui/material";

const AddExpenserMultiHotelSelect = ({
  value,
  onValueChange,
  required,
  hotels,
}) => {


  return (
    <>
      <InputLabel
        variant="standard"
        sx={{
          width: "100%",
          margin: "3px 0px",
        }}
      >
        <Typography variant="h6" color="primary" fontSize="15px">
          Choose Hotels
        </Typography>
      </InputLabel>
      <Select
        fullWidth
        value={value}
        onChange={(e) => {
          onValueChange(e.target.value);
        }}
        variant="outlined"
        margin="none"
        label="Hotel"
        sx={{
          margin: "8px 0px",
        }}
        required={Boolean(required)}
        style={{
          marginLeft: "1px",
        }}
        multiple={true}
        renderValue={(val) => {
          return val.map(p => hotels.find(h => h._id === p).hotelName).join(",")
        }}
      >
        {hotels.map((hot) => {
          return (
            <MenuItem key={hot._id} value={hot._id}>
              <Stack direction={"row"} sx={{
                alignItems:"center"
              }}>
                <Checkbox checked={value.includes(hot._id)} />
                <ListItemText primary={hot.hotelName} />
              </Stack>
            </MenuItem>
          );
        })}
      </Select>
    </>
  );
};

export default AddExpenserMultiHotelSelect;
