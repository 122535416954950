
const accountFormatter = (data) => {
    return {
        Name:data.name,
        PhoneNumber:data.phoneNumber,
        // Hotel:hotel.hotelName,
        Rooms:data.rooms,
        Stay:data.stay,
        BookingId:data.bookingId,
        Amount:data.amount,
        ...(data.data),
        Status:data.status
    }
}

export default accountFormatter