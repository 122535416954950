import {
  MenuItem,
  FormControl,
  FormControlLabel,
  Switch,
  Button,
  Typography,
  Popper,
  Paper,
  MenuList,
  Grow,
} from "@mui/material";
import { useEffect, useRef, useState } from "react";

const HotelGroupSwitch = ({ state, onStateChange, width, isSingle }) => {
  let fOptions = Object.keys(state);
  let fSelectedOptions = Object.keys(state).filter((value) => state[value]);
  const buttonRef = useRef(undefined);
  const popperRef = useRef(undefined);
  const [isVisible, setIsVisible] = useState(false);

  const onHandleChange = (value) => {
    let val = {};
    fOptions.forEach((va) => {
      val[va] = false;
    });
    value.forEach((va) => {
      val[va] = true;
    });
    onStateChange(val);
  };

  useEffect(() => {
    const handleOutsideClick = (event) => {
      if (
        popperRef.current &&
        !popperRef.current.contains(event.target) &&
        buttonRef.current &&
        !buttonRef.current.contains(event.target)
      ) {
        setIsVisible(false);
      }
    };

    document.addEventListener("mousedown", handleOutsideClick);

    return () => {
      document.removeEventListener("mousedown", handleOutsideClick);
    };
  }, []);

  return (
    <FormControl
      style={{
        zIndex: "unset",
      }}
    >
      <Button
        ref={buttonRef}
        sx={{
          width: width,
          border: "1px solid #262626",
          outline: isVisible ? "1px solid #262626" : "1px solid transparent",
          fontSize: "16px",
        }}
        onClick={() => {
          setIsVisible((p) => !p);
        }}
      >
        <Typography
          sx={{
            padding: "9px 12px",
            textOverflow: "ellipsis",
            textTransform: "none",
          }}
          variant="h6"
          fontSize={"15px"}
          color="primary.main"
        >
          View
        </Typography>
      </Button>

      <Popper
        anchorEl={buttonRef.current}
        role={undefined}
        placement="bottom"
        open={isVisible && Boolean(buttonRef.current)}
        disablePortal={true}
        transition
        ref={popperRef}
      >
        {({ TransitionProps }) => (
          <Grow {...TransitionProps} style={{ transformOrigin: "center top" }}>
            <Paper
              elevation={10}
              sx={{
                marginTop: "3px",
                maxHeight:"450px",
                overflow:"scroll",
                
              }}
            >
              <MenuList>
                {!isSingle && (
                  <MenuItem key="All">
                    <FormControlLabel
                      onChange={(ev) => {
                        if (ev.target.checked) {
                          onHandleChange(fOptions);
                        } else {
                          onHandleChange([]);
                        }
                      }}
                      control={
                        <Switch
                          checked={fSelectedOptions.length === fOptions.length}
                        />
                      }
                      label={"All"}
                    />
                  </MenuItem>
                )}

                {[...fOptions].map((option) => {
                  return (
                    <MenuItem key={option} value={option}>
                      <FormControlLabel
                        control={
                          <Switch
                            checked={fSelectedOptions.indexOf(option) > -1}
                            onChange={(ev) => {
                              if(isSingle){
                                onHandleChange([option])
                              }else{
                                if (ev.target.checked) {
                                  onHandleChange([
                                    ...new Set([...fSelectedOptions, option]),
                                  ]);
                                } else {
                                  let opt = [
                                    ...new Set([...fSelectedOptions, option]),
                                  ];
                                  opt.splice(opt.indexOf(option), 1);
                                  onHandleChange(opt);
                                }
                              }
                            }}
                          />
                        }
                        label={option}
                      />
                    </MenuItem>
                  );
                })}
              </MenuList>
            </Paper>
          </Grow>
        )}
      </Popper>
    </FormControl>
  );
};

export default HotelGroupSwitch;
