import {
  Button,
  Card,
  CircularProgress,
  Modal,
  Typography,
} from "@mui/material";
import { useState } from "react";
import AddExpenserInput from "../common/AddExpenserInput";
import OxyOsTheme from "../../../../theme/theme";
import AddExpenseDatePicker from "../common/AddExpenseDatePicker";
import moment from "moment";
import AddExpenserSourceSelector from "../common/AddExpenseSourceSelector";

const AddPaymentModel = ({
  isOpen,
  onClose,
  expenseId,
  isLoading,
  onSubmit,
  eSources,
  pSources,
  amount,
  date,
  note
}) => {
  const [pData, setPData] = useState({
    amount: Number(amount),
    note: note,
    eSourceId: "",
    pSourceId: "",
    expenseId,
    date: moment(date).format("DD-MM-YYYY"),
  });

  return (
    <Modal
      open={isOpen}
      onClose={() => {
        onClose();
      }}
      sx={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <Card
        sx={{
          flexDirection: "row",
          minHeight: "500px",
          maxHeight: "600px",
          maxWidth: "700px",
          minWidth: "500px",
          background: "#FFF",
          borderRadius: "10px",
          padding: "20px 30px",
          overflowY: "scroll",
          "::-webkit-scrollbar": {
            display: "none",
          },
          border: "none",
        }}
      >
        <Typography
          variant="h1"
          sx={{
            width: "100%",
            textAlign: "center",
            marginBottom: "40px",
            marginTop: "10px",
          }}
          style={{
            textShadow: "0px 1px 1px rgba(0,0,0,0.24)",
          }}
          fontSize={"25px"}
        >
          Add Payment ₹({amount})
        </Typography>

        <AddExpenserSourceSelector
          sources={eSources}
          value={pData.eSourceId}
          onValueChange={(val) => {
            setPData((p) => {
              return { ...p, eSourceId: val };
            });
          }}
          required={true}
          label={"Choose Payment Type"}
        />

        {pSources && (
          <AddExpenserSourceSelector
            sources={pSources}
            value={pData.pSourceId}
            onValueChange={(val) => {
              setPData((p) => {
                return { ...p, pSourceId: val };
              });
            }}
            required={true}
            label={"Choose Receiver Payment Type"}
          />
        )}

        <AddExpenserInput
          value={pData.amount}
          onValueChange={(val) => {
            setPData((p) => {
              return { ...p, amount: val };
            });
          }}
          label="Amount"
          type="number"
          required={true}
        />

        <AddExpenserInput
          value={pData.note}
          onValueChange={(val) => {
            setPData((p) => {
              return { ...p, note: val };
            });
          }}
          label="Note"
          type="text"
          required={true}
        />

        <AddExpenseDatePicker
          date={pData.date}
          onDateChange={(date) => {
            setPData((p) => {
              return {
                ...p,
                date: date,
              };
            });
          }}
        />

        <Button
          variant="outlined"
          size="large"
          sx={{
            backgroundColor: "#262626",
            borderRadius: "30px",
            padding: "10px 15px",
            margin: "20px auto",
            display: "block",
            color: OxyOsTheme.palette.background.main,
            "&:hover": {
              backgroundColor: OxyOsTheme.palette.primary.main,
              color: OxyOsTheme.palette.background.main,
              opacity: 0.6,
            },
          }}
          onClick={() => {
            if (!isLoading) {
              onSubmit(pData);
            }
          }}
        >
          {isLoading ? (
            <CircularProgress color="background" />
          ) : (
            <Typography variant="h6">Add Payment</Typography>
          )}
        </Button>
      </Card>
    </Modal>
  );
};
export default AddPaymentModel;
