import { useState } from "react";
import SingleTransctionViewer from "../SingleTransctionViewer";
import { Stack, Typography, Button, Collapse } from "@mui/material";
import { KeyboardArrowDown, KeyboardArrowUp } from "@mui/icons-material";
import FilterMultipleInput from "./FilterMultipleInput";
import KanbanContainer from "../kanban/KanbanContainer";
import summaryDataFormatter from "../Summary/summartDataFormatter";
import SummaryStageFirstFilter from "../Summary/SummaryStageFirstFilter";
import SummaryStageResult from "../Summary/SummaryStageResult";
import roundToDecimalPlace from "../../../../../utils/expense/roundToDecimalPlace";

const SingleEntityLedger = ({
  allTransactions,
  entityName,
  dTransId,
  onDeleteTransaction,
  isSummary,
}) => {
  const [isOpen, setIsOpen] = useState(false);

  const [elements, setElements] = useState([
    { id: 1, title: "Category", field: "category" },
    { id: 2, title: "Expenser", field: "expenseRefId" },
    { id: 3, title: "Date", field: "date" },
    { id: 4, title: "source", field: "sourceRef" },
  ]);

  const tTypes = allTransactions.reduce((acc, cur) => {
    if (acc.includes(cur.type)) {
      return acc;
    } else {
      return [...acc, cur.type];
    }
  }, []);

  const [sTTypes, setSTTypes] = useState(tTypes);

  const sources = allTransactions
    .filter((trans) => sTTypes.includes(trans.type))
    .reduce((acc, cur) => {
      if (acc.includes(cur.sourceRef)) {
        return acc;
      } else {
        return [...acc, cur.sourceRef];
      }
    }, []);

  const [sSource, setSSource] = useState(sources);

  const expensers = allTransactions
    .filter(
      (trans) =>
        sSource.includes(trans.sourceRef) && sTTypes.includes(trans.type)
    )
    .reduce((acc, cur) => {
      if (acc.includes(cur.expenseRefId)) {
        return acc;
      } else {
        return [...acc, cur.expenseRefId];
      }
    }, []);

  const [sExpensers, setSExpernsers] = useState(expensers);

  const categories = allTransactions
    .filter(
      (trans) =>
        sSource.includes(trans.sourceRef) &&
        sExpensers.includes(trans.expenseRefId) &&
        sTTypes.includes(trans.type)
    )
    .reduce((acc, cur) => {
      if (acc.includes(cur.category)) {
        return acc;
      } else {
        return [...acc, cur.category];
      }
    }, []);

  const [sCategory, setSCategory] = useState(categories);

  let fTransactions = allTransactions.filter((trans) => {
    return (
      sSource.includes(trans.sourceRef) &&
      sExpensers.includes(trans.expenseRefId) &&
      sCategory.includes(trans.category) &&
      sTTypes.includes(trans.type)
    );
  });

  const onToggle = () => {
    setIsOpen((p) => !p);
  };

  return (
    <>
      <Stack
        direction="row"
        sx={{
          flexGrow: 1,
          // justifyContent: "space-between",
          display: "flex",
          margin: "10px 10px",
          alignItems: "center",
        }}
        spacing={3}
      >
        <Stack direction={"row"}>
          <Button
            onClick={onToggle}
            disableRipple
            sx={{
              justifyContent: "space-between",
              display: "flex",
              alignItems: "center",
              flexDirection: "row",
              textTransform: "none",
            }}
          >
            <Typography
              variant="h6"
              color="primary.main"
              sx={{ fontSize: "15px" }}
            >
              {entityName}
            </Typography>

            {isOpen ? (
              <KeyboardArrowUp sx={{ marginLeft: "20px" }} />
            ) : (
              <KeyboardArrowDown sx={{ marginLeft: "20px" }} />
            )}
          </Button>
        </Stack>

        {isSummary && (
          <KanbanContainer
            elements={elements}
            setElements={(elem) => {
              setElements(elem);
            }}
          />
        )}

        <Stack
          direction={"row"}
          spacing={"10px"}
          alignItems={"baseline"}
          sx={{
            position: "absolute",
            right: 100,
            top: 15,
          }}
        >
          <FilterMultipleInput
            selectedOptions={sTTypes}
            options={tTypes}
            onOptionsChange={(va) => {
              setSTTypes(va);
            }}
            width={"75px"}
            label={"Type"}
            isSingle={false}
            isEmptyToMulti={true}
          />

          <FilterMultipleInput
            selectedOptions={sSource}
            options={sources}
            onOptionsChange={(va) => {
              setSSource(va);
            }}
            width={"90px"}
            label={"Source"}
            isSingle={false}
            isEmptyToMulti={true}
          />

          <FilterMultipleInput
            selectedOptions={sExpensers}
            options={expensers}
            onOptionsChange={(va) => {
              setSExpernsers(va);
            }}
            width={"115px"}
            label={"Expensers"}
            isSingle={false}
            isEmptyToMulti={false}
          />

          <FilterMultipleInput
            selectedOptions={sCategory}
            options={categories}
            onOptionsChange={(va) => {
              setSCategory(va);
            }}
            width={"120px"}
            label={"Categories"}
            isSingle={false}
            isEmptyToMulti={false}
          />
        </Stack>
      </Stack>

      <SummaryStageResult
        name={"Total"}
        key={"Total"}
        amount={fTransactions
          .filter((a) => !a.type.startsWith("Incoming"))
          .reduce(
            (acc, cur) =>
              roundToDecimalPlace(
                roundToDecimalPlace(acc, 2) +
                  roundToDecimalPlace(cur.amount, 2),
                2
              ),
            0
          )}
        pAmount={fTransactions
          .filter((a) => a.type.startsWith("Incoming"))
          .reduce(
            (acc, cur) =>
              roundToDecimalPlace(
                roundToDecimalPlace(acc, 2) +
                  roundToDecimalPlace(cur.amount, 2),
                2
              ),
            0
          )}
        isOpen={true}
        onToggle={() => {}}
        isResult={true}
      />

      <Collapse timeout={100} in={isOpen && !isSummary} unmountOnExit>
        <Stack
          sx={{
            marginLeft: "40px",
          }}
          spacing={"10px"}
          direction={"column"}
        >
          {fTransactions.map((trans) => {
            return (
              <SingleTransctionViewer
                key={trans._id}
                Transction={trans}
                dTransId={dTransId}
                onDeleteTransaction={(transId) => {
                  onDeleteTransaction(transId);
                }}
              />
            );
          })}
        </Stack>
      </Collapse>

      {isSummary && (
        <>
          {
            <SummaryStageFirstFilter
              summary={summaryDataFormatter(elements, fTransactions)}
              dTransId={dTransId}
              onDeleteTransaction={(transId) => {
                onDeleteTransaction(transId);
              }}
            />
          }
        </>
      )}
    </>
  );
};

export default SingleEntityLedger;
