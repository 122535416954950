import { Stack, Typography } from "@mui/material";
import moment from "moment-timezone";

const Partner = ({ partner }) => {
  return (
    <Stack
      sx={{
        borderRadius: "5px",
        border: "1px solid #262626",
        margin: "10px 0px",
        padding: "7px 10px",
        transition: "border 1s",
        ":hover": {
          boxShadow: "0px 10px 15px -3px rgba(0,0,0,0.1)",
        },
        cursor: "pointer",
      }}
      direction="row"
      spacing={2}
    >
      <Stack direction="column" spacing={1} sx={{ width: "150px" }}>
        <Typography variant="h6" color="primary.main" sx={{ fontSize: "15px" }}>
          Partner Owner
        </Typography>
        <Typography variant="h6" color="primary.main" sx={{ fontSize: "13px" }}>
          {partner.id}
        </Typography>
      </Stack>

      <Stack sx={{ width: "150px", justifyContent: "center" }} spacing={1}>
        <Typography variant="h6" color="primary.main" sx={{ fontSize: "15px" }}>
          Partner
        </Typography>
        <Typography variant="h6" color="primary.main" sx={{ fontSize: "13px" }}>
          {partner.partnerId}
        </Typography>
      </Stack>

      <Stack sx={{ width: "70px", alignItems: "center" }} spacing={1}>
        <Typography variant="h6" color="primary.main" sx={{ fontSize: "15px" }}>
          XBR
        </Typography>
        <Typography variant="h6" color="primary.main" sx={{ fontSize: "13px" }}>
          {partner.XBR}%
        </Typography>
      </Stack>

      <Stack sx={{ width: "70px", alignItems: "center" }} spacing={1}>
        <Typography variant="h6" color="primary.main" sx={{ fontSize: "15px" }}>
          XECR
        </Typography>
        <Typography variant="h6" color="primary.main" sx={{ fontSize: "13px" }}>
          {partner.XECR}%
        </Typography>
      </Stack>

      <Stack sx={{ width: "70px", alignItems: "center" }} spacing={1}>
        <Typography variant="h6" color="primary.main" sx={{ fontSize: "15px" }}>
          OBR
        </Typography>
        <Typography variant="h6" color="primary.main" sx={{ fontSize: "13px" }}>
          {partner.OBR}%
        </Typography>
      </Stack>

      <Stack sx={{ width: "70px", alignItems: "center" }} spacing={1}>
        <Typography variant="h6" color="primary.main" sx={{ fontSize: "15px" }}>
          OECR
        </Typography>
        <Typography variant="h6" color="primary.main" sx={{ fontSize: "13px" }}>
          {partner.OECR}%
        </Typography>
      </Stack>

      <Stack sx={{ width: "70px", alignItems: "center" }} spacing={1}>
        <Typography variant="h6" color="primary.main" sx={{ fontSize: "15px" }}>
          CFEE
        </Typography>
        <Typography variant="h6" color="primary.main" sx={{ fontSize: "13px" }}>
          {partner.CFEE}%
        </Typography>
      </Stack>

      <Stack sx={{ width: "70px", alignItems: "center" }} spacing={1}>
        <Typography variant="h6" color="primary.main" sx={{ fontSize: "15px" }}>
          GST
        </Typography>
        <Typography variant="h6" color="primary.main" sx={{ fontSize: "13px" }}>
          {partner.GST}%
        </Typography>
      </Stack>

      {partner.isProfitSharing && (
        <Stack sx={{ width: "150px", alignItems: "center" }} spacing={1}>
          <Typography
            variant="h6"
            color="primary.main"
            sx={{ fontSize: "15px" }}
          >
            Profit Sharing
          </Typography>
        </Stack>
      )}

      {partner.isRelative && (
        <Stack sx={{ width: "150px", alignItems: "center" }} spacing={1}>
          <Typography
            variant="h6"
            color="primary.main"
            sx={{ fontSize: "15px" }}
          >
            Relative Sharing
          </Typography>
        </Stack>
      )}

      <Stack sx={{ width: "150px", alignItems: "center" }} spacing={1}>
        <Typography variant="h6" color="primary.main" sx={{ fontSize: "15px" }}>
          {
            moment(partner.sDate).format("DD MMM YYYY") + " -> " +  moment(partner.eDate).format("DD MMM YYYY")
          }
        </Typography>
      </Stack>
    </Stack>
  );
};

export default Partner;
