import { Stack, Typography, Button, IconButton } from "@mui/material";
import { KeyboardArrowDown, KeyboardArrowUp } from "@mui/icons-material";

const SummaryStageResult = ({
  name,
  amount,
  pAmount,
  isOpen,
  onToggle,
  isResult,
}) => {
  return (
    <Stack
      direction="row"
      sx={{
        flexGrow: 1,
        justifyContent: "space-between",
        display: "flex",
        width: "100%",
        margin: "auto",
      }}
    >
      <Button
        disableRipple
        onClick={() => {
          onToggle();
        }}
        sx={{
          justifyContent: "space-between",
          display: "flex",
          alignItems: "center",
          flexDirection: "row",
          textTransform: "none",
          width: "100%",
          maxWidth: "650px",
        }}
      >
        <Typography variant="h6" color="primary.main" sx={{ fontSize: "14px" }}>
          {name}
        </Typography>

        <Stack direction={"row"}>
          <Typography
            variant="h6"
            color="primary.main"
            sx={{ fontSize: "14px", textAlign: "left" }}
            width={"180px"}
          >
            Debited - {Math.floor(amount * 100) / 100}
          </Typography>

          <Typography
            variant="h6"
            color="primary.main"
            sx={{ fontSize: "14px", textAlign: "left" }}
            width={"180px"}
          >
            Credited - {Math.floor(pAmount * 100) / 100}
          </Typography>
          {!isResult ? (
            isOpen ? (
              <KeyboardArrowDown />
            ) : (
              <KeyboardArrowUp />
            )
          ) : (
            <Typography
              variant="h6"
              color="primary.main"
              sx={{ fontSize: "14px", textAlign: "left" }}
              width={"180px"}
            >
              Total - ({Math.floor((amount - pAmount) * 100) / 100})
            </Typography>
          )}
        </Stack>
      </Button>
    </Stack>
  );
};

export default SummaryStageResult;
