import { Stack, Typography } from "@mui/material";
import AddExpenserInput from "./AddExpenserInput";

const ExpenseDateAmountPicker = ({ label, amount, onAmountChange }) => {
  return (
    <Stack
      direction={"row"}
      spacing={2}
      sx={{
        alignItems: "center",
        margin: "10px 0px",
      }}
    >
      <Typography
        variant="h1"
        fontSize={"17px"}
        sx={{
          minWidth: "150px",
        }}
      >
        {label}
      </Typography>
      <AddExpenserInput
        value={amount}
        onValueChange={(val) => {
          onAmountChange(val);
        }}
        label={"Amount"}
        type={"numeric"}
        s
        required={true}
      />
    </Stack>
  );
};

export default ExpenseDateAmountPicker;
