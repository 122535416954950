import { Button, Collapse, Stack, Typography } from "@mui/material";
import { KeyboardArrowDown, KeyboardArrowUp } from "@mui/icons-material";
import { useState } from "react";
import SingleNameDateViewer from "./SingleNameDateViewer";

const SingleNameViewer = ({ name, amountData }) => {
  const [isOpen, setIsOpen] = useState(false);

  const amount = Object.values(amountData).reduce((ac, cu) => {
    return ac + cu;
  }, 0);

  const onToggle = () => {
    setIsOpen((p) => !p);
  };

  return (
    <>
      <Stack
        direction="row"
        sx={{
          flexGrow: 1,
          justifyContent: "space-between",
          display: "flex",
          width: "100%",
        }}
      >
        <Button
          onClick={onToggle}
          disableRipple
          sx={{
            justifyContent: "space-between",
            display: "flex",
            alignItems: "center",
            flexDirection: "row",
            textTransform: "none",
            width: "100%",
            maxWidth: "480px",
          }}
        >
          <Typography
            variant="h6"
            color="primary.main"
            sx={{ fontSize: "14px" }}
          >
            {name}
          </Typography>

          <Stack direction={"row"}>
            <Typography
              variant="h6"
              color="primary.main"
              sx={{ fontSize: "14px" }}
            >
              {amount}
            </Typography>

            {isOpen ? (
              <KeyboardArrowUp sx={{ marginLeft: "20px" }} />
            ) : (
              <KeyboardArrowDown sx={{ marginLeft: "20px" }} />
            )}
          </Stack>
        </Button>
      </Stack>
      <Collapse in={isOpen} timeout="auto" unmountOnExit>
        <div style={{ marginLeft: "10px" }}>
          {Object.keys(amountData).map((at) => {
            return (
              <SingleNameDateViewer name={at} amount={amountData[at]} key={at} />
            );
          })}
        </div>
      </Collapse>
    </>
  );
};

export default SingleNameViewer;
