import {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useState,
} from "react";
import { AuthContext } from "./auth";
import TaskRequester from "../utils/task/TaskRequester";
import Constant from "../constant/Constant";
import allRoutes from "../pages/routes";

export const TaskContext = createContext({
  isCompanyLoaded: false,
  isCompanyLoading: false,
  companies: [],

  isUsersLoading: false,
  users: {},

  pData: undefined,
  onSetCompanyPData: (data) => {},

  isCompanyAdderOpen: false,

  isUserAdderOpen: false,
  onToggleUserAdder: () => {},

  isUserAdding: false,
  onAddUser: () => {},
  pUserData: undefined,
  onSetPUserData: (data) => {},

  isCompanyAdding: false,
  onAddCompany: async (data) => {},

  selectedComapnyId: undefined,
  onSelectCompany: () => {},

  isError: false,
  errorMessage: "",
  clearMessage: () => {},

  onToggleCompanyAdder: () => {},
});

const TaskContextProvider = ({ children, selRoute, taskToken }) => {
  const authContext = useContext(AuthContext);

  const [taskData, setTaskData] = useState({
    isCompanyLoaded: false,
    isCompanyLoading: false,
    companies: [],

    isCompanyAdderOpen: false,
    isCompanyAdding: false,

    isUserAdderOpen: false,

    selectedComapnyId: undefined,

    pData: undefined,

    isUserAdding: false,
    pUserData: undefined,

    isUsersLoading: false,
    users: {},

    isError: false,
    errorMessage: "",
  });

  const loadCompanies = useCallback(async () => {
    if (taskData.isCompanyLoaded || taskData.isCompanyLoading) {
      return;
    }

    try {
      const data = await TaskRequester(
        Constant.companiesRoute,
        authContext.authToken,
        {},
        "GET"
      );

      if (!data.status || !data.data) {
        setTaskData((p) => {
          return {
            ...p,
            isError: true,
            errorMessage: data.message,
            isCompanyLoaded: false,
            isCompanyLoading: false,
          };
        });
      } else {
        setTaskData((p) => {
          return {
            ...p,
            isError: true,
            errorMessage: "Companies Loaded Succesfully.",
            isCompanyLoaded: true,
            isCompanyLoading: false,
            companies: data.data,
          };
        });
      }
    } catch (err) {
      console.log(err);
      setTaskData((p) => {
        return {
          ...p,
          isError: true,
          errorMessage: "Please try after some time",
          isCompanyLoaded: false,
          isCompanyLoading: false,
        };
      });
    }
  }, [
    authContext.authToken,
    taskData.isCompanyLoaded,
    taskData.isCompanyLoading,
  ]);

  const onAddCompany = useCallback(
    async (rData) => {
      if (taskData.isCompanyAdding) {
        return;
      }

      setTaskData((t) => {
        return { ...t, isCompanyAdding: true };
      });

      try {
        const data = await TaskRequester(
          Constant.companiesRoute,
          authContext.authToken,
          rData,
          "POST"
        );

        if (!data.status || !data.data) {
          setTaskData((p) => {
            return {
              ...p,
              isError: true,
              errorMessage: data.message,
              isCompanyAdding: false,
              pData: undefined,
            };
          });
        } else {
          setTaskData((p) => {
            return {
              ...p,
              isError: true,
              errorMessage: "Company Added Succesfully.",
              isCompanyAdding: false,
              isCompanyAdderOpen: false,
              pData: undefined,
              companies: [
                ...p.companies.filter((c) => c._id !== data.data._id),
                data.data,
              ],
            };
          });
        }
      } catch (err) {
        console.log(err);
        setTaskData((p) => {
          return {
            ...p,
            isError: true,
            errorMessage: "Please try after some time",
            isCompanyAdding: false,
            pData: undefined,
          };
        });
      }
    },
    [authContext.authToken, taskData.isCompanyAdding]
  );

  const onLoadUsers = useCallback(async () => {
    if (taskData.selectedComapnyId) {
      if (!Object.keys(taskData.users).includes(taskData.selectedComapnyId)) {
        setTaskData((t) => {
          return {
            ...t,
            isUsersLoading: true,
          };
        });

        try {
          const data = await TaskRequester(
            Constant.usersRoute,
            authContext.authToken,
            {
              companyId: taskData.selectedComapnyId,
            },
            "GET"
          );

          if (!data.status || !data.data) {
            setTaskData((p) => {
              return {
                ...p,
                isError: true,
                errorMessage: data.message,
                isUsersLoading: false,
              };
            });
          } else {
            setTaskData((p) => {
              let users = {
                ...p.users,
              };

              users[taskData.selectedComapnyId] = data.data;
              return {
                ...p,
                isError: true,
                errorMessage: "Users Loaded Succesfully.",
                isUsersLoading: false,
                users: users,
              };
            });
          }
        } catch (err) {
          console.log(err);
          setTaskData((p) => {
            return {
              ...p,
              isError: true,
              errorMessage: "Please try after some time",
              isUsersLoading: false,
            };
          });
        }
      }
    }
  }, [authContext.authToken, taskData.selectedComapnyId, taskData.users]);

  const onAddUser = useCallback(
    async (rData) => {
      if (taskData.isUserAdding) {
        return;
      }

      setTaskData((t) => {
        return { ...t, isUserAdding: true };
      });

      try {
        const data = await TaskRequester(
          Constant.usersRoute,
          authContext.authToken,
          rData,
          "POST"
        );

        if (!data.status || !data.data) {
          setTaskData((p) => {
            return {
              ...p,
              isError: true,
              errorMessage: data.message,
              isUserAdding: false,
              pUserData: undefined,
            };
          });
        } else {
          setTaskData((p) => {
            return {
              ...p,
              isError: true,
              errorMessage: "User Added Succesfully.",
              isUserAdding: false,
              isUserAdderOpen: false,
              pUserData: false,
            };
          });
        }
      } catch (err) {
        console.log(err);
        setTaskData((p) => {
          return {
            ...p,
            isError: true,
            errorMessage: "Please try after some time",
            isCompanyAdding: false,
            pUserData: undefined,
          };
        });
      }
    },
    [authContext.authToken, taskData.isUserAdding]
  );

  const clearMessage = useCallback(() => {
    setTaskData((p) => {
      return {
        ...p,
        isError: false,
        errorMessage: "",
      };
    });
  }, []);

  useEffect(() => {
    if (selRoute === allRoutes[25]) {
      loadCompanies();
    }
  }, [selRoute, loadCompanies]);

  useEffect(() => {
    onLoadUsers();
  }, [taskData.selectedComapnyId, onLoadUsers]);

  const onSetCompanyPData = useCallback((pData) => {
    setTaskData((p) => {
      return {
        ...p,
        pData: pData,
        isCompanyAdderOpen: Boolean(pData),
      };
    });
  }, []);



  const onToggleCompanyAdder = useCallback(() => {
    setTaskData((p) => {
      return {
        ...p,
        isCompanyAdderOpen: !p.isCompanyAdderOpen,
        pData: p.isCompanyAdderOpen ? undefined : p.pData,
      };
    });
  }, []);

  const onSetPUserData = useCallback((pData) => {
    setTaskData((p) => {
      return {
        ...p,
        pUserData: pData,
        isUserAdderOpen: Boolean(pData),
      };
    });
  }, []);

  const onToggleUserAdder = useCallback(() => {
    setTaskData((p) => {
      return {
        ...p,
        isUserAdderOpen: !p.isUserAdderOpen,
        pUserData: p.isUserAdderOpen ? undefined : p.pUserData,
      };
    });
  }, []);

  const onSelectCompany = useCallback((company) => {
    setTaskData((t) => {
      return {
        ...t,
        selectedComapnyId: company,
      };
    });
  }, []);

  const value = {
    ...taskData,
    clearMessage,
    onToggleCompanyAdder,
    onAddCompany,
    onSetCompanyPData,
    onSelectCompany,
    onToggleUserAdder,
    onAddUser,
    onSetPUserData
  };

  return <TaskContext.Provider value={value}>{children}</TaskContext.Provider>;
};

export default TaskContextProvider;
