
const partnerFinder = (analytics,field) => {
    return [
        ...(new Set(
            Object.values(analytics)
                .map(a => Object.values(a))
                .flat()
                .flat()
                .map(a => a[field] ? a[field] : "Default")
        ))
    ]
}

export default partnerFinder