import {
  Button,
  Card,
  CircularProgress,
  Modal,
  Typography,
} from "@mui/material";
import {  useState } from "react";
import OxyOsTheme from "../../../../theme/theme";
import AddExpenserInput from "../common/AddExpenserInput";
import AddExpenserMultiHotelSelect from "../common/AddExpenserMultiHotelSelect";

const AddExpenserModal = ({ isOpen, onClose, onAdd, isLoading,hotels }) => {


  const [userData, setUserData] = useState({
    name: "",
    phoneNumber: "",
    email: "",
    aadhar: "",
    pan: "",
    entities: [],
  });

  const onSubmit = () => {
    onAdd(userData);
  };

  return (
    <Modal
      open={isOpen}
      onClose={onClose}
      sx={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <Card
        sx={{
          flexDirection: "row",
          minHeight: "500px",
          maxHeight: "600px",
          maxWidth: "700px",
          minWidth: "500px",
          background: "#FFF",
          borderRadius: "10px",
          padding: "20px 30px",
          overflowY: "scroll",
          "::-webkit-scrollbar": {
            display: "none",
          },
          border: "none",
        }}
      >
        <Typography
          variant="h1"
          sx={{
            width: "100%",
            textAlign: "center",
            marginBottom: "40px",
            marginTop: "10px",
          }}
          style={{
            textShadow: "0px 1px 1px rgba(0,0,0,0.24)",
          }}
          fontSize={"25px"}
        >
          Add Expenser
        </Typography>

        <AddExpenserInput
          value={userData.name}
          onValueChange={(val) => {
            setUserData((p) => {
              return { ...p, name: val };
            });
          }}
          label="Name"
          type="text"
          required={true}
        />

        <AddExpenserInput
          value={userData.phoneNumber}
          onValueChange={(val) => {
            setUserData((p) => {
              return { ...p, phoneNumber: val };
            });
          }}
          label="Phone Number"
          type="number"
          required={true}
        />

        <AddExpenserInput
          value={userData.email}
          onValueChange={(val) => {
            setUserData((p) => {
              return { ...p, email: val };
            });
          }}
          label="Email"
          type="email"
          required={false}
        />

        <AddExpenserInput
          value={userData.aadhar}
          onValueChange={(val) => {
            setUserData((p) => {
              return { ...p, aadhar: val };
            });
          }}
          label="Aadhar"
          type="number"
          required={true}
        />

        <AddExpenserInput
          value={userData.pan}
          onValueChange={(val) => {
            setUserData((p) => {
              return { ...p, pan: val };
            });
          }}
          label="Pan"
          type="text"
        />

        <AddExpenserMultiHotelSelect
          value={userData.entities}
          onValueChange={(val) => {
            setUserData((e) => {
              return {
                ...e,
                entities: val,
              };
            });
          }}
          required={true}
          hotels={hotels}
        />

        <Button
          variant="outlined"
          size="large"
          sx={{
            backgroundColor: "#262626",
            borderRadius: "30px",
            padding: "10px 15px",
            margin: "20px auto",
            display: "block",
            color: OxyOsTheme.palette.background.main,
            "&:hover": {
              backgroundColor: OxyOsTheme.palette.primary.main,
              color: OxyOsTheme.palette.background.main,
              opacity: 0.6,
            },
          }}
          onClick={() => {
            if (!isLoading) {
              onSubmit();
            }
          }}
        >
          {isLoading ? (
            <CircularProgress color="background" />
          ) : (
            <Typography variant="h6">Add Expenser</Typography>
          )}
        </Button>
      </Card>
    </Modal>
  );
};

export default AddExpenserModal;
