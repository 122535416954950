import { useContext } from "react";
import LoadingBookings from "../../common/LoadingBookings";
import ReprGraph from "../../graph/extra/ReprGraph";
import { AnalyticsContext } from "../../../store/analyticsS";
import { HotelContext } from "../../../store/hotel";
import CustomDateRangePicker from "../../analytics/components/DateRangePicker";
import Constant from "../../../constant/Constant";
import RoomGraph from "../../graph/extra/roomGraph";

const ExtraGraphs = () => {
  const analyticsContext = useContext(AnalyticsContext);
  const hotelContext = useContext(HotelContext);
  let bookingSummaryAnalytics = analyticsContext.bookingSummaryAnalytics;
  let allHotels = Object.keys(bookingSummaryAnalytics);

  const dateContent = (
    <div style={{ position: "absolute", top: "15px", left: "30px", zIndex: 4 }}>
      <CustomDateRangePicker
        startDay={hotelContext.startDay}
        days={hotelContext.days}
        onDateChange={(date, days) => {
          hotelContext.onUpdateDate(date, days);
        }}
      />
    </div>
  );

  if (
    analyticsContext.isBookingSummaryAnalyticsLoading ||
    !analyticsContext.isBookingSummaryAnalyticsLoaded
  ) {
    return (
      <>
        {dateContent}
        <LoadingBookings />
      </>
    );
  }

  return (
    <>
      {dateContent}
      {allHotels.length > 0 && (
        <>
          <ReprGraph
            bookingSummaryAnalytics={bookingSummaryAnalytics}
            isBar={analyticsContext.isBar}
            isDate={analyticsContext.isDate}
            isHotel={analyticsContext.isHotel}
            onToggleBar={() => {
              analyticsContext.onToggleBar();
            }}
            onToggleDate={() => {
              analyticsContext.onToggleDate();
            }}
            onToggleHotel={() => {
              analyticsContext.onToggleHotel();
            }}
            isArr={false}
            allBSources={Constant.BookingSourcesShort}
            allRSources={Constant.RevenueAnalyticsShort}
          />
          <ReprGraph
            bookingSummaryAnalytics={bookingSummaryAnalytics}
            isBar={analyticsContext.isBar}
            isDate={analyticsContext.isDate}
            isHotel={analyticsContext.isHotel}
            onToggleBar={() => {
              analyticsContext.onToggleBar();
            }}
            onToggleDate={() => {
              analyticsContext.onToggleDate();
            }}
            onToggleHotel={() => {
              analyticsContext.onToggleHotel();
            }}
            isArr={true}
            allBSources={Constant.BookingSourcesShort}
            allRSources={Constant.RevenueAnalyticsShort}
          />
          <RoomGraph
            bookingSummaryAnalytics={bookingSummaryAnalytics}
            isBar={analyticsContext.isBar}
            isDate={analyticsContext.isDate}
            isHotel={analyticsContext.isHotel}
            onToggleBar={() => {
              analyticsContext.onToggleBar();
            }}
            onToggleDate={() => {
              analyticsContext.onToggleDate();
            }}
            onToggleHotel={() => {
              analyticsContext.onToggleHotel();
            }}
            // isArr={true}
            allBSources={Constant.BookingSourcesShort}
            allRSources={Constant.RevenueAnalyticsShort}
          />
          <RoomGraph
            bookingSummaryAnalytics={bookingSummaryAnalytics}
            isBar={analyticsContext.isBar}
            isDate={analyticsContext.isDate}
            isHotel={analyticsContext.isHotel}
            onToggleBar={() => {
              analyticsContext.onToggleBar();
            }}
            onToggleDate={() => {
              analyticsContext.onToggleDate();
            }}
            onToggleHotel={() => {
              analyticsContext.onToggleHotel();
            }}
            isArr={true}
            allBSources={Constant.BookingSourcesShort}
            allRSources={Constant.RevenueAnalyticsShort}
          />
        </>
      )}
    </>
  );
};

export default ExtraGraphs;
