import axios from "axios"

const GetAnalytics = async (route,hotelId, token, startDay, days, isTable,cancelToken) => {
    try {
        const response = await axios({
            method: "POST",
            url: route,
            headers: {
                "Authorization": `Bearer ${token}`
            },
            params: {
                hotelId
            },
            data: {
                startDay,
                days,
                isTable
            },
            cancelToken:cancelToken
        })
        return response.data
    } catch (err) {
        console.log(err)
        return err.response ? (err.response.data ? err.response.data : {}) : {}
    }
}

export default GetAnalytics