import { useContext } from "react";
import allRoutes from "../../pages/routes";
import CustomSnackBar from "../common/CustomSnackBar";
import ApprovedListWrapper from "./components/approveds/ApprovedListWrapper";
import { NewExpenseContext } from "../../store/expenses";
import ExpenserListWrapper from "./components/expensers/ExpenserListWrapper";
import ExpenseListWrapper from "./components/expenses/ExpenseListWrapper";
import { Stack } from "@mui/material";
import EntityListWrapper from "./components/entity/EntityListWrapper";
import ExpenseRevenueScreen from "./expenseRevenueAnalytics/expenseRevenueScreen";
import ExpenseTransctionScreen from "./expenseTransctions/expenseTransctionsScreen";
import BalanceSheetBuilderScreen from "./balanceSheetBuilder/BalanceSheetBuilderScreen";
import LoansAndAdvanceScreen from "./loansAndAdvance/loansAndAdvanceScreen";
import { ExpenseContext } from "../../store/expensesS";
// import CategoryManageModel from "./components/categories/CategoryManageModel";
import ProfitAndLossScreen from "./profitAndLoss/ProfitAndLossScreen";
import PartnerListWrapper from "./components/partners/PartnerListWrapper";
import AdvancesListWrapper from "./components/advances/AdvancesListWrapper";
import CategoryManageModel from "./components/categories/CategoryManageModel";
// import CategoryManageModel from "./components/categories/CategoryManageModel";

const ExpenseStack = ({ selRoute }) => {
  const expenseContext = useContext(NewExpenseContext);
  const expenseCont = useContext(ExpenseContext);

  if (selRoute === allRoutes[7]) {
    return (
      <ExpenseRevenueScreen
        isLoading={
          expenseContext.isEntitiesRevenueLoading ||
          !expenseContext.isEntitiesRevenueLoaded
        }
        revenue={expenseContext.entitiesRevenue}
        startDay={expenseContext.startDay}
        days={expenseContext.days}
        onDaysUpdate={(startDay, days) => {
          expenseContext.onUpdateDate(startDay, days);
        }}
      />
    );
  }

  if (selRoute === allRoutes[8]) {
    return (
      <>
        <ExpenseTransctionScreen />
        <CustomSnackBar
          isOpen={expenseCont.isError}
          message={expenseCont.errorMessage}
          onClose={() => {
            expenseCont.onClearMessage();
          }}
        />
      </>
    );
  }

  if (selRoute === allRoutes[9]) {
    return (
      <>
        <BalanceSheetBuilderScreen
          category={expenseContext.categories.categories}
        />
        <CustomSnackBar
          isOpen={expenseCont.isError}
          message={expenseCont.errorMessage}
          onClose={() => {
            expenseCont.onClearMessage();
          }}
        />
      </>
    );
  }

  if (selRoute === allRoutes[10]) {
    return (
      <LoansAndAdvanceScreen
        data={expenseContext.loansAndAdvances}
        isLoading={expenseContext.isLoansAndAdvancesLoading}
        onAddAdvancePayment={(data) => {
          console.log(data);
        }}
        onAddLoanPayment={(data) => {
          console.log(data);
        }}
        sClearer={expenseContext.sClearer}
        onChangeClearer={(id) => {
          expenseContext.onChangeLoanClearer(id);
        }}
        onClearPayment={(data) => {
          expenseContext.onClearLoandAndAdvancePayment(data);
        }}
        isClearing={expenseContext.isClearingPayment}
      />
    );
  }

  if (selRoute === allRoutes[11]) {
    return <ProfitAndLossScreen />;
  }

  if (selRoute !== allRoutes[6]) {
    return;
  }

  return (
    <>
      <Stack direction={"row"}>
        <ExpenserListWrapper />
        <EntityListWrapper />
      </Stack>

      <PartnerListWrapper />

      <ExpenseListWrapper category={expenseContext.categories.categories} />

      <ApprovedListWrapper />

      {/* <AdvancesListWrapper /> */}

      {/* <CategoryManageModel
        onUpdateCategory={(data, ent) => {
          expenseContext.onUpdateCategory(data, ent);
        }}
        isLoading={
          !expenseContext.isCategoryLoaded ||
          expenseContext.isCategoryLoading 
        }
        isUpdating={expenseContext.isCategoryUpdating}
        category={expenseContext.category}
        onChangeCategory={(data) => {
          expenseContext.onCategoryChange(data);
        }}
        entities={expenseContext.permissions}
        onEntitiesChange={(ent) => {
          expenseContext.onEntitiesChange(ent);
        }}
        sEntities={expenseContext.sEntities}
      /> */}

      <CustomSnackBar
        isOpen={expenseCont.isError}
        message={expenseCont.errorMessage}
        onClose={() => {
          expenseCont.onClearMessage();
        }}
      />
    </>
  );
};

export default ExpenseStack;
