import Constant from "../constant/Constant";
import allRoutes from "../pages/routes";
import TaskRequester from "../utils/task/TaskRequester";
import { AuthContext } from "./auth";

const {
  createContext,
  useState,
  useContext,
  useCallback,
  useEffect,
} = require("react");

const TaskUserContext = createContext({
  isTasksLoaded: false,
  isTasksLoading: false,
  tasks: [],

  isCompaniesLoaded: false,
  isCompaniesLoading: false,
  comapnies: [],

  isTaskAdderOpen: false,
  isTaskAdding: false,
  onAddTask: () => {},
  onToggleTaskAdder: () => {},

  isError: false,
  errorMessage: "",

  onClearMessage: () => {},
});

const TaskUserContextProvider = ({ children, selRoute }) => {
  const authContext = useContext(AuthContext);

  const [data, setData] = useState({
    isCompaniesLoaded: false,
    isCompaniesLoading: false,
    comapnies: [],

    isTasksLoaded: false,
    isTasksLoading: false,
    tasks: [],

    isTaskAdderOpen: false,
    isTaskAdding: false,
    isError: false,
    errorMessage: "",
  });


  const onLoadCompanies = useCallback(async () => {
    if (data.isCompaniesLoading) {
      return;
    }

    setData((p) => {
      return {
        ...p,
        isCompaniesLoading: false,
      };
    });

    try {
      const rData = await TaskRequester(
        Constant.getComapnyRoute,
        authContext.taskToken,
        {},
        "POST"
      );

      if (!rData.status || !rData.data) {
        setData((p) => {
          return {
            ...p,
            isError: true,
            errorMessage: rData.errorMessage,
            isCompaniesLoading: false,
            isCompaniesLoaded: false,
          };
        });
      } else {
        setData((p) => {
          return {
            ...p,
            isError: true,
            errorMessage: rData.errorMessage,
            isCompaniesLoading: false,
            isCompaniesLoaded: true,
            comapnies: rData.data,
          };
        });
      }
    } catch (err) {
      console.log(err);
      setData((p) => {
        return {
          ...p,
          isError: true,
          errorMessage: "Please try after some time.",
          isCompaniesLoading: false,
          isCompaniesLoaded: false,
        };
      });
    }
  }, [authContext.taskToken, data.isCompaniesLoading]);

  const onLoadTasks = useCallback(async () => {
    if (data.isTasksLoading) {
      return;
    }

    setData((p) => {
      return {
        ...p,
        isTasksLoading: false,
      };
    });

    try {
      const rData = await TaskRequester(
        Constant.getComapnyRoute,
        authContext.taskToken,
        {},
        "POST"
      );

      if (!rData.status || !rData.data) {
        setData((p) => {
          return {
            ...p,
            isError: true,
            errorMessage: rData.errorMessage,
            isTasksLoading: false,
            isTasksLoaded: false,
          };
        });
      } else {
        setData((p) => {
          return {
            ...p,
            isError: true,
            errorMessage: rData.errorMessage,
            isTasksLoading: false,
            isTasksLoaded: true,
            tasks: rData.data,
          };
        });
      }
    } catch (err) {
      console.log(err);
      setData((p) => {
        return {
          ...p,
          isError: true,
          errorMessage: "Please try after some time.",
          isTasksLoading: false,
          isTasksLoaded: false,
        };
      });
    }
  }, [authContext.taskToken, data.isTasksLoading]);

  // const onLoadTasks = useCallback(async () => {}, [authContext.taskToken]);

  const onAddTask = useCallback(async () => {
    if (authContext.taskToken) {
    }
  }, [authContext.taskToken]);

  const onToggleTaskAdder = useCallback(() => {
    setData((p) => {
      return {
        ...p,
        isTaskAdderOpen: !p.isTaskAdderOpen,
      };
    });
  }, []);

  const onClearMessage = useCallback(() => {
    setData((p) => {
      return {
        ...p,
        isError: true,
        errorMessage: "",
      };
    });
  }, []);

  useEffect(() => {
    if (selRoute === allRoutes[26]) {
      onLoadCompanies();
    }
  }, [onLoadCompanies, selRoute]);

  useEffect(() => {
    if (selRoute === allRoutes[26]) {
      onLoadTasks();
    }
  }, [onLoadTasks, selRoute]);

  const value = {
    ...data,
    onClearMessage,
    onToggleTaskAdder,
    onAddTask,
  };

  return (
    <TaskUserContext.Provider value={value}>
      {children}
    </TaskUserContext.Provider>
  );
};

export default TaskUserContextProvider;
