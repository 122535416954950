import { Stack } from "@mui/material";
import SingleSourceViewer from "./SingleSourceViewer";
import SingleHNameViewer from "./SingleHNameViewer";

const PropertyExpenseViewer = ({ expenses, analytics, hotelName }) => {
  const Revenue = Object.values(analytics.Revenue[hotelName])
    .map((a) => Object.values(a))
    .flat()
    .reduce((acc, cur) => {
      if (acc[cur.sources]) {
        acc[cur.sources] =
          cur.XBR +
          cur.OBR +
          cur.XECR +
          cur.OECR +
          cur.CFEE +
          cur.GST +
          acc[cur.sources];
      } else {
        acc[cur.sources] =
          cur.XBR + cur.OBR + cur.XECR + cur.OECR + cur.CFEE + cur.GST;
      }
      return acc;
    }, {});

  const TotalRevenue = Object.values(Revenue).reduce((acc, cur) => {
    return acc + cur;
  }, 0);

  const Gst = Object.values(analytics.Revenue[hotelName])
    .map((a) => Object.values(a))
    .flat()
    .reduce((acc, cur) => {
      return acc + cur.GST;
    }, 0);

  const Summary = Object.values(analytics.Expense[hotelName])
    .flat()
    .reduce((acc, cur) => {
      if (acc[cur.sources]) {
        if (acc[cur.sources][cur.category]) {
          if (acc[cur.sources][cur.category][cur.name]) {
            if (acc[cur.sources][cur.category][cur.name][cur.date]) {
              acc[cur.sources][cur.category][cur.name][cur.date] =
                acc[cur.sources][cur.category][cur.name][cur.date] + cur.amount;
            } else {
              acc[cur.sources][cur.category][cur.name][cur.date] = cur.amount;
            }
          } else {
            acc[cur.sources][cur.category][cur.name] = {};
            acc[cur.sources][cur.category][cur.name][cur.date] = cur.amount;
          }
        } else {
          acc[cur.sources][cur.category] = {};
          acc[cur.sources][cur.category][cur.name] = {};
          acc[cur.sources][cur.category][cur.name][cur.date] = cur.amount;
        }
      } else {
        acc[cur.sources] = {};
        acc[cur.sources][cur.category] = {};
        acc[cur.sources][cur.category][cur.name] = {};
        acc[cur.sources][cur.category][cur.name][cur.date] = cur.amount;
      }
      return acc;
    }, {});

  return (
    <Stack
      direction={"column"}
      padding={1}
      sx={{
        margin: "50px",
        border: "1px solid #262626",
        borderRadius: "10px",
        padding: "10px",
        width: "540px",
        alignItems: "center",
        paddingLeft: "20px",
      }}
    >
      {Object.keys(Revenue).map((rev, index) => {
        return (
          <SingleHNameViewer
            name={rev}
            key={rev}
            amount={Math.round(Revenue[rev] * 100) / 100}
            pAmount={Object.keys(Revenue)
              .slice(0, index)
              .reduce((acc, cur) => {
                return acc + Revenue[cur];
              }, 0)}
            isSum={true}
          />
        );
      })}
      <SingleHNameViewer
        name={"GST Bill"}
        key={"GST"}
        amount={Math.round(Gst * 100) / 100}
        pAmount={TotalRevenue}
        isSum={false}
      />
      {Object.keys(Summary).map((at, index) => {
        return (
          <SingleSourceViewer
            key={at}
            sourceName={at}
            sourceData={Summary[at]}
            pAmount={
              TotalRevenue -
              Gst -
              Object.keys(Summary)
                .slice(0, index)
                .map((a) => Object.values(Summary[a]))
                .flat()
                .map((a) => Object.values(a))
                .flat()
                .map((a) => Object.values(a))
                .flat()
                .reduce((acc, cur) => {
                  return acc + cur;
                }, 0)
            }
          />
        );
      })}
    </Stack>
  );
};

export default PropertyExpenseViewer;
