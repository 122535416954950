import { Container, Typography } from "@mui/material";

const MobilePage = () => {
  return (
    <Container
      sx={{
        display: "flex",
        width: "100vw",
        height: "100vh",
        justifyContent: "space-between",
        alignItems: "center",
        flexGrow:1
      }}
    >
      <Typography variant="h6" color="primary.main" sx={{width:"100vw",textAlign:"center"}}>
        Please open this on desktop.
      </Typography>
    </Container>
  );
};

export default MobilePage;
