import { useState } from "react";
import {
  Button,
  Card,
  CircularProgress,
  Modal,
  Typography,
} from "@mui/material";
import OxyOsTheme from "../../../../theme/theme";
import AddExpenserHotelSelect from "../common/AddExpenserHotelSelect";
import AddExpenserInput from "../common/AddExpenserInput";
import AddExpenserMultiHotelSelect from "../common/AddExpenserMultiHotelSelect";

const AddSourceModel = ({ isOpen, onClose, onAdd, isLoading, hotels }) => {
  const [data, setData] = useState({
    id: [],
    name: "",
  });

  return (
    <Modal
      open={isOpen}
      onClose={onClose}
      sx={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <Card
        sx={{
          flexDirection: "row",
          minHeight: "500px",
          maxHeight: "600px",
          maxWidth: "700px",
          minWidth: "500px",
          background: "#FFF",
          borderRadius: "10px",
          padding: "20px 30px",
          overflowY: "scroll",
          "::-webkit-scrollbar": {
            display: "none",
          },
          border: "none",
        }}
      >
        <Typography
          variant="h1"
          sx={{
            width: "100%",
            textAlign: "center",
            marginBottom: "40px",
            marginTop: "10px",
          }}
          style={{
            textShadow: "0px 1px 1px rgba(0,0,0,0.24)",
          }}
          fontSize={"25px"}
        >
          Add Source
        </Typography>

        <AddExpenserMultiHotelSelect
          value={data.id}
          onValueChange={(data) => {
            setData((e) => {
              return {
                ...e,
                id: data,
              };
            });
          }}
          required={true}
          hotels={hotels}
        />

        <AddExpenserInput
          value={data.name}
          onValueChange={(val) => {
            setData((e) => {
              return {
                ...e,
                name: val,
              };
            });
          }}
          label={"Name"}
          type={"text"}
          required={true}
        />

        <Button
          variant="outlined"
          size="large"
          sx={{
            backgroundColor: "#262626",
            borderRadius: "30px",
            padding: "10px 15px",
            margin: "20px auto",
            display: "block",
            color: OxyOsTheme.palette.background.main,
            "&:hover": {
              backgroundColor: OxyOsTheme.palette.primary.main,
              color: OxyOsTheme.palette.background.main,
              opacity: 0.6,
            },
          }}
          onClick={() => {
            if (!isLoading) {
              onAdd(data);
            }
          }}
        >
          {isLoading ? (
            <CircularProgress color="background" />
          ) : (
            <Typography variant="h6">Add Source</Typography>
          )}
        </Button>
      </Card>
    </Modal>
  );
};

export default AddSourceModel;
