import { Delete } from "@mui/icons-material";
import { Stack, Typography, IconButton } from "@mui/material";

const ExpenseViewer = ({ expense, onDelete, expensers, hotels }) => {
  return (
    <Stack
      sx={{
        margin: "10px 10px",
        alignItems: "center",
      }}
      direction={"row"}
    >
      <Stack direction={"column"}>
        <Stack direction={"row"} spacing={1}>
          <Typography variant="h6" fontSize={"15px"}>
            {expense.note} - ₹
            {expense.dates
              ? Object.values(expense.dates).reduce((acc, cur) => {
                  return acc + Number(cur);
                }, 0)
              : 0}
          </Typography>
          <Typography variant="h6" fontSize={"15px"}>
            {Boolean(expense.isAdvance) && "Advance Payment"}
          </Typography>
          <Typography variant="h6" fontSize={"15px"}>
            {expense.isEntity
              ? hotels.find((hot) => hot._id === expense.expenserId).hotelName
              : expensers.find((e) => e.id === expense.expenserId).name}
          </Typography>

          <Typography variant="h6" fontSize={"15px"}>
            Category -
            {Array.isArray(expense.category) && expense.category.join(",")}
          </Typography>
        </Stack>
        <Stack>
          {expense.payments.map((pa) => {
            return (
              <Stack direction={"row"} spacing={1}>
                <Typography variant="h6" fontSize={"15px"}>
                  {pa.sourceName} {"->"} {expense.isEntity && pa.pSourceName}
                </Typography>
                <Typography variant="h6" fontSize={"15px"}>
                  {pa.amount} - {pa.date}
                </Typography>
              </Stack>
            );
          })}
        </Stack>
      </Stack>
      <IconButton
        onClick={() => {
          onDelete();
        }}
      >
        <Delete />
      </IconButton>
    </Stack>
  );
};

export default ExpenseViewer;
