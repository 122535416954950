import {
  Button,
  Card,
  CircularProgress,
  Modal,
  Stack,
  Typography,
} from "@mui/material";
import moment from "moment";
import OxyOsTheme from "../../../../theme/theme";
import AddPaymentModel from "./AddPaymentModel";

const EditApprovedModel = ({
  expense,
  onClose,
  isPaymentAdding,
  onAddPayment,
  isPaymentAdderOpen,
  onTogglePaymentAdder,
}) => {
  return (
    <Modal
      open={true}
      onClose={onClose}
      sx={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <Card
        sx={{
          flexDirection: "row",
          minHeight: "500px",
          maxHeight: "600px",
          maxWidth: "700px",
          minWidth: "500px",
          background: "#FFF",
          borderRadius: "10px",
          padding: "20px 30px",
          overflowY: "scroll",
          "::-webkit-scrollbar": {
            display: "none",
          },
          border: "none",
        }}
      >
        <Stack
          sx={{
            borderRadius: "5px",
            border: "1px solid #262626",
            margin: "10px 0px",
            padding: "7px 10px",
            transition: "border 1s",
            ":hover": {
              boxShadow: "0px 10px 15px -3px rgba(0,0,0,0.1)",
            },
            cursor: "pointer",
          }}
          direction="row"
          spacing={2}
        >
          <Stack direction="column" spacing={0} sx={{ width: "120px" }}>
            <Typography
              variant="h6"
              color="primary.main"
              sx={{ fontSize: "13px", textOverflow: "ellipsis" }}
            >
              {`${expense.partnerRefId[0].toUpperCase()}${expense.partnerRefId.slice(
                1
              )}`}
            </Typography>
            <Typography
              variant="h6"
              color="primary.main"
              sx={{ fontSize: "11px" }}
            >
              #{expense.id}
            </Typography>
          </Stack>

          <Stack sx={{ width: "100px" }}>
            <Typography
              variant="h6"
              color="primary.main"
              sx={{ fontSize: "13px" }}
            >
              {expense.entityRefId}
            </Typography>
          </Stack>

          {expense.createdAt && (
            <Stack sx={{ width: "100px" }}>
              <Typography
                variant="h6"
                color="primary.main"
                sx={{ fontSize: "13px" }}
              >
                {moment(expense.createdAt).format("DD-MM-YYYY")}
              </Typography>
            </Stack>
          )}

          {expense.payment && (
            <Stack sx={{ width: "100px" }}>
              <Typography
                variant="h6"
                color="primary.main"
                sx={{ fontSize: "13px" }}
              >
                Paid - ₹
                {expense.payment.reduce((acc, cur) => {
                  return acc + cur.value;
                }, 0)}
              </Typography>
            </Stack>
          )}
        </Stack>
        <Stack
          sx={{
            borderRadius: "5px",
            border: "1px solid #262626",
            margin: "10px 0px",
            padding: "7px 10px",
            transition: "border 1s",
            ":hover": {
              boxShadow: "0px 10px 15px -3px rgba(0,0,0,0.1)",
            },
            // width: "max-content",
            cursor: "pointer",
          }}
          direction="row"
          spacing={2}
        >
          <Stack sx={{ width: "190px" }}>
            <Typography
              variant="h6"
              color="primary.main"
              sx={{ fontSize: "13px" }}
            >
              {(expense.isExpenserToBusinessLoan ? "Personal " : "") +
                expense.category}
            </Typography>
          </Stack>

          <Stack sx={{ width: "100px" }}>
            <Typography
              variant="h6"
              color="primary.main"
              sx={{ fontSize: "13px" }}
            >
              {expense.note}
            </Typography>
          </Stack>

          {expense.date && (
            <Stack sx={{ width: "100px" }}>
              <Typography
                variant="h6"
                color="primary.main"
                sx={{ fontSize: "13px" }}
              >
                {moment(expense.date).format("DD-MM-YYYY")}
              </Typography>
            </Stack>
          )}

          {expense.amount && (
            <Stack sx={{ width: "100px" }}>
              <Typography
                variant="h6"
                color="primary.main"
                sx={{ fontSize: "13px" }}
              >
                ₹{expense.amount}
              </Typography>
            </Stack>
          )}
        </Stack>

        <Stack
          direction="row"
          sx={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-around",
            alignItems: "center",
            margin: "10px 10px",
          }}
        >
          {expense.isApproved && !expense.isPaid && (
            <Button
              variant="outlined"
              size="large"
              sx={{
                backgroundColor: "#262626",
                borderRadius: "30px",
                padding: "10px 15px",
                margin: "20px auto",
                display: "block",
                color: OxyOsTheme.palette.background.main,
                "&:hover": {
                  backgroundColor: OxyOsTheme.palette.primary.main,
                  color: OxyOsTheme.palette.background.main,
                  opacity: 0.6,
                },
              }}
              onClick={() => {
                if (!isPaymentAdding && !isPaymentAdderOpen) {
                  onTogglePaymentAdder();
                }
              }}
            >
              {isPaymentAdderOpen && (
                <AddPaymentModel
                  isOpen={isPaymentAdderOpen}
                  onClose={() => {
                    onTogglePaymentAdder();
                  }}
                  expenseId={expense.id}
                  isLoading={isPaymentAdding}
                  onSubmit={(data) => {
                    onAddPayment(data);
                  }}
                  eSources={expense.eSources}
                  pSources={expense.pSources}
                  amount={
                    expense.amount -
                    expense.payment.reduce((acc, cur) => {
                      return acc + cur.value;
                    }, 0)
                  }
                  date={expense.createdAt}
                  note={expense.approvalNote}
                />
              )}
              {isPaymentAdding ? (
                <CircularProgress color="background" />
              ) : (
                <Typography variant="h6">Payment</Typography>
              )}
            </Button>
          )}
        </Stack>
      </Card>
    </Modal>
  );
};

export default EditApprovedModel;
