import React, { useState, useEffect } from "react";
import ChartDataLabels from "chartjs-plugin-datalabels";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  BarElement,
} from "chart.js";
import { Line, Bar } from "react-chartjs-2";
import Graph from "../util/graph";
import HotelChooser from "../components/HotelChooser";
import { Stack } from "@mui/material";
import HotelSwitch from "../components/HotelSwitch";
import labelFinder from "../util/labelFinder";
import ReprDataFinder from "./ReprDataFinder";
import HotelGroupSwitchContent from "../components/HotelGroupSwitchContent";
import moment from "moment";

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  BarElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  ChartDataLabels
);

const ReprGraph = ({
  bookingSummaryAnalytics,
  isHotel,
  isDate,
  isBar,
  onToggleHotel,
  onToggleDate,
  onToggleBar,
  isArr,
  allBSources,
  allRSources,

}) => {
  let title = Boolean(isArr) ? "ARR" : "REPR";
  const [sHotels, setSHotels] = useState([]);
  const hotels = Object.keys(bookingSummaryAnalytics);
  let allDates = Object.keys(bookingSummaryAnalytics[hotels[0]].data);
  const [bSources, setBSources] = useState(allBSources);
  const [rSources, setRSources] = useState(allRSources);

  const [data, setData] = useState({});

  useEffect(() => {
    const hotels = Object.keys(bookingSummaryAnalytics);
    let allDates = Object.keys(bookingSummaryAnalytics[hotels[0]].data);

    const datasets = ReprDataFinder(
      bookingSummaryAnalytics,
      allDates,
      isHotel,
      isDate,
      sHotels,
      isBar,
      Boolean(isArr),
      bSources,
      rSources
    );

    const labels = labelFinder(
      allDates.map((dt) => moment(dt, "DD-MM-YYYY").format("DD-MMM")),
      isHotel,
      isDate,
      sHotels
    );
    setData({ labels, datasets });
  }, [
    bookingSummaryAnalytics,
    isHotel,
    isDate,
    sHotels,
    isBar,
    isArr,
    bSources,
    rSources,
  ]);

  useEffect(() => {
    setSHotels(Object.keys(bookingSummaryAnalytics));
  }, [bookingSummaryAnalytics]);

  return (
    <div style={{ margin: "20px", paddingTop: "20px", position: "relative" }}>
      <Stack
        style={{
          position: "absolute",
          top: "-10px",
          right: "10px",
        }}
        direction="row"
        spacing={1}
      >
        <HotelGroupSwitchContent
          content={
            <>
              <HotelSwitch
                isChecked={isBar}
                label={"Bar"}
                onChange={(v) => {
                  onToggleBar();
                }}
              />

              {hotels.length > 1 ? (
                <HotelSwitch
                  isChecked={isHotel}
                  label={"Hotel"}
                  onChange={(v) => {
                    onToggleHotel();
                  }}
                />
              ) : (
                <></>
              )}

              {allDates.length > 1 ? (
                <HotelSwitch
                  isChecked={isDate}
                  label={"Date"}
                  onChange={(v) => {
                    onToggleDate();
                  }}
                />
              ) : (
                <></>
              )}
            </>
          }
        />

        <HotelChooser
          hotels={allBSources}
          selectedHotels={bSources}
          onHotelsChange={(o) => {
            setBSources(o);
          }}
          width="80px"
        />

        <HotelChooser
          hotels={allRSources}
          selectedHotels={rSources}
          onHotelsChange={(o) => {
            setRSources(o);
          }}
          width="80px"
        />

        <HotelChooser
          hotels={hotels}
          selectedHotels={sHotels}
          onHotelsChange={(s) => {
            setSHotels(s);
          }}
          width="200px"
        />
      </Stack>

      {data &&
        data.datasets &&
        data.datasets.length > 0 &&
        (isBar ? (
          <Bar
            style={{ marginTop: "20px" }}
            options={Graph.BarOptions(
              title,
              true,
              data.datasets.reduce((acc, cur) => {
                if (!acc[cur.stack]) {
                  let nAcc = { ...acc };
                  nAcc[cur.stack] = [cur];
                  return nAcc;
                } else {
                  let nAcc = { ...acc };
                  nAcc[cur.stack] = [...nAcc[cur.stack], cur];
                  return nAcc;
                }
              }, {})
            )}
            data={data}
          />
        ) : (
          <Line
            style={{ marginTop: "20px" }}
            options={Graph.Options(title)}
            data={data}
          />
        ))}
    </div>
  );
};

export default ReprGraph;
