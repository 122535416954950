import { useContext, useEffect, useRef, useState } from "react";
import { HotelContext } from "../../store/hotel";
import {
  AppBar,
  Menu,
  MenuItem,
  Toolbar,
  Typography,
  Stack,
  Button,
  ClickAwayListener,
  IconButton,
} from "@mui/material";
import SelctedHotelDetails from "../allhotels/SelctedHotelDetails";
import ViewHotelOption from "../allhotels/ViewHotelOption";
import AllHotelsOption from "../allhotels/AllHotelsOption";
import MenuIcon from "@mui/icons-material/Menu";

const Header = ({ isHeaderOpen, onToggle }) => {
  const hotelContext = useContext(HotelContext);
  const [isOpen, setIsOpen] = useState(false);

  const toggleSelector = (e) => {
    e.stopPropagation();
    setIsOpen((p) => !p);
  };
  const handleOutside = () => {
    setIsOpen((p) => !p);
  };

  useEffect(() => {
    if (isOpen) {
      window.addEventListener("click", handleOutside);
    } else {
      window.removeEventListener("click", handleOutside);
    }
    return () => {
      window.removeEventListener("click", handleOutside);
    };
  }, [isOpen]);

  const anchorEl = useRef();

  return (
    <AppBar
      position="static"
      sx={{
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        justifyContent: "space-between",
        padding: "10px 40px",
        transition: "height 300ms ease",
        height: "100px",
      }}
    >
      <Stack
        direction="row"
        sx={{
          display: "flex",
          flexGrow: 1,
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        
        <div
          style={{
            display: "flex",
            justifyContent: "space-around",
            alignItems: "center",
          }}
        >
          <Toolbar
            sx={{
              marginRight: "50px",
              marginTop: "2.1px",
            }}
          >
            <IconButton
              onClick={() => {
                onToggle()
              }}
            >
              <MenuIcon sx={{
                fontSize:"37px",
                color:"white"
              }} />
            </IconButton>
          </Toolbar>
          <Typography sx={{ minWidth: "150px" }} variant="h1">
            Oxy Os
          </Typography>
        </div>

        <Button
          ref={anchorEl}
          onClick={toggleSelector}
          sx={{
            border: "1px solid #fff",
            borderRadius: "10px",
            padding: "10px 15px",
            transition: "width 3s",
          }}
        >
          <SelctedHotelDetails hotel={hotelContext.selctedHotelId} />
        </Button>
      </Stack>
      <ClickAwayListener
        onClickAway={() => {
          setIsOpen(false);
        }}
      >
        <Toolbar
          sx={{
            position: "absolute",
          }}
        >
          <Menu
            anchorEl={anchorEl.current}
            open={Boolean(anchorEl.current) && isOpen}
            keepMounted
            sx={{
              maxHeight: "500px",
              padding: "10px 0px",
            }}
          >
            <MenuItem
              onClick={(event) => {
                hotelContext.updateSelectedHotel("");
                toggleSelector(event);
              }}
              key={""}
              selected={hotelContext.selctedHotelId === ""}
            >
              <AllHotelsOption />
            </MenuItem>
            {hotelContext.hotels.map((hotel) => {
              return (
                <MenuItem
                  onClick={(event) => {
                    hotelContext.updateSelectedHotel(hotel._id);
                    toggleSelector(event);
                  }}
                  key={hotel._id}
                  selected={hotelContext.selctedHotelId === hotel._id}
                >
                  <ViewHotelOption hotel={hotel} />
                </MenuItem>
              );
            })}
          </Menu>
        </Toolbar>
      </ClickAwayListener>
    </AppBar>
  );
};

export default Header;
