import { Button, Collapse, Stack, Typography } from "@mui/material";
import { useState } from "react";
import { KeyboardArrowDown, KeyboardArrowUp } from "@mui/icons-material";
import BookingAnanlyticsBookingPreview from "../BookingAnanlyticsBookingPreview";

const SingleUserAnalyticsViewer = ({ user, type }) => {
  const [isOpen, setIsOpen] = useState(false);

  const onToggle = () => {
    setIsOpen((p) => !p);
  };

  return (
    <>
      <Button
        onClick={onToggle}
        disableRipple
        sx={{
          justifyContent: "space-between",
          display: "flex",
          alignItems: "center",
          flexDirection: "row",
          textTransform: "none",
        }}
      >
        <Stack
          sx={{
            borderRadius: "4px",
            border: "1px solid #262626",
            margin: "3px 0px",
            padding: "7px 7px",
            transition: "border 1s",
            ":hover": {
              boxShadow: "0px 10px 15px -3px rgba(0,0,0,0.1)",
            },
            width: "min-content",
          }}
          direction="row"
          spacing={2}
        >
          <Stack sx={{ width: "130px",paddingLeft:"10px" }}>
            <Typography
              variant="h6"
              color="primary.main"
              sx={{ fontSize: "13px",textAlign:"left" }}
            >
              {user.name[0].toUpperCase()}
              {user.name.slice(1)}
            </Typography>
          </Stack>

          <Stack sx={{ width: "130px" }}>
            <Typography
              variant="h6"
              color="primary.main"
              sx={{ fontSize: "11px" }}
            >
              {user.phoneNumber}
            </Typography>
          </Stack>

          <Stack>
            {isOpen ? (
              <KeyboardArrowUp sx={{ marginLeft: "20px" }} />
            ) : (
              <KeyboardArrowDown sx={{ marginLeft: "20px" }} />
            )}
          </Stack>
        </Stack>
      </Button>

      <Collapse
        sx={{ paddingLeft: "30px" }}
        in={isOpen}
        timeout="auto"
        unmountOnExit
      >
        <div style={{ marginLeft: "10px" }}>
          {user.bookings.map((book) => {
            return (
              <BookingAnanlyticsBookingPreview
                key={book.bookingId}
                booking={book}
                type={type}
                isHotel={true}
              />
            );
          })}
        </div>
      </Collapse>
    </>
  );
};

export default SingleUserAnalyticsViewer;
