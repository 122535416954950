import { Stack, Typography } from "@mui/material";

const UserViewer = ({ user, onClick }) => {

  return (
    <Stack
      sx={{
        borderRadius: "5px",
        border: "1px solid #262626",
        margin: "10px 0px",
        padding: "7px 10px",
        transition: "border 1s",
        ":hover": {
          boxShadow: "0px 10px 15px -3px rgba(0,0,0,0.1)",
        },
        cursor: "pointer",
      }}
      direction="row"
      spacing={2}
      onClick={() => {
        onClick();
      }}
    >
      <Stack sx={{ width: "140px" }}>
        <Typography variant="h6" color="primary.main" sx={{ fontSize: "13px" }}>
          {user.name}
        </Typography>
        <Typography
          
          variant="h6"
          color="primary.main"
          sx={{ fontSize: "10px" }}
        >
          +{user.phoneNo}
        </Typography>
      </Stack>

      <Stack sx={{ flexGrow: 1 }}>
        <Typography variant="h6" color="primary.main" sx={{ fontSize: "13px" }}>
          {
            user.company.map(cc => `${cc.name} - ${cc.position} (${cc.priority})`).join(" , ")
          }
        </Typography>
      </Stack>
    </Stack>
  );
};

export default UserViewer;
