import {
  Button,
  Card,
  CircularProgress,
  Modal,
  Stack,
  Typography,
} from "@mui/material";
import moment from "moment";
import splitArrayIntoPairs from "../../../../utils/common/splitArrayIntoPairs";
import UpdateRecurringStatus from "../common/UpdateRecurringStatus";
import OxyOsTheme from "../../../../theme/theme";
import { useState } from "react";
import AddExpenserInput from "../common/AddExpenserInput";

const EditExpenseModel = ({ expense, onClose, onApprove, isApproving,naote }) => {
  const [note, setNote] = useState(naote);

  let allowedDates = [
    moment.tz(new Date(), "Asia/Kolkata").format("DD-MM-YYYY"),
    moment
      .tz(new Date(), "Asia/Kolkata")
      .subtract(1, "day")
      .format("DD-MM-YYYY"),
  ];

  const [editedValue, setEditedValue] = useState({});

  return (
    <Modal
      open={true}
      onClose={onClose}
      sx={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <Card
        sx={{
          flexDirection: "row",
          minHeight: "500px",
          maxHeight: "600px",
          maxWidth: "700px",
          minWidth: "500px",
          background: "#FFF",
          borderRadius: "10px",
          padding: "20px 30px",
          overflowY: "scroll",
          "::-webkit-scrollbar": {
            display: "none",
          },
          border: "none",
        }}
      >
        <Stack
          sx={{
            borderRadius: "5px",
            border: "1px solid #262626",
            margin: "10px 0px",
            padding: "7px 10px",
            transition: "border 1s",
            ":hover": {
              boxShadow: "0px 10px 15px -3px rgba(0,0,0,0.1)",
            },
            cursor: "pointer",
          }}
          direction="row"
          spacing={2}
        >
          <Stack direction="column" spacing={0} sx={{ width: "120px" }}>
            <Typography
              variant="h6"
              color="primary.main"
              sx={{ fontSize: "13px", textOverflow: "ellipsis" }}
            >
              {`${expense.partnerRefId[0].toUpperCase()}${expense.partnerRefId.slice(
                1
              )}`}
            </Typography>
            <Typography
              variant="h6"
              color="primary.main"
              sx={{ fontSize: "11px" }}
            >
              #{expense.id}
            </Typography>
          </Stack>

          <Stack sx={{ width: "100px" }}>
            <Typography
              variant="h6"
              color="primary.main"
              sx={{ fontSize: "13px" }}
            >
              {expense.entityRefId}
            </Typography>
          </Stack>

          {expense.createdAt && (
            <Stack sx={{ width: "100px" }}>
              <Typography
                variant="h6"
                color="primary.main"
                sx={{ fontSize: "13px" }}
              >
                {moment(expense.createdAt).format("DD-MM-YYYY")}
              </Typography>
            </Stack>
          )}

          {expense.payment && (
            <Stack sx={{ width: "100px" }}>
              <Typography
                variant="h6"
                color="primary.main"
                sx={{ fontSize: "13px" }}
              >
                Paid - ₹
                {expense.payment.reduce((acc, cur) => {
                  return acc + cur.value;
                }, 0)}
              </Typography>
            </Stack>
          )}
        </Stack>
        <Stack
          sx={{
            borderRadius: "5px",
            border: "1px solid #262626",
            margin: "10px 0px",
            padding: "7px 10px",
            transition: "border 1s",
            ":hover": {
              boxShadow: "0px 10px 15px -3px rgba(0,0,0,0.1)",
            },
            // width: "max-content",
            cursor: "pointer",
          }}
          direction="row"
          spacing={2}
        >
          <Stack sx={{ width: "190px" }}>
            <Typography
              variant="h6"
              color="primary.main"
              sx={{ fontSize: "13px" }}
            >
              {(expense.isExpenserToBusinessLoan ? "Personal " : "") +
                expense.category}
            </Typography>
          </Stack>

          <Stack sx={{ width: "100px" }}>
            <Typography
              variant="h6"
              color="primary.main"
              sx={{ fontSize: "13px" }}
            >
              {expense.note}
            </Typography>
          </Stack>

          {expense.date && (
            <Stack sx={{ width: "100px" }}>
              <Typography
                variant="h6"
                color="primary.main"
                sx={{ fontSize: "13px" }}
              >
                {moment(expense.date).format("DD-MM-YYYY")}
              </Typography>
            </Stack>
          )}

          {expense.amount && (
            <Stack sx={{ width: "100px" }}>
              <Typography
                variant="h6"
                color="primary.main"
                sx={{ fontSize: "13px" }}
              >
                ₹{expense.amount}
              </Typography>
            </Stack>
          )}
        </Stack>

        {expense.breakup && (
          <Stack
            sx={{
              borderRadius: "5px",
              border: "1px solid #262626",
              margin: "10px 0px",
              padding: "7px 10px",
              transition: "border 1s",
              ":hover": {
                boxShadow: "0px 10px 15px -3px rgba(0,0,0,0.1)",
              },
              // width: "max-content",
              cursor: "pointer",
            }}
            direction="column"
            spacing={2}
          >
            {splitArrayIntoPairs(Object.keys(expense.breakup)).map(
              (pair, index) => {
                return (
                  <Stack
                    key={index}
                    sx={{
                      flexGrow: 1,
                      flexDirection: "row",
                      justifyContent: "space-between",
                      alignItems: "center",
                    }}
                  >
                    <UpdateRecurringStatus
                      value={
                        allowedDates.includes(pair[0])
                          ? editedValue[pair[0]]
                            ? editedValue[pair[0]]
                            : expense.breakup[pair[0]]
                          : expense.breakup[pair[0]]
                      }
                      onValueChange={(val) => {
                        let data = {};
                        data[pair[0]] = val;
                        setEditedValue(data);
                      }}
                      label={moment
                        .tz(pair[0], "DD-MM-YYYY", "Asia/Kolkata")
                        .format("DD-MMM")}
                      isEnabled={false}
                    />
                    {Boolean(pair[1]) && (
                      <UpdateRecurringStatus
                        value={
                          allowedDates.includes(pair[1])
                            ? editedValue[pair[1]]
                              ? editedValue[pair[1]]
                              : expense.breakup[pair[1]]
                            : expense.breakup[pair[1]]
                        }
                        onValueChange={(val) => {
                          let data = {};
                          data[pair[1]] = val;
                          setEditedValue(data);
                        }}
                        label={moment
                          .tz(pair[1], "DD-MM-YYYY", "Asia/Kolkata")
                          .format("DD-MMM")}
                        isEnabled={false}
                      />
                    )}
                  </Stack>
                );
              }
            )}
          </Stack>
        )}

        <AddExpenserInput
          value={note}
          onValueChange={(val) => {
            setNote(val);
          }}
          label={"Note"}
          required={true}
          type={"text"}
        />

        {!expense.isApproved && !expense.isNotApproved && (
          <Stack direction={"row"}>
            <Button
              variant="outlined"
              size="large"
              sx={{
                backgroundColor: "#262626",
                borderRadius: "30px",
                padding: "10px 15px",
                margin: "20px auto",
                display: "block",
                color: OxyOsTheme.palette.background.main,
                "&:hover": {
                  backgroundColor: OxyOsTheme.palette.primary.main,
                  color: OxyOsTheme.palette.background.main,
                  opacity: 0.6,
                },
              }}
              onClick={() => {
                if (!isApproving) {
                  onApprove(expense.id, false, note);
                }
              }}
            >
              {isApproving ? (
                <CircularProgress color="background" />
              ) : (
                <Typography variant="h6">Not Approve</Typography>
              )}
            </Button>

            <Button
              variant="outlined"
              size="large"
              sx={{
                backgroundColor: "#262626",
                borderRadius: "30px",
                padding: "10px 15px",
                margin: "20px auto",
                display: "block",
                color: OxyOsTheme.palette.background.main,
                "&:hover": {
                  backgroundColor: OxyOsTheme.palette.primary.main,
                  color: OxyOsTheme.palette.background.main,
                  opacity: 0.6,
                },
              }}
              onClick={() => {
                if (!isApproving) {
                  onApprove(expense.id, true, note);
                }
              }}
            >
              {isApproving ? (
                <CircularProgress color="background" />
              ) : (
                <Typography variant="h6">Approve</Typography>
              )}
            </Button>
          </Stack>
        )}
      </Card>
    </Modal>
  );
};

export default EditExpenseModel;
