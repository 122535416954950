import { Stack, Typography } from "@mui/material";

const SingleRoomViewer = ({ room, isFilled }) => {
  let color = isFilled ? "#e57373" : "#81c784";

  return (
    <Stack
      sx={{
        width: "100px",
        height: "100px",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        borderRadius: "10px",
        border: `1px solid ${color}`,
        margin: "15px 15px",
        background: color,
        filter:"drop-shadow(0px 2px 2px rgba(0,0,0,0.24))"
      }}
      spacing={2}
    >
      <Typography
        sx={{ width: "100px", textAlign: "center" }}
        variant="h1"
        color="primary.main"
        fontSize="18px"
      >
        {room.roomNo}
      </Typography>
      <Typography variant="h1" color="primary.main" fontSize="12px">
        {room.roomType}
      </Typography>
    </Stack>
  );
};

export default SingleRoomViewer;
