import { useContext, useState } from "react";
import LoadingBookings from "../../common/LoadingBookings";
import { ExpenseContext } from "../../../store/expensesS";
import SingleEntityLedger from "./components/Ledger/SingleEntityLedger";
import SingleEntityTransctionViewer from "./components/SingleEntityTransctionViewer";
import TransactionGroupSwitchContent from "./components/common/TransactionGroupSwitchContent";
import TransactionSwitch from "./components/common/TransactionSwitch";
import AccountsDateRangePicker from "../../analytics/components/AccountsDateRangePicker";

const ExpenseTransctionScreen = () => {
  const expenseContext = useContext(ExpenseContext);
  const transctions = expenseContext.transactionAnalytics;
  let allEntities = Object.keys(transctions);

  const [format, setFormat] = useState({
    archive: true,
    summary: true,
  });

  const dateContent = (
    <div style={{ position: "absolute", top: "15px", left: "30px", zIndex: 4 }}>
      <AccountsDateRangePicker
        startDay={expenseContext.startDay}
        days={expenseContext.days}
        onDateChange={(date, days) => {
          expenseContext.onDaysUpdate(date, days);
        }}
        previous={expenseContext.isPreviousFinancialYear}
      />
    </div>
  );

  if (
    expenseContext.isTransactionsAnalyticsLoading ||
    !expenseContext.isTransactionsAnalyticsLoaded
  ) {
    return <LoadingBookings />;
  }

  return (
    <>
      {dateContent}
      <div
        style={{
          position: "absolute",
          right: 20,
          top: 15,
        }}
      >
        <TransactionGroupSwitchContent
          content={
            <>
              <TransactionSwitch
                label={"Archive"}
                onChange={() => {
                  setFormat((f) => {
                    return {
                      ...f,
                      archive: !f.archive,
                    };
                  });
                }}
                isChecked={format.archive}
              />

              {format.archive && (
                <TransactionSwitch
                  label={"Summary"}
                  onChange={() => {
                    setFormat((f) => {
                      return {
                        ...f,
                        summary: !f.summary,
                      };
                    });
                  }}
                  isChecked={format.summary}
                />
              )}
            </>
          }
          label={"View"}
        />
      </div>

      <div style={{ paddingTop: "100px" }}></div>

      {allEntities.map((ent) => {
        return format.archive ? (
          <SingleEntityLedger
            allTransactions={Object.values(transctions[ent].transctions)
              .map((a) => a.transctions)
              .flat()}
            entityName={ent}
            dTransId={expenseContext.deletingTransactionId}
            onDeleteTransaction={(transId) => {
              expenseContext.onDeleteTransaction(transId);
            }}
            key={ent}
            isSummary={format.summary}
          />
        ) : (
          <SingleEntityTransctionViewer
            allDateData={transctions[ent].transctions}
            entityName={ent}
            dTransId={expenseContext.deletingTransactionId}
            onDeleteTransaction={(transId) => {
              expenseContext.onDeleteTransaction(transId);
            }}
            key={ent}
          />
        );
      })}
    </>
  );
};

export default ExpenseTransctionScreen;
