import {
  MenuItem,
  FormControl,
  ListItemText,
  Checkbox,
  Button,
  Typography,
  Grow,
  Paper,
  MenuList,
  Popper,
} from "@mui/material";
import { useEffect, useRef, useState } from "react";

const HotelChooser = ({
  hotels,
  selectedHotels,
  onHotelsChange,
  width,
  isSingle,
  label,
}) => {
  const buttonRef = useRef(undefined);
  const popperRef = useRef(undefined);
  const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {
    const handleOutsideClick = (event) => {
      if (
        popperRef.current &&
        !popperRef.current.contains(event.target) &&
        buttonRef.current &&
        !buttonRef.current.contains(event.target)
      ) {
        setIsVisible(false);
      }
    };

    document.addEventListener("mousedown", handleOutsideClick);

    return () => {
      document.removeEventListener("mousedown", handleOutsideClick);
    };
  }, []);

  return (
    <FormControl>
      <Button
        ref={buttonRef}
        sx={{
          width: width,
          border: "1px solid #262626",
          outline: isVisible ? "1px solid #262626" : "1px solid transparent",
          fontSize: "16px",
          height: "55px",
        }}
        onClick={() => {
          setIsVisible((p) => !p);
        }}
      >
        <Typography
          noWrap
          sx={{
            padding: "9px 12px",
            textOverflow: "ellipsis",
            textTransform: "none",
            wordBreak: "break-all",
            maxLines: 1,
          }}
          variant="h6"
          fontSize={"15px"}
          color="primary.main"
        >
          {label ? label : selectedHotels.join(", ")}
        </Typography>
      </Button>

      <Popper
        anchorEl={buttonRef.current}
        role={undefined}
        placement="bottom"
        open={isVisible && Boolean(buttonRef.current)}
        disablePortal={true}
        transition
        ref={popperRef}
      >
        {({ TransitionProps }) => (
          <Grow {...TransitionProps} style={{ transformOrigin: "center top" }}>
            <Paper
              elevation={10}
              sx={{
                marginTop: "3px",
                maxHeight: "450px",
                overflow: "scroll",
              }}
            >
              <MenuList>
                {!isSingle && (
                  <MenuItem
                    onClick={() => {
                      if (selectedHotels.length === hotels.length) {
                        onHotelsChange([]);
                      } else {
                        onHotelsChange([...hotels]);
                      }
                    }}
                    key="All"
                  >
                    <Checkbox
                      checked={selectedHotels.length === hotels.length}
                    />
                    <ListItemText primary="All" />
                  </MenuItem>
                )}

                {[...hotels].map((hotel) => {
                  return (
                    <MenuItem
                      onClick={() => {
                        if (isSingle) {
                          onHotelsChange([hotel]);
                        } else {
                          if (selectedHotels.includes(hotel)) {
                            let sHot = [...selectedHotels];
                            sHot.splice(sHot.indexOf(hotel), 1);
                            onHotelsChange(sHot);
                          } else {
                            onHotelsChange([...selectedHotels, hotel]);
                          }
                        }
                      }}
                      key={hotel}
                      value={hotel}
                    >
                      <Checkbox checked={selectedHotels.indexOf(hotel) > -1} />
                      <ListItemText primary={hotel} />
                    </MenuItem>
                  );
                })}
              </MenuList>
            </Paper>
          </Grow>
        )}
      </Popper>
    </FormControl>
  );
};

export default HotelChooser;
