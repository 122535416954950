import { Stack, Typography, Button } from "@mui/material";

const SingleHNameViewer = ({ name, amount,pAmount,isSum }) => {
  return (
    <Stack
      direction="row"
      sx={{
        flexGrow: 1,
        justifyContent: "space-between",
        display: "flex",
        width: "100%",
        margin: "auto",
      }}
    >
      <Button
        disableRipple
        sx={{
          justifyContent: "space-between",
          display: "flex",
          alignItems: "center",
          flexDirection: "row",
          textTransform: "none",
          width: "100%",
          maxWidth: "500px",
        }}
      >
        <Typography variant="h6" color="primary.main" sx={{ fontSize: "14px" }}>
          {name}
        </Typography>

        <Stack direction={"row"}>
          <Typography
            variant="h6"
            color="primary.main"
            sx={{ fontSize: "14px" }}
          >
           {isSum ? "+" : "-"} {amount}  = {Math.round(((isSum ? pAmount + amount : pAmount - amount)) * 100)/100}
          </Typography>
        </Stack>
      </Button>
    </Stack>
  );
};

export default SingleHNameViewer;
