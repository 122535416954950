function bookingDateFormatter(checkInTime, checkOutTime) {
  const checkIn = checkInTime.format("DD MMM YYYY");
  const checkOut = checkOutTime.format("DD MMM YYYY");

  if (checkIn.slice(3) === checkOut.slice(3)) {
    return `${checkIn.slice(0, 2)} - ${checkOut.slice(0, 6)}`;
  } else if (checkIn.slice(7) === checkOut.slice(7)) {
    return `${checkIn.slice(0, 6)} - ${checkOut.slice(0, 6)}`;
  } else {
    return `${checkIn} - ${checkOut}`;
  }
}

export default bookingDateFormatter;
