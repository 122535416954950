export const ActionTypes = {
  CREATED: "CREATED",
  ASSIGNED: "ASSIGNED",
  ACCEPTED: "ACCEPTED",
  DENIED: "DENIED",
  STARTED: "STARTED",
  FORWARD_REQUESTED: "FORWARD_REQUESTED",
  FORWARD_ACCEPTED: "FORWARD_ACCEPTED",
  FORWARD_DENIED: "FORWARD_DENIED",
  DUE_DATE_CHANGE_REQUESTED: "DUE_DATE_CHANGE_REQUESTED",
  DUE_DATE_CHANGE_APPROVED: "DUE_DATE_CHANGE_APPROVED",
  DUE_DATE_CHANGE_DENIED: "DUE_DATE_CHANGE_DENIED",
  COMPLETION_REQUESTED: "COMPLETION_REQUESTED",
  COMPLETION_APPROVED: "COMPLETION_APPROVED",
  COMPLETION_DENIED: "COMPLETION_DENIED",
  TEXT_MESSAGE: "TEXT_MESSAGE",
};

export const TaskStatus = {
  CREATED: {
    status: "CREATED",
    color: { r: 101, g: 111, b: 125 },
  },
  ASSIGNED: {
    status: "ASSIGNED",
    color: { r: 225, g: 107, b: 22 },
  },
  ACCEPTED: {
    status: "ACCEPTED",
    color: { r: 225, g: 107, b: 22 },
  },
  DENIED: {
    status: "DENIED",
    color: { r: 211, g: 61, b: 68 },
  },
  IN_PROGRESS: {
    status: "IN_PROGRESS",
    color: { r: 95, g: 85, b: 238 },
  },
  NEED_APPROVAL: {
    status: "NEED_APPROVAL",
    color: { r: 248, g: 174, b: 0 },
  },
  COMPLETED: {
    status: "COMPLETED",
    color: { r: 0, g: 136, b: 68 },
  },
};

export const actionTypeMessage = (action) => {
  switch (action.action) {
    case ActionTypes.CREATED:
      return action.byMe
        ? "You created this task."
        : `This task was created by ${action.by}.`;

    case ActionTypes.ASSIGNED:
      if (action.byMe) {
        return `You assigned this task to ${
          action.to && action.to.length > 1
            ? `${action.to.slice(0, action.to.length - 1).join(", ")} and`
            : ""
        } ${action.to ? action.to[action.to.length - 1] : ""}.`;
      } else {
        return `This task was assigned to ${
          action.to && action.to.length > 1
            ? `${action.to.slice(0, action.to.length - 1).join(", ")} and`
            : ""
        } ${action.to ? action.to[action.to.length - 1] : ""} by ${action.by}.`;
      }

    case ActionTypes.ACCEPTED:
      return action.byMe
        ? "You accepted this task."
        : `This task was accepted by ${action.by}.`;

    case ActionTypes.DENIED:
      return action.byMe
        ? "You denied this task."
        : `This task was denied by ${action.by}.`;

    case ActionTypes.FORWARD_REQUESTED:
      if (action.byMe) {
        return `You forwarded this task to ${
          action.to && action.to.length > 1
            ? `${action.to.slice(0, action.to.length - 1).join(", ")} and`
            : ""
        } ${action.to ? action.to[action.to.length - 1] : ""}.`;
      } else {
        return `This task was forwarded to ${
          action.to && action.to.length > 1
            ? `${action.to.slice(0, action.to.length - 1).join(", ")} and`
            : ""
        } ${action.to ? action.to[action.to.length - 1] : ""} by ${action.by}.`;
      }

    case ActionTypes.FORWARD_ACCEPTED:
      return action.byMe
        ? "You accepted this task."
        : `This task was accepted by ${action.by}.`;

    case ActionTypes.FORWARD_DENIED:
      return action.byMe
        ? "You denied this task."
        : `This task was denied by ${action.by}.`;

    case ActionTypes.DUE_DATE_CHANGE_REQUESTED:
      return action.byMe
        ? `You requested to change due date to ${action.requestedDate}.`
        : `${action.by} requested to change due date to ${action.requestedDate}.`;

    case ActionTypes.DUE_DATE_CHANGE_APPROVED:
      return action.byMe
        ? `You approved the due date change request of ${action.requestedDate}.`
        : `${action.by} approved the due date change request of ${action.requestedDate}.`;

    case ActionTypes.DUE_DATE_CHANGE_DENIED:
      return action.byMe
        ? `You denied the due date change request of ${action.requestedDate}.`
        : `${action.by} denied the due date change request of ${action.requestedDate}.`;

    case ActionTypes.COMPLETION_REQUESTED:
      return action.byMe
        ? "You requested to mark this task as complete."
        : `${action.by} requested to mark this task as complete.`;

    case ActionTypes.COMPLETION_APPROVED:
      return action.byMe
        ? "You marked this task complete."
        : `${action.by} marked this task complete.`;

    case ActionTypes.COMPLETION_DENIED:
      return action.byMe
        ? "You denied the completion request."
        : `${action.by} denied the completion request.`;

    case ActionTypes.TEXT_MESSAGE:
      return action.message || "";

    case ActionTypes.STARTED:
      return "";

    default:
      return "";
  }
};
