import * as FileSaver from "file-saver"
import XLSX from "sheetjs-style"


const excelGenerator = async (data, formatter, fileName, isDownload) => {


    if (!isDownload) {
        const fData = Object.values(data).flat().map(dat => formatter(dat))
        const keys = Object.keys(fData[0]);
        const rows = fData.map(item => Object.values(item));
        const allRows = [keys, ...rows];
        const formattedData = allRows.map(row => row.join('\t')).join('\n');
        await navigator.clipboard.writeText(formattedData);
    } else {
        const wb = XLSX.utils.book_new()

        Object.keys(data).forEach(dType => {
            const ws = XLSX.utils.json_to_sheet(data[dType].map(dat => formatter(dat)))
            XLSX.utils.book_append_sheet(wb, ws, dType)
        })
        const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
        const bData = new Blob([excelBuffer], { type: "application/octet-stream" });
        FileSaver.saveAs(bData, fileName + ".xlsx");
    }

}



export default excelGenerator