import Graph from "../util/graph";

let SampleR = { XBR: 0, XECR: 0, OBR: 914, OECR: 0, CFEE: 60 }

const RevenueReconcilationFinder = (
    revenueReconcilation,
    allDates,
    isHotelSummed,
    isDateSummed,
    sHotels,
    options,
    isBar
) => {

    if (!isHotelSummed && !isDateSummed) {
        if (isBar) {

            return sHotels.map((hotel, index) => {
                let cData = options.map((option, oIndex) => {
                    return {
                        label: `${hotel} Collection ${option}`,
                        data: allDates.map((date) => {
                            let data = revenueReconcilation[hotel][date];
                            return data.reduce((acc, cur) => {
                                let types = Object.keys(cur.data).filter((t) =>
                                    t === option
                                );
                                return (
                                    acc +
                                    types.reduce((accc, curr) => {
                                        return accc + cur.data[curr].reduce((acccc, currr) => {
                                            return acccc + currr.effectiveValue
                                        }, 0);
                                    }, 0)
                                );
                            }, 0);
                        }),
                        borderColor: Graph.Colors[index + ((oIndex > 3 ? (oIndex - 3) : oIndex) * 10)],
                        backgroundColor: Graph.Colors[index + ((oIndex > 3 ? (oIndex - 3) : oIndex) * 10)],
                        ...Graph.ExtraOptions,
                        stack: `Stack Coll- ${hotel}`
                    };
                }).flat()

                let rData = Object.keys(SampleR).map((option, oIndex) => {
                    return {
                        label: `${hotel} ${option}`,
                        data: allDates.map((date) => {
                            let data = revenueReconcilation[hotel][date];
                            return data.reduce((acc, cur) => {
                                return (
                                    acc + cur.REV[option]
                                );
                            }, 0);
                        }),
                        borderColor: Graph.Colors[index + ((oIndex > 3 ? (oIndex - 3) : oIndex) * 10)],
                        backgroundColor: Graph.Colors[index + ((oIndex > 3 ? (oIndex - 3) : oIndex) * 10)],
                        ...Graph.ExtraOptions,
                        stack: `Stack REV- ${hotel}`
                    };
                }).flat()

                let pData = {
                    label: `${hotel} Pending`,
                    data: allDates.map((date) => {
                        let data = revenueReconcilation[hotel][date];
                        return data.reduce((acc, cur) => {
                            return acc + cur.PEND
                        }, 0);
                    }),
                    borderColor: Graph.Colors[index + sHotels.length * 2],
                    backgroundColor: Graph.Colors[index + sHotels.length * 2],
                    ...Graph.ExtraOptions,
                    stack: `Stack PEND - ${hotel}`
                };

                return [cData, rData, pData].flat()
            }).flat()
        }

        return sHotels.map((hotel, index) => {
            let cData = {
                label: `${hotel} Collection`,
                data: allDates.map((date) => {
                    let data = revenueReconcilation[hotel][date];
                    return data.reduce((acc, cur) => {
                        let types = Object.keys(cur.data).filter((t) =>
                            options.includes(t)
                        );
                        return (
                            acc +
                            types.reduce((accc, curr) => {
                                return accc + cur.data[curr].reduce((acccc, currr) => {
                                    return acccc + currr.effectiveValue
                                }, 0);
                            }, 0)
                        );
                    }, 0);
                }),
                borderColor: Graph.Colors[index],
                backgroundColor: Graph.Colors[index],
                ...Graph.ExtraOptions,
            };

            let rData = {
                label: `${hotel} Revenue`,
                data: allDates.map((date) => {
                    let data = revenueReconcilation[hotel][date];
                    return data.reduce((acc, cur) => {
                        let types = Object.keys(cur.REV)
                        return (
                            acc +
                            types.reduce((accc, curr) => {
                                return accc + cur.REV[curr];
                            }, 0)
                        );
                    }, 0);
                }),
                borderColor: Graph.Colors[index + sHotels.length],
                backgroundColor: Graph.Colors[index + sHotels.length],
                ...Graph.ExtraOptions,
            };

            let pData = {
                label: `${hotel} Pending`,
                data: allDates.map((date) => {
                    let data = revenueReconcilation[hotel][date];
                    return data.reduce((acc, cur) => {
                        return acc + cur.PEND
                    }, 0);
                }),
                borderColor: Graph.Colors[index + sHotels.length * 2],
                backgroundColor: Graph.Colors[index + sHotels.length * 2],
                ...Graph.ExtraOptions,
            };

            return [cData, rData, pData]
        }).flat()


    } else if (isHotelSummed && !isDateSummed) {

        if (isBar) {
            let cDate = options.map((option, oIndex) => {
                return {
                    label: `All Hotels ${option}`,
                    data: allDates.map((date) => {
                        return Object.keys(revenueReconcilation).filter(hot => sHotels.includes(hot)).map(hot => revenueReconcilation[hot]).map(dateData => {
                            return dateData[date]
                        }).flat().reduce((acc, cur) => {
                            let types = Object.keys(cur.data).filter(opt => opt === option)
                            return acc + types.reduce((accc, curr) => {
                                return accc + cur.data[curr].reduce((acccc, currr) => {
                                    return acccc + currr.effectiveValue
                                }, 0);
                            }, 0)
                        }, 0)
                    }),
                    borderColor: Graph.Colors[oIndex],
                    backgroundColor: Graph.Colors[oIndex],
                    ...Graph.ExtraOptions,
                    stack: `All Hotels Cols`
                }
            }).flat()

            let rDate = Object.keys(SampleR).map((option, oIndex) => {
                return {
                    label: `All Hotels ${option}`,
                    data: allDates.map((date) => {
                        return Object.keys(revenueReconcilation).filter(hot => sHotels.includes(hot)).map(hot => revenueReconcilation[hot]).map(dateData => {
                            return dateData[date]
                        }).flat().reduce((acc, cur) => {
                            return acc + cur.REV[option]
                        }, 0)
                    }),
                    borderColor: Graph.Colors[oIndex + options.length],
                    backgroundColor: Graph.Colors[oIndex + options.length],
                    ...Graph.ExtraOptions,
                    stack: `All Hotels Revs`
                }
            }).flat()

            let pDate = {
                label: `All Hotels Pending`,
                data: allDates.map((date) => {
                    return Object.keys(revenueReconcilation).filter(hot => sHotels.includes(hot)).map(hot => revenueReconcilation[hot]).map(dateData => {
                        return dateData[date]
                    }).flat().reduce((acc, cur) => {
                        return acc + cur.PEND
                    }, 0)
                }),
                borderColor: Graph.Colors[options.length + Object.keys(SampleR).length],
                backgroundColor: Graph.Colors[options.length + Object.keys(SampleR).length],
                ...Graph.ExtraOptions,
                stack: `All Hotels Pendings`
            }

            return [cDate, rDate, pDate].flat()
        }
        return [
            {
                label: "All Hotels Cols",
                data: allDates.map((date) => {
                    return Object.keys(revenueReconcilation).filter(hot => sHotels.includes(hot)).map(hot => revenueReconcilation[hot]).map(dateData => {
                        return dateData[date]
                    }).flat().reduce((acc, cur) => {
                        let types = Object.keys(cur.data).filter(opt => options.includes(opt))
                        return acc + types.reduce((accc, curr) => {
                            return accc + cur.data[curr].reduce((acccc, currr) => {
                                return acccc + currr.effectiveValue
                            }, 0);
                        }, 0)
                    }, 0)
                }),
                borderColor: Graph.Colors[0],
                backgroundColor: Graph.Colors[0],
                ...Graph.ExtraOptions,
            },
            {
                label: "All Hotels Rev",
                data: allDates.map((date) => {
                    return Object.keys(revenueReconcilation).filter(hot => sHotels.includes(hot)).map(hot => revenueReconcilation[hot]).map(dateData => {
                        return dateData[date]
                    }).flat().reduce((acc, cur) => {
                        let types = Object.keys(cur.REV)
                        return acc + types.reduce((accc, curr) => {
                            return accc + cur.REV[curr]
                        }, 0)
                    }, 0)
                }),
                borderColor: Graph.Colors[1],
                backgroundColor: Graph.Colors[1],
                ...Graph.ExtraOptions,
            },
            {
                label: "All Hotels Pending",
                data: allDates.map((date) => {
                    return Object.keys(revenueReconcilation).filter(hot => sHotels.includes(hot)).map(hot => revenueReconcilation[hot]).map(dateData => {
                        return dateData[date]
                    }).flat().reduce((acc, cur) => {
                        return acc + cur.PEND
                    }, 0)
                }),
                borderColor: Graph.Colors[2],
                backgroundColor: Graph.Colors[2],
                ...Graph.ExtraOptions,
            }
        ]
    } else if (!isHotelSummed && isDateSummed) {

        let hotels = Object.keys(revenueReconcilation).filter(hot => sHotels.includes(hot))

        if (isBar) {
            let cDate = options.map((option, oIndex) => {
                return {
                    label: `All Dates ${option}`,
                    data: hotels.map(hotel => {
                        let allData = Object.keys(revenueReconcilation[hotel]).map(date => {
                            return revenueReconcilation[hotel][date].reduce((acc, cur) => {
                                let types = Object.keys(cur.data).filter(opt => opt === option)
                                return acc + types.reduce((accc, curr) => {
                                    return accc + cur.data[curr].reduce((acccc, currr) => {
                                        return acccc + currr.effectiveValue
                                    }, 0);
                                }, 0)
                            }, 0)
                        }).reduce((acc, cur) => {
                            return acc + cur
                        }, 0)
                        return allData
                    }),
                    borderColor: Graph.Colors[oIndex],
                    backgroundColor: Graph.Colors[oIndex],
                    ...Graph.ExtraOptions,
                    stack: `All Dates Cols`
                }
            })

            let rDate = Object.keys(SampleR).map((option, oIndex) => {
                return {
                    label: `All Dates ${option}`,
                    data: hotels.map(hotel => {
                        let allData = Object.keys(revenueReconcilation[hotel]).map(date => {
                            return revenueReconcilation[hotel][date].reduce((acc, cur) => {
                                return acc + cur.REV[option]
                            }, 0)
                        }).reduce((acc, cur) => {
                            return acc + cur
                        }, 0)
                        return allData
                    }),
                    borderColor: Graph.Colors[oIndex],
                    backgroundColor: Graph.Colors[oIndex],
                    ...Graph.ExtraOptions,
                    stack: `All Dates Revs`
                }
            })


            let pDate = {
                label: `All Dates Pendings`,
                data: hotels.map(hotel => {
                    let allData = Object.keys(revenueReconcilation[hotel]).map(date => {
                        return revenueReconcilation[hotel][date].reduce((acc, cur) => {
                            return acc + cur.PEND
                        }, 0)
                    }).reduce((acc, cur) => {
                        return acc + cur
                    }, 0)
                    return allData
                }),
                borderColor: Graph.Colors[Object.keys(SampleR).length],
                backgroundColor: Graph.Colors[Object.keys(SampleR).length],
                ...Graph.ExtraOptions,
                stack: `All Dates Pendings`
            }
            return [cDate, rDate, pDate].flat()
        }
        return [
            {
                label: "All Dates Cols",
                data: hotels.map(hotel => {
                    let allData = Object.keys(revenueReconcilation[hotel]).map(date => {
                        let dateData = revenueReconcilation[hotel][date]
                        return dateData.reduce((acc, cur) => {
                            let types = Object.keys(cur.data).filter(opt => options.includes(opt))
                            return acc + types.reduce((accc, curr) => {
                                return accc + cur.data[curr].reduce((acccc, currr) => {
                                    return acccc + currr.effectiveValue
                                }, 0);
                            }, 0)
                        }, 0)
                    }).reduce((acc, cur) => {
                        return acc + cur
                    }, 0)
                    return allData
                }),
                borderColor: Graph.Colors[0],
                backgroundColor: Graph.Colors[0],
                ...Graph.ExtraOptions,
                stack: `All Cols`
            },
            {
                label: "All Dates Revs",
                data: hotels.map(hotel => {
                    let allData = Object.keys(revenueReconcilation[hotel]).map(date => {
                        let dateData = revenueReconcilation[hotel][date]
                        return dateData.reduce((acc, cur) => {
                            let types = Object.keys(cur.REV)
                            return acc + types.reduce((accc, curr) => {
                                return accc + cur.REV[curr]
                            }, 0)
                        }, 0)
                    }).reduce((acc, cur) => {
                        return acc + cur
                    }, 0)
                    return allData
                }),
                borderColor: Graph.Colors[1],
                backgroundColor: Graph.Colors[1],
                ...Graph.ExtraOptions,
                stack: `All Revs`
            },
            {
                label: "All Dates Pendings",
                data: hotels.map(hotel => {
                    let allData = Object.keys(revenueReconcilation[hotel]).map(date => {
                        let dateData = revenueReconcilation[hotel][date]
                        return dateData.reduce((acc, cur) => {
                            return acc + cur.PEND
                        }, 0)
                    }).reduce((acc, cur) => {
                        return acc + cur
                    }, 0)
                    return allData
                }),
                borderColor: Graph.Colors[2],
                backgroundColor: Graph.Colors[2],
                ...Graph.ExtraOptions,
                stack: `All Pendings`
            }
        ]
    } else if (isHotelSummed && isDateSummed) {
        let hotels = Object.keys(revenueReconcilation).filter(hot => sHotels.includes(hot))
        if (isBar) {
            let cDate = options.map((option, oIndex) => {
                return {
                    label: `All Dates ${option}`,
                    data: [
                        hotels.map(hotel => {
                            let allData = Object.keys(revenueReconcilation[hotel]).map(date => {
                                return revenueReconcilation[hotel][date].reduce((acc, cur) => {
                                    let types = Object.keys(cur.data).filter(opt => opt === option)
                                    return acc + types.reduce((accc, curr) => {
                                        return accc + cur.data[curr].reduce((acccc, currr) => {
                                            return acccc + currr.effectiveValue
                                        }, 0);
                                    }, 0)
                                }, 0)
                            }).reduce((acc, cur) => {
                                return acc + cur
                            }, 0)
                            return allData
                        }).reduce((acc, cur) => {
                            return acc + cur
                        }, 0)
                    ],
                    borderColor: Graph.Colors[oIndex],
                    backgroundColor: Graph.Colors[oIndex],
                    ...Graph.ExtraOptions,
                    stack: "All Dates and Hotels Cols"
                }
            })

            let rDate = Object.keys(SampleR).map((option, oIndex) => {
                return {
                    label: `All Dates ${option}`,
                    data: [
                        hotels.map(hotel => {
                            let allData = Object.keys(revenueReconcilation[hotel]).map(date => {
                                return revenueReconcilation[hotel][date].reduce((acc, cur) => {
                                    return acc + cur.REV[option]
                                }, 0)
                            }).reduce((acc, cur) => {
                                return acc + cur
                            }, 0)
                            return allData
                        }).reduce((acc, cur) => {
                            return acc + cur
                        }, 0)
                    ],
                    borderColor: Graph.Colors[oIndex + options.length],
                    backgroundColor: Graph.Colors[oIndex + options.length],
                    ...Graph.ExtraOptions,
                    stack: "All Dates and Hotels Revs"
                }
            })


            let pDate = {
                label: `All Dates Pendings`,
                data: [
                    hotels.map(hotel => {
                        let allData = Object.keys(revenueReconcilation[hotel]).map(date => {
                            return revenueReconcilation[hotel][date].reduce((acc, cur) => {
                                return acc + cur.PEND
                            }, 0)
                        }).reduce((acc, cur) => {
                            return acc + cur
                        }, 0)
                        return allData
                    }).reduce((acc, cur) => {
                        return acc + cur
                    }, 0)
                ],
                borderColor: Graph.Colors[options.length + Object.keys(SampleR).length],
                backgroundColor: Graph.Colors[options.length + Object.keys(SampleR).length],
                ...Graph.ExtraOptions,
                stack: "All Dates and Hotels Pendings"
            }

            return [cDate, rDate, pDate].flat()
        }
        return [
            {
                label: "All Dates Cols",
                data: [
                    hotels.map(hotel => {
                        let allData = Object.keys(revenueReconcilation[hotel]).map(date => {
                            return revenueReconcilation[hotel][date].reduce((acc, cur) => {
                                let types = Object.keys(cur.data).filter(opt => options.includes(opt))
                                return acc + types.reduce((accc, curr) => {
                                    return accc + cur.data[curr].reduce((acccc, currr) => {
                                        return acccc + currr.effectiveValue
                                    }, 0);
                                }, 0)
                            }, 0)
                        }).reduce((acc, cur) => {
                            return acc + cur
                        }, 0)
                        return allData
                    }).reduce((acc, cur) => {
                        return acc + cur
                    }, 0)
                ],
                borderColor: Graph.Colors[0],
                backgroundColor: Graph.Colors[0],
                ...Graph.ExtraOptions,
            },
            {
                label: "All Dates Revs",
                data: [
                    hotels.map(hotel => {
                        let allData = Object.keys(revenueReconcilation[hotel]).map(date => {
                            return revenueReconcilation[hotel][date].reduce((acc, cur) => {
                                let types = Object.keys(cur.REV)
                                return acc + types.reduce((accc, curr) => {
                                    return accc + cur.REV[curr]
                                }, 0)
                            }, 0)
                        }).reduce((acc, cur) => {
                            return acc + cur
                        }, 0)
                        return allData
                    }).reduce((acc, cur) => {
                        return acc + cur
                    }, 0)
                ],
                borderColor: Graph.Colors[1],
                backgroundColor: Graph.Colors[1],
                ...Graph.ExtraOptions,
            },
            {
                label: "All Dates Pendings",
                data: [
                    hotels.map(hotel => {
                        let allData = Object.keys(revenueReconcilation[hotel]).map(date => {
                            return revenueReconcilation[hotel][date].reduce((acc, cur) => {
                                return acc + cur.PEND
                            }, 0)
                        }).reduce((acc, cur) => {
                            return acc + cur
                        }, 0)
                        return allData
                    }).reduce((acc, cur) => {
                        return acc + cur
                    }, 0)
                ],
                borderColor: Graph.Colors[2],
                backgroundColor: Graph.Colors[2],
                ...Graph.ExtraOptions,
            }
        ]
    }
}

export default RevenueReconcilationFinder