import { CardMedia, Stack, Typography } from "@mui/material";
import Constant from "../../constant/Constant";

const ViewHotelOption = ({ hotel }) => {
  return (
    <Stack
      direction="row"
      spacing={2}
      sx={{
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
      }}
    >
      <CardMedia
        component="img"
        height="50px"
        width="50px"
        alt="Hotel Image"
        image={`${Constant.DOMAIN_NAME}${
          hotel.imageData[Object.keys(hotel.imageData)[0]][0]
        }`}
        sx={{
          borderRadius: "10px",
          width: "50px",
          height: "50px",
          objectFit: "cover",
          overflow: "hidden",
        }}
      />
      <Stack direction="column">
        <Typography variant="h6" color="primary.main">
          {hotel.hotelName}
        </Typography>
        <Typography
          variant="body2"
          color="primary.main"
          sx={{
            maxWidth: "170px",
            overflowWrap:"break-word",
            whiteSpace:"pre-wrap"
          }}
        >
          {hotel.hotelAddress}
        </Typography>
      </Stack>
    </Stack>
  );
};

export default ViewHotelOption;
