import { Delete } from "@mui/icons-material";
import { Stack, Typography, IconButton } from "@mui/material";

const PaymentViewer = ({ payment, onDelete }) => {
  return (
    <Stack
      sx={{
        margin: "10px 10px",
        alignItems: "center",
      }}
      direction={"row"}
    >
      <Typography variant="h6" fontSize={"15px"}>
        {payment.sourceName} - ₹{payment.amount} - {payment.pSourceName} - {payment.note} - {payment.date}
      </Typography>

      <IconButton
        onClick={() => {
          onDelete();
        }}
      >
        <Delete />
      </IconButton>
    </Stack>
  );
};

export default PaymentViewer;
