import { Menu as MenuIcon } from "@mui/icons-material";
import {
  IconButton,
  InputBase,
  Stack,
  Typography,
  styled,
  Menu,
  MenuItem,
  Modal,
  Box,
  Button,
} from "@mui/material";
import { useState } from "react";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import "dayjs/locale/en-gb";
import dayjs from "dayjs";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { taskCompletion, updateDueDate } from "../../../utils/task/TaskActions";
import CustomSnackBar from "../../common/CustomSnackBar";

const Search = styled("div")(({ theme }) => ({
  backgroundColor: "#F4F6FA",
  borderRadius: theme.shape.borderRadius,
  width: "400px",
  fontSize: "50%",
}));

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  height: 300,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};

const ChatTopBar = ({ taskId, title, token, dueDate }) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);

  const [newDueDate, setNewDueDate] = useState(
    dayjs(dueDate).format("DD-MM-YYYY")
  );
  const [openModal, setOpenModal] = useState(false);
  const [requestType, setRequestType] = useState("");
  const [error, setError] = useState("");

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const queryClient = useQueryClient();

  const {
    mutate: mutateDueDate,
    isPending: isUpdationPending,
    isError: isUpdationError,
  } = useMutation({
    mutationFn: ({ taskId, dueDate, token }) => {
      return updateDueDate(taskId, dueDate, token);
    },
    retry: 1,
    mutationKey: ["updateDueDate"],
    onSuccess: () => {
      setOpenModal(false);
      queryClient.invalidateQueries(["actions", taskId]);
    },
    onError: (error) => {
      setError(error?.message);
    },
  });

  const {
    mutate: mutateCompletion,
    isPending: isCompletionPending,
    isError: isCompletionError,
  } = useMutation({
    mutationFn: ({ taskId, token }) => {
      return taskCompletion(taskId, token);
    },
    retry: 1,
    mutationKey: ["taskCompletion"],
    onSuccess: () => {
      setOpenModal(false);
      queryClient.invalidateQueries(["actions", taskId]);
    },
    onError: (error) => {
      setError(error?.message);
    },
  });

  return (
    <Stack
      flexDirection={"row"}
      justifyContent={"space-between"}
      alignItems={"center"}
      marginBottom={"10px"}
      paddingBottom={"6px"}
      borderBottom={"1px solid #ccc"}
    >
      <Typography
        variant="h6"
        fontSize={"18px"}
        sx={{
          width: "250px",
          marginLeft: "5px",
        }}
        noWrap
      >
        {title}
      </Typography>
      <Search
        sx={{
          borderRadius: "5px",
          boxShadow: "rgba(0, 0, 0, 0.3) 0px 1px 2px 0px",
        }}
      >
        <InputBase
          placeholder="Search..."
          sx={{
            paddingX: "12px",
            paddingY: "6px",
            width: "100%",
          }}
        />
      </Search>
      <IconButton onClick={handleClick}>
        <MenuIcon />
      </IconButton>
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          "aria-labelledby": "basic-button",
        }}
      >
        <MenuItem
          onClick={() => {
            setRequestType("update");
            setOpenModal(true);
            handleClose();
          }}
        >
          Update Request
        </MenuItem>
        <MenuItem
          onClick={() => {
            setRequestType("complete");
            setOpenModal(true);
            handleClose();
          }}
        >
          Completion Request
        </MenuItem>
        <MenuItem onClick={handleClose}>Settings</MenuItem>
      </Menu>
      {requestType === "update" ? (
        <Modal open={openModal} onClose={() => setOpenModal(false)}>
          <Box sx={style}>
            <Typography variant="h1" component="h3" textAlign={"center"}>
              REQUEST DUE DATE UPDATION
            </Typography>
            <Stack marginY={"20px"} gap={"10px"} overflow={"auto"}>
              <LocalizationProvider
                dateAdapter={AdapterDayjs}
                adapterLocale="en-gb"
              >
                <DemoContainer components={["DatePicker"]}>
                  <DatePicker
                    label="Due Date"
                    fullWidth
                    sx={{ width: "100%", marginTop: 2 }}
                    value={dayjs(dueDate, "DD-MM-YYYY")}
                    onChange={(date) => {
                      setNewDueDate(dayjs(date).format("DD-MM-YYYY"));
                    }}
                  />
                </DemoContainer>
              </LocalizationProvider>
            </Stack>

            <Button
              variant="contained"
              sx={{ height: "60px", width: "100%" }}
              onClick={() =>
                mutateDueDate({ taskId, dueDate: newDueDate, token })
              }
            >
              CONFIRM
            </Button>
          </Box>
        </Modal>
      ) : (
        <Modal open={openModal} onClose={() => setOpenModal(false)}>
          <Box sx={style}>
            <Typography variant="h1" component="h3" textAlign={"center"}>
              REQUEST TASK COMPLETION
            </Typography>

            <Button
              variant="contained"
              sx={{ height: "60px", width: "100%", marginTop: "50px" }}
              onClick={() => mutateCompletion({ taskId, token })}
            >
              CONFIRM
            </Button>
          </Box>
        </Modal>
      )}
      <CustomSnackBar
        isOpen={Boolean(error)}
        message={error}
        onClose={() => setError(null)}
      />
    </Stack>
  );
};
export default ChatTopBar;
