import { LocalizationProvider } from "@mui/x-date-pickers-pro";
import { AdapterDayjs } from "@mui/x-date-pickers-pro/AdapterDayjs";
import { DateRangePicker } from "@mui/x-date-pickers-pro/DateRangePicker";
import moment from "moment";
import dayjs from "dayjs";

const CustomDateRangePicker = ({ startDay, days, onDateChange }) => {
  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <DateRangePicker
        value={[
          dayjs(moment(startDay, "DD-MM-YYYY").format("YYYY-MM-DD")),
          dayjs(
            moment(startDay, "DD-MM-YYYY").add(days, "day").format("YYYY-MM-DD")
          ),
        ]}
        onChange={(value) => {
          if (value.length === 2) {
            try {
              let startDate = moment(value[0].$d);
              let endDate = moment(value[1].$d);
              const days = (endDate.unix() - startDate.unix()) / (24 * 60 * 60);
              onDateChange(startDate.format("DD-MM-YYYY"), days);
            } catch (err) {
            }
          } else {
            console.log(value);
          }
        }}
        localeText={{ start: "StartDate", end: "EndDate" }}
        format="DD-MM-YYYY"
      />
    </LocalizationProvider>
  );
};

export default CustomDateRangePicker;
