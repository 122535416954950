import { createContext, useEffect, useState } from "react";
import axios from "axios";
import Constant from "../constant/Constant";

export const AuthContext = createContext({
  isAuthenticated: false,
  isAuthenticating: false,

  authToken: "",
  taskToken: "",

  authenticate: async () => {},

  isError: false,
  errorMessage: "",

  clearMessage: () => {},
});

const AuthContextProvider = ({ children }) => {

  const [authState, setAuthState] = useState({
    isAuthenticated: false,
    isAuthenticating: false,
    authToken: "",
    taskToken: "",
    isError: false,
    socket: undefined,
    isSocketConnected: false,
    errorMessage: "",
  });

  const authenticate = async (username, password) => {
    if (!username || username.length < 6) {
      setAuthState((p) => {
        return {
          ...p,
          isError: true,
          errorMessage: "Please enter correct username",
        };
      });
      return;
    }
    if (!password || password.length < 7) {
      setAuthState((p) => {
        return {
          ...p,
          isError: true,
          errorMessage: "Please enter correct password",
        };
      });
      return;
    }

    try {
      const response = await axios.post(Constant.authRoute, {
        username,
        password,
      });
      if (!response.data.status) {
        setAuthState((prevState) => {
          return {
            ...prevState,
            isError: true,
            errorMessage: response.data?.message
              ? response.data?.message
              : "Please try after some time",
            isAuthenticating: false,
          };
        });
        return;
      }
      setAuthState((prevState) => {
        return {
          ...prevState,
          isError: true,
          errorMessage: "Logged In Successfully",
          isAuthenticated: true,
          isAuthenticating: false,
          authToken: response.data.data,
          taskToken: response.data.taskToken,
        };
      });
      window.localStorage.setItem(
        "authToken",
        JSON.stringify({
          token: response.data.data,
          taskToken: response.data.taskToken,
        })
      );
    } catch (err) {
      console.log(err);
      setAuthState((prevState) => {
        return {
          ...prevState,
          isError: true,
          errorMessage: err.response.data?.message
            ? err.response.data?.message
            : "Please try after some time",
          isAuthenticating: false,
        };
      });
    }
  };

  const clearMessage = () => {
    setAuthState((prevState) => {
      return {
        ...prevState,
        isError: false,
        errorMessage: "",
      };
    });
  };

  useEffect(() => {
    try {
      const token = JSON.parse(window.localStorage.getItem("authToken"));
      if (token.token) {
        setAuthState((prevState) => {
          return {
            ...prevState,
            isError: true,
            errorMessage: "Logged In Successfully",
            isAuthenticated: true,
            isAuthenticating: false,
            taskToken: token.taskToken,
            authToken: token.token,
          };
        });
      }
    } catch (err) {
      console.log(err);
    }
  }, [setAuthState]);



  const value = {
    ...authState,
    authenticate: authenticate,
    clearMessage: clearMessage,
  };

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
};

export default AuthContextProvider;
