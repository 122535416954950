import Constant from "../../constant/Constant";
import TaskRequester from "./TaskRequester";

export const getAllCompanies = async (token) => {
  try {
    const { data } = await TaskRequester(
      Constant.getComapnyRoute,
      token,
      {},
      "POST"
    );
    return data;
  } catch (error) {
    console.log(error);
    throw new Error(error);
  }
};

export const getAllTasks = async (token, companyId, department) => {
  try {
    const { data } = await TaskRequester(
      Constant.getTaskRoute,
      token,
      {
        companyId: companyId === "ALL" ? null : companyId,
        department: department === "ALL" ? null : department,
      },
      "GET"
    );
    return data;
  } catch (error) {
    console.log(error);
    throw new Error(error);
  }
};

export const createTask = async (
  title,
  description,
  dueDate,
  companyId,
  priority,
  department,
  token
) => {
  console.log(title, description, dueDate, companyId, priority, department);
  try {
    const { data } = await TaskRequester(
      Constant.getTaskRoute,
      token,
      {
        title,
        description,
        dueDate,
        companyId,
        priority,
        department,
      },
      "POST"
    );
    return data;
  } catch (error) {
    console.log(error);
    throw new Error(error);
  }
};

export const getAllActions = async (taskId, token) => {
  try {
    const { data } = await TaskRequester(
      Constant.ActionsTaskRoute,
      token,
      {
        taskId,
      },
      "POST"
    );
    return data;
  } catch (error) {
    console.log(error);
    throw new Error(error);
  }
};

export const getUsers = async (taskId, token) => {
  try {
    const { data } = await TaskRequester(
      Constant.UsersTaskRoute,
      token,
      {
        taskId,
      },
      "POST"
    );
    return data;
  } catch (error) {
    console.log(error);
    throw new Error(error);
  }
};

export const assignTask = async (taskId, to, action, token) => {
  console.log(taskId, to, action, token);
  try {
    const { data } = await TaskRequester(
      action === "ASSIGN"
        ? Constant.AssignTaskRoute
        : Constant.ForwardTaskRoute,
      token,
      {
        taskId,
        to,
      },
      "POST"
    );
    return data;
  } catch (error) {
    console.log(error);
    throw new Error(error);
  }
};

export const taskAction = async (actionId, action, token) => {
  console.log(actionId, action, token);
  try {
    const { data } = await TaskRequester(
      Constant.CommonActionRoute,
      token,
      {
        actionId,
        action,
      },
      "POST"
    );
    return data;
  } catch (error) {
    console.log(error);
    throw new Error(error);
  }
};

export const updateDueDate = async (taskId, dueDate, token) => {
  console.log(taskId, dueDate, token);
  try {
    const { data } = await TaskRequester(
      Constant.DueDateChangeRoute,
      token,
      {
        taskId,
        dueDate,
      },
      "POST"
    );
    return data;
  } catch (error) {
    console.log(error);
    throw new Error(error);
  }
};

export const taskCompletion = async (taskId, token) => {
  console.log(taskId, token);
  try {
    const { data } = await TaskRequester(
      Constant.CompletionRequestRoute,
      token,
      {
        taskId,
      },
      "POST"
    );
    return data;
  } catch (error) {
    console.log(error);
    throw new Error(error);
  }
};

export const sendingMessage = async (taskId, message, token) => {
  console.log(taskId, message, token);
  try {
    const { data } = await TaskRequester(
      Constant.TextMessageRoute,
      token,
      {
        taskId,
        message,
      },
      "POST"
    );
    return data;
  } catch (error) {
    console.log(error);
    throw new Error(error);
  }
};
