import { Stack, Typography } from "@mui/material";

const EntityViewer = ({ entity }) => {
  return (
    <Stack
      sx={{
        borderRadius: "5px",
        border: "1px solid #262626",
        margin: "10px 0px",
        padding: "7px 10px",
        transition: "border 1s",
        ":hover": {
          boxShadow: "0px 10px 15px -3px rgba(0,0,0,0.1)",
        },
        width: "min-content",
        cursor: "pointer",
      }}
      direction="row"
      spacing={2}
    >
      <Stack sx={{ width: "150px" }}>
        <Typography variant="h6" color="primary.main" sx={{ fontSize: "13px" }}>
          {entity.hotelName}
        </Typography>
      </Stack>

      {entity.sources.map((sr, index) => {
        return (
          <Stack key={index} sx={{ width: "80px" }}>
            <Typography
              variant="h6"
              color="primary.main"
              sx={{ fontSize: "13px" }}
            >
              {sr.name}
            </Typography>
            <Typography
              variant="h6"
              color="primary.main"
              sx={{ fontSize: "13px" }}
            >
              ₹{sr.value}
            </Typography>
          </Stack>
        );
      })}

    </Stack>
  );
};

export default EntityViewer;
