import {
  Button,
  Card,
  CircularProgress,
  Modal,
  Typography,
} from "@mui/material";
import AddExpenserHotelSelect from "../common/AddExpenserHotelSelect";
import AddExpenserSelector from "../common/AddExpenserSelector";
import { useEffect, useState } from "react";
import AddExpenseSelector from "../common/AddExpenseSelector";
import AddExpenserInput from "../common/AddExpenserInput";
import CustomSnackBar from "../../../common/CustomSnackBar";
import OxyOsTheme from "../../../../theme/theme";
import AddExpenseCategoryInput from "../common/AddExpenseCategoryInput";
import findCategory from "../../../../utils/expense/findCategory";
import ExpenseDateRangePicker from "../common/ExpenseDateRangePicker";
import moment from "moment";
import ExpenseDateAmountPicker from "../common/ExpenseDateAmountPicker";
import AddExpenseDatePicker from "../common/AddExpenseDatePicker";
import AddExpenseBooleanInput from "../common/AddExpenseBooleanInput";

const AddExpenseModel = ({
  isOpen,
  onClose,
  isLoading,
  onSubmit,
  category,
  expensers,
  hotels,
  hotelId,
}) => {
  const [expenseData, setExpenseData] = useState({
    hotelId: hotelId,
    expenserId: "",
    category: [""],
    aDates: [moment(new Date()).format("DD-MM-YYYY")],
    dates: {},
    note: "",
    cDate: "",
    isAdvance: false,
    isEntity: false,
    isExpenserToBusinessLoan: false,
    isParentCompanyPayment: false,
    isPayable: false,
  });

  const [error, setError] = useState({
    isError: false,
    errorMessage: "",
  });

  useEffect(() => {
    setExpenseData((p) => {
      let a = {};
      for (let i = 0; i < p.aDates.length; i++) {
        a[p.aDates[i]] = "0";
      }
      return {
        ...p,
        dates: a,
      };
    });
  }, [expenseData.aDates]);

  return (
    <Modal
      open={isOpen}
      onClose={onClose}
      sx={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <Card
        sx={{
          flexDirection: "row",
          minHeight: "500px",
          maxHeight: "600px",
          maxWidth: "700px",
          minWidth: "500px",
          background: "#FFF",
          borderRadius: "10px",
          padding: "20px 30px",
          overflowY: "scroll",
          "::-webkit-scrollbar": {
            display: "none",
          },
          border: "none",
        }}
      >
        <Typography
          variant="h1"
          sx={{
            width: "100%",
            textAlign: "center",
            marginBottom: "40px",
            marginTop: "10px",
          }}
          style={{
            textShadow: "0px 1px 1px rgba(0,0,0,0.24)",
          }}
          fontSize={"25px"}
        >
          Add Expense
        </Typography>

        {!expenseData.isExpenserToBusinessLoan && (
          <AddExpenserHotelSelect
            value={expenseData.hotelId}
            onValueChange={(val) => {
              setExpenseData((p) => {
                return { ...p, hotelId: hotelId };
              });
            }}
            required={true}
            hotels={hotels}
          />
        )}

        {!expenseData.isExpenserToBusinessLoan &&
          expenseData.hotelId &&
          !expenseData.isEntity && (
            <AddExpenserSelector
              value={expenseData.expenserId}
              onValueChange={(val) => {
                setExpenseData((p) => {
                  return { ...p, expenserId: val };
                });
              }}
              hotels={hotels}
              required={true}
              expensers={expensers.filter((exp) => {
                return exp.entities.includes(expenseData.hotelId);
              })}
            />
          )}

        {!expenseData.isExpenserToBusinessLoan &&
          expenseData.hotelId &&
          expenseData.isEntity && (
            <AddExpenserHotelSelect
              value={expenseData.expenserId}
              onValueChange={(val) => {
                setExpenseData((p) => {
                  return { ...p, expenserId: val };
                });
              }}
              required={true}
              hotels={hotels}
            />
          )}

        {expenseData.isExpenserToBusinessLoan && (
          <AddExpenserHotelSelect
            value={expenseData.hotelId}
            onValueChange={(val) => {
              setExpenseData((p) => {
                return { ...p, hotelId: val };
              });
            }}
            required={true}
            hotels={hotels}
            title={"Choose Loan Taker"}
          />
        )}

        {expenseData.isExpenserToBusinessLoan && expenseData.hotelId && (
          <AddExpenserSelector
            value={expenseData.expenserId}
            onValueChange={(val) => {
              setExpenseData((p) => {
                return { ...p, expenserId: val };
              });
            }}
            hotels={hotels}
            required={true}
            expensers={expensers.filter((exp) => {
              return exp.entities.includes(expenseData.hotelId);
            })}
            title={"Choose Loan Provider"}
          />
        )}

        {expenseData.hotelId && expenseData.expenserId && (
          <>
            {
              <AddExpenseCategoryInput
                category={
                  expenseData.category.length > 0
                    ? expenseData.category[0]
                    : undefined
                }
                onChange={(ncat) => {
                  setExpenseData((p) => {
                    return {
                      ...p,
                      category: [ncat],
                    };
                  });
                }}
                categories={category.map((cat) => cat.type)}
              />
            }

            {expenseData.category.map((cat, index) => {
              let categorie = findCategory(
                expenseData.category,
                index,
                category
              );

              return categorie &&
                categorie.categories &&
                categorie.categories.length > 0 ? (
                <AddExpenseSelector
                  key={index}
                  value={expenseData.category[index + 1]}
                  onValueChange={(val) => {
                    let a = categorie.categories.find((ca) => ca.type === val);
                    setExpenseData((p) => {
                      return {
                        ...p,
                        category: [...p.category.slice(0, index + 1), val],
                        expenseType:
                          a && a.expenseType ? a.expenseType : undefined,
                      };
                    });
                  }}
                  required={true}
                  label={`Choose ${
                    expenseData.category[index]
                      ? expenseData.category[index]
                      : ""
                  } Category`}
                  options={categorie.categories.map((ca) => ca.type)}
                />
              ) : (
                <></>
              );
            })}
          </>
        )}

        {expenseData.hotelId &&
          expenseData.expenserId &&
          expenseData.category.length > 0 && (
            <ExpenseDateRangePicker
              dateRange={expenseData.aDates}
              onDateRangeChange={(range) => {
                setExpenseData((p) => {
                  return {
                    ...p,
                    aDates: range,
                  };
                });
              }}
            />
          )}

        {expenseData.hotelId && expenseData.expenserId && (
          <>
            <ExpenseDateAmountPicker
              amount={
                Object.values(expenseData.dates).length > 0
                  ? Object.values(expenseData.dates)[0]
                  : 0
              }
              onAmountChange={(val) => {
                setExpenseData((p) => {
                  let a = p.dates;
                  let allDates = Object.keys(a);
                  for (let i = 0; i < allDates.length; i++) {
                    a[allDates[i]] = val;
                  }
                  return {
                    ...p,
                    dates: a,
                  };
                });
              }}
              label={"Equal Amount"}
            />
            {expenseData.aDates.map((date) => {
              return (
                <ExpenseDateAmountPicker
                  amount={expenseData.dates[date]}
                  onAmountChange={(val) => {
                    setExpenseData((p) => {
                      let a = p.dates;
                      a[date] = val;
                      return {
                        ...p,
                        dates: a,
                      };
                    });
                  }}
                  label={date}
                />
              );
            })}
          </>
        )}

        {expenseData.hotelId && expenseData.expenserId && (
          <AddExpenserInput
            value={expenseData.note}
            onValueChange={(val) => {
              setExpenseData((p) => {
                return { ...p, note: val };
              });
            }}
            label={"Note"}
            type={"text"}
            required={true}
          />
        )}

        {expenseData.hotelId &&
          expenseData.expenserId &&
          expenseData.category.length > 0 && (
            <AddExpenseBooleanInput
              value={expenseData.isAdvance}
              onToggle={() => {
                setExpenseData((e) => {
                  return {
                    ...e,
                    isAdvance: !e.isAdvance,
                  };
                });
              }}
              label={"Is Advance Payment"}
            />
          )}

        <AddExpenseBooleanInput
          value={expenseData.isEntity}
          onToggle={() => {
            setExpenseData((e) => {
              return {
                ...e,
                isEntity: !e.isEntity,
                isExpenserToBusinessLoan: !e.isEntity
                  ? false
                  : e.isExpenserToBusinessLoan,
                expenserId: "",
              };
            });
          }}
          label={"Business Payment"}
        />

        <AddExpenseBooleanInput
          value={expenseData.isParentCompanyPayment}
          onToggle={() => {
            setExpenseData((e) => {
              return {
                ...e,
                isParentCompanyPayment: !e.isParentCompanyPayment,
                isEntity: !e.isParentCompanyPayment ? true : false,
                expenserId: "",
              };
            });
          }}
          label={"Parent Company"}
        />

        <AddExpenseBooleanInput
          value={expenseData.isExpenserToBusinessLoan}
          onToggle={() => {
            setExpenseData((e) => {
              return {
                ...e,
                isExpenserToBusinessLoan: !e.isExpenserToBusinessLoan,
                isEntity: !e.isExpenserToBusinessLoan ? false : e.isEntity,
                expenserId: "",
              };
            });
          }}
          label={"Personal Loan"}
        />

        <AddExpenseBooleanInput
          value={expenseData.isPayable}
          onToggle={() => {
            setExpenseData((e) => {
              return {
                ...e,
                isPayable: !e.isPayable,
              };
            });
          }}
          label={"Payable"}
        />

        <AddExpenseDatePicker
          date={expenseData.cDate}
          onDateChange={(date) => {
            setExpenseData((p) => {
              return {
                ...p,
                cDate: date,
              };
            });
          }}
        />

        <Button
          variant="outlined"
          size="large"
          sx={{
            backgroundColor: "#262626",
            borderRadius: "30px",
            padding: "10px 15px",
            margin: "20px auto",
            display: "block",
            color: OxyOsTheme.palette.background.main,
            "&:hover": {
              backgroundColor: OxyOsTheme.palette.primary.main,
              color: OxyOsTheme.palette.background.main,
              opacity: 0.6,
            },
          }}
          onClick={() => {
            if (!isLoading) {
              onSubmit(expenseData);
            }
          }}
        >
          {isLoading ? (
            <CircularProgress color="background" />
          ) : (
            <Typography variant="h6">Add Expense</Typography>
          )}
        </Button>

        <CustomSnackBar
          isOpen={error.isError}
          message={error.errorMessage}
          onClose={() => {
            setError((p) => {
              return { isError: false, errorMessage: "" };
            });
          }}
        />
      </Card>
    </Modal>
  );
};

export default AddExpenseModel;
