import { Button, Card, Modal, Typography } from "@mui/material";
import AddBalanceSourceSelector from "./AddBalanceSourceSelector";
import OxyOsTheme from "../../../../theme/theme";
import { useState } from "react";
import AddExpenserInput from "../../components/common/AddExpenserInput";
import AddExpenseDatePicker from "../../components/common/AddExpenseDatePicker";

const AddBalancePaymentInput = ({
  isOpen,
  onClose,
  onAdd,
  sources,
  sDate,
  isEntity,
  pSource,
  source,
  amount,
  note
}) => {
  const [data, setData] = useState({
    sourceName: source,
    amount: amount,
    note: note,
    date: sDate,
    pSourceName: pSource,
  });

  return (
    <Modal
      open={isOpen}
      onClose={() => {
        onClose();
      }}
      sx={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <Card
        sx={{
          flexDirection: "row",
          minHeight: "500px",
          maxHeight: "600px",
          maxWidth: "700px",
          minWidth: "500px",
          background: "#FFF",
          borderRadius: "10px",
          padding: "20px 30px",
          overflowY: "scroll",
          "::-webkit-scrollbar": {
            display: "none",
          },
          border: "none",
        }}
      >
        <AddBalanceSourceSelector
          sources={sources}
          source={data.sourceName}
          onSourceChange={(v) => {
            setData((p) => {
              return {
                ...p,
                sourceName: v,
              };
            });
          }}
          label={"Choose Source"}
          required={true}
        />

        {isEntity && (
          <AddBalanceSourceSelector
            sources={sources}
            source={data.pSourceName}
            onSourceChange={(v) => {
              setData((p) => {
                return {
                  ...p,
                  pSourceName: v,
                };
              });
            }}
            label={"Choose Partner Source"}
            required={true}
          />
        )}

        <AddExpenserInput
          value={data.amount}
          onValueChange={(val) => {
            setData((p) => {
              return {
                ...p,
                amount: val,
              };
            });
          }}
          label="Amount"
          type="number"
          required={true}
        />

        <AddExpenserInput
          value={data.note}
          onValueChange={(val) => {
            setData((p) => {
              return {
                ...p,
                note: val,
              };
            });
          }}
          label={"Note"}
          type={"text"}
          required={true}
        />

        <AddExpenseDatePicker
          date={data.date}
          onDateChange={(date) => {
            setData((p) => {
              return {
                ...p,
                date: date,
              };
            });
          }}
        />
        <Button
          variant="outlined"
          size="large"
          sx={{
            backgroundColor: "#262626",
            borderRadius: "30px",
            padding: "10px 15px",
            margin: "20px auto",
            display: "block",
            color: OxyOsTheme.palette.background.main,
            "&:hover": {
              backgroundColor: OxyOsTheme.palette.primary.main,
              color: OxyOsTheme.palette.background.main,
              opacity: 0.6,
            },
          }}
          onClick={() => {
            onAdd(data);
          }}
        >
          <Typography variant="h6">Add Payment</Typography>
        </Button>
      </Card>
    </Modal>
  );
};

export default AddBalancePaymentInput;
