import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs from "dayjs";
import moment from "moment";
const AddExpenseDatePicker = ({ date, onDateChange }) => {
  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <DatePicker
        sx={{ width: "100%",margin:"8px 0px" }}
        format="DD-MM-YYYY"
        label="Date"
        value={dayjs(moment(date,"DD-MM-YYYY").format("YYYY-MM-DD"))}
        onChange={(date) => {
            onDateChange(moment(date.$d).format("DD-MM-YYYY"))
        }}
      />
    </LocalizationProvider>
  );
};

export default AddExpenseDatePicker;
