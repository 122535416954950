import React, { useState, useEffect } from "react";
import ChartDataLabels from "chartjs-plugin-datalabels";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  BarElement,
} from "chart.js";
import { Line, Bar } from "react-chartjs-2";
import Graph from "../util/graph";
import HotelChooser from "../components/HotelChooser";
import { Stack } from "@mui/material";
import HotelSwitch from "../components/HotelSwitch";
import labelFinder from "../util/labelFinder";
import HotelGroupSwitchContent from "../components/HotelGroupSwitchContent";
import moment from "moment";
import profitAndLossDataFinder from "./profitAndLossGraphDataFinder";

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  BarElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  ChartDataLabels
);

const opt = ["Revenue", "Expense", "Profit"];

const ProfitAndLossGraph = ({
  analytics,
  title,
  isTablularData,
  onToggleTabularData,
  isBar,
  isHotel,
  isDate,
  onToggleBar,
  onToggleHotel,
  onToggleDate,
  allOptions,
}) => {
  const [sHotels, setSHotels] = useState([]);
  const hotels = Object.keys(analytics.Revenue);
  let allDates = Object.keys(analytics.Revenue[hotels[0]]);
  const [data, setData] = useState({});

  const [eoptions, setEoptions] = useState([...opt]);

  useEffect(() => {
    const hotels = Object.keys(analytics.Revenue);
    let allDates = Object.keys(analytics.Revenue[hotels[0]]);

    let datasets = profitAndLossDataFinder(
      analytics,
      allDates,
      isHotel,
      isDate,
      sHotels,
      isBar,
      isTablularData,
      eoptions,
      // options
    );

    const labels = labelFinder(
      allDates.map((dt) => moment(dt, "DD-MM-YYYY").format("DD-MMM")),
      isHotel,
      isDate,
      sHotels
    );
    setData({ labels, datasets });
  }, [analytics, isHotel, isDate, sHotels, isTablularData, isBar,eoptions,]);

  useEffect(() => {
    setSHotels(Object.keys(analytics.Revenue));
  }, [analytics]);

  return (
    <div style={{ margin: "20px", paddingTop: "20px", position: "relative" }}>
      <Stack
        style={{
          position: "absolute",
          top: "-10px",
          right: "10px",
        }}
        direction="row"
        spacing={1}
      >
        <HotelGroupSwitchContent
          content={
            <>
              <HotelSwitch
                isChecked={!isTablularData}
                label={"Data"}
                onChange={(v) => {
                  onToggleTabularData();
                }}
              />

              <HotelSwitch
                isChecked={isBar}
                label={"Bar"}
                onChange={(v) => {
                  onToggleBar();
                }}
              />

              {hotels.length > 1 ? (
                <HotelSwitch
                  isChecked={isHotel}
                  label={"Hotel"}
                  onChange={(v) => {
                    onToggleHotel();
                  }}
                />
              ) : (
                <></>
              )}

              {allDates.length > 1 ? (
                <HotelSwitch
                  isChecked={isDate}
                  label={"Date"}
                  onChange={(v) => {
                    onToggleDate();
                  }}
                />
              ) : (
                <></>
              )}
            </>
          }
        />

        <HotelChooser
          hotels={opt}
          selectedHotels={eoptions}
          onHotelsChange={(s) => {
            setEoptions(s);
          }}
          width="120px"
        />


        {hotels.length > 0 && (
          <HotelChooser
            hotels={hotels}
            selectedHotels={sHotels}
            onHotelsChange={(s) => {
              setSHotels(s);
            }}
            width="200px"
          />
        )}
      </Stack>

      {data &&
        data.datasets &&
        data.datasets.length > 0 &&
        (isBar ? (
          <Bar
            style={{ marginTop: "20px" }}
            options={Graph.BarOptions(
              title,
              true,
              data.datasets.reduce((acc, cur) => {
                if (!acc[cur.stack]) {
                  let nAcc = { ...acc };
                  nAcc[cur.stack] = [cur];
                  return nAcc;
                } else {
                  let nAcc = { ...acc };
                  nAcc[cur.stack] = [...nAcc[cur.stack], cur];
                  return nAcc;
                }
              }, {})
            )}
            data={data}
          />
        ) : (
          <Line
            style={{ marginTop: "20px" }}
            options={Graph.Options(title)}
            data={data}
          />
        ))}
    </div>
  );
};

export default ProfitAndLossGraph;
