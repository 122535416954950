import Graph from "../util/graph"


const userAnalyticsDataFinder = (
    bookingAnalytics,
    allDates,
    isDateSummed,
    options,
    isBar,
    isDataTabular
) => {

    let sOptions = [...options]


    if (!isDateSummed) {


        if (isBar) {
            return sOptions.map((option, oIndex) => {
                return {
                    label: `${option} User Analytics`,
                    data: allDates.map(date => {
                        return (isDataTabular ? bookingAnalytics[date][option] : bookingAnalytics[date][option].length)
                    }),
                    borderColor: Graph.Colors[oIndex],
                    backgroundColor: Graph.Colors[oIndex],
                    ...Graph.ExtraOptions,
                    stack:"All"
                }
            })
        }

        return [{
            label: "User Analytics",
            data: allDates.map(date => {
                return sOptions.reduce((acc, cur) => {
                    return acc + (isDataTabular ? bookingAnalytics[date][cur] : bookingAnalytics[date][cur].length)
                }, 0)
            }),
            borderColor: Graph.Colors[0],
            backgroundColor: Graph.Colors[0],
            ...Graph.ExtraOptions,
        }]

    } else {

        if (isBar) {

            return sOptions.map((option, sIndex) => {
                return {
                    label: `${option} User Analytics`,
                    data: [
                        allDates.reduce((dcc, date) => {
                            return dcc + (isDataTabular ? bookingAnalytics[date][option] : bookingAnalytics[date][option].length)
                        }, 0)
                    ],
                    borderColor: Graph.Colors[sIndex],
                    backgroundColor: Graph.Colors[sIndex],
                    ...Graph.ExtraOptions,
                    stack:"All"
                }
            })

        }

        return [{
            label: "User Analytics",
            data: [
                allDates.reduce((dcc, date) => {
                    return dcc + sOptions.reduce((scc, option) => {
                        return scc + (isDataTabular ? bookingAnalytics[date][option] : bookingAnalytics[date][option].length)
                    }, 0)
                }, 0)
            ],
            borderColor: Graph.Colors[0],
            backgroundColor: Graph.Colors[0],
            ...Graph.ExtraOptions,
        }]

    }

}

export default userAnalyticsDataFinder