import { Button, Stack, Typography, styled } from "@mui/material";
import { actionTypeMessage } from "../../../utils/task/ActionTypes";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { taskAction } from "../../../utils/task/TaskActions";

const MessageBox = styled(Stack)({
  backgroundColor: "#F4F6FA",
  width: "350px",
  padding: "10px",
  border: "1px solid #e0dbdb",
});

let i = 0;

const MessageContainer = ({ taskId, action, token }) => {
  const queryClient = useQueryClient();

  const {
    mutate: mutateAction,
    isPending: isActionPending,
    isError: isActionError,
  } = useMutation({
    mutationFn: ({ actionId, action, token }) => {
      return taskAction(actionId, action, token);
    },
    mutationKey: ["taskAction"],
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["actions", taskId] });
      console.log("Action Success");
    },
    onError: () => {
      console.log("Action Error");
    },
  });

  return (
    <MessageBox
      sx={{
        alignSelf: action.byMe && "flex-end",
        borderRadius: action.byMe ? "15px 15px 0" : "0 15px 15px",
      }}
      gap={"4px"}
    >
      <Typography
        color={action.byMe ? "dodgerblue" : "cadetblue"}
        variant="body1"
      >
        {action.byMe ? "Me" : action.by}
      </Typography>
      <Typography
        variant="body2"
        sx={{ fontSize: "14px", fontWeight: "400", textShadow: "none" }}
      >
        {actionTypeMessage(action)}
      </Typography>

      {action.isActionable && (
        <Stack
          width={"100%"}
          flexDirection={"row"}
          justifyContent={"end"}
          gap={"20px"}
        >
          <Button
            color="success"
            variant="outlined"
            sx={{ fontSize: "10px", padding: "2px" }}
            onClick={() =>
              mutateAction({ actionId: action._id, action: true, token })
            }
          >
            ACCEPT
          </Button>
          <Button
            color="error"
            variant="outlined"
            sx={{ fontSize: "10px", padding: "2px" }}
            onClick={() =>
              mutateAction({ actionId: action._id, action: false, token })
            }
          >
            DENY
          </Button>
        </Stack>
      )}

      <Stack
        flexDirection={"row"}
        justifyContent={"space-between"}
        marginBottom={"-7px"}
      >
        <Typography
          variant="body2"
          color={"dimgray"}
          sx={{
            fontSize: "10px",
          }}
        >
          {action.date}
        </Typography>
        <Typography
          variant="body2"
          color={"dimgray"}
          sx={{
            fontSize: "10px",
          }}
        >
          {action.time}
        </Typography>
      </Stack>
    </MessageBox>
  );
};
export default MessageContainer;
