import axios from "axios";
import { createContext, useCallback, useContext, useEffect, useState } from "react";
import Constant from "../constant/Constant";
import { AuthContext } from "./auth";
import moment from "moment";

export const HotelContext = createContext({
    
    isHotelsLoading: false,
    isHotelLoaded: false,
    hotels: [],
    loadHotels: async () => { },
    isError: false,
    errorMessage: "",
    clearMessage: () => { },
    selctedHotelId: undefined,

    startDay: moment().subtract(6, "day").format("DD-MM-YYYY"),
    days: 7,
    onUpdateDate: (date, days) => { },

    isAnalyticsChanged: false,
    onClearAnalyticsChange: () => { },
    isBookingSalesChanged: true,

    updateSelectedHotel: (hotelId) => { }
});

const HotelContextProvider = ({ children }) => {

    const authContext = useContext(AuthContext)
    const [hotelState, setHotelState] = useState({
        isHotelsLoading: false,
        isHotelLoaded: false,
        hotels: [],
        isError: false,
        errorMessage: "",
        selectedHotelId: undefined,
        startDay: moment().subtract(6, "day").format("DD-MM-YYYY"),
        days: 7,

        isAnalyticsChanged: false,
        isBookingSalesChanged: true
    });

    const loadHotels = useCallback(async () => {
        try {
            let req = {
                url: Constant.getHotelsRoute,
                method: 'POST',
                headers: {
                    'Authorization': `Bearer ${authContext.authToken}`,
                    'Content-Type': 'application/json',
                },
            }
            const response = await axios(req)
            if (!response.data.status) {
                setHotelState(prevState => {
                    return {
                        ...prevState,
                        isError: true,
                        errorMessage: response.data?.message ? response.data?.message : "Please try after some time",
                        isAuthenticating: false,
                    }
                })
                return
            }
            setHotelState(prevState => {
                return {
                    ...prevState,
                    isError: true,
                    errorMessage: "Hotels Loaded Successfully.",
                    isHotelsLoading: false,
                    isHotelLoaded: true,
                    hotels: response.data.data,
                    selctedHotelId: response.data.data.length > 0 ? (window.localStorage.getItem("hotelId") ? window.localStorage.getItem("hotelId") : "") : undefined,
                    isChanged: true,
                    isBookingSalesChanged: true
                }
            })
        } catch (err) {
            console.log(err)
            setHotelState(prevState => {
                return {
                    ...prevState,
                    isError: true,
                    errorMessage: err.response.data?.message ? err.response.data?.message : "Please try after some time",
                    isHotelsLoading: false
                }
            })
        }
    }, [authContext.authToken])




    const onClearAnalyticsChange = useCallback(() => {
        setHotelState(p => {
            return {
                ...p,
                isAnalyticsChanged: false
            }
        })
    }, [])

    const onUpdateDate = (date, days) => {
        setHotelState(p => {
            return {
                ...p,
                startDay: date,
                days: days,
                isChanged: true,
                isBookingSalesChanged: true
            }
        })
    }

    const clearMessage = () => {
        setHotelState(prevState => {
            return {
                ...prevState,
                isError: false,
                errorMessage: "",
            }
        })
    }
    const updateSelectedHotel = (hotelId) => {
        window.localStorage.setItem("hotelId", hotelId)
        setHotelState(prevState => {
            return {
                ...prevState,
                selctedHotelId: hotelId,
                isChanged: hotelId !== prevState.selectedHotelId,
                isBookingSalesChanged: hotelId !== prevState.selectedHotelId
            }
        })
    }


    useEffect(() => {
        if (!hotelState.isHotelLoaded) {
            loadHotels()
        }
    }, [hotelState.isHotelLoaded, loadHotels])

    const value = {
        ...hotelState,
        loadHotels,
        clearMessage,
        updateSelectedHotel,
        onUpdateDate,
        onClearAnalyticsChange
    }

    return <HotelContext.Provider value={value}>{children}</HotelContext.Provider>;
};

export default HotelContextProvider;
