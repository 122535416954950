import { useContext } from "react";
import CustomSnackBar from "../common/CustomSnackBar";
import { BookingContext } from "../../store/booking";
import UpcomingBookings from "./screens/UpcomingBookings";
import allRoutes from "../../pages/routes";
import InHouseBookings from "./screens/InHouseBookings";
import NeedActionBookings from "./screens/NeedActionBookings";
import PendingBookings from "./screens/PendingBookings";
import CompletedBookings from "./screens/CompletedBookings";
import RoomStatusScreen from "../roomStatus/screens/RoomStatusScreen";

const BookingStack = ({ selRoute }) => {
  const bookingContext = useContext(BookingContext);

  let content = <></>;
  if (selRoute === allRoutes[0]) {
    content = (
      <UpcomingBookings
        bookings={bookingContext.upcomingBookings}
        isLoading={bookingContext.isUpcomingLoading}
      />
    );
  } else if (selRoute === allRoutes[1]) {
    content = (
      <InHouseBookings
        bookings={bookingContext.inHouseBookings}
        isLoading={bookingContext.isInHouseLoading}
      />
    );
  } else if (selRoute === allRoutes[2]) {
    content = (
      <CompletedBookings
        selctedDate={bookingContext.selectedDate}
        onDateChange={(date) => {
          bookingContext.updateDate(date);
        }}
        bookings={bookingContext.completeBookings}
        isLoading={bookingContext.isCompleteLoading}
      />
    );
  } else if (selRoute === allRoutes[3]) {
    content = (
      <NeedActionBookings
        bookings={bookingContext.actionNeededBookings}
        isLoading={bookingContext.isActionNeededLoading}
      />
    );
  } else if (selRoute === allRoutes[4]) {
    content = (
      <PendingBookings
        bookings={bookingContext.pendingBookings}
        isLoading={bookingContext.isPendingBookingLoading}
      />
    );
  }else if(selRoute === allRoutes[5]){
    content = (
      <RoomStatusScreen isLoading={
        bookingContext.isInHouseLoading 
      } />
    )
  }

  return (
    <>
      <CustomSnackBar
        isOpen={bookingContext.isError}
        message={bookingContext.errorMessage}
        onClose={() => {
          bookingContext.clearMessage();
        }}
      ></CustomSnackBar>
      {content}
    </>
  );
};

export default BookingStack;
