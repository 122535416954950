import { useContext } from "react";

import CustomSnackBar from "../common/CustomSnackBar";
import allRoutes from "../../pages/routes";
import BookingSalesScreen from "../bookingAnalytics/screens/BookingSalesScreen";
import BookingCheckInScreen from "../bookingAnalytics/screens/BookingCheckInScreen";
import BookingCheckOutScreen from "../bookingAnalytics/screens/BookingCheckOutScreen";
import RevenueScreen from "../accountAnalytics/screen/RevenueScreen";
import CollectionsScreen from "../accountAnalytics/screen/CollectionScreen";
import SalesScreen from "../accountAnalytics/screen/SalesScreen";
import ExtraGraphs from "../accountAnalytics/screen/ExtraGraphs";
import RevenueReconcilationScreen from "../revenueReconcilation/screen/RevenueReconcilationScreen";
import CollectionConcilationScreen from "../collectionConcilation/screens/CollectionConcilationScreen";
import { AnalyticsContext } from "../../store/analyticsS";
import { AnalyticsContext as OldAnalytics } from "../../store/analytics";
import CustomDateRangePicker from "./components/DateRangePicker";
import FeedbackScreen from "../feedback/feedbackScreen";
import FeedbackCountScreen from "../feedbackCountAnalytics/screens/FeedbackCountScreen";
import UserAnalyticsBookingScreen from "../bookingAnalytics/screens/UserAnalyticsBookingScreen";
import DepUserAnalyticsScreen from "../bookingAnalytics/screens/DepUserAnalyticsScreen";

let startIndex = 13;
const AnalyticsStack = ({ selRoute }) => {
  const analyticsContext = useContext(AnalyticsContext);

  const oldAnalytics = useContext(OldAnalytics);

  let content = <></>;
  if (selRoute === allRoutes[startIndex - 1]) {
    content = <DepUserAnalyticsScreen />;
  } else if (selRoute === allRoutes[startIndex]) {
    content = <UserAnalyticsBookingScreen />;
  } else if (selRoute === allRoutes[startIndex + 1]) {
    content = <BookingSalesScreen />;
  } else if (selRoute === allRoutes[startIndex + 2]) {
    content = <BookingCheckInScreen />;
  } else if (selRoute === allRoutes[startIndex + 3]) {
    content = <BookingCheckOutScreen />;
  } else if (selRoute === allRoutes[startIndex + 4]) {
    content = <FeedbackCountScreen />;
  } else if (selRoute === allRoutes[startIndex + 5]) {
    content = <RevenueScreen />;
  } else if (selRoute === allRoutes[startIndex + 6]) {
    content = <CollectionsScreen />;
  } else if (selRoute === allRoutes[startIndex + 7]) {
    content = <SalesScreen />;
  } else if (selRoute === allRoutes[startIndex + 8]) {
    content = <FeedbackScreen />;
  } else if (selRoute === allRoutes[startIndex + 9]) {
    content = <ExtraGraphs />;
  } else if (selRoute === allRoutes[startIndex + 10]) {
    content = (
      <RevenueReconcilationScreen
        revenueReconcilation={oldAnalytics.revenueReconcilation}
        isLoading={
          oldAnalytics.isRevenueReconcilationLoading ||
          !oldAnalytics.isRevenueReconcilationLoaded
        }
      />
    );
  } else if (selRoute === allRoutes[startIndex + 11]) {
    content = (
      <CollectionConcilationScreen
        collectionConcilation={oldAnalytics.collectionConcilation}
        isLoading={
          oldAnalytics.isCollectionConcilationLoading ||
          !oldAnalytics.isCollectionConcilationLoaded
        }
      />
    );
  }

  return (
    <>
      <CustomSnackBar
        isOpen={analyticsContext.isError}
        message={analyticsContext.errorMessage}
        onClose={() => {
          analyticsContext.clearMessage();
        }}
      />
      {allRoutes.indexOf(selRoute) >= 23 &&
      allRoutes.indexOf(selRoute) <= 24 ? (
        <div
          style={{ position: "absolute", top: "15px", left: "30px", zIndex: 4 }}
        >
          <CustomDateRangePicker
            startDay={oldAnalytics.startDay}
            days={oldAnalytics.days}
            onDateChange={(date, days) => {
              oldAnalytics.onUpdateDate(date, days);
            }}
          />
        </div>
      ) : (
        <></>
      )}
      {content}
    </>
  );
};

export default AnalyticsStack;
