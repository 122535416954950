import {
  Card,
  CircularProgress,
  Stack,
  Typography,
  IconButton,
} from "@mui/material";
import EntityViewer from "./EntityViewer";
import { useContext } from "react";
import { ExpenseContext } from "../../../../store/expensesS";
import AddIcon from "@mui/icons-material/Add";
import AddSourceModel from "./AddSourceModel";
import { HotelContext } from "../../../../store/hotel";

const EntityListWrapper = () => {
  const expenseContext = useContext(ExpenseContext);
  const hotelContext = useContext(HotelContext);

  return (
    <Card
      elevation={3}
      sx={{
        flexDirection: "column",
        justifyContent: "center",
        flexGrow: "1",
        width: "50%",
        padding: "15px 10px 10px 15px",
        margin: "40px 20px",
        borderRadius: "10px",
        alignItems: "center",
      }}
    >
      <Stack
        direction="row"
        sx={{
          flexDirection: "row",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <Typography variant="h1" fontSize="22px">
          Amounts
        </Typography>
        <IconButton
          onClick={() => {
            expenseContext.onToggleAmountAdder();
          }}
        >
          <AddIcon fontSize="20px" color="primary.main" />
        </IconButton>
      </Stack>

      {!expenseContext.isAmountsLoaded || expenseContext.isAmountsLoading ? (
        <CircularProgress
          sx={{
            minHeight: "200px",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            margin: "auto",
          }}
          color="primary"
          size={25}
        />
      ) : (
        <Stack
          style={{
            marginLeft: "10px",
            maxHeight: "400px",
            overflowY: "scroll",
            margin: "12px 0px 15px 15px",
          }}
          spacing={0}
        >
          {expenseContext.amounts.map((en) => {
            return <EntityViewer key={en.hotelName} entity={en} />;
          })}
        </Stack>
      )}

      {expenseContext.isAmountAdderOpen && (
        <AddSourceModel
          isLoading={expenseContext.isAmountAdding}
          isOpen={expenseContext.isAmountAdderOpen}
          onAdd={(data) => {
            expenseContext.onAddAmount(data);
          }}
          onClose={() => {
            expenseContext.onToggleAmountAdder();
          }}
          hotels={hotelContext.hotels}
        />
      )}
    </Card>
  );
};

export default EntityListWrapper;
