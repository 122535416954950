import { KeyboardArrowDown, KeyboardArrowUp } from "@mui/icons-material";
import { Button, Collapse, Typography } from "@mui/material";
import { useState } from "react";
import CollectionConcilationBookingPreview from "./CollectionConcilationBookingPreview";


const SingleDateCollectionConcilationViewer = ({date, bookings}) => {

    const [isOpen, setIsOpen] = useState(bookings.length <= 4);

    const onToggle = () => {
      setIsOpen((p) => !p);
    };

    
  return (
    <>
      <Button
        onClick={onToggle}
        disableRipple
        sx={{
          justifyContent: "space-between",
          display: "flex",
          alignItems: "center",
          flexDirection: "row",
          textTransform: "none",
        }}
      >
        <Typography variant="h6" color="primary.main" sx={{ fontSize: "14px" }}>
          {date}
        </Typography>

        {isOpen ? (
          <KeyboardArrowUp sx={{ marginLeft: "20px" }} />
        ) : (
          <KeyboardArrowDown sx={{ marginLeft: "20px" }} />
        )}
      </Button>

      <Collapse in={isOpen} timeout="auto" unmountOnExit>
        <div style={{ marginLeft: "10px" }}>
          {bookings.map((book) => {
            return (
              <CollectionConcilationBookingPreview
                key={book.bookingId}
                booking={book}
              />
            );
          })}
        </div>
      </Collapse>
    </>
  );
}

export default SingleDateCollectionConcilationViewer