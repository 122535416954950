import Expenser from "./Expnser";

const ExpenserList = ({ expensers}) => {
  return (
    <>
      {expensers.map((expenser, index) => {
        return <Expenser  disableEmail={true} expenser={expenser} key={index} />;
      })}
    </>
  );
};

export default ExpenserList;
