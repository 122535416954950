import { Container } from "@mui/material";
import LoginForm from "../components/auth/LoginForm";
import { useContext } from "react";
import { AuthContext } from "../store/auth";
import HomePage from "./HomePage";
import CustomSnackBar from "../components/common/CustomSnackBar";
import HotelContextProvider from "../store/hotel";

const LoginPage = () => {
  const authContext = useContext(AuthContext);

  if (!authContext.isAuthenticated) {
    return (
      <Container
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          width: "100vw",
          height: "100vh",
        }}
      >
        <LoginForm />
        <CustomSnackBar
          isOpen={authContext.isError}
          message={authContext.errorMessage}
          onClose={() => {
            authContext.clearMessage();
          }}
        />
      </Container>
    );
  }

  return (
    <>
      <CustomSnackBar
        isOpen={authContext.isError}
        message={authContext.errorMessage}
        onClose={() => {
          authContext.clearMessage();
        }}
      />
      <HotelContextProvider>
        <HomePage />
      </HotelContextProvider>
    </>
  );
};

export default LoginPage;
