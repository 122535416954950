import {
  InputLabel,
  MenuItem,
  Select,
  Typography,
  Stack,
  Checkbox,
  ListItemText,
} from "@mui/material";

const MultiDepartmentSelectInput = ({
  value,
  onValueChange,
  required,
  options,
  label = true,
}) => {
  return (
    <>
      {label && (
        <InputLabel
          variant="standard"
          sx={{
            width: "100%",
            margin: "3px 0px",
          }}
        >
          <Typography variant="h6" color="primary" fontSize="15px">
            Select Departments
          </Typography>
        </InputLabel>
      )}
      <Select
        fullWidth
        value={value}
        onChange={(e) => {
          onValueChange(e.target.value);
        }}
        variant="outlined"
        margin="none"
        label="Department"
        sx={{
          margin: "8px 0px",
        }}
        required={Boolean(required)}
        style={{
          marginLeft: "1px",
        }}
        multiple={true}
        renderValue={(val) => {
          return val.join(",");
        }}
      >
        {options.map((option) => {
          return (
            <MenuItem key={option} value={option}>
              <Stack
                direction={"row"}
                sx={{
                  alignItems: "center",
                }}
              >
                <Checkbox checked={value.includes(option)} />
                <ListItemText primary={option} />
              </Stack>
            </MenuItem>
          );
        })}
      </Select>
    </>
  );
};

export default MultiDepartmentSelectInput;
