import { Card, Stack, Typography, CircularProgress } from "@mui/material";
import LoansAndAdvancesListViewer from "./LoanAndAdvancesList";

const LoansViewerList = ({ loans, isLoading }) => {
  return (
    <>
      <Card
        elevation={3}
        sx={{
          flexDirection: "column",
          justifyContent: "center",
          flexGrow: "1",
          padding: "15px 10px 10px 15px",
          margin: "40px 20px",
          borderRadius: "10px",
          marginTop:"60px"
        }}
      >
        <Stack
          direction="row"
          sx={{
            flexDirection: "row",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <Typography variant="h1" fontSize="22px">
            Loans
          </Typography>
        </Stack>

        {isLoading ? (
          <CircularProgress
            sx={{
              minHeight: "200px",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              margin: "auto",
            }}
            color="primary"
            size={25}
          />
        ) : (
          <Stack
            style={{
              marginLeft: "10px",
              maxHeight: "400px",
              overflowY: "scroll",
              margin: "12px 0px 15px 15px",
            }}
            spacing={0}
          >
            <LoansAndAdvancesListViewer
              expenses={loans}
              onSelect={(loan) => {
                // onSelectClick(expense);
              }}
            />
          </Stack>
        )}
      </Card>
    </>
  );
};

export default LoansViewerList
