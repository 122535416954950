import { CardMedia, Stack, Typography } from "@mui/material";
import BedIcon from "@mui/icons-material/Bed";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import { useContext, useEffect, useState } from "react";
import { HotelContext } from "../../store/hotel";
import Constant from "../../constant/Constant";

const SelctedHotelDetails = ({ hotel }) => {
  const hotelContext = useContext(HotelContext);
  const [sHotel, setSHotel] = useState(undefined);

  useEffect(() => {
    const selectedHotel = hotelContext.hotels.find(
      (hotel) => hotel._id === hotelContext.selctedHotelId
    );

    if (selectedHotel) {
      setSHotel(selectedHotel);
    } else {
      setSHotel(undefined);
    }
  }, [hotelContext.selctedHotelId, setSHotel, hotelContext.hotels]);

  if (hotel === undefined || (sHotel === undefined && hotel !== "")) {
    return (
      <Stack
        direction="row"
        spacing={2}
        sx={{
          display: "flex",
          alignItems: "center",
        }}
      >
        <BedIcon
          sx={{
            fontSize: "40px",
          }}
          color="primary.main"
          style={{
            color: "white",
          }}
        />

        <Typography variant="h6" color="background.main">
          No Hotels
        </Typography>

        <KeyboardArrowUpIcon sx={{color:"white"}} />
      </Stack>
    );
  } else if (hotel === "") {
    return (
      <Stack
        direction="row"
        spacing={2}
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <BedIcon
          sx={{
            fontSize: "40px",
          }}
          color="primary.main"
          style={{
            color: "white",
          }}
        />
        <Typography variant="h6" color="background.main">
          All Hotels
        </Typography>

        <KeyboardArrowUpIcon sx={{color:"white"}} />
      </Stack>
    );
  }

  return (
    <Stack
      direction="row"
      spacing={2}
      sx={{
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
      }}
    >
      <CardMedia
        component="img"
        height="50px"
        width="50px"
        alt="Hotel Image"
        image={`${Constant.DOMAIN_NAME}${
          sHotel.imageData[Object.keys(sHotel.imageData)[0]][0]
        }`}
        sx={{
          borderRadius: "10px",
          width: "50px",
          height: "50px",
          objectFit: "cover",
          overflow: "hidden",
        }}
      />
      <Typography variant="h6" color="background.main">
        {sHotel.hotelName}
      </Typography>

      <KeyboardArrowUpIcon sx={{color:"white"}} />
    </Stack>
  );
};

export default SelctedHotelDetails;
