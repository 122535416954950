import { Stack,  Typography } from "@mui/material";
import BedIcon from "@mui/icons-material/Bed";

const AllHotelsOption = () => {
  return (
    <Stack
      direction="row"
      spacing={2}
      sx={{
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
      }}
    >
      <BedIcon
        sx={{
          fontSize: "40px",
          width: "40px",
          height: "40px",
        }}
      />
      <Stack direction="column">
        <Typography variant="h6" color="primary.main">
          All Hotels
        </Typography>
      </Stack>
    </Stack>
  );
};

export default AllHotelsOption;
