const Options = (title) => {
  return {
    responsive: true,
    plugins: {
      legend: {
        display: false
      },
      title: {
        display: true,
        text: title,
      },
      datalabels: {
        display: false
      },
      beforeInit: function (chart, args, options) {
        console.log(chart, args, options)
      }
    },
    scales: {
      y: {
        beginsAtZero: true
      }
    }
  };
}



const BarOptions = (title, isSum, stackData) => {
  return {
    responsive: true,
    plugins: {
      legend: {
        display: false
      },
      title: {
        display: true,
        text: title,
      },
      datalabels: {
        color: 'black',
        font: {
          weight: 'bold',
          size:20
        },
        formatter: (value, ctx) => {
          let stackeData = (stackData[ctx.dataset.stack])
          stackeData = stackeData[stackeData.length - 1]
          if (stackeData.label === ctx.dataset.label) {
            let sum = (stackData[ctx.dataset.stack]).reduce((acc, cur) => {
              let value = cur.data[ctx.dataIndex]
              return acc + (value ? value : 0)
            }, 0)
            return (sum > 0) ? Math.round(sum * 100) / 100 : ''
          } else {
            return ''
          }

        },
        anchor: 'end',
        align: 'end',
        rotation: 0,
        display: isSum
      },
    },
    scales: {
      x: {
        stacked: true
      },
      y: {
        beginsAtZero: false,
        // stacked:true
      }
    }
  };
}


let Colors = [
  "#555555",
  "#edbf33",
  "#ede15b",
  "#ea5545",
  "#f46a9b",
  "#bdcf32",
  "#87bc45",
  "#27aeef",
  "#b33dc6",
  "#50e991",
  "#e60049",
  "#0bb4ff",
  "#50e991",
  "#e6d800",
  "#9b19f5",
  "#ffa300",
  "#dc0ab4",
  "#b3d4ff",
  "#00bfa0",
  "#b30000",
  "#7c1158",
  "#4421af",
  "#1a53ff",
  "#0d88e6",
  "#00b7c7",
  "#5ad45a",
  "#8be04e",
  "#ebdc78",
  "#fd7f6f",
  "#7eb0d5",
  "#b2e061",
  "#bd7ebe",
  "#ffb55a",
  "#ffee65",
  "#beb9db",
  "#fdcce5",
  "#8bd3c7"
];

const AccountAnalytics = {
  "REV": ["XBR", "XECR", "OBR", "OECR", "CFEE"],
  "COLS": ["Cash", "Bank", "Ota"],
  "SAL": ["XBS", "XECS", "OBS", "OECS", "CFEE"]
};

const ExpenseAnalytics = {
  "REV": ["XBR", "XCR", "OBR", "CFEE"]
}

const ExtraOptions = {
  tension: 0.3,
  pointBorderWidth: 8,
  hoverBorderWidth: 15,
}

const Graph = {
  Options,
  BarOptions,
  Colors,
  AccountAnalytics,
  ExpenseAnalytics,
  ExtraOptions
}

export default Graph