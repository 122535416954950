import { Stack, Typography } from "@mui/material";
import { useEffect } from "react";
import { TaskStatus } from "../../../utils/task/ActionTypes";
import { rgbToHex } from "../../../utils/task/rgbToHex";

const ChatSideBar = ({ task, selectedTask, setSelectedTask, token }) => {
  useEffect(() => {
    setSelectedTask(task);
  }, [task, setSelectedTask]);

  return (
    <div onClick={() => setSelectedTask(task)}>
      <Stack
        flexDirection={"column"}
        sx={{
          backgroundColor:
            selectedTask?.id === task?.id ? "#cad2e1" : "#F4F6FA",
          padding: "10px",
          borderRadius: "5px",
          cursor: "pointer",
          boxShadow: "rgba(0, 0, 0, 0.3) 0px 1px 2px 0px",
        }}
        border={3}
        borderColor={rgbToHex(TaskStatus[task.status]?.color)}
      >
        <Stack
          flexDirection={"row"}
          justifyContent={"space-between"}
          alignItems={"center"}
        >
          <Typography variant="body1" noWrap>
            {task.title}
          </Typography>
          <Typography
            variant="body2"
            color={"red"}
            sx={{
              fontSize: "10px",
            }}
            noWrap
          >
            {task.dueDate}
          </Typography>
        </Stack>
        <Typography
          variant="body2"
          fontSize={"13px"}
          sx={{
            paddingLeft: "3px",
            paddingTop: "4px",
            letterSpacing: "0px",
          }}
          noWrap
        >
          {task.company}: {task.description}
        </Typography>
      </Stack>
    </div>
  );
};
export default ChatSideBar;
