import { MenuItem, Select, Typography } from "@mui/material";


const StringSelectInput = ({
  value,
  onValueChange,
  options,
  required,
  title,
  fullWidth
}) => {


  return (
    <>
      <Select
        value={value}
        onChange={(e) => {
          onValueChange(e.target.value);
        }}
        variant="outlined"
        margin="none"
        label="Hotel"
        sx={{
          margin: "8px 8px",
        }}
        required={Boolean(required)}
        style={{
          marginLeft: "1px",
          flexGrow:1,
          width:"48%"
        }}
        fullWidth={Boolean(fullWidth)}
      >
        {options
          .sort((a, b) => {
            if (a.title.toLowerCase() < b.title.toLowerCase()) {
              return -1;
            }
            if (a.title.toLowerCase() > b.title.toLowerCase()) {
              return 1;
            }
            return 0;
          })
          .map((option) => {
            return (
              <MenuItem key={option.value} value={option.value}>
                <Typography variant="primary">
                    {option.title}
                </Typography>
              </MenuItem>
            );
          })}
      </Select>
    </>
  );
};

export default StringSelectInput;
