import AdvanceViewerList from "./components/AdvanceViewerList";
import LoansViewerList from "./components/LoansViewerList";

const LoansAndAdvanceScreen = ({
  data,
  isLoading,
  sClearer,
  isClearing,
  onAddAdvancePayment,
  onAddLoanPayment,
  onChangeClearer,
  onClearPayment
}) => {
  const Loans = data.LOANS ? data.LOANS : [];
  const Advances = data.ADVANCES ? data.ADVANCES : [];

  return (
    <>
      <AdvanceViewerList
        sClearer={sClearer}
        advances={Advances}
        isLoading={isLoading}
        isClearing={isClearing}
        onChangeClearer={(id) => {
          onChangeClearer(id)
        }}
        onClearPayment = {(data) => {
          onClearPayment(data)
        }}
      />
      <LoansViewerList loans={Loans} isLoading={isLoading} />
    </>
  );
};

export default LoansAndAdvanceScreen;
