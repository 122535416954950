import LoadingBookings from "../../common/LoadingBookings";
import { Stack, Button, Typography, CircularProgress } from "@mui/material";
import CustomDateRangePicker from "../../analytics/components/DateRangePicker";
import BalanceRemoteEntitySelector from "./components/BalanceRemoteEntitySelector";
import BalanceSheetShowComponent from "./components/BalanceSheetShowComponent";
import OxyOsTheme from "../../../theme/theme";
import { useContext } from "react";
import { ExpenseContext } from "../../../store/expensesS";
import { HotelContext } from "../../../store/hotel";
import moment from "moment";

const BalanceSheetBuilderScreen = ({ category }) => {
  const hotelContext = useContext(HotelContext);

  const expenseContext = useContext(ExpenseContext);

  const transctions = expenseContext.transactionAnalytics;
  const balanceSheetData = expenseContext.balanceSheetData;

  if (
    !expenseContext.isTransactionsAnalyticsLoaded ||
    expenseContext.isTransactionsAnalyticsLoading
  ) {
    return <LoadingBookings />;
  }

  const dateContent = (
    <div style={{ position: "absolute", top: "15px", left: "30px", zIndex: 4 }}>
      <CustomDateRangePicker
        startDay={expenseContext.startDay}
        days={expenseContext.days}
        onDateChange={(date, days) => {
          expenseContext.onDaysUpdate(date, days);
        }}
      />
    </div>
  );

  return (
    <>
      {dateContent}
      <div style={{ paddingTop: "100px" }}></div>
      <Stack
        direction={"row"}
        sx={{
          display: "flex",
          justifyContent: "end",
          paddingRight: "30px",
          height: "90px",
          position: "absolute",
          top: "0px",
          right: "0px",
        }}
      >
        <BalanceRemoteEntitySelector
          hotel={balanceSheetData.entity}
          onValueChange={(entity) => {
            expenseContext.onBalanceSelectedEntityChange(entity, transctions);
          }}
          hotels={Object.keys(transctions)}
          label={"Choose Entity"}
          required={true}
        />
      </Stack>

      {Object.keys(balanceSheetData.balances).map((value, index) => {
        let bal = balanceFinder(
          Object.keys(balanceSheetData.balances).slice(0, index),
          balanceSheetData.balances,
          transctions[balanceSheetData.entity].transctions,
          balanceSheetData.opening,
          balanceSheetData.hotelId
        );

        return (
          <BalanceSheetShowComponent
            key={value}
            hotels={hotelContext.hotels}
            sDate={value}
            category={category}
            date={value}
            openingBank={bal.Bank}
            openingCash={bal.Cash}
            openingOta={bal.Ota}
            expenses={balanceSheetData.balances[value].expenses}
            transctions={transctions[balanceSheetData.entity].transctions[
              value
            ].transctions.filter((tr) => tr.type.startsWith("Incoming"))}
            opening={{ Cash: 0, Bank: 0, Ota: 0 }}
            onUpdateExpenses={(expenses) => {
              let balances = { ...balanceSheetData.balances };
              balances[value].expenses = expenses;
              expenseContext.onBalanceSheetDataUpdate({
                ...balanceSheetData,
                balances,
              });
            }}
            onAddExpense={(data) => {
              let balances = { ...balanceSheetData.balances };
              balances[value].expenses = [...balances[value].expenses, data];
              expenseContext.onBalanceSheetDataUpdate({
                ...balanceSheetData,
                balances,
              });
            }}
            expensers={expenseContext.expensers}
            hotelId={balanceSheetData.hotelId}
          />
        );
      })}

      <Button
        variant="outlined"
        size="large"
        sx={{
          backgroundColor: "#262626",
          borderRadius: "30px",
          padding: "10px 15px",
          margin: "20px auto",
          color: OxyOsTheme.palette.background.main,
          "&:hover": {
            backgroundColor: OxyOsTheme.palette.primary.main,
            color: OxyOsTheme.palette.background.main,
            opacity: 0.6,
          },
          display: "flex",
          alignItems: "center",
        }}
        onClick={() => {
          expenseContext.onBalanceSheetUpdate(balanceSheetData);
        }}
      >
        {expenseContext.isBalanceSheetUpdating ? (
          <CircularProgress color="background" />
        ) : (
          <Typography variant="h6">Update</Typography>
        )}
      </Button>
    </>
  );
};

const balanceFinder = (balances, allBalance, transctions, opening, hotelId) => {
  let allTrans = balances.map((bal) => transctions[bal].transctions).flat();

  let cash =
    allTrans.reduce((acc, cur) => {
      let payment =
        cur.type.startsWith("Incoming") && cur.sourceRef === "Cash"
          ? cur.amount
          : 0;
      return acc + payment;
    }, 0) + opening.Cash;
  let bank =
    allTrans.reduce((acc, cur) => {
      let payment =
        cur.type.startsWith("Incoming") && cur.sourceRef === "Bank"
          ? cur.amount
          : 0;
      return acc + payment;
    }, 0) + opening.Bank;
  let ota =
    allTrans.reduce((acc, cur) => {
      let payment =
        cur.type.startsWith("Incoming") && cur.sourceRef === "Ota"
          ? cur.amount
          : 0;
      return acc + payment;
    }, 0) + opening.Ota;

  const payments = Object.values(allBalance)
    .map((a) =>
      a.expenses
        .map((e) => {
          if (e.isEntity) {
            if (e.expenserId === hotelId) {
              return [
                ...e.payments.map((dt) => {
                  return {
                    ...dt,
                    date: moment
                      .tz(dt.date, "DD-MM-YYYY", "Asia/Kolkata")
                      .format("DD-MM-YYYY"),
                  };
                }),
                ...e.payments.map((dt) => {
                  return {
                    ...dt,
                    date: moment
                      .tz(dt.date, "DD-MM-YYYY", "Asia/Kolkata")
                      .format("DD-MM-YYYY"),
                    sourceName: dt.pSourceName,
                    amount: 0 - Number(dt.amount),
                  };
                }),
              ];
            } else {
              return [
                ...e.payments.map((dt) => {
                  return {
                    ...dt,
                    date: moment
                      .tz(dt.date, "DD-MM-YYYY", "Asia/Kolkata")
                      .subtract(1, "day")
                      .format("DD-MM-YYYY"),
                  };
                }),
              ];
            }
          }
          return [...e.payments];
        })
        .flat()
    )
    .flat()
    .filter((dt) => {
      return balances.includes(
        moment.tz(dt.date, "DD-MM-YYYY", "Asia/Kolkata").format("DD-MMM")
      );
    })
    .map((a) => {
      return {
        sourceName: a.sourceName,
        amount: a.amount,
      };
    });

  return {
    Cash:
      cash -
      payments
        .filter((p) => p.sourceName === "Cash")
        .reduce((acc, cur) => {
          return acc + Number(cur.amount);
        }, 0),
    Bank:
      bank -
      payments
        .filter((p) => p.sourceName === "Bank")
        .reduce((acc, cur) => {
          return acc + Number(cur.amount);
        }, 0),
    Ota:
      ota -
      payments
        .filter((p) => p.sourceName === "Ota")
        .reduce((acc, cur) => {
          return acc + Number(cur.amount);
        }, 0),
  };
};

export default BalanceSheetBuilderScreen;
