import { LocalizationProvider } from "@mui/x-date-pickers-pro";
import { AdapterDayjs } from "@mui/x-date-pickers-pro/AdapterDayjs";
import { DateRangePicker } from "@mui/x-date-pickers-pro/DateRangePicker";
import moment from "moment";
import dayjs from "dayjs";

const ExpenseDateRangePicker = ({ dateRange, onDateRangeChange }) => {
  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <DateRangePicker
        value={[
          dayjs(moment(dateRange[0], "DD-MM-YYYY").format("YYYY-MM-DD")),
          dayjs(moment(dateRange[dateRange.length - 1], "DD-MM-YYYY").format("YYYY-MM-DD")),
        ]}
        sx={{
          margin:"10px 0px"
        }}
        onChange={(value) => {
          if (value.length === 2) {
            try {
              let startDate = moment(value[0].$d);
              let endDate = moment(value[1].$d);
              const days = (endDate.unix() - startDate.unix()) / (24 * 60 * 60);
              let range = [startDate.format("DD-MM-YYYY")];
              for (let i = 1; i < days; i++) {
                range.push(
                  startDate.clone().add(i, "day").format("DD-MM-YYYY")
                );
              }
              onDateRangeChange(range);
            } catch (err) {}
          } else if (value.length === 1) {
            let startDate = moment(value[0].$d);
            let range = [startDate.format("DD-MM-YYYY")];
            onDateRangeChange(range);
          } else {
            console.log(value);
          }
        }}
        localeText={{ start: "StartDate", end: "EndDate" }}
        format="DD-MM-YYYY"
      />
    </LocalizationProvider>
  );
};

export default ExpenseDateRangePicker;
