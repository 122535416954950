import moment from "moment";
import BookingList from "../components/BookingList";
import CustomDatePicker from "../components/DatePicker";
import NoBookings from "../components/NoBookings";
import LoadingBookings from "../../common/LoadingBookings";

const CompletedBookings = ({
  bookings,
  selctedDate,
  onDateChange,
  isLoading,
}) => {
  let cBook = bookings.filter((book) => book.hasCheckedOut);

  let sameDayCheckOutBookings = cBook.filter((book) => {
    let dTime = moment(book.checkOutTime);
    let aTime = moment(book.actualCheckOut);
    return (
      dTime.unix() < aTime.unix() &&
      dTime.format("DD-MM-YYYY") !== aTime.format("DD-MM-YYYY")
    );
  });

  let earlyCheckOutBookings = cBook.filter((book) => {
    let dTime = moment(book.checkOutTime);
    let aTime = moment(book.actualCheckOut);
    return (
      dTime.unix() < aTime.unix() &&
      dTime.format("DD-MM-YYYY") === aTime.format("DD-MM-YYYY")
    );
  });

  let checkOutBookings = cBook.filter((book) => {
    let dTime = moment(book.checkOutTime);
    let aTime = moment(book.actualCheckOut);
    return dTime.unix() >= aTime.unix();
  });

  let noShowBookings = bookings.filter((book) => {
    return book.hasNotShown;
  });

  let cancelledBookings = bookings.filter((book) => {
    return book.isCancelled;
  });

  let notCheckedOutBookings = bookings.filter((book) => {
    return book.hasCheckedIn && !book.hasCheckedOut;
  });

  let notCheckedInBookings = bookings.filter((book) => {
    return !book.hasCheckedIn && !book.hasCheckedOut;
  });

  return (
    <>
      <div
        style={{
          position: "absolute",
          top: "10px",
          right: "30px",
        }}
      >
        <CustomDatePicker date={selctedDate} onDateChange={onDateChange} />
      </div>
      {isLoading && <LoadingBookings />}
      <BookingList
        name="Same Day CheckOut"
        bookings={sameDayCheckOutBookings}
      />
      <BookingList name="Early CheckOut" bookings={earlyCheckOutBookings} />
      <BookingList name="Checked Out" bookings={checkOutBookings} />
      <BookingList name="Not shown" bookings={noShowBookings} />
      <BookingList name="Cancelled" bookings={cancelledBookings} />
      <BookingList name="Not Checked In" bookings={notCheckedInBookings} />
      <BookingList name="Not Checked Out" bookings={notCheckedOutBookings} />
      {bookings.length === 0 && <NoBookings />}
    </>
  );
};

export default CompletedBookings;
