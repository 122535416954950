import axios from "axios";

const GetBookingsWithDate =async (route,token,hotelId,date,cancelToken) => {

    try{
        const response = await axios({
            method:"POST",
            url:route,
            headers:{
                "Authorization":`Bearer ${token}`
            },
            params:{
                hotelId
            },
            data:{
                date
            },
            cancelToken:cancelToken
        })
        return response.data
    }catch(err){
        console.log(err)
        return err.response ? (err.response.data ? err.response.data : {}) : {}
    }

}

export default GetBookingsWithDate