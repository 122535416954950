import { KeyboardArrowDown, KeyboardArrowUp } from "@mui/icons-material";
import { Button, Collapse, Typography } from "@mui/material";
import { useState } from "react";
import SingleDateRevenueReconcilationViewer from "./SingleDateRevenueReconcilationViewer";


const SingleHotelRevenueReconcilationViewer = ({hotelName, allDateData}) => {

    let allDates = Object.keys(allDateData);
    const [isOpen, setIsOpen] = useState(false);
  
    const onToggle = () => {
      setIsOpen((p) => !p);
    };

    return (
        <>
          <Button
            onClick={onToggle}
            disableRipple
            sx={{
              justifyContent: "space-between",
              display: "flex",
              alignItems: "center",
              flexDirection: "row",
              textTransform: "none",
            }}
          >
            <Typography variant="h6" color="primary.main" sx={{ fontSize: "17px" }}>
              {hotelName}
            </Typography>
    
            {isOpen ? (
              <KeyboardArrowUp sx={{ marginLeft: "20px" }} />
            ) : (
              <KeyboardArrowDown sx={{ marginLeft: "20px" }} />
            )}
          </Button>
          <Collapse in={isOpen} timeout="auto" unmountOnExit>
            <div style={{ paddingLeft: "10px" }}>
              {allDates.map((date) => {
                return (
                  <SingleDateRevenueReconcilationViewer
                    key={date + hotelName}
                    date={date}
                    bookings={allDateData[date]}
                  />

                );
              })}
            </div>
          </Collapse>
        </>
      );

}

export default SingleHotelRevenueReconcilationViewer