import moment from "moment-timezone";

const summaryDataFormatter = (order, transactions) => {
  let stage1 = transactions.reduce((acc, cur) => {
    let curCategory = cur[order[0].field];

    if (order[0].field === "date") {
      curCategory = moment(curCategory).format("DD-MMM-YYYY");
    }

    if (acc[curCategory]) {
      acc[curCategory] = [...acc[curCategory], cur];
    } else {
      acc[curCategory] = [cur];
    }
    return acc;
  }, {});

  let stage2 = Object.keys(stage1).map((stage1Key) => {
    let stage1Value = stage1[stage1Key];

    let stage2Partial = stage1Value.reduce((acc, cur) => {
      let curCategory = cur[order[1].field];

      if (order[1].field === "date") {
        curCategory = moment(curCategory).format("DD-MMM-YYYY");
      }

      if (acc[curCategory]) {
        acc[curCategory] = [...acc[curCategory], cur];
      } else {
        acc[curCategory] = [cur];
      }

      return acc;
    }, {});

    let stage3 = Object.keys(stage2Partial).map((stage2Key) => {
      let stage2Value = stage2Partial[stage2Key];

      let stage3Partial = stage2Value.reduce((acc, cur) => {
        let curCategory = cur[order[2].field];
        if (order[2].field === "date") {
          curCategory = moment(curCategory).format("DD-MMM-YYYY");
        }
        if (acc[curCategory]) {
          acc[curCategory] = [...acc[curCategory], cur];
        } else {
          acc[curCategory] = [cur];
        }
        return acc;
      }, {});

      let stage4 = Object.keys(stage3Partial).map((stage3Key) => {
        let stage3Value = stage3Partial[stage3Key];

        let stage4Partial = stage3Value.reduce((acc, cur) => {
          let curCategory = cur[order[3].field];

          if (order[3].field === "date") {
            curCategory = moment(curCategory).format("DD-MMM-YYYY");
          }
          if (acc[curCategory]) {
            acc[curCategory] = [...acc[curCategory], cur];
          } else {
            acc[curCategory] = [cur];
          }
          return acc;
        }, {});

        return [stage3Key, stage4Partial];
      });

      return [stage2Key, Object.fromEntries(stage4)];
    });

    return [stage1Key, Object.fromEntries(stage3)];
  });

  return Object.fromEntries(stage2);
};

export default summaryDataFormatter;
