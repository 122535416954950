import BookingList from "../components/BookingList"
import NoBookings from "../components/NoBookings"
import LoadingBookings from "../../common/LoadingBookings";

const NeedActionBookings = ({bookings,isLoading }) => {


    let noShowNeededBookings = bookings.filter(book => {
        return !book.hasCheckedIn && !book.hasCheckedOut
    })

    let checkOutNeededBookings = bookings.filter(book => {
        return book.hasCheckedIn && !book.hasCheckedOut
    })

    return (
        <>
        {isLoading && <LoadingBookings />}
        <BookingList name="No Show Needed" bookings={noShowNeededBookings} />
        <BookingList name="Check Out Needed" bookings={checkOutNeededBookings} />
        {bookings.length === 0 && <NoBookings />}
        </>
    )

}

export default NeedActionBookings