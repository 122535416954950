import { Stack, Typography, IconButton, CircularProgress } from "@mui/material";
import { Delete } from "@mui/icons-material";
import moment from "moment";

const SingleTransctionViewer = ({
  Transction,
  dTransId,
  onDeleteTransaction,
  isShort
}) => {
  let transction = Transction;


  return (
    <Stack
      sx={{
        borderRadius: "5px",
        border: `1px solid ${
          transction.type.startsWith("Incoming") ? "#3D9970" : "#FF4136"
        }`,
        margin: "15px 0px",
        padding: "7px 7px",
        transition: "border 1s",
        ":hover": {
          boxShadow: "0px 10px 15px -3px rgba(0,0,0,0.1)",
        },
        width: "min-content",
      }}
      direction="row"
      spacing={2}
    >
      {transction.entityName && !isShort && (
        <Stack sx={{ width: "150px" }}>
          <Typography
            variant="h6"
            color="primary.main"
            sx={{ fontSize: "11px" }}
          >
            {transction.entityName}
          </Typography>
          <Typography
            variant="h6"
            color="primary.main"
            sx={{ fontSize: "11px" }}
          >
            #{transction.expenseId}
          </Typography>
          {transction.category && (
            <Typography
              variant="h6"
              color="primary.main"
              sx={{ fontSize: "11px" }}
            >
              {transction.category}
            </Typography>
          )}
        </Stack>
      )}

      {transction.entityName && !isShort && (
        <Stack sx={{ width: "150px" }}>
          <Typography
            variant="h6"
            color="primary.main"
            sx={{ fontSize: "11px" }}
          >
            {transction.entityName}
          </Typography>

          <Typography
            variant="h6"
            color="primary.main"
            sx={{ fontSize: "11px" }}
          >
            {transction.expenseRefId}
          </Typography>
        </Stack>
      )}

      <Stack direction="column" spacing={0} sx={{ width: "100px" }}>
        <Typography
          variant="h6"
          color="primary.main"
          sx={{ fontSize: "13px", textOverflow: "ellipsis" }}
        >
          {transction.sourceRef[0].toUpperCase()}
          {transction.sourceRef.slice(1)}
        </Typography>
        <Typography variant="h6" color="primary.main" sx={{ fontSize: "11px" }}>
          ₹{transction.amount}
        </Typography>
      </Stack>

      <Stack sx={{ width: "60px" }}>
        <Typography variant="h6" color="primary.main" sx={{ fontSize: "11px" }}>
          {moment(transction.date).format("DD MMM HH:mm")}
        </Typography>
      </Stack>

      <Stack sx={{ width: "150px" }}>
        <Typography variant="h6" color="primary.main" sx={{ fontSize: "11px" }}>
          {transction.type}
        </Typography>
      </Stack>

      <Stack sx={{ width: "150px" }}>
        <Typography variant="h6" color="primary.main" sx={{ fontSize: "11px" }}>
          {moment(transction.date).format("DD-MMM HH:mm")}
        </Typography>
      </Stack>

      <Stack sx={{ width: "120px" }}>
        <Typography
          variant="h6"
          sx={{
            fontSize: "12px",
            textAlign: "center",
            color: `${
              transction.type.startsWith("Incoming") ? "#3D9970" : "#FF4136"
            }`,
          }}
        >
          {transction.type.startsWith("Incoming") ? "CREDITED" : "DEBITED"}
        </Typography>
      </Stack>
      <Stack alignItems={"flex-start"} justifyContent={"top"}>
        {dTransId === transction._id ? (
          <CircularProgress />
        ) : (
          <IconButton
            onClick={() => {
              onDeleteTransaction(transction._id);
            }}
          >
            <Delete />
          </IconButton>
        )}
      </Stack>
    </Stack>
  );
};

export default SingleTransctionViewer;
