import { Button, Collapse, Stack, Typography } from "@mui/material";
import { KeyboardArrowDown, KeyboardArrowUp } from "@mui/icons-material";
import { useState } from "react";
import SingleCategoryViewer from "./SingleCategoryViewer";

const SingleSourceViewer = ({ sourceName, sourceData,pAmount,t}) => {



  const [isOpen, setIsOpen] = useState(false);

  const amount = Object.values(sourceData)
    .map((a) => Object.values(a))
    .flat()
    .map((a) => Object.values(a))
    .flat()
    .reduce((ac, cu) => {
      return ac + cu;
    }, 0);

  const onToggle = () => {
    setIsOpen((p) => !p);
  };

  return (
    <>
      <Stack
        direction="row"
        sx={{
          flexGrow: 1,
          justifyContent: "space-between",
          display: "flex",
          width: "100%",
          margin: "auto",
        }}
      >
        <Button
          onClick={onToggle}
          disableRipple
          sx={{
            justifyContent: "space-between",
            display: "flex",
            alignItems: "center",
            flexDirection: "row",
            textTransform: "none",
            width: "100%",
            maxWidth: "500px",
          }}
        >
          <Typography
            variant="h6"
            color="primary.main"
            sx={{ fontSize: "14px" }}
          >
            {sourceName}
          </Typography>

          <Stack direction={"row"}>
            <Typography
              variant="h6"
              color="primary.main"
              sx={{ fontSize: "14px" }}
            >
             - {Math.round(amount * 100)/100} = {Math.round((pAmount - amount)*100)/100}
            </Typography>

            {isOpen ? (
              <KeyboardArrowUp sx={{ marginLeft: "20px" }} />
            ) : (
              <KeyboardArrowDown sx={{ marginLeft: "20px" }} />
            )}
          </Stack>
        </Button>
      </Stack>
      <Collapse sx={{ width: "100%" }} in={isOpen} timeout="auto" unmountOnExit>
        <div style={{ marginLeft: "10px", width: "100%" }}>
          {Object.keys(sourceData).map((at) => {
            return (
              <SingleCategoryViewer
                categoryName={at}
                categoryData={sourceData[at]}
                key={at}
              />
            );
          })}
        </div>
      </Collapse>
    </>
  );
};

export default SingleSourceViewer;
