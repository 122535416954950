
const findCategory = (sCategory, index, category) => {
    let data = category.find(cat => cat.type === sCategory[0])
    for (let i = 1; i <= index; i++) {
        let type = sCategory[i]
        data = data.categories.find(cat => cat.type === type)
    }
    return data
}

export default findCategory