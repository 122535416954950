import { Stack, Typography } from "@mui/material";

const SingleDateSummaryViewer = ({ balances,date }) => {
  let types = Object.keys(balances).sort((a, b) => {
    if (a === "OpeningBalance") {
      return -2;
    } else if (a.startsWith("Incoming") && b.startsWith("Outgoing")) {
      return -1;
    } else {
      return 1;
    }
  });

  let OpeningBalance = balances.OpeningBalance;
  
  const allSources = Object.keys(OpeningBalance);
  const defaultValue = Object.fromEntries(allSources.map((sr) => [sr, 0]));

  let TotalBalance = types.reduce(
    (acc, cur) => {
      const isIncoming =
        cur.startsWith("Incoming") || cur.startsWith("Opening");
      return Object.fromEntries(
        allSources.map((sr) => [
          sr,
          acc[sr] +
            (isIncoming ? (balances[cur][sr] ? balances[cur][sr] : 0) : 0),
        ])
      );
    },
    {
      ...defaultValue,
    }
  );
  let ClosingBalance = types.reduce(
    (acc, cur) => {
      const isIncoming =
        cur.startsWith("Incoming") || cur.startsWith("Opening");

      return Object.fromEntries(
        allSources.map((sr) => [
          sr,
          roundNumber(
            acc[sr] +
              (isIncoming
                ? roundNumber(balances[cur][sr])
                : roundNumber(0 - roundNumber(balances[cur][sr])))
          ),
        ])
      );
    },
    {
      ...defaultValue,
    }
  );
  return (
    <Stack
      sx={{
        borderRadius: "5px",
        // border: `1px solid ${
        // //   transction.type.startsWith("Incoming") ? "#3D9970" : "#FF4136"
        // "#262626"
        // }`,
        margin: "15px 0px",
        padding: "7px 7px",
        transition: "border 1s",
        ":hover": {
          boxShadow: "0px 10px 15px -3px rgba(0,0,0,0.1)",
        },
        width: "min-content",
      }}
      direction="column"
      spacing={2}
    >
      <SingleSummary
        sources={OpeningBalance}
        isIncoming={true}
        type={"OpeningBalance"}
      />

      {types
        .filter((ty) => ty.startsWith("Incoming"))
        .map((type) => {
          return (
            <SingleSummary
              sources={balances[type]}
              isIncoming={true}
              type={type}
            />
          );
        })}

      <SingleSummary
        sources={TotalBalance}
        isNeutral={true}
        type={"TotalBalance"}
      />

      {types
        .filter((ty) => ty.startsWith("Outgoing"))
        .map((type) => {
          return (
            <SingleSummary
              sources={balances[type]}
              isIncoming={false}
              type={type}
            />
          );
        })}

      <SingleSummary
        sources={ClosingBalance}
        isIncoming={true}
        type={"Closing Balance"}
      />
    </Stack>
  );
};

const SingleSummary = ({ sources, type, isIncoming, isNeutral }) => {
  return (
    <Stack
      sx={{
        borderRadius: "5px",
        border: `1px solid ${
          isNeutral ? "#262626" : isIncoming ? "#3D9970" : "#FF4136"
        }`,
        margin: "15px 0px",
        padding: "7px 7px",
        transition: "border 1s",
        ":hover": {
          boxShadow: "0px 10px 15px -3px rgba(0,0,0,0.1)",
        },
        width: "100%",
      }}
      direction="row"
      spacing={2}
    >
      <Stack sx={{ width: "200px", wordBreak: "break-all" }}>
        <Typography variant="h6" color="primary.main" sx={{ fontSize: "13px" }}>
          {type}
        </Typography>
      </Stack>

      {Object.keys(sources).map((sorce) => {
        return (
          <>
            <Stack key={sorce} sx={{ width: "100px" }}>
              <Typography
                variant="h6"
                color="primary.main"
                sx={{ fontSize: "11px" }}
              >
                {sorce}
              </Typography>

              <Typography
                variant="h6"
                color="primary.main"
                sx={{ fontSize: "11px" }}
              >
                {sources[sorce]}
              </Typography>
            </Stack>
          </>
        );
      })}
    </Stack>
  );
};

const roundNumber = (x) => {
  if (!x) return 0;
  return Math.round((x + Number.EPSILON) * 100) / 100;
};
export default SingleDateSummaryViewer;
