import AddExpenseSelector from "./AddExpenseSelector";

const AddExpenseCategoryInput = ({ categories, category, onChange }) => {
  return (
    <AddExpenseSelector
      value={category}
      onValueChange={(value) => {
        onChange(value);
      }}
      required={true}
      options={categories}
      label={"Choose Category"}
    />
  );
};

export default AddExpenseCategoryInput;
