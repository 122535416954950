import BookingList from "../components/BookingList";
import NoBookings from "../components/NoBookings";
import LoadingBookings from "../../common/LoadingBookings";

const PendingBookings = ({ bookings, isLoading }) => {
  return (
    <>
      {isLoading && <LoadingBookings />}
      <BookingList name="Pending" bookings={bookings} />
      {bookings.length === 0 && <NoBookings />}
    </>
  );
};

export default PendingBookings;
