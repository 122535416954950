import { Stack, Typography, IconButton } from "@mui/material";
import TaskStringInput from "./TaskStringInput";
import AddIcon from "@mui/icons-material/Add";
import DeleteIcon from "@mui/icons-material/Delete";
import MultiDepartmentSelectInput from "./MultiDepartmentSelect";

const CompanyPosition = ({ positions, onUpdatePosition, options }) => {
  return (
    <Stack direction={"column"}>
      <Stack
        direction="row"
        sx={{
          flexDirection: "row",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <Typography variant="h1" fontSize="22px">
          Companies
        </Typography>
        <IconButton
          onClick={() => {
            let nPosition = { ...positions };
            nPosition[Math.random()] = {
              position: "",
              priority: "",
              departments: [],
            };
            onUpdatePosition(nPosition);
          }}
        >
          <AddIcon fontSize="20px" color="primary.main" />
        </IconButton>
      </Stack>

      {Object.keys(positions).map((uid) => {
        return (
          <Stack
            key={uid}
            direction={"row"}
            spacing={1}
            sx={{
              marginTop: "15px",
              alignItems: "center",
            }}
          >
            <TaskStringInput
              value={positions[uid].position}
              onValueChange={(value) => {
                let nPosition = { ...positions };
                nPosition[uid].position = value;
                onUpdatePosition(nPosition);
              }}
              label={"Position"}
              type={"text"}
              required={true}
            />

            <TaskStringInput
              value={positions[uid].priority}
              onValueChange={(value) => {
                let nPosition = { ...positions };
                nPosition[uid].priority = value;
                onUpdatePosition(nPosition);
              }}
              label={"Priority"}
              type={"number"}
              required={true}
            />

            <MultiDepartmentSelectInput
              value={positions[uid].departments}
              onValueChange={(value) => {
                let nPosition = { ...positions };
                nPosition[uid].departments = value;
                onUpdatePosition(nPosition);
              }}
              required={true}
              options={options}
              label={false}
            />

            <IconButton
              onClick={() => {
                let nPosition = { ...positions };
                nPosition = Object.fromEntries(
                  Object.entries(nPosition).filter((a) => a[0] !== uid)
                );
                onUpdatePosition(nPosition);
              }}
            >
              <DeleteIcon />
            </IconButton>
          </Stack>
        );
      })}
    </Stack>
  );
};

export default CompanyPosition;
