import { Typography } from "@mui/material";
import SingleRoomViewer from "./SingleRoomViewer";

const SingleHotelViewer = ({ hotel, bookings }) => {
  const allRooms = Object.values(hotel.hotelStructure).flat();
  const bookedRooms = bookings
    .map((book) => {
      return book.assignedRoom ? book.assignedRoom.rooms : [];
    })
    .flat();

  return (
    <>
      <Typography
        sx={{ margin: "5px 10px" }}
        color="primary.main"
        fontSize="20px"
      >
        {hotel.hotelName}
      </Typography>
      <div
        style={{
          flexGrow: 1,
          display: "flex",
          flexDirection: "row",
          flexWrap: "wrap",
          padding: "20px 20px",
        }}
      >
        {allRooms.map((room) => {
          return (
            <SingleRoomViewer
              key={room.roomNo + hotel.hotelName}
              room={room}
              isFilled={bookedRooms.find((a) => a.roomNo === room.roomNo)}
            />
          );
        })}
      </div>
    </>
  );
};

export default SingleHotelViewer;
