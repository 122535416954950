import LoadingBookings from "../../common/LoadingBookings";
import RevenueReconcilationGraph from "../../graph/revenueReconcilation/RevenueReconcilationGraph";
import SingleHotelRevenueReconcilationViewer from "../component/SingleHotelRevenueReconcilationViewer";

const RevenueReconcilationScreen = ({ revenueReconcilation, isLoading }) => {
  
  let allHotels = Object.keys(revenueReconcilation);
  
  if (isLoading) {
    return <LoadingBookings />;
  }

  return (
    <>
      <RevenueReconcilationGraph revenueReconcilation={revenueReconcilation} />
      {allHotels.map((hotel) => {
        return (
          <SingleHotelRevenueReconcilationViewer
            key={hotel}
            hotelName={hotel}
            allDateData={revenueReconcilation[hotel]}
          />
        );
      })}
    </>
  );
};

export default RevenueReconcilationScreen;
