import {
  MenuItem,
  FormControl,
  ListItemText,
  Checkbox,
  Button,
  Typography,
  Grow,
  Paper,
  MenuList,
  Popper,
} from "@mui/material";
import { useEffect, useRef, useState } from "react";

const FilterMultipleInput = ({
  options,
  selectedOptions,
  onOptionsChange,
  width,
  isSingle,
  label,
  isEmptyToMulti,
}) => {
  const buttonRef = useRef(undefined);
  const popperRef = useRef(undefined);
  const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {
    const handleOutsideClick = (event) => {
      if (
        popperRef.current &&
        !popperRef.current.contains(event.target) &&
        buttonRef.current &&
        !buttonRef.current.contains(event.target)
      ) {
        setIsVisible(false);
      }
    };

    document.addEventListener("mousedown", handleOutsideClick);

    return () => {
      document.removeEventListener("mousedown", handleOutsideClick);
    };
  }, []);

  return (
    <FormControl>
      <Button
        ref={buttonRef}
        sx={{
          width: width,
          border: "1px solid #262626",
          outline: isVisible ? "1px solid #262626" : "1px solid transparent",
          fontSize: "16px",
          height: "55px",
        }}
        onClick={() => {
          setIsVisible((p) => !p);
        }}
      >
        <Typography
          noWrap
          sx={{
            padding: "9px 9px",
            textOverflow: "ellipsis",
            textTransform: "none",
            wordBreak: "break-all",
            maxLines: 1,
          }}
          variant="h6"
          fontSize={"15px"}
          color="primary.main"
        >
          {label ? label : selectedOptions.join(", ")}
        </Typography>
      </Button>

      <Popper
        anchorEl={buttonRef.current}
        role={undefined}
        placement="bottom"
        open={isVisible && Boolean(buttonRef.current)}
        disablePortal={true}
        transition
        ref={popperRef}
        sx={{
          zIndex:1000000
        }}
      >
        {({ TransitionProps }) => (
          <Grow {...TransitionProps} style={{ transformOrigin: "center top" }}>
            <Paper
              elevation={10}
              sx={{
                marginTop: "3px",
                maxHeight: "450px",
                overflow: "scroll",
              }}
            >
              <MenuList>
                {!isSingle && (
                  <MenuItem
                    onClick={() => {
                      if (isEmptyToMulti) {
                        onOptionsChange([...options]);
                      } else {
                        if (selectedOptions.length === options.length) {
                          onOptionsChange([]);
                        } else {
                          onOptionsChange([...options]);
                        }
                      }
                    }}
                    key="All"
                  >
                    <Checkbox
                      checked={selectedOptions.length === options.length}
                    />
                    <ListItemText primary="All" />
                  </MenuItem>
                )}

                {[...options].map((option) => {
                  return (
                    <MenuItem
                      onClick={() => {
                        if (isSingle) {
                          onOptionsChange([option]);
                        } else {
                          if (selectedOptions.includes(option)) {
                            let sHot = [...selectedOptions];
                            sHot.splice(sHot.indexOf(option), 1);
                            if(isEmptyToMulti && sHot.length === 0){
                              onOptionsChange([...options]);
                            }else{
                              onOptionsChange(sHot);
                            }
                          } else {
                            onOptionsChange([...selectedOptions, option]);
                          }
                        }
                      }}
                      key={option}
                      value={option}
                    >
                      <Checkbox
                        checked={selectedOptions.indexOf(option) > -1}
                      />
                      <ListItemText primary={option} />
                    </MenuItem>
                  );
                })}
              </MenuList>
            </Paper>
          </Grow>
        )}
      </Popper>
    </FormControl>
  );
};

export default FilterMultipleInput;
