import { useContext } from "react";
import LoadingBookings from "../../common/LoadingBookings";
import AccountGraph from "../../graph/accounts/accountGraph";
import SingleHotelAccountViewer from "../component/SingleHotelAccountViewer";
import { AnalyticsContext } from "../../../store/analyticsS";
import { HotelContext } from "../../../store/hotel";
import CustomDateRangePicker from "../../analytics/components/DateRangePicker";
import Constant from "../../../constant/Constant";

const RevenueScreen = () => {
  const analyticsContext = useContext(AnalyticsContext);
  const hotelContext = useContext(HotelContext);
  let revenueAnalytics = analyticsContext.revenueAnalytics;
  let allHotels = Object.keys(revenueAnalytics);

  const dateContent = (
    <div style={{ position: "absolute", top: "15px", left: "30px", zIndex: 4 }}>
      <CustomDateRangePicker
        startDay={hotelContext.startDay}
        days={hotelContext.days}
        onDateChange={(date, days) => {
          hotelContext.onUpdateDate(date, days);
        }}
      />
    </div>
  );

  if (
    analyticsContext.isRevenueAnalyticsLoading ||
    !analyticsContext.isRevenueAnalyticsLoaded
  ) {
    return (
      <>
        {dateContent}
        <LoadingBookings />
      </>
    );
  }

  return (
    <>
      {dateContent}
      {allHotels.length > 0 && (
        <AccountGraph
          analytics={revenueAnalytics}
          title="Revenue Analytics"
          allOptions={Constant.RevenueAnalyticsShort}
          isTablularData={analyticsContext.isTabularData}
          onToggleTabularData={() => {
            analyticsContext.onToggleTabularData();
          }}
          isBar={analyticsContext.isBar}
          isHotel={analyticsContext.isHotel}
          isDate={analyticsContext.isDate}
          isPercentage={analyticsContext.isPercentage}
          onTogglePercentage={() => {
            analyticsContext.onTogglePercentage();
          }}
          onToggleBar={() => {
            analyticsContext.onToggleBar();
          }}
          onToggleHotel={() => {
            analyticsContext.onToggleHotel();
          }}
          onToggleDate={() => {
            analyticsContext.onToggleDate();
          }}
        />
      )}

      {!analyticsContext.isTabularData &&
        allHotels.map((hotel) => {
          return (
            <SingleHotelAccountViewer
              key={hotel}
              hotelName={hotel}
              allDateData={revenueAnalytics[hotel]}
              types={Constant.RevenueAnalyticsShort}
            />
          );
        })}
    </>
  );
};

export default RevenueScreen;
