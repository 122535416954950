import { createContext, useCallback, useContext, useEffect, useRef, useState } from "react";
import axios from "axios"
import GetAnalytics from "../utils/analytics/GetAnalytics";
import Constant from "../constant/Constant";
import { AuthContext } from "./auth";
import { HotelContext } from "./hotel";
import allRoutes from "../pages/routes";

export const AnalyticsContext = createContext({


    isDepUserAnalyticsLoaded: false,
    isDepUserAnalyticsLoading: false,
    depUserAnalytics: {},
    depUserAnalyticsConf: "",

    isUserAnalyticsLoaded: false,
    isUserAnalyticsLoading: false,
    userAnalytics: {},
    userAnalyticsConf: "",

    isBookingSalesLoaded: false,
    isBookingSalesLoading: false,
    bookingSales: {},
    bookingSalesConf: "",


    isCheckInAnalyticsLoaded: false,
    isCheckInAnalyticsLoading: false,
    checkInAnalytics: {},
    checkInAnalyticsConf: "",

    isCheckOutAnalyticsLoaded: false,
    isCheckOutAnalyticsLoading: false,
    checkOutAnalytics: {},
    checkOutAnalyticsConf: "",

    isFeedbackCountAnalyticsLoaded: false,
    isFeedbackCountAnalyticsLoading: false,
    feedbackCountAnalytics: {},
    feedbackCountAnalyticsConf: "",

    isRevenueAnalyticsLoaded: false,
    isRevenueAnalyticsLoading: false,
    revenueAnalytics: {},
    revenueAnalyticsConf: "",

    isCollectionAnalyticsLoaded: false,
    isCollectionAnalyticsLoading: false,
    collectionAnalytics: {},
    collectionAnalyticsConf: "",

    isAccountSalesAnalyticsLoaded: false,
    isAccountSalesAnalyticsLoading: false,
    accountSalesAnalytics: {},
    accountSalesAnalyticsConf: "",

    isBookingSummaryAnalyticsLoaded: false,
    isBookingSummaryAnalyticsLoading: false,
    bookingSummaryAnalytics: {},
    bookingSummaryAnalyticsConf: "",

    isFeedbackAnalyticsLoaded: false,
    isFeedbackAnalyticsLoading: false,
    feedbackAnalytics: {},
    feedbackAnalyticsConf: "",


    isTabularData: false,

    onToggleTabularData: () => { },
    onToggleBar: () => { },
    onToggleHotel: () => { },
    onToggleDate: () => { },
    loadBookingSalesAnalytics: async (isTable) => { },
    onTogglePercentage: () => { },
    onToggleAverage: () => { },

    isBar: false,
    isHotel: false,
    isDate: false,
    isPercentage: false,
    isAverage: false,

    isError: false,
    errorMessage: "",
    clearMessage: () => { },

})

let startIndex = 13

const NAnalyticsContextProvider = ({ children, selRoute }) => {

    const authContext = useContext(AuthContext)
    const hotelContext = useContext(HotelContext)

    const [analyticsData, setAnalyticsData] = useState({

        isDepUserAnalyticsLoaded: false,
        isDepUserAnalyticsLoading: false,
        depUserAnalytics: {},
        depUserAnalyticsConf: "",

        isUserAnalyticsLoaded: false,
        isUserAnalyticsLoading: false,
        userAnalytics: {},
        userAnalyticsConf: "",

        isBookingSalesLoaded: false,
        isBookingSalesLoading: false,
        bookingSales: {},
        bookingSalesConf: "",

        isCheckInAnalyticsLoaded: false,
        isCheckInAnalyticsLoading: false,
        checkInAnalytics: {},
        checkInAnalyticsConf: "",

        isCheckOutAnalyticsLoaded: false,
        isCheckOutAnalyticsLoading: false,
        checkOutAnalytics: {},
        checkOutAnalyticsConf: "",

        isFeedbackCountAnalyticsLoaded: false,
        isFeedbackCountAnalyticsLoading: false,
        feedbackCountAnalytics: {},
        feedbackCountAnalyticsConf: "",

        isRevenueAnalyticsLoaded: false,
        isRevenueAnalyticsLoading: false,
        revenueAnalytics: {},
        revenueAnalyticsConf: "",

        isCollectionAnalyticsLoaded: false,
        isCollectionAnalyticsLoading: false,
        collectionAnalytics: {},
        collectionAnalyticsConf: "",

        isAccountSalesAnalyticsLoaded: false,
        isAccountSalesAnalyticsLoading: false,
        accountSalesAnalytics: {},
        accountSalesAnalyticsConf: "",

        isBookingSummaryAnalyticsLoaded: false,
        isBookingSummaryAnalyticsLoading: false,
        bookingSummaryAnalytics: {},
        bookingSummaryAnalyticsConf: "",

        isFeedbackAnalyticsLoaded: false,
        isFeedbackAnalyticsLoading: false,
        feedbackAnalytics: {},
        feedbackAnalyticsConf: "",


        isTabularData: Boolean(window.localStorage.getItem("isTabularData") === "true"),
        isBar: Boolean(window.localStorage.getItem("isBar") === "true"),
        isHotel: Boolean(window.localStorage.getItem("isHotel") === "true"),
        isDate: Boolean(window.localStorage.getItem("isDate") === "true"),
        isPercentage: Boolean(window.localStorage.getItem("isPercentage") === "true"),
        isAverage: Boolean(window.localStorage.getItem("isAverage") === "true"),


        isError: false,
        errorMessage: "",
        isChange: false

    })
    const depUserAnalyticsToken = useRef(null)
    const userAnalyticsToken = useRef(null)
    const bookingSalesToken = useRef(null)
    const checkInAnalyticsToken = useRef(null)
    const checkOutAnalyticsToken = useRef(null)
    const revenueAnalyticsToken = useRef(null)
    const collectionAnalyticsToken = useRef(null)
    const accountSalesAnalyticsToken = useRef(null)
    const bookingSummaryAnalyticsToken = useRef(null)
    const feedbackAnalyticsToken = useRef(null)
    const feedbackCountAnalyticsToken = useRef(null)

    const clearMessage = () => {
        setAnalyticsData(prevState => {
            return {
                ...prevState,
                isError: false,
                errorMessage: "",
            }
        })
    }

    const onToggleTabularData = useCallback(() => {
        setAnalyticsData(p => {
            window.localStorage.setItem("isTabularData", !p.isTabularData)
            return {
                ...p,
                isTabularData: !p.isTabularData,
                isBookingSalesLoaded: false,
                isCheckInAnalyticsLoaded: false,
                isCheckOutAnalyticsLoaded: false,
                isRevenueAnalyticsLoaded: false,
                isCollectionAnalyticsLoaded: false,
                isAccountSalesAnalyticsLoaded: false,
                isBookingSummaryAnalyticsLoaded: false,
                isFeedbackAnalyticsLoaded: false,
                isUserAnalyticsLoaded: false,
                isDepUserAnalyticsLoaded:false
            }
        })
    }, [])

    const onToggleBar = useCallback(() => {
        setAnalyticsData(p => {
            window.localStorage.setItem("isBar", !p.isBar)
            return {
                ...p,
                isBar: !p.isBar
            }
        })
    }, [])

    const onToggleHotel = useCallback(() => {
        setAnalyticsData(p => {
            window.localStorage.setItem("isHotel", !p.isHotel)
            return {
                ...p,
                isHotel: !p.isHotel
            }
        })
    }, [])

    const onToggleDate = useCallback(() => {

        setAnalyticsData(p => {
            window.localStorage.setItem("isDate", !p.isDate)
            return {
                ...p,
                isDate: !p.isDate
            }
        })
    }, [])

    const onTogglePercentage = useCallback(() => {

        setAnalyticsData(p => {
            window.localStorage.setItem("isPercentage", !p.isPercentage)
            return {
                ...p,
                isPercentage: !p.isPercentage
            }
        })
    }, [])

    const onToggleAverage = useCallback(() => {

        setAnalyticsData(p => {
            window.localStorage.setItem("isAverage", !p.isAverage)
            return {
                ...p,
                isAverage: !p.isAverage
            }
        })
    }, [])


    const loadDepUserAnalytics = useCallback(async (hotelId, startDay, days, isTable, conf) => {
        if (conf === `${hotelId}${startDay}${days}${isTable}`) {
            return
        }

        if (depUserAnalyticsToken.current && depUserAnalyticsToken.current.token) {
            depUserAnalyticsToken.current.cancel()
        }
        depUserAnalyticsToken.current = axios.CancelToken.source()
        setAnalyticsData(p => { return { ...p, isDepUserAnalyticsLoading: true, depUserAnalytics: {} } })

        try {
            const data = await GetAnalytics(Constant.getUserAnalyticsRoute, hotelId, authContext.authToken, startDay, days, isTable, depUserAnalyticsToken.current.token)
            if (!data.status || !data.data) {
                setAnalyticsData(p => {
                    return {
                        ...p,
                        isDepUserAnalyticsLoading: false,
                        isDepUserAnalyticsLoaded: false,
                        isError: true,
                        errorMessage: data.message,
                    }
                })
            } else {
                setAnalyticsData(p => {
                    return {
                        ...p,
                        isDepUserAnalyticsLoading: false,
                        isDepUserAnalyticsLoaded: true,
                        isError: true,
                        errorMessage: "User Analytics Updated Successfully.",
                        depUserAnalytics: data.data,
                        depUserAnalyticsConf: `${hotelId}${startDay}${days}${isTable}`
                    }
                })
            }
        } catch (err) {
            console.log(err)
        }


    }, [
        authContext.authToken,
    ])

    const loadUserAnalytics = useCallback(async (hotelId, startDay, days, isTable, conf) => {
        if (conf === `${hotelId}${startDay}${days}${isTable}`) {
            return
        }

        if (userAnalyticsToken.current && userAnalyticsToken.current.token) {
            userAnalyticsToken.current.cancel()
        }
        userAnalyticsToken.current = axios.CancelToken.source()
        setAnalyticsData(p => { return { ...p, isUserAnalyticsLoading: true, userAnalytics: {} } })

        try {
            const data = await GetAnalytics(Constant.getUserBookingAnalyticsRoute, hotelId, authContext.authToken, startDay, days, isTable, userAnalyticsToken.current.token)
            if (!data.status || !data.data) {
                setAnalyticsData(p => {
                    return {
                        ...p,
                        isUserAnalyticsLoading: false,
                        isUserAnalyticsLoaded: false,
                        isError: true,
                        errorMessage: data.message,
                    }
                })
            } else {
                setAnalyticsData(p => {
                    return {
                        ...p,
                        isUserAnalyticsLoading: false,
                        isUserAnalyticsLoaded: true,
                        isError: true,
                        errorMessage: "User Analytics Updated Successfully.",
                        userAnalytics: data.data,
                        userAnalyticsConf: `${hotelId}${startDay}${days}${isTable}`
                    }
                })
            }
        } catch (err) {
            console.log(err)
        }


    }, [
        authContext.authToken,
    ])

    const loadBookingSalesAnalytics = useCallback(async (hotelId, startDay, days, isTable, conf) => {
        if (conf === `${hotelId}${startDay}${days}${isTable}`) {
            return
        }
        if (bookingSalesToken.current && bookingSalesToken.current.token) {
            bookingSalesToken.current.cancel()
        }
        bookingSalesToken.current = axios.CancelToken.source()
        setAnalyticsData(p => { return { ...p, isBookingSalesLoading: true, bookingSales: {} } })

        try {
            const data = await GetAnalytics(Constant.getBookingSalesAnalytics, hotelId, authContext.authToken, startDay, days, isTable, bookingSalesToken.current.token)
            if (!data.status || !data.data) {
                setAnalyticsData(p => {
                    return {
                        ...p,
                        isBookingSalesLoading: false,
                        isBookingSalesLoaded: false,
                        isError: true,
                        errorMessage: data.message,
                    }
                })
            } else {
                setAnalyticsData(p => {
                    return {
                        ...p,
                        isBookingSalesLoading: false,
                        isBookingSalesLoaded: true,
                        isError: true,
                        errorMessage: "Booking Analytics Updated Successfully.",
                        bookingSales: data.data,
                        bookingSalesConf: `${hotelId}${startDay}${days}${isTable}`
                    }
                })
            }
        } catch (err) {
            console.log(err)
        }


    }, [
        authContext.authToken,
    ])



    const loadCheckInAnalytics = useCallback(async (hotelId, startDay, days, isTable, conf) => {
        if (conf === `${hotelId}${startDay}${days}${isTable}`) {
            return
        }
        if (checkInAnalyticsToken.current && checkInAnalyticsToken.current.token) {
            checkInAnalyticsToken.current.cancel()
        }
        checkInAnalyticsToken.current = axios.CancelToken.source()
        setAnalyticsData(p => { return { ...p, isCheckInAnalyticsLoading: true, checkInAnalytics: {} } })

        try {
            const data = await GetAnalytics(Constant.getCheckInAnalyticsRoutes, hotelId, authContext.authToken, startDay, days, isTable, checkInAnalyticsToken.current.token)
            if (!data.status || !data.data) {
                setAnalyticsData(p => {
                    return {
                        ...p,
                        isCheckInAnalyticsLoading: false,
                        isCheckInAnalyticsLoaded: false,
                        isError: true,
                        errorMessage: data.message,
                    }
                })
            } else {
                setAnalyticsData(p => {
                    return {
                        ...p,
                        isCheckInAnalyticsLoading: false,
                        isCheckInAnalyticsLoaded: true,
                        isError: true,
                        errorMessage: "Check In Analytics Updated Successfully.",
                        checkInAnalytics: data.data,
                        checkInAnalyticsConf: `${hotelId}${startDay}${days}${isTable}`
                    }
                })
            }
        } catch (err) {
            console.log(err)
        }


    }, [
        authContext.authToken,
    ])

    const loadCheckOutAnalytics = useCallback(async (hotelId, startDay, days, isTable, conf) => {
        if (conf === `${hotelId}${startDay}${days}${isTable}`) {
            return
        }
        if (checkOutAnalyticsToken.current && checkOutAnalyticsToken.current.token) {
            checkOutAnalyticsToken.current.cancel()
        }
        checkOutAnalyticsToken.current = axios.CancelToken.source()
        setAnalyticsData(p => { return { ...p, isCheckOutAnalyticsLoading: true, checkOutAnalytics: {} } })

        try {
            const data = await GetAnalytics(Constant.getCheckOutAnalyticsRoutes, hotelId, authContext.authToken, startDay, days, isTable, checkOutAnalyticsToken.current.token)
            if (!data.status || !data.data) {
                setAnalyticsData(p => {
                    return {
                        ...p,
                        isCheckOutAnalyticsLoading: false,
                        isCheckOutAnalyticsLoaded: false,
                        isError: true,
                        errorMessage: data.message,
                    }
                })
            } else {
                setAnalyticsData(p => {
                    return {
                        ...p,
                        isCheckOutAnalyticsLoading: false,
                        isCheckOutAnalyticsLoaded: true,
                        isError: true,
                        errorMessage: "Check In Analytics Updated Successfully.",
                        checkOutAnalytics: data.data,
                        checkOutAnalyticsConf: `${hotelId}${startDay}${days}${isTable}`
                    }
                })
            }
        } catch (err) {
            console.log(err)
        }


    }, [
        authContext.authToken,
    ])

    const loadFeedbackCountAnalytics = useCallback(async (hotelId, startDay, days, isTable, conf) => {
        if (conf === `${hotelId}${startDay}${days}${isTable}`) {
            return
        }
        if (feedbackCountAnalyticsToken.current && feedbackCountAnalyticsToken.current.token) {
            feedbackCountAnalyticsToken.current.cancel()
        }
        feedbackCountAnalyticsToken.current = axios.CancelToken.source()
        setAnalyticsData(p => { return { ...p, isFeedbackCountAnalyticsLoading: true, feedbackCountAnalytics: {} } })

        try {
            const data = await GetAnalytics(Constant.getFeedbackCountAnalyticsRoutes, hotelId, authContext.authToken, startDay, days, isTable, feedbackCountAnalyticsToken.current.token)
            if (!data.status || !data.data) {
                setAnalyticsData(p => {
                    return {
                        ...p,
                        isFeedbackCountAnalyticsLoading: false,
                        isFeedbackCountAnalyticsLoaded: false,
                        isError: true,
                        errorMessage: data.message,
                    }
                })
            } else {
                setAnalyticsData(p => {
                    return {
                        ...p,
                        isFeedbackCountAnalyticsLoading: false,
                        isFeedbackCountAnalyticsLoaded: true,
                        isError: true,
                        errorMessage: "Feedback Analytics Updated Successfully.",
                        feedbackCountAnalytics: data.data,
                        feedbackCountAnalyticsConf: `${hotelId}${startDay}${days}${isTable}`
                    }
                })
            }
        } catch (err) {
            console.log(err)
        }


    }, [
        authContext.authToken,
    ])


    const loadRevenueAnalytics = useCallback(async (hotelId, startDay, days, isTable, conf) => {
        if (conf === `${hotelId}${startDay}${days}${isTable}`) {
            return
        }
        if (revenueAnalyticsToken.current && revenueAnalyticsToken.current.token) {
            revenueAnalyticsToken.current.cancel()
        }
        revenueAnalyticsToken.current = axios.CancelToken.source()
        setAnalyticsData(p => { return { ...p, isRevenueAnalyticsLoading: true, revenueAnalytics: {} } })

        try {
            const data = await GetAnalytics(Constant.getRevenueAnalyticsRoutes, hotelId, authContext.authToken, startDay, days, isTable, revenueAnalyticsToken.current.token)
            if (!data.status || !data.data) {
                setAnalyticsData(p => {
                    return {
                        ...p,
                        isRevenueAnalyticsLoading: false,
                        isRevenueAnalyticsLoaded: false,
                        isError: true,
                        errorMessage: data.message,
                    }
                })
            } else {
                setAnalyticsData(p => {
                    return {
                        ...p,
                        isRevenueAnalyticsLoading: false,
                        isRevenueAnalyticsLoaded: true,
                        isError: true,
                        errorMessage: "Revenue Analytics Updated Successfully.",
                        revenueAnalytics: data.data,
                        revenueAnalyticsConf: `${hotelId}${startDay}${days}${isTable}`
                    }
                })
            }
        } catch (err) {
            console.log(err)
        }


    }, [
        authContext.authToken,
    ])



    const loadCollectionAnalytics = useCallback(async (hotelId, startDay, days, isTable, conf) => {
        if (conf === `${hotelId}${startDay}${days}${isTable}`) {
            return
        }
        if (collectionAnalyticsToken.current && collectionAnalyticsToken.current.token) {
            collectionAnalyticsToken.current.cancel()
        }
        collectionAnalyticsToken.current = axios.CancelToken.source()
        setAnalyticsData(p => { return { ...p, isCollectionAnalyticsLoading: true, collectionAnalytics: {} } })

        try {
            const data = await GetAnalytics(Constant.getCollectionAnalyticsRoutes, hotelId, authContext.authToken, startDay, days, isTable, collectionAnalyticsToken.current.token)
            if (!data.status || !data.data) {
                setAnalyticsData(p => {
                    return {
                        ...p,
                        isCollectionAnalyticsLoading: false,
                        isCollectionAnalyticsLoaded: false,
                        isError: true,
                        errorMessage: data.message,
                    }
                })
            } else {
                setAnalyticsData(p => {
                    return {
                        ...p,
                        isCollectionAnalyticsLoading: false,
                        isCollectionAnalyticsLoaded: true,
                        isError: true,
                        errorMessage: "Collection Analytics Updated Successfully.",
                        collectionAnalytics: data.data,
                        collectionAnalyticsConf: `${hotelId}${startDay}${days}${isTable}`
                    }
                })
            }
        } catch (err) {
            console.log(err)
        }


    }, [
        authContext.authToken,
    ])


    const loadSalesAnalytics = useCallback(async (hotelId, startDay, days, isTable, conf) => {
        if (conf === `${hotelId}${startDay}${days}${isTable}`) {
            return
        }
        if (accountSalesAnalyticsToken.current && accountSalesAnalyticsToken.current.token) {
            accountSalesAnalyticsToken.current.cancel()
        }
        accountSalesAnalyticsToken.current = axios.CancelToken.source()
        setAnalyticsData(p => { return { ...p, isAccountSalesAnalyticsLoading: true, accountSalesAnalytics: {} } })

        try {
            const data = await GetAnalytics(Constant.getAccountSalesAnalyticsRoutes, hotelId, authContext.authToken, startDay, days, isTable, accountSalesAnalyticsToken.current.token)
            if (!data.status || !data.data) {
                setAnalyticsData(p => {
                    return {
                        ...p,
                        isAccountSalesAnalyticsLoading: false,
                        isAccountSalesAnalyticsLoaded: false,
                        isError: true,
                        errorMessage: data.message,
                    }
                })
            } else {
                setAnalyticsData(p => {
                    return {
                        ...p,
                        isAccountSalesAnalyticsLoading: false,
                        isAccountSalesAnalyticsLoaded: true,
                        isError: true,
                        errorMessage: "Sales Analytics Updated Successfully.",
                        accountSalesAnalytics: data.data,
                        accountSalesAnalyticsConf: `${hotelId}${startDay}${days}${isTable}`
                    }
                })
            }
        } catch (err) {
            console.log(err)
        }


    }, [
        authContext.authToken,
    ])


    const loadFeedbackAnalytics = useCallback(async (hotelId, startDay, days, isTable, conf) => {
        if (conf === `${hotelId}${startDay}${days}${isTable}`) {
            return
        }
        if (feedbackAnalyticsToken.current && feedbackAnalyticsToken.current.token) {
            feedbackAnalyticsToken.current.cancel()
        }
        feedbackAnalyticsToken.current = axios.CancelToken.source()
        setAnalyticsData(p => { return { ...p, isFeedbackAnalyticsLoading: true, feedbackAnalytics: {} } })

        try {
            const data = await GetAnalytics(Constant.getFeedbackAnalyticsRoutes, hotelId, authContext.authToken, startDay, days, isTable, feedbackAnalyticsToken.current.token)
            if (!data.status || !data.data) {
                setAnalyticsData(p => {
                    return {
                        ...p,
                        isFeedbackAnalyticsLoading: false,
                        isFeedbackAnalyticsLoaded: false,
                        isError: true,
                        errorMessage: data.message,
                    }
                })
            } else {
                console.log(data.data)
                setAnalyticsData(p => {
                    return {
                        ...p,
                        isFeedbackAnalyticsLoading: false,
                        isFeedbackAnalyticsLoaded: true,
                        isError: true,
                        errorMessage: "Feedback Analytics Updated Successfully.",
                        feedbackAnalytics: data.data,
                        feedbackAnalyticsConf: `${hotelId}${startDay}${days}${isTable}`
                    }
                })
            }
        } catch (err) {
            console.log(err)
        }


    }, [
        authContext.authToken,
    ])

    const loadBookingSummary = useCallback(async (hotelId, startDay, days, isTable, conf) => {
        if (conf === `${hotelId}${startDay}${days}${isTable}`) {
            return
        }
        if (bookingSummaryAnalyticsToken.current && bookingSummaryAnalyticsToken.current.token) {
            bookingSummaryAnalyticsToken.current.cancel()
        }
        bookingSummaryAnalyticsToken.current = axios.CancelToken.source()
        setAnalyticsData(p => { return { ...p, isBookingSummaryAnalyticsLoading: true, bookingSummaryAnalytics: {} } })

        try {
            const data = await GetAnalytics(Constant.getBookingSummaryAnalyticsRoutes, hotelId, authContext.authToken, startDay, days, isTable, bookingSummaryAnalyticsToken.current.token)
            if (!data.status || !data.data) {
                setAnalyticsData(p => {
                    return {
                        ...p,
                        isBookingSummaryAnalyticsLoading: false,
                        isBookingSummaryAnalyticsLoaded: false,
                        isError: true,
                        errorMessage: data.message,
                    }
                })
            } else {
                setAnalyticsData(p => {
                    return {
                        ...p,
                        isBookingSummaryAnalyticsLoading: false,
                        isBookingSummaryAnalyticsLoaded: true,
                        isError: true,
                        errorMessage: "Booking Summary Analytics Updated Successfully.",
                        bookingSummaryAnalytics: data.data,
                        bookingSummaryAnalyticsConf: `${hotelId}${startDay}${days}${isTable}`
                    }
                })
            }
        } catch (err) {
            console.log(err)
        }


    }, [
        authContext.authToken,
    ])



    useEffect(() => {
        if (selRoute === allRoutes[startIndex - 1] && hotelContext.selctedHotelId !== undefined) {
            loadDepUserAnalytics(
                hotelContext.selctedHotelId,
                hotelContext.startDay,
                hotelContext.days,
                analyticsData.isTabularData,
                analyticsData.depUserAnalyticsConf
            )
        }
    }, [
        selRoute,
        hotelContext.selctedHotelId,
        hotelContext.startDay,
        hotelContext.days,
        loadDepUserAnalytics,
        analyticsData.isTabularData,
        analyticsData.depUserAnalyticsConf
    ])

    useEffect(() => {
        if (selRoute === allRoutes[startIndex] && hotelContext.selctedHotelId !== undefined) {
            loadUserAnalytics(
                hotelContext.selctedHotelId,
                hotelContext.startDay,
                hotelContext.days,
                analyticsData.isTabularData,
                analyticsData.userAnalyticsConf
            )
        }
    }, [
        selRoute,
        hotelContext.selctedHotelId,
        hotelContext.startDay,
        hotelContext.days,
        loadUserAnalytics,
        analyticsData.isTabularData,
        analyticsData.userAnalyticsConf
    ])



    useEffect(() => {
        if (selRoute === allRoutes[startIndex + 1] && hotelContext.selctedHotelId !== undefined) {
            loadBookingSalesAnalytics(
                hotelContext.selctedHotelId,
                hotelContext.startDay,
                hotelContext.days,
                analyticsData.isTabularData,
                analyticsData.bookingSalesConf
            )
        }
    }, [
        selRoute,
        hotelContext.selctedHotelId,
        hotelContext.startDay,
        hotelContext.days,
        loadBookingSalesAnalytics,
        analyticsData.isTabularData,
        analyticsData.bookingSalesConf
    ])

    useEffect(() => {
        if (selRoute === allRoutes[startIndex + 2] && hotelContext.selctedHotelId !== undefined) {
            loadCheckInAnalytics(
                hotelContext.selctedHotelId,
                hotelContext.startDay,
                hotelContext.days,
                analyticsData.isTabularData,
                analyticsData.checkInAnalyticsConf
            )
        }
    }, [
        selRoute,
        hotelContext.selctedHotelId,
        hotelContext.startDay,
        hotelContext.days,
        loadCheckInAnalytics,
        analyticsData.isTabularData,
        analyticsData.checkInAnalyticsConf
    ])

    useEffect(() => {
        if (selRoute === allRoutes[startIndex + 3] && hotelContext.selctedHotelId !== undefined) {
            loadCheckOutAnalytics(
                hotelContext.selctedHotelId,
                hotelContext.startDay,
                hotelContext.days,
                analyticsData.isTabularData,
                analyticsData.checkOutAnalyticsConf
            )
        }
    }, [
        selRoute,
        hotelContext.selctedHotelId,
        hotelContext.startDay,
        hotelContext.days,
        loadCheckOutAnalytics,
        analyticsData.isTabularData,
        analyticsData.checkOutAnalyticsConf
    ])

    useEffect(() => {
        if (selRoute === allRoutes[startIndex + 4] && hotelContext.selctedHotelId !== undefined) {
            loadFeedbackCountAnalytics(
                hotelContext.selctedHotelId,
                hotelContext.startDay,
                hotelContext.days,
                analyticsData.isTabularData,
                analyticsData.feedbackCountAnalyticsConf
            )
        }
    }, [
        selRoute,
        hotelContext.selctedHotelId,
        hotelContext.startDay,
        hotelContext.days,
        loadFeedbackCountAnalytics,
        analyticsData.isTabularData,
        analyticsData.feedbackCountAnalyticsConf
    ])

    useEffect(() => {
        if (selRoute === allRoutes[startIndex + 5] && hotelContext.selctedHotelId !== undefined) {
            loadRevenueAnalytics(
                hotelContext.selctedHotelId,
                hotelContext.startDay,
                hotelContext.days,
                analyticsData.isTabularData,
                analyticsData.revenueAnalyticsConf
            )
        }
    }, [
        selRoute,
        hotelContext.selctedHotelId,
        hotelContext.startDay,
        hotelContext.days,
        loadRevenueAnalytics,
        analyticsData.isTabularData,
        analyticsData.revenueAnalyticsConf
    ])

    useEffect(() => {
        if (selRoute === allRoutes[startIndex + 6] && hotelContext.selctedHotelId !== undefined) {
            loadCollectionAnalytics(
                hotelContext.selctedHotelId,
                hotelContext.startDay,
                hotelContext.days,
                analyticsData.isTabularData,
                analyticsData.collectionAnalyticsConf
            )
        }
    }, [
        selRoute,
        hotelContext.selctedHotelId,
        hotelContext.startDay,
        hotelContext.days,
        loadCollectionAnalytics,
        analyticsData.isTabularData,
        analyticsData.collectionAnalyticsConf
    ])

    useEffect(() => {
        if (selRoute === allRoutes[startIndex + 7] && hotelContext.selctedHotelId !== undefined) {
            loadSalesAnalytics(
                hotelContext.selctedHotelId,
                hotelContext.startDay,
                hotelContext.days,
                analyticsData.isTabularData,
                analyticsData.accountSalesAnalyticsConf
            )
        }
    }, [
        selRoute,
        hotelContext.selctedHotelId,
        hotelContext.startDay,
        hotelContext.days,
        loadSalesAnalytics,
        analyticsData.isTabularData,
        analyticsData.accountSalesAnalyticsConf
    ])



    useEffect(() => {
        if (selRoute === allRoutes[startIndex + 8] && hotelContext.selctedHotelId !== undefined) {
            loadFeedbackAnalytics(
                hotelContext.selctedHotelId,
                hotelContext.startDay,
                hotelContext.days,
                analyticsData.isTabularData,
                analyticsData.feedbackAnalyticsConf
            )
        }
    }, [
        selRoute,
        hotelContext.selctedHotelId,
        hotelContext.startDay,
        hotelContext.days,
        loadFeedbackAnalytics,
        analyticsData.isTabularData,
        analyticsData.feedbackAnalyticsConf
    ])


    useEffect(() => {
        if (selRoute === allRoutes[startIndex + 9] && hotelContext.selctedHotelId !== undefined) {
            loadBookingSummary(
                hotelContext.selctedHotelId,
                hotelContext.startDay,
                hotelContext.days,
                analyticsData.isTabularData,
                analyticsData.bookingSummaryAnalyticsConf
            )
        }
    }, [
        selRoute,
        hotelContext.selctedHotelId,
        hotelContext.startDay,
        hotelContext.days,
        loadBookingSummary,
        analyticsData.isTabularData,
        analyticsData.bookingSummaryAnalyticsConf
    ])

    const value = {
        ...analyticsData,
        clearMessage,
        loadBookingSalesAnalytics,
        onToggleTabularData,
        onToggleBar,
        onToggleHotel,
        onToggleDate,
        onToggleAverage,
        onTogglePercentage
    }

    return (
        <AnalyticsContext.Provider value={value}>
            {children}
        </AnalyticsContext.Provider>
    )
}

export default NAnalyticsContextProvider