import moment from "moment/moment";
import { createContext, useCallback, useContext, useEffect, useRef, useState } from "react";
import GetBookingsWithoutDate from "../utils/bookings/GetBookingsWithoutDate";
import Constant from "../constant/Constant";
import { HotelContext } from "./hotel";
import { AuthContext } from "./auth";
import GetBookingsWithDate from "../utils/bookings/GetBookingsWithDate";
import axios from "axios"
import allRoutes from "../pages/routes";

export const BookingContext = createContext({
    upcomingBookings: [],
    isUpcomingLoading: false,
    upcomingConf: "",


    inHouseBookings: [],
    isInHouseLoading: false,
    inHouseConf: "",


    completeBookings: [],
    isCompleteLoading: false,
    completedConf: "",

    actionNeededBookings: [],
    isActionNeededLoading: false,
    actionNeededConf: "",


    pendingBookings: [],
    isPendingBookingLoading: false,
    pendingConf: "",


    selectedBooking: null,

    isError: false,
    errorMessage: "",


    selectedDate: "",


    clearMessage: () => { },
    updateDate: () => { },
})

const BookingContextProvider = ({ children, selRoute }) => {


    const upcomingCancelToken = useRef(null)
    const inHouseCancelToken = useRef(null)
    const completedBookingToken = useRef(null)
    const needActionCancelToken = useRef(null)
    const pendingCancelToken = useRef(null)

    const hotelContext = useContext(HotelContext)
    const authContext = useContext(AuthContext)

    const [bookingDataState, setBookingDataState] = useState({

        upcomingBookings: [],
        isUpcomingLoading: false,
        upcomingConf: undefined,

        inHouseBookings: [],
        isInHouseLoading: false,
        inHouseConf: undefined,

        completeBookings: [],
        isCompleteLoading: false,
        completedConf: undefined,

        actionNeededBookings: [],
        isActionNeededLoading: false,
        actionNeededConf: undefined,

        pendingBookings: [],
        isPendingBookingLoading: false,
        pendingConf: undefined,

        selectedBooking: null,
        isError: false,
        errorMessage: "",
        selectedDate: moment().format("YYYY-MM-DD")
    })

    const loadUpcomingBookings = useCallback(async (hotelId, token, conf) => {

        if (conf === `${hotelId}`) {
            return
        }
        if (upcomingCancelToken.current && upcomingCancelToken.current.token) {
            upcomingCancelToken.current.cancel()
        }
        upcomingCancelToken.current = axios.CancelToken.source()

        setBookingDataState(p => { return { ...p, upcomingBookings: [], isUpcomingLoading: true } })
        const resp = await GetBookingsWithoutDate(Constant.getUpcomingBookings, token, hotelId, upcomingCancelToken.current.token)
        if (!resp.status) {
            setBookingDataState((p) => { return { ...p, isError: true, errorMessage: resp.message, isUpcomingLoading: false } })
            return
        }
        setBookingDataState(p => {
            return {
                ...p,
                upcomingBookings: resp.data,
                isError: true,
                errorMessage: "Bookings Updated Successfully.",
                isUpcomingLoading: false,
                upcomingConf: `${hotelId}`
            }
        })
    }, [])

    const loadInHouseBookings = useCallback(async (hotelId, token, conf) => {
        if (conf === `${hotelId}`) {
            return
        }
        if (inHouseCancelToken.current && inHouseCancelToken.current.token) {
            inHouseCancelToken.current.cancel()
        }
        inHouseCancelToken.current = axios.CancelToken.source()

        setBookingDataState(p => { return { ...p, inHouseBookings: [], isInHouseLoading: true } })
        const resp = await GetBookingsWithoutDate(Constant.getInHouseBookings, token, hotelId, inHouseCancelToken.current.token)
        if (!resp.status) {
            setBookingDataState((p) => { return { ...p, isError: true, errorMessage: resp.message, isInHouseLoading: false } })
            return
        }
        setBookingDataState(p => {
            return {
                ...p,
                inHouseBookings: resp.data,
                isError: true,
                errorMessage: "Bookings Updated Successfully.",
                isInHouseLoading: false,
                inHouseConf: `${hotelId}`
            }
        })
    }, [])

    const loadNeedActionBookings = useCallback(async (hotelId, token, conf) => {
        if (conf === `${hotelId}`) {
            return
        }
        if (needActionCancelToken.current && needActionCancelToken.current.token) {
            needActionCancelToken.current.cancel()
        }
        needActionCancelToken.current = axios.CancelToken.source()

        setBookingDataState(p => { return { ...p, actionNeededBookings: [], isActionNeededLoading: true } })
        const resp = await GetBookingsWithoutDate(Constant.getNeedActionBookings, token, hotelId, needActionCancelToken.current.token)
        if (!resp.status) {
            setBookingDataState((p) => { return { ...p, isError: true, errorMessage: resp.message, isActionNeededLoading: false } })
            return
        }
        setBookingDataState(p => {
            return {
                ...p,
                actionNeededBookings: resp.data,
                isError: true,
                errorMessage: "Bookings Updated Successfully.",
                isActionNeededLoading: false,
                actionNeededConf: `${hotelId}`
            }
        })
    }, [])

    const loadPendingBookings = useCallback(async (hotelId, token, conf) => {
        if (conf === `${hotelId}`) {
            return
        }
        if (pendingCancelToken.current && pendingCancelToken.current.token) {
            pendingCancelToken.current.cancel()
        }
        pendingCancelToken.current = axios.CancelToken.source()

        setBookingDataState(p => { return { ...p, pendingBookings: [], isPendingBookingLoading: true } })
        const resp = await GetBookingsWithoutDate(Constant.getPendingPaymentBookings, token, hotelId, pendingCancelToken.current.token)
        if (!resp.status) {
            setBookingDataState((p) => { return { ...p, isError: true, errorMessage: resp.message, isPendingBookingLoading: false } })
            return
        }
        setBookingDataState(p => {
            return {
                ...p,
                pendingBookings: resp.data.filter(book => {
                    return (book.amount - (book.collections.reduce((acc, cur) => { return acc + cur.total }, 0))) > 0
                }),
                isError: true,
                errorMessage: "Bookings Updated Successfully.",
                isPendingBookingLoading: false,
                pendingConf: `${hotelId}`
            }
        })
    }, [])

    const loadCompleteBookings = useCallback(async (hotelId, token, selectedDate, conf) => {
        if (conf === `${hotelId}${selectedDate}`) {
            return
        }
        if (completedBookingToken.current && completedBookingToken.current.token) {
            completedBookingToken.current.cancel()
        }
        completedBookingToken.current = axios.CancelToken.source()

        setBookingDataState(p => { return { ...p, completeBookings: [], isCompleteLoading: true } })
        const date = moment(selectedDate, "YYYY-MM-DD").format("DD-MM-YYYY")
        const resp = await GetBookingsWithDate(Constant.getCompletedBookings, token, hotelId, date, completedBookingToken.current.token)
        if (!resp.status) {
            setBookingDataState((p) => { return { ...p, isError: true, errorMessage: resp.message, isCompleteLoading: false } })
            return
        }
        setBookingDataState(p => {
            return {
                ...p,
                completeBookings: resp.data,
                isError: true,
                errorMessage: "Bookings Updated Successfully.",
                isCompleteLoading: false,
                completedConf: `${hotelId}${selectedDate}`
            }
        })
    }, [])

    useEffect(() => {
        if (hotelContext.selctedHotelId !== undefined && bookingDataState.selectedDate && selRoute === allRoutes[2]) {
            loadCompleteBookings(hotelContext.selctedHotelId, authContext.authToken, bookingDataState.selectedDate, bookingDataState.completedConf)
        }
    }, [
        hotelContext.selctedHotelId,
        authContext.authToken,
        bookingDataState.selectedDate,
        loadCompleteBookings,
        selRoute,
        bookingDataState.completedConf
    ])

    useEffect(() => {
        if (selRoute === allRoutes[0] && hotelContext.selctedHotelId !== undefined) {
            loadUpcomingBookings(hotelContext.selctedHotelId, authContext.authToken, bookingDataState.upcomingConf)
        }
    },
        [
            hotelContext.selctedHotelId,
            authContext.authToken,
            bookingDataState.upcomingConf,
            loadUpcomingBookings,
            selRoute
        ]
    )


    useEffect(() => {
        if ((selRoute === allRoutes[1] || selRoute === allRoutes[5]) && hotelContext.selctedHotelId !== undefined) {
            loadInHouseBookings(hotelContext.selctedHotelId, authContext.authToken, bookingDataState.inHouseConf)
        }
    },
        [
            hotelContext.selctedHotelId,
            authContext.authToken,
            bookingDataState.inHouseConf,
            loadInHouseBookings,
            selRoute
        ]
    )

    useEffect(() => {
        if (selRoute === allRoutes[3] && hotelContext.selctedHotelId !== undefined) {
            loadNeedActionBookings(hotelContext.selctedHotelId, authContext.authToken, bookingDataState.actionNeededConf)
        }
    },
        [
            hotelContext.selctedHotelId,
            authContext.authToken,
            bookingDataState.actionNeededConf,
            loadNeedActionBookings,
            selRoute
        ]
    )

    useEffect(() => {
        if (selRoute === allRoutes[4] && hotelContext.selctedHotelId !== undefined) {
            loadPendingBookings(hotelContext.selctedHotelId, authContext.authToken, bookingDataState.pendingConf)
        }
    },
        [
            hotelContext.selctedHotelId,
            authContext.authToken,
            bookingDataState.pendingConf,
            loadPendingBookings,
            selRoute
        ]
    )

    const clearMessage = () => {
        setBookingDataState(p => { return { ...p, isError: false, errorMessage: "" } })
    }
    const updateDate = (date) => {
        setBookingDataState(p => { return { ...p, selectedDate: moment(date.$d).format("YYYY-MM-DD") } })
    }
    const value = {
        ...bookingDataState,
        clearMessage,
        updateDate
    }
    return (
        <BookingContext.Provider value={value}>
            {children}
        </BookingContext.Provider>
    )
}

export default BookingContextProvider