import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  BarElement,
} from "chart.js";
import { Line, Bar } from "react-chartjs-2";
import { Stack } from "@mui/material";
import HotelSwitch from "../components/HotelSwitch";
import Graph from "../util/graph";
import HotelGroupSwitchContent from "../components/HotelGroupSwitchContent";
import moment from "moment";
import { useEffect, useState } from "react";
import HotelChooser from "../components/HotelChooser";
import userAnalyticsLabelFinder from "../util/userAnalyticsLabelFinder";
import userAnalyticsDataFinder from "./userAnalyticsDataFinder";
import saleGraphProcessor from "../booking/saleGraphProcessor";

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  BarElement,
  Title,
  Tooltip,
  Legend
);

const UserAnalyticsGraph = ({
  bookingAnalytics,
  title,
  allOptions,
  isTablularData,
  onToggleTabularData,
  isBar,
  isDate,
  isPercentage,
  onToggleBar,
  onToggleDate,
  onTogglePercentage,
}) => {
  let allDates = Object.keys(bookingAnalytics);
  const [options, setOptions] = useState(allOptions);

  const [data, setData] = useState({});

  useEffect(() => {
    setOptions(allOptions);
  }, [allOptions]);


  useEffect(() => {
    const allDates = Object.keys(bookingAnalytics);
    
    let datasets = userAnalyticsDataFinder(
      bookingAnalytics,
      allDates,
      isDate,
      options,
      isBar,
      isTablularData
    );
    let labels = userAnalyticsLabelFinder(
        allDates.map((dt) => moment(dt, "DD-MM-YYYY").format("DD-MMM")),
        isDate
    )
    datasets = saleGraphProcessor(isPercentage, datasets, labels.length);
    setData({ labels, datasets });
  }, [
    bookingAnalytics,
    isDate,
    options,
    isBar,
    isTablularData,
    isPercentage,
  ]);

  return (
    <div style={{ margin: "20px", paddingTop: "30px", position: "relative" }}>
      <Stack
        style={{
          position: "absolute",
          top: "-10px",
          right: "10px",
        }}
        direction="row"
        spacing={1}
      >
        <HotelGroupSwitchContent
          content={
            <>
              <HotelSwitch
                isChecked={!isTablularData}
                label={"Data"}
                onChange={(v) => {
                  onToggleTabularData();
                }}
              />

              <HotelSwitch
                isChecked={isBar}
                label={"Bar"}
                onChange={(v) => {
                  onToggleBar();
                }}
              />

              {allDates.length > 1 ? (
                <HotelSwitch
                  isChecked={isDate}
                  label={"Date"}
                  onChange={(v) => {
                    onToggleDate();
                  }}
                />
              ) : (
                <></>
              )}

              <HotelSwitch
                isChecked={isPercentage}
                label={"Per(%)"}
                onChange={(v) => {
                  onTogglePercentage();
                }}
              />
            </>
          }
        />

        <HotelChooser
          hotels={allOptions}
          selectedHotels={options}
          onHotelsChange={(o) => {
            setOptions(o);
          }}
          width="80px"
        />
      </Stack>

      {data &&
        data.datasets &&
        data.datasets.length > 0 &&
        (isBar ? (
          <Bar
            style={{ marginTop: "20px" }}
            options={Graph.BarOptions(
              title,
              true,
              data.datasets.reduce((acc, cur) => {
                if (!acc[cur.stack]) {
                  let nAcc = { ...acc };
                  nAcc[cur.stack] = [cur];
                  return nAcc;
                } else {
                  let nAcc = { ...acc };
                  nAcc[cur.stack] = [...nAcc[cur.stack], cur];
                  return nAcc;
                }
              }, {})
            )}
            data={data}
          />
        ) : (
          <Line
            style={{ marginTop: "20px" }}
            options={Graph.Options(title)}
            data={data}
          />
        ))}
    </div>
  );
};

export default UserAnalyticsGraph;
