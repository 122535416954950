import moment from "moment";
import BookingList from "../components/BookingList";
import NoBookings from "../components/NoBookings";
import LoadingBookings from "../../common/LoadingBookings";

const InHouseBookings = ({ bookings, isLoading }) => {
  const todayCheckInTime = moment()
    .hour(11)
    .minutes(0)
    .second(0)
    .millisecond(0)
    .unix();
  const oneDay = 24 * 60 * 60;


  let departingTodayBookings = bookings.filter((book) => {
    let diff = moment(book.actualCheckOut).unix() - todayCheckInTime;
    return diff === 0;
  });

  let departingTomorrowBookings = bookings.filter((book) => {
    let diff = moment(book.actualCheckOut).unix() - todayCheckInTime;
    return diff > 0 && diff <= oneDay;
  });

  let departingLaterBookings = bookings.filter((book) => {
    let diff = moment(book.actualCheckOut).unix() - todayCheckInTime;
    return diff > oneDay;
  });

  return (
    <>
      {isLoading && <LoadingBookings />}
      <BookingList name="Departing Today" bookings={departingTodayBookings} />
      <BookingList
        name="Departing Tomorrow"
        bookings={departingTomorrowBookings}
      />
      <BookingList name="Departing Later" bookings={departingLaterBookings} />
      {bookings.length === 0 && <NoBookings />}
    </>
  );
};

export default InHouseBookings;
