import { IconButton, Stack, Typography } from "@mui/material";

const Expenser = ({ expenser, disableEmail}) => {
  return (
    <Stack
      sx={{
        borderRadius: "5px",
        border: "1px solid #262626",
        margin: "10px 0px",
        padding: "7px 10px",
        transition: "border 1s",
        ":hover": {
          boxShadow: "0px 10px 15px -3px rgba(0,0,0,0.1)",
        },
        width: "min-content",
        cursor: "pointer",
      }}
      direction="row"
      spacing={2}
    >
      <Stack sx={{ width: "50px" }}>
        <IconButton
          onClick={() => {
            window.location = `tel:+${expenser.phoneNumber}`;
          }}
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            height="24"
            viewBox="0 -960 960 960"
            width="28"
          >
            <path d="M760-480q0-117-81.5-198.5T480-760v-80q75 0 140.5 28.5t114 77q48.5 48.5 77 114T840-480h-80Zm-160 0q0-50-35-85t-85-35v-80q83 0 141.5 58.5T680-480h-80Zm198 360q-125 0-247-54.5T329-329Q229-429 174.5-551T120-798q0-18 12-30t30-12h162q14 0 25 9.5t13 22.5l26 140q2 16-1 27t-11 19l-97 98q20 37 47.5 71.5T387-386q31 31 65 57.5t72 48.5l94-94q9-9 23.5-13.5T670-390l138 28q14 4 23 14.5t9 23.5v162q0 18-12 30t-30 12ZM241-600l66-66-17-94h-89q5 41 14 81t26 79Zm358 358q39 17 79.5 27t81.5 13v-88l-94-19-67 67ZM241-600Zm358 358Z" />
          </svg>
        </IconButton>
      </Stack>

      <Stack direction="column" spacing={0} sx={{ minWidth: "200px" }}>
        <Typography
          variant="h6"
          color="primary.main"
          sx={{ fontSize: "13px", textOverflow: "ellipsis" }}
        >
          {`${expenser.name[0].toUpperCase()}${expenser.name.slice(1)}`}
        </Typography>
        <Typography variant="h6" color="primary.main" sx={{ fontSize: "11px" }}>
          #{expenser.id}
        </Typography>
      </Stack>

      {!disableEmail && (
        <Stack sx={{ minWidth: "140px" }}>
          <Typography
            variant="h6"
            color="primary.main"
            sx={{ fontSize: "11px" }}
          >
            {expenser.email}
          </Typography>
        </Stack>
      )}

      <Stack sx={{ width: "190px" }}>
        <Typography variant="h6" color="primary.main" sx={{ fontSize: "13px" }}>
          {expenser.entityNames.join(",")}
        </Typography>
      </Stack>
    </Stack>
  );
};

export default Expenser;
