import moment from "moment-timezone";
import BookingList from "../components/BookingList";
import NoBookings from "../components/NoBookings";
import LoadingBookings from "../../common/LoadingBookings";

const UpcomingBookings = ({ bookings,isLoading }) => {
  const todayCheckInDate = moment()
    .hour(0)
    .minutes(0)
    .second(0)
    .millisecond(0)
    .unix();
  const oneDayDiff = 24 * 60 * 60;

  const upcomingYesterdayBookings = bookings.filter((book) => {
    let diff =
      moment.tz(book.actualCheckIn, book.timezone).unix() - todayCheckInDate;
    return diff <= 0;
  });

  const upcomingTodayBookings = bookings.filter((book) => {
    let diff =
      moment.tz(book.actualCheckIn, book.timezone).unix() - todayCheckInDate;
    return diff >= 1 && diff < oneDayDiff;
  });

  const upcomingTomorrowBookings = bookings.filter((book) => {
    let diff =
      moment.tz(book.actualCheckIn, book.timezone).unix() - todayCheckInDate;
    return diff >= oneDayDiff && diff < oneDayDiff * 2;
  });

  const upcomingLaterBookings = bookings.filter((book) => {
    let diff =
      moment.tz(book.actualCheckIn, book.timezone).unix() - todayCheckInDate;
    return diff >= oneDayDiff * 2;
  });

  return (
    <>
      {isLoading && <LoadingBookings />}
      <BookingList
        name="Arriving Yesterday"
        bookings={upcomingYesterdayBookings}
      />
      <BookingList name="Arriving Today" bookings={upcomingTodayBookings} />
      <BookingList
        name="Arriving Tommorow"
        bookings={upcomingTomorrowBookings}
      />
      <BookingList name="Arriving Later" bookings={upcomingLaterBookings} />
      {bookings.length === 0 && <NoBookings />}
    </>
  );
};

export default UpcomingBookings;
