import { Stack, Typography,IconButton } from "@mui/material";
import EditIcon from '@mui/icons-material/Edit';

const CompanyViewer = ({ company, onClick ,isEdit,onEditClick,isSelected}) => {

  return (
    <Stack
      sx={{
        borderRadius: "5px",
        border: "1px solid #262626",
        margin: "10px 0px",
        padding: "7px 10px",
        transition: "border 1s",
        ":hover": {
          boxShadow: "0px 10px 15px -3px rgba(0,0,0,0.1)",
        },
        cursor: "pointer",
        background:isSelected ? "#d6d4d4" : "inherit"
      }}
      direction="row"
      spacing={2}
      onClick={() => {
        onClick();
      }}
    >
      <Stack sx={{ width: "100px" }}>
        <Typography variant="h6" color="primary.main" sx={{ fontSize: "13px" }}>
          {company.name}
        </Typography>
      </Stack>

      <Stack sx={{ width: "100px" }}>
        <Typography variant="h6" color="primary.main" sx={{ fontSize: "13px" }}>
          {company.parentCompany?.name}
        </Typography>
      </Stack>



      <Stack sx={{ flexGrow:1 }}>
        <Typography variant="h6" color="primary.main" sx={{ fontSize: "13px" }}>
          {Object.entries(company.positions).map(a => `${a[0]}(${a[1].departments.join(",")}) - ${a[1].priority}`).join("  ,  ")}
        </Typography>
      </Stack>
      {
        isEdit && (
          <IconButton onClick={() => {onEditClick()}} >
            <EditIcon />
          </IconButton>
        )
      }
    </Stack>
  );
};

export default CompanyViewer;
