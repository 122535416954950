import { useState } from "react";
import BalanceStackViewer from "./BalanceStackViewer";
import { Stack, Button, Typography } from "@mui/material";
import OxyOsTheme from "../../../../theme/theme";
import AddBalanceExpense from "./AddBalanceExpense";
import ExpenseViewer from "./ExpenseViewer";
import { Add } from "@mui/icons-material";
import moment from "moment";

const BalanceSheetShowComponent = ({
  date,
  openingCash,
  openingBank,
  openingOta,
  // cashLoanToOxyCorporations,
  // bankLoanToOxyCorporations,
  // revenueToKotakBank,
  expensers,
  expenses,
  transctions,
  // onCashLoanUpdate,
  // onBankLoanUpdate,
  // onKotakUpdate,
  onAddExpense,
  onUpdateExpenses,
  opening,
  category,
  sDate,
  hotels,
  hotelId,
}) => {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <>
      <Stack
        sx={{
          border: "1px solid #262626",
          margin: "15px 0px",
          borderRadius: "15px",
          padding: "15px",
        }}
      >
        <Stack
          direction={"row"}
          spacing={10}
          sx={{
            alignItems: "center",
          }}
        >
          <Typography variant="h6" fontSize={"16px"}>
            {date}
          </Typography>
          <BalanceStackViewer
            label={"Cash"}
            opening={openingCash}
            value={
              transctions
                .filter((tr) => tr.sourceRef === "Cash")
                .reduce((acc, cur) => {
                  return acc + cur.amount;
                }, 0) + opening.Cash
            }
          />
          <BalanceStackViewer
            label={"Bank"}
            opening={openingBank}
            value={
              transctions
                .filter((tr) => tr.sourceRef === "Bank")
                .reduce((acc, cur) => {
                  return acc + cur.amount;
                }, 0) + opening.Bank
            }
          />
          <BalanceStackViewer
            label={"Ota"}
            opening={openingOta}
            value={
              transctions
                .filter((tr) => tr.sourceRef === "Ota")
                .reduce((acc, cur) => {
                  return acc + cur.amount;
                }, 0) + opening.Ota
            }
          />

          <Button
            variant="outlined"
            size="large"
            sx={{
              backgroundColor: "#262626",
              borderRadius: "30px",
              padding: "10px 15px",
              margin: "20px auto",
              color: OxyOsTheme.palette.background.main,
              "&:hover": {
                backgroundColor: OxyOsTheme.palette.primary.main,
                color: OxyOsTheme.palette.background.main,
                opacity: 0.6,
              },
              display: "flex",
              alignItems: "center",
            }}
            onClick={() => {
              setIsOpen((p) => !p);
            }}
          >
            <Add />
          </Button>
        </Stack>

        {expenses.map((exp, index) => {
          return (
            <ExpenseViewer
              expense={exp}
              onDelete={() => {
                let exp = [...expenses];
                exp.splice(index, 1);
                onUpdateExpenses(exp);
              }}
              expensers={expensers}
              hotels={hotels}
            />
          );
        })}

        {isOpen && (
          <AddBalanceExpense
            isOpen={isOpen}
            onClose={() => {
              setIsOpen((p) => !p);
            }}
            expensers={expensers}
            onAddExpense={(data) => {
              onAddExpense(data);
              setIsOpen(false);
            }}
            category={category}
            sDate={moment
              .tz(sDate, "DD-MMM", "Asia/Kolkata")
              .format("DD-MM-YYYY")}
            hotels={hotels}
            hotelId={hotelId}
            
          />
        )
        }
      </Stack>
    </>
  );
};

export default BalanceSheetShowComponent;
