import { InputLabel, MenuItem, Select, Typography } from "@mui/material";
import CompanyViewer from "./CompanyViewer";

const CompanyChooser = ({
  value,
  onValueChange,
  companies,
  required,
  title
}) => {


  return (
    <>
      <InputLabel
        variant="standard"
        sx={{
          width: "100%",
          margin: "3px 0px",
        }}
      >
        <Typography variant="h6" color="primary" fontSize="15px">
          {
            title ? title : "Choose Company"
          }
        </Typography>
      </InputLabel>
      <Select
        fullWidth
        value={value}
        onChange={(e) => {
          onValueChange(e.target.value);
        }}
        variant="outlined"
        margin="none"
        label="Hotel"
        sx={{
          margin: "8px 0px",
        }}
        required={Boolean(required)}
        style={{
          marginLeft: "1px",
        }}
      >
        {companies
          .sort((a, b) => {
            if (a.name.toLowerCase() < b.name.toLowerCase()) {
              return -1;
            }
            if (a.name.toLowerCase() > b.name.toLowerCase()) {
              return 1;
            }
            return 0;
          })
          .map((company) => {
            return (
              <MenuItem key={company._id} value={company._id}>
                <CompanyViewer company={company}  onClick={() => {}} />
              </MenuItem>
            );
          })}
      </Select>
    </>
  );
};

export default CompanyChooser;
