import { useContext, useState } from "react";
import { ExpenseContext } from "../../../store/expensesS";
import LoadingBookings from "../../common/LoadingBookings";
import ProfitAndLossGraph from "../../graph/profitAndLoss/ProfitAndLossGraph";
import Constant from "../../../constant/Constant";
import CustomDateRangePicker from "../../analytics/components/DateRangePicker";
import PropertyExpenseViewer from "./PropertyExpenseViewer";
import { Stack } from "@mui/material";
import HotelChooser from "../../graph/components/HotelChooser";

const ProfitAndLossScreen = () => {
  const expenseContext = useContext(ExpenseContext);
  const [hotels, setHotels] = useState([]);

  const dateContent = (
    <div style={{ position: "absolute", top: "15px", left: "30px", zIndex: 4 }}>
      <CustomDateRangePicker
        startDay={expenseContext.startDay}
        days={expenseContext.days}
        onDateChange={(date, days) => {
          expenseContext.onDaysUpdate(date, days);
        }}
      />
    </div>
  );

  if (
    !expenseContext.isProfitAndLossLoaded ||
    expenseContext.isProfitAndLossLoading
  ) {
    return <LoadingBookings />;
  }

  return (
    <>
      {dateContent}
      <ProfitAndLossGraph
        analytics={expenseContext.profitAndLossAnalytics}
        isBar={expenseContext.isBar}
        isDate={expenseContext.isDate}
        isHotel={expenseContext.isHotel}
        isTablularData={expenseContext.isTable}
        onToggleBar={() => {
          expenseContext.onToggleBar();
        }}
        onToggleDate={() => {
          expenseContext.onToggleDate();
        }}
        onToggleHotel={() => {
          expenseContext.onToggleHotel();
        }}
        onToggleTabularData={() => {
          expenseContext.onToggleTabularData();
        }}
        title={"Profit And Loss"}
        allOptions={Constant.RevenueAnalyticsShort}
      />
      <Stack
        direction={"column"}
        sx={{
          justifyContent: "space-between",
        }}
      >
        <div
          style={{
            // width: "100%",
            display: "flex",
            justifyContent: "flex-end",
            margin: "0px 50px",
          }}
        >
          <HotelChooser
            hotels={Object.keys(expenseContext.profitAndLossAnalytics.Revenue)}
            selectedHotels={hotels}
            isSingle={true}
            onHotelsChange={(h) => {
              setHotels(h);
            }}
          />
        </div>
        {!expenseContext.isTable &&
          hotels.length > 0 &&
          expenseContext.profitAndLossAnalytics.Revenue &&
          expenseContext.profitAndLossAnalytics.Revenue[hotels[0]] && (
            <PropertyExpenseViewer
              expenses={expenseContext.profitAndLossAnalytics.Expense}
              analytics={expenseContext.profitAndLossAnalytics}
              hotelName={hotels[0]}
            />
          )}
      </Stack>
    </>
  );
};

export default ProfitAndLossScreen;
