import { Stack, Typography } from "@mui/material";

const RevenueReconcilationPayment = ({ type, data }) => {

  if (data.length <= 0) {
    return <></>;
  }

  return (
    <Stack sx={{ width: "170px", textAlign: "center" }}>
      <Typography variant="h6" color="primary.main" sx={{ fontSize: "13px" }}>
        {type}
      </Typography>
      {data.map((pay) => {
        return (
          <Typography
            variant="h6"
            color="primary.main"
            sx={{ fontSize: "11px" }}
          >
            {`${pay.date} - ${pay.effectiveValue} - ${pay.actualValue} - ${pay.method}`}
          </Typography>
        );
      })}
    </Stack>
  );
};

export default RevenueReconcilationPayment;
