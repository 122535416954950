import { InputLabel, MenuItem, Select, Typography } from "@mui/material";
import Expenser from "../expensers/Expnser";

const AddExpenserSelector = ({
  value,
  onValueChange,
  expensers,
  required,
  title
}) => {
  return (
    <>
      <InputLabel
        variant="standard"
        sx={{
          width: "100%",
          margin: "3px 0px",
        }}
      >
        <Typography variant="h6" color="primary" fontSize="15px">
          {
            title ? title : "Choose Expensers"
          }
        </Typography>
      </InputLabel>
      <Select
        fullWidth
        value={value}
        onChange={(e) => {
          onValueChange(e.target.value);
        }}
        variant="outlined"
        margin="none"
        label="Hotel"
        sx={{
          margin: "8px 0px",
        }}
        required={Boolean(required)}
        style={{
          marginLeft: "1px",
        }}
      >
        {expensers
          .sort((a, b) => {
            if (a.name.toLowerCase() < b.name.toLowerCase()) {
              return -1;
            }
            if (a.name.toLowerCase() > b.name.toLowerCase()) {
              return 1;
            }
            return 0;
          })
          .map((expenser) => {
            return (
              <MenuItem key={expenser.id} value={expenser.id}>
                <Expenser expenser={expenser}  />
              </MenuItem>
            );
          })}
      </Select>
    </>
  );
};

export default AddExpenserSelector;
