import moment from "moment-timezone";
import Constant from "../constant/Constant";
import GetExpenser from "../utils/expense/GetExpenser";
import { AuthContext } from "./auth";
import allRoutes from "../pages/routes";

const { createContext, useState, useCallback, useContext, useEffect } = require("react");


export const NewExpenseContext = createContext({

    permissions: [],
    isPermissionLoaded: false,
    isPermissionLoading: false,

    categories: [],
    isCategoryLoaded: false,
    isCategoryLoading: false,

    sCategory: null,

    isCategoryUpdating: false,
    onUpdateCategory: async () => { },

    category: [],
    onCategoryChange: () => { },
    sEntities: [],
    onEntitiesChange: () => { },


    expensers: [],
    isExpensersLoading: false,
    isExpenserAdding: false,
    isAdderOpen: false,
    sExpenser: null,
    onAdderUpdate: (isOpen, sExpenser) => { },
    onAddExpenser: (data) => { },
    isVisited: false,


    expenses: {},
    isExpensesLoading: false,
    isExpenseAdderOpen: false,
    isExpenseAdding: false,
    onExpenseAdderUpdate: (data) => { },
    onAddExpense: async (data) => { },
    onSExpenseUpdate: (expense) => { },

    sExpense: null,
    isApproving: false,
    onApproveExpense: (expenseId, status, note) => { },


    isAddingPayment: false,
    approveds: {},
    isApprovedLoading: false,
    sApproved: null,
    isApprovedLoaded: false,
    onSApprovedUpdate: (approved) => { },

    isError: false,
    errorMessage: "",


    isMarking: false,
    onMarkUpdate: async (date, status, expenseId) => { },

    isPaymentOpen: false,
    onPaymentUpdate: () => { },
    isPaymentAdding: false,
    onAddPayment: async (data) => { },

    onClearError: () => { },


    isEntitiesRevenueLoaded: false,
    entitiesRevenue: {},
    isEntitiesRevenueLoading: false,

    startDay: "01-11-2023",
    days: 30,
    isTable: false,

    isEntitiesTransctionsLoaded: false,
    transctions: {},
    isEntitiesTransctionsLoading: false,

    isLoanOpen: false,
    isLoanLoading: false,
    onCreateLoan: async (data) => { },
    onToggleLoan: () => { },


    isMoneyExchangeOpen: false,
    isMoneyExchangeLoading: false,
    onMoneyExchange: async (data) => { },
    onToggleMoneyExchange: () => { },

    isCreatePartnerBillOpen: false,
    isCreatePartnerBillLoading: false,
    onCreatePartnerBill: async (data) => { },
    onToggleCreatePartnerBill: () => { },

    isColSyncOpen: false,
    isColSyncLoading: false,
    onColSync: async (data) => { },
    onToggleColSync: () => { },
    balanceSheetData: {
        entity: "",
        balances: {},
        opening: {
            cash: 0,
            bank: 0,
            ota: 0,
        },
    },
    onBalanceSheetDataUpdate: () => { },
    onBalanceSelectedEntityChange: (entity, transctions) => { },

    isBalanceSheetUpdating: false,
    onBalanceSheetUpdate: async (data) => { },



    loansAndAdvances: {
        ADVANCES: [],
        LOANS: []
    },
    isLoansAndAdvancesLoading: false,
    isClearingPayment: false,
    sClearer: "",
    onChangeLoanClearer: () => { },
    onClearLoandAndAdvancePayment: async () => { },



    onUpdateDate: (date, days) => { },
    selectedEntityId: undefined,
    onEntitesUpdate: (entityId) => { }
})


const NewExpenseContextProvider = ({ children, selRoute }) => {

    const authContext = useContext(AuthContext)

    const [expenseData, setExpenseData] = useState({
        permissions: [],
        isPermissionLoaded: false,
        isPermissionLoading: false,

        categories: [],
        isCategoryLoaded: false,
        isCategoryLoading: false,

        sCategory: null,
        isCategoryUpdating: false,
        sEntities: [],

        category: [],


        expensers: [],
        isExpensersLoading: false,
        isExpenserAdding: false,
        isAdderOpen: false,
        sExpenser: null,


        expenses: {},
        isExpensesLoading: false,
        isExpenseAdderOpen: false,
        isExpenseAdding: false,

        sExpense: null,
        isApproving: false,

        isAddingPayment: false,
        approveds: {},
        isApprovedLoading: false,
        sApproved: null,
        isApprovedLoaded: false,

        isMarking: false,
        isPaymentOpen: false,
        isPaymentAdding: false,

        isError: false,
        errorMessage: "",

        isVisited: false,


        isEntitiesRevenueLoaded: false,
        entitiesRevenue: {},
        isEntitiesRevenueLoading: false,

        isEntitiesTransctionsLoaded: false,
        transctions: {},
        isEntitiesTransctionsLoading: false,

        selectedEntityId: "",

        isLoanOpen: false,
        isLoanLoading: false,

        isMoneyExchangeOpen: false,
        isMoneyExchangeLoading: false,

        isCreatePartnerBillOpen: false,
        isCreatePartnerBillLoading: false,

        isColSyncOpen: false,
        isColSyncLoading: false,

        isBalanceSheetUpdating: false,

        loansAndAdvances: {
            ADVANCES: [],
            LOANS: []
        },
        isLoansAndAdvancesLoading: false,
        isClearingPayment: false,
        sClearer: "",

        balanceSheetData: {
            entity: "",
            balances: {},
            opening: {
                cash: 0,
                bank: 0,
                ota: 0,
            },
        },

        startDay: "01-01-2024",
        days: 20,
        isTable: false,
    })


    // moment().subtract(6, "day").format("DD-MM-YYYY")


    const loadExpense = useCallback(async (
        isLoaded,
        isLoading,
        data,
        route,
        rData
    ) => {

        setExpenseData(p => {
            let op = { ...p }
            if (isLoaded) {
                op[isLoaded] = false;
            }
            op[isLoading] = true;
            return op
        })

        const status = await GetExpenser(
            route,
            expenseData.selectedEntityId,
            authContext.authToken,
            rData
        )

        if (!status.status) {
            setExpenseData(p => {
                let op = { ...p }
                if (isLoaded) {
                    op[isLoaded] = true;
                }
                op[isLoading] = false;
                op.isError = true;
                op.errorMessage = status.message;
                return op
            })
            return
        }


        setExpenseData(p => {
            let op = { ...p }
            if (isLoaded) {
                op[isLoaded] = true;
            }
            op[isLoading] = false;
            op.isError = true;
            op.errorMessage = status.message ? status.message : "Updated successfully."
            op[data] = status.data;
            return op
        })
    }, [
        expenseData.selectedEntityId,
        authContext.authToken
    ])

    const loadEntitities = useCallback(async (
        isLoaded,
        isLoading,
        data,
        route,
        rData
    ) => {

        setExpenseData(p => {
            return {
                ...p,
                isPermissionLoaded: false,
                isPermissionLoading: true
            }
        })

        const status = await GetExpenser(
            route,
            "",
            authContext.authToken,
            rData
        )

        if (!status.status) {
            setExpenseData(p => {
                return {
                    ...p,
                    isPermissionLoaded: false,
                    isPermissionLoading: false,
                    isError: true,
                    errorMessage: status.message
                }
            })
            return
        }


        setExpenseData(p => {
            return {
                ...p,
                isPermissionLoaded: true,
                isPermissionLoading: false,
                isError: true,
                errorMessage: status.message ? status.message : "Permissions Updated successfully.",
                permissions: status.data,
                selectedEntityId: window.localStorage.getItem("entityId") ? window.localStorage.getItem("entityId") : ""
            }
        })
    }, [
        authContext.authToken
    ])

    const onUpdateCategory = useCallback(async (data, ent) => {

        loadExpense(
            undefined,
            "isCategoryUpdating",
            "categories",
            Constant.updateCategoryRoute,
            {
                categories: data,
                entities: ent
            }
        )
    }, [
        loadExpense
    ])

    useEffect(() => {
        setExpenseData(p => {
            return {
                ...p,
                category: p.categories.categories && p.categories.categories.length > 0
                    ? p.categories.categories
                    : [
                        {
                            type: "Category",
                            categories: [],
                        },
                    ],
                sEntities: p.categories.entities && p.categories.entities.length > 0
                    ? p.categories.entities
                    : [],
            }
        })
    }, [
        expenseData.categories
    ])

    const onClearError = useCallback(() => {
        setExpenseData(p => {
            return { ...p, isError: false, errorMessage: "" }
        })
    }, [])

    const onCategoryChange = useCallback((cat) => {
        setExpenseData(p => {
            return { ...p, category: cat }
        })
    }, [])

    const onEntitiesChange = useCallback((ent) => {
        setExpenseData(p => {
            return { ...p, sEntities: ent, isVisited: false }
        })
    }, [])

    const onAdderUpdate = useCallback((isOpen, sExpenser) => {
        setExpenseData(p => {
            return {
                ...p,
                isAdderOpen: isOpen,
                sExpenser
            }
        })
    }, [])

    const onBalanceSheetDataUpdate = useCallback((data) => {
        setExpenseData(p => {
            return {
                ...p,
                balanceSheetData: data
            }
        })
    }, [])

    const onBalanceSelectedEntityChange = useCallback((entity, transactions) => {
        if (entity) {
            let values = Object.keys(
                transactions[entity].transctions
            ).map((date) => {
                return [
                    date,
                    {
                        cashLoanToOxyCorporations: "0",
                        bankLoanToOxyCorporations: "0",
                        revenueToKotakBank: "0",
                        expenses: [],
                    },
                ];
            });

            let fDate = Object.keys(transactions[entity].transctions)
                .map((dt) => {
                    return moment.tz(dt, "DD-MMM", "Asia/Kolkata");
                })
                .sort((a, b) => {
                    return a.unix() - b.unix();
                })[0]
                .format("DD-MMM");

            const open =
                transactions[entity].transctions[fDate].balances
                    .OpeningBalance;
            const opening = open ? open : { cash: 0, bank: 0, ota: 0 };

            setExpenseData(exp => {
                return {
                    ...exp,
                    balanceSheetData: {
                        balances: Object.fromEntries(values),
                        opening,
                        entity
                    }
                }
            })
        }
    }, [])

    const onToggleLoan = useCallback(() => {
        setExpenseData(p => {
            return {
                ...p,
                isLoanOpen: !p.isLoanOpen
            }
        })
    }, [])

    const onToggleMoneyExchange = useCallback(() => {
        setExpenseData(p => {
            return {
                ...p,
                isMoneyExchangeOpen: !p.isMoneyExchangeOpen
            }
        })
    }, [])


    const onToggleCreatePartnerBill = useCallback(() => {
        setExpenseData(p => {
            return {
                ...p,
                isCreatePartnerBillOpen: !p.isCreatePartnerBillOpen
            }
        })
    }, [])

    const onToggleColSync = useCallback(() => {
        setExpenseData(p => {
            return {
                ...p,
                isColSyncOpen: !p.isColSyncOpen
            }
        })
    }, [])

    const onChangeLoanClearer = useCallback((sClearer) => {
        setExpenseData(p => {
            return {
                ...p,
                sClearer: sClearer
            }
        })
    }, [])


    const onCreateLoan = useCallback(async (data) => {
        setExpenseData(p => {
            return { ...p, isLoanLoading: true }
        })

        const status = await GetExpenser(
            Constant.createLoanRoute,
            expenseData.selectedEntityId,
            authContext.authToken,
            data
        )

        if (!status.status) {
            setExpenseData(p => {
                return {
                    ...p,
                    isLoanLoading: false,
                    isError: true,
                    errorMessage: status.message
                }
            })
            return
        }

        setExpenseData(p => {
            return {
                ...p,
                isLoanLoading: false,
                isError: true,
                errorMessage: "Loan Created Successfully.",
                approveds: {
                    ...p.approveds,
                    LOANS: [...p.approveds.LOANS, status.data]
                },
                isLoanOpen: false
            }
        })

    }, [
        expenseData.selectedEntityId,
        authContext.authToken

    ])


    const onClearLoandAndAdvancePayment = useCallback(async (data) => {
        setExpenseData(p => {
            return { ...p, isClearingPayment: true }
        })

        const status = await GetExpenser(
            Constant.clearLoansAndAdvancesRoutes,
            expenseData.selectedEntityId,
            authContext.authToken,
            data
        )

        if (!status.status) {
            setExpenseData(p => {
                return {
                    ...p,
                    isClearingPayment: false,
                    isError: true,
                    errorMessage: "Please try again unknown error"
                }
            })
            return
        }

        setExpenseData(p => {
            return {
                ...p,
                isClearingPayment: false,
                isError: true,
                errorMessage: "Payment Cleared Successfully.",
                sClearer: "",
            }
        })

    }, [
        expenseData.selectedEntityId,
        authContext.authToken

    ])


    const onBalanceSheetUpdate = useCallback(async (data) => {
        setExpenseData(p => {
            return { ...p, isBalanceSheetUpdating: true }
        })

        const status = await GetExpenser(
            Constant.balanceSheetUpdateRoute,
            expenseData.selectedEntityId,
            authContext.authToken,
            data
        )

        if (!status.status) {
            setExpenseData(p => {
                return {
                    ...p,
                    isBalanceSheetUpdating: false,
                    isError: true,
                    errorMessage: status.message
                }
            })
            return
        }

        setExpenseData(p => {
            return {
                ...p,
                isBalanceSheetUpdating: false,
                isError: true,
                errorMessage: "Balance Sheet Successfully.",
            }
        })

    }, [
        expenseData.selectedEntityId,
        authContext.authToken

    ])

    const onColSync = useCallback(async (data) => {
        setExpenseData(p => {
            return { ...p, isColSyncLoading: true }
        })

        const status = await GetExpenser(
            Constant.colSyncRoute,
            expenseData.selectedEntityId,
            authContext.authToken,
            data
        )

        if (!status.status) {
            setExpenseData(p => {
                return {
                    ...p,
                    isColSyncLoading: false,
                    isError: true,
                    errorMessage: status.message
                }
            })
            return
        }

        setExpenseData(p => {
            return {
                ...p,
                isColSyncLoading: false,
                isError: true,
                errorMessage: "Col Synced Successfully.",
                isColSyncOpen: false
            }
        })

    }, [
        expenseData.selectedEntityId,
        authContext.authToken
    ])

    const onMoneyExchange = useCallback(async (data) => {
        setExpenseData(p => {
            return { ...p, isMoneyExchangeLoading: true }
        })

        const status = await GetExpenser(
            Constant.moneyExchangeRoute,
            expenseData.selectedEntityId,
            authContext.authToken,
            data
        )

        if (!status.status) {
            setExpenseData(p => {
                return {
                    ...p,
                    isMoneyExchangeLoading: false,
                    isError: true,
                    errorMessage: status.message
                }
            })
            return
        }

        setExpenseData(p => {
            return {
                ...p,
                isMoneyExchangeLoading: false,
                isError: true,
                errorMessage: "Money Exchanged Successfully.",
                isMoneyExchangeOpen: false
            }
        })

    }, [
        expenseData.selectedEntityId,
        authContext.authToken

    ])


    const onCreatePartnerBill = useCallback(async (data) => {
        setExpenseData(p => {
            return { ...p, isCreatePartnerBillLoading: true }
        })

        const status = await GetExpenser(
            Constant.createPartnerRevenue,
            expenseData.selectedEntityId,
            authContext.authToken,
            data
        )

        if (!status.status) {
            setExpenseData(p => {
                return {
                    ...p,
                    isCreatePartnerBillLoading: false,
                    isError: true,
                    errorMessage: status.message
                }
            })
            return
        }

        setExpenseData(p => {
            return {
                ...p,
                isCreatePartnerBillLoading: false,
                isError: true,
                errorMessage: "Bill Raised Successfully.",
                approveds: {
                    ...p.approveds,
                    BILLS: [...p.approveds.BILLS, status.data]
                },
                isCreatePartnerBillOpen: false
            }
        })

    }, [
        expenseData.selectedEntityId,
        authContext.authToken

    ])

    const onAddExpenser = useCallback(async (data) => {
        setExpenseData(p => {
            return { ...p, isExpenserAdding: true }
        })
        const status = await GetExpenser(
            Constant.addExpenserRoute,
            expenseData.selectedEntityId,
            authContext.authToken,
            data
        )

        if (!status.status) {
            setExpenseData(p => {
                return {
                    ...p,
                    isExpenserAdding: false,
                    isAdderOpen: false,
                    isError: true,
                    errorMessage: status.message
                }
            })
            return
        }

        setExpenseData(p => {
            return {
                ...p,
                isExpenserAdding: false,
                isError: true,
                errorMessage: "Expenser Added Successfully",
                expensers: [...p.expensers, status.data],
                isAdderOpen: false,
                sExpense: null
            }
        })
    }, [
        expenseData.selectedEntityId,
        authContext.authToken
    ])


    const onExpenseAdderUpdate = useCallback((isOpen) => {
        setExpenseData(p => {
            return {
                ...p,
                isExpenseAdderOpen: isOpen,
            }
        })
    }, [])

    const onSExpenseUpdate = useCallback((expense) => {
        setExpenseData(p => {
            return {
                ...p,
                sExpense: expense
            }
        })
    }, [])

    const onAddExpense = useCallback(async (data) => {

        setExpenseData(p => {
            return { ...p, isExpenseAdding: true }
        })
        const status = await GetExpenser(
            Constant.addExpenseRoute,
            expenseData.selectedEntityId,
            authContext.authToken,
            data
        )

        if (!status.status) {
            setExpenseData(p => {
                return {
                    ...p,
                    isExpenseAdding: false,
                    isError: true,
                    errorMessage: status.message
                }
            })
            return
        }

        setExpenseData(p => {
            let a = { ...p.expenses }
            let b = [...status.data.expenseCategory].pop()
            a[b] = [...a[b], status.data]
            return {
                ...p,
                isExpenseAdding: false,
                isError: true,
                errorMessage: "Expense Added Successfully",
                expenses: {
                    ...a
                },
                isExpenseAdderOpen: false
            }
        })

    }, [
        expenseData.selectedEntityId,
        authContext.authToken
    ])

    const onApproveExpense = useCallback(async (expenseId, state, note) => {

        if (!note) {
            setExpenseData(p => {
                return {
                    ...p,
                    isError: true,
                    errorMessage: "Please enter correct note."
                }
            })
            return
        }

        setExpenseData((p) => { return { ...p, isApproving: true } })
        const status = await GetExpenser(
            Constant.approveExpenseRoute,
            expenseData.selectedEntityId,
            authContext.authToken,
            {
                expenseId,
                status: state,
                note
            }
        )

        if (!status.status) {
            setExpenseData(p => {
                return {
                    ...p,
                    isApproving: false,
                    isError: true,
                    errorMessage: status.message
                }
            })
            return
        }

        setExpenseData(p => {
            let a = { ...p.approveds }
            let b = [...status.data.expenseCategory].pop()
            if (status.data.expenseStatus === "APPROVED") {
                a[b] = [...a[b], status.data]
            }

            let c = { ...p.expenses }
            let d = [...status.data.expenseCategory].pop()
            c[d] = c[d].filter(a => a.expenseId !== status.data.expenseId)
            return {
                ...p,
                isApproving: false,
                isError: true,
                errorMessage: "Expense Updated Successfully",
                approveds: {
                    ...a
                },
                expenses: {
                    ...c
                },
                sExpense: null
            }
        })
    }, [
        authContext.authToken,
        expenseData.selectedEntityId,
    ])

    const onSApprovedUpdate = useCallback((approved) => {
        setExpenseData(p => {
            return {
                ...p,
                sApproved: approved
            }
        })
    }, [])

    const onPaymentUpdate = useCallback((value) => {
        setExpenseData(p => {
            return {
                ...p,
                isPaymentOpen: value
            }
        })
    }, [])


    const onMarkUpdate = useCallback(async (date, state, expenseId) => {
        setExpenseData(p => { return { ...p, isMarking: true } })
        const status = await GetExpenser(Constant.markRecurringExpenseRoute, expenseData.selectedEntityId, authContext.authToken, { expenseId, date, status: state })
        if (!status.status) {
            setExpenseData(p => {
                return {
                    ...p,
                    isMarking: false,
                    isError: true,
                    errorMessage: status.message,
                }
            })
            return
        }
        setExpenseData(p => {
            let a = { ...p.approveds }
            let b = [...status.data.expenseCategory].pop()
            a[b] = [...a[b].filter(ab => {
                return ab.expenseId !== status.data.expenseId
            }), status.data]
            return {
                ...p,
                isMarking: false,
                isError: true,
                errorMessage: "Expense Marked Successfully",
                approveds: {
                    ...a
                }
            }
        })


    }, [expenseData.selectedEntityId, authContext.authToken])

    const onUpdateDate = (date, days) => {
        setExpenseData(p => {
            return {
                ...p,
                startDay: date,
                days: days
            }
        })

    }


    const onAddPayment = useCallback(async (data) => {
        setExpenseData(p => { return { ...p, isPaymentAdding: true } })
        const status = await GetExpenser(Constant.addPaymentToExpenseRoute, expenseData.selectedEntityId, authContext.authToken, data)
        if (!status.status) {
            setExpenseData(p => {
                return {
                    ...p,
                    isPaymentAdding: false,
                    isError: true,
                    errorMessage: status.message,
                }
            })
            return
        }

        setExpenseData(p => {
            let a = { ...p.approveds }
            let b;

            if (status.data.expenseType === "Bills") {
                b = "BILLS"
            } else if (status.data.expenseType === "Loans") {
                b = "LOANS"
            } else {
                b = [...status.data.expenseCategory].pop()
            }
            a[b] = [...a[b].filter(ab => {
                return ab.expenseId !== status.data.expenseId
            }), status.data]
            return {
                ...p,
                isPaymentAdding: false,
                isError: true,
                errorMessage: "Payment Added Successfully",
                approveds: {
                    ...a
                },
                isPaymentOpen: false,
                sApproved: undefined
            }
        })
    }, [
        expenseData.selectedEntityId,
        authContext.authToken
    ])

    const onEntitesUpdate = useCallback((entityId) => {
        window.localStorage.setItem("entityId", entityId)
        setExpenseData(p => {
            return {
                ...p,
                selectedEntityId: entityId
            }
        })
    }, [])


    const value = {
        ...expenseData,
        onUpdateCategory,
        onCategoryChange,
        onEntitiesChange,
        onAdderUpdate,
        onAddExpenser,
        onClearError,
        onExpenseAdderUpdate,
        onSExpenseUpdate,
        onAddExpense,
        onApproveExpense,
        onSApprovedUpdate,
        onMarkUpdate,
        onPaymentUpdate,
        onAddPayment,
        onUpdateDate,
        onEntitesUpdate,
        onCreateLoan,
        onToggleLoan,
        onMoneyExchange,
        onToggleMoneyExchange,
        onCreatePartnerBill,
        onToggleCreatePartnerBill,
        onColSync,
        onToggleColSync,
        onBalanceSheetUpdate,
        onClearLoandAndAdvancePayment,
        onChangeLoanClearer,
        onBalanceSheetDataUpdate,
        onBalanceSelectedEntityChange
    }
    useEffect(() => {
        if (allRoutes.indexOf(selRoute) >= 6 && allRoutes.indexOf(selRoute) <= 10) {
            setExpenseData(p => {
                return {
                    ...p,
                    isVisited: true
                }
            })
        }
    },
        [
            selRoute
        ]
    )

    useEffect(() => {

        if (expenseData.selectedEntityId !== undefined) {
            // if (allRoutes.indexOf(selRoute) >= 6 && allRoutes.indexOf(selRoute) <= 10 && !expenseData.isVisited) {
                // loadExpense("isEntitiesRevenueLoaded", "isEntitiesRevenueLoading", "entitiesRevenue", Constant.getPayableRoute, {
                //     startDay: expenseData.startDay,
                //     days: expenseData.days,
                //     isTable: expenseData.isTable
                // })
                // loadExpense("isEntitiesTransctionsLoaded", "isEntitiesTransctionsLoading", "transctions", Constant.getTransctionsRoute, {
                //     startDay: expenseData.startDay,
                //     days: expenseData.days,
                //     isTable: expenseData.isTable
                // })
            // }
        }
    }, [
        expenseData.startDay,
        expenseData.isTable,
        expenseData.days,
        expenseData.selectedEntityId,
        authContext.authToken,
        loadExpense,
        // selRoute,
        // expenseData.isVisited
    ])
    useEffect(() => {
        // if (allRoutes.indexOf(selRoute) >= 6 && allRoutes.indexOf(selRoute) <= 10 && !expenseData.isVisited) {
            // loadEntitities("isPermissionLoaded", "isPermissionsLoading", "permissions", Constant.getExpensePermissionsRoute, {})
        // }
    }, [
        loadEntitities,
        authContext.authToken,
        // selRoute,
        // expenseData.isVisited
    ])

    useEffect(() => {
        if (expenseData.selectedEntityId !== undefined) {
            // console.log("fired,", expenseData.isVisited)
            // if (allRoutes.indexOf(selRoute) >= 6 && allRoutes.indexOf(selRoute) <= 10 && !expenseData.isVisited) {
                loadExpense("isCategoryLoaded", "isCategoryLoading", "categories", Constant.getCategoryRoute, {})
                // loadExpense(undefined, "isExpensesLoading", "expenses", Constant.getExpensesRoute, {})
                // loadExpense("isApprovedLoaded", "isApprovedLoading", "approveds", Constant.getApprovedExpensesRoute, {})
                // loadExpense(undefined, "isLoansAndAdvancesLoading", "loansAndAdvances", Constant.getLoansAndAdvancesRoutes, {})
            // }
        }
    }, [
        expenseData.selectedEntityId,
        authContext.authToken,
        loadExpense,
        // selRoute,
        // expenseData.isVisited
    ])



    return (
        <NewExpenseContext.Provider value={value}>
            {children}
        </NewExpenseContext.Provider>
    )


}

export default NewExpenseContextProvider