import { Card, CircularProgress, IconButton, Stack, Typography } from "@mui/material";
import EditApprovedModel from "./EditApprovedModel";
import { useContext } from "react";
import { ExpenseContext } from "../../../../store/expensesS";
import ExpenseViewer from "../common/expenseViewer";
import AddIcon from "@mui/icons-material/Add";

const ApprovedListWrapper = () => {
  const expenseContext = useContext(ExpenseContext);

  return (
    <Card
      elevation={3}
      sx={{
        flexDirection: "column",
        justifyContent: "center",
        flexGrow: "1",
        // width: "50%",
        padding: "15px 10px 10px 15px",
        margin: "40px 20px",
        borderRadius: "10px",
      }}
    >
      <Stack
        direction="row"
        sx={{
          flexDirection: "row",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <Typography variant="h1" fontSize="22px">
          Approveds
        </Typography>

        <IconButton
          onClick={() => {
            expenseContext.onToggleExpenseAdder();
          }}
        >
          <AddIcon fontSize="20px" color="primary.main" />
        </IconButton>
      </Stack>

      {expenseContext.isApprovedsLoading ||
      !expenseContext.isApprovedsLoaded ? (
        <CircularProgress
          sx={{
            minHeight: "200px",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            margin: "auto",
          }}
          color="primary"
          size={25}
        />
      ) : (
        <Stack
          style={{
            marginLeft: "10px",
            maxHeight: "400px",
            overflowY: "scroll",
            margin: "12px 0px 15px 15px",
          }}
          spacing={0}
        >
          {expenseContext.approveds.map((approved, index) => {
            return (
              <ExpenseViewer
                key={index}
                expense={approved}
                onClick={() => {
                  expenseContext.onSelectApprovedId(approved.id);
                }}
              />
            );
          })}
        </Stack>
      )}

      {expenseContext.sApprovedId && (
        <EditApprovedModel
          expense={expenseContext.approveds.find((app) => {
            return app.id === expenseContext.sApprovedId;
          })}
          onClose={() => {
            expenseContext.onSelectApprovedId(null);
          }}
          isPaymentAdderOpen={expenseContext.isPaymentAdderOpen}
          onTogglePaymentAdder={() => {
            expenseContext.onTogglePaymentAdder();
          }}
          isPaymentAdding={expenseContext.isPaymentAdding}
          onAddPayment={(data) => {
            expenseContext.onAddPayment(data);
          }}
        />
      )}
    </Card>
  );
};

export default ApprovedListWrapper;
