import { useContext, useEffect, useState } from "react";
import CustomSnackBar from "../components/common/CustomSnackBar";
import { HotelContext } from "../store/hotel";
import Header from "../components/common/Header";
import SideNav from "../components/common/SideNav";
import { Grid } from "@mui/material";
import BookingContextProvider from "../store/booking";
import BookingStack from "../components/bookings/BookingStack";
import allRoutes from "./routes";
import AnalyticsStack from "../components/analytics/AnalyticsStack";
import AnalyticsContextProvider from "../store/analytics";
import ExpenseStack from "../components/expense/expenseStack";
import NewExpenseContextProvider from "../store/expenses";
import NAnalyticsContextProvider from "../store/analyticsS";
import ExpenseContextProvider from "../store/expensesS";
import TaskContextProvider from "../store/task";
import TaskManagerStack from "../components/taskManager/stacks/TaskManagerStack";
import TaskUserContextProvider from "../store/taskUser";
import TaskUserScreen from "../components/taskUser/screens/TaskUserScreen";


const HomePage = () => {
  const hotelContext = useContext(HotelContext);
  const [selRoute, setSelRoute] = useState(allRoutes[0]);
  const [isHeaderOpen, setIsHeaderOpen] = useState(true);

  useEffect(() => {
    const url = new URL(window.location.href);
    let sideNaveName = url.searchParams.get("sideNavName");
    if (sideNaveName) {
      setSelRoute(sideNaveName);
    } else {
      const url = new URL(window.location.href);
      url.searchParams.set("sideNavName", allRoutes[0]);
      window.history.pushState({}, "", url);
    }
  }, []);
  return (
    <div style={{ height: "100vh", display: "flex", flexDirection: "column" }}>
      <Header
        isHeaderOpen={isHeaderOpen}
        onToggle={() => {
          setIsHeaderOpen((p) => !p);
        }}
      />
      <CustomSnackBar
        isOpen={hotelContext.isError}
        message={hotelContext.errorMessage}
        onClose={() => {
          hotelContext.clearMessage();
        }}
      />
      <Grid
        container
        sx={{
          flexGrow: 1,
          flexDirection: "row",
        }}
        style={{ height: "calc(100% - 100px)", width: "100%" }}
      >
        <Grid
          item
          sx={{
            boxShadow: "2px 0px 0px 0px rgba(0, 0, 0, 0.20)",
            height: "100%",
            width: isHeaderOpen ? "240px" : "0px",
            backgroundColor: "#282828",
            borderTop: "0.2px solid #fff",
            overflow: "auto",
            "::-webkit-scrollbar": {
              display: "none",
            },
            transition: "width 400ms",
          }}
        >
          <SideNav
            selectedRoute={selRoute}
            onRouteChange={(r) => {
              const url = new URL(window.location.href);
              url.searchParams.set("sideNavName", r);
              window.history.pushState({}, "", url);
              setSelRoute(r);
            }}
            isOpen={isHeaderOpen}
          />
        </Grid>

        <Grid
          item
          sx={{
            overflow: "auto",
            height: "100%",
            width: "100%",
            position: "relative",
            paddingLeft: "10px",
            "::-webkit-scrollbar": {
              display: "none",
            },
            transition: "width 400ms",
          }}
          style={{
            width: isHeaderOpen ? `calc(100% - 240px)` : "100%",
          }}
        >
          <BookingContextProvider selRoute={selRoute}>
            <BookingStack selRoute={selRoute} />
          </BookingContextProvider>

          <AnalyticsContextProvider selRoute={selRoute}>
            <NAnalyticsContextProvider selRoute={selRoute}>
              <AnalyticsStack selRoute={selRoute} />
            </NAnalyticsContextProvider>
          </AnalyticsContextProvider>

          <NewExpenseContextProvider selRoute={selRoute}>
            <ExpenseContextProvider selRoute={selRoute}>
              <ExpenseStack selRoute={selRoute} />
            </ExpenseContextProvider>
          </NewExpenseContextProvider>

          <TaskContextProvider selRoute={selRoute} token={""}>
            <TaskManagerStack selRoute={selRoute} />
          </TaskContextProvider>

          <TaskUserContextProvider selRoute={selRoute}>
            <TaskUserScreen selRoute={selRoute} />
          </TaskUserContextProvider>
        </Grid>
      </Grid>
    </div>
  );
};

export default HomePage;
