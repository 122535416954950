
const bookingStatusFinder = (booking ) => {
  if (booking.isCancelled) {
    return "Cancelled"
  } else if (booking.hasNotShown) {
    return "Not Shown"
  } else if (booking.hasCheckedOut) {
    return "Checked Out"
  } else if (booking.hasCheckedIn) {
    return "Checked In"
  } else {
    return "Upcoming"
  }
};

export default bookingStatusFinder
