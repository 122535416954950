import { useContext } from "react";
import { HotelContext } from "../../../store/hotel";
import { BookingContext } from "../../../store/booking";
import LoadingBookings from "../../common/LoadingBookings";
import SingleHotelViewer from "../components/SingleHotelViewer";

const RoomStatusScreen = ({ isLoading }) => {
  const hotel = useContext(HotelContext);
  const booking = useContext(BookingContext);


  if (isLoading || hotel.selctedHotelId === undefined) {
    return <LoadingBookings />;
  }

  if (hotel.selctedHotelId !== undefined && hotel.selctedHotelId === "") {
    return hotel.hotels.map((hotl) => {
      return (
        <SingleHotelViewer
          key={hotl._id}
          hotel={hotl}
          bookings={booking.inHouseBookings.filter((book) => {
            return book.hotelId === hotl._id;
          })}
        />
      );
    });
  }

  return (
    <SingleHotelViewer
      hotel={hotel.hotels.find((hot) => hot._id === hotel.selctedHotelId)}
      bookings={booking.inHouseBookings.filter((book) => {
        return book.hotelId === hotel.selctedHotelId;
      })}
    />
  );
};

export default RoomStatusScreen;
