import moment from "moment";
import { AuthContext } from "./auth";
import { HotelContext } from "./hotel";
import GetAnalytics from "../utils/analytics/GetAnalytics";
import Constant from "../constant/Constant";
import axios from "axios"
import allRoutes from "../pages/routes";

const { createContext, useState, useContext, useEffect, useCallback, useRef } = require("react");

export const AnalyticsContext = createContext({


    revenueReconcilation: {},
    isRevenueReconcilationLoading: false,
    isRevenueReconcilationLoaded: false,
    revenueReconcilationConf: "",



    collectionConcilation: {},
    isCollectionConcilationLoading: false,
    isCollectionConcilationLoaded: false,
    collectionConcilationConf: "",

    isError: false,
    errorMessage: "",
    clearMessage: () => { },
    startDay: "01-12-2023",
    days: 7,

    isTable: false,
    onUpdateDate: (date, days) => { },

})

const AnalyticsContextProvider = ({ children, selRoute }) => {

    const hotelContext = useContext(HotelContext)
    const authContext = useContext(AuthContext)

    const revenueReconcilationAnalyticsToken = useRef(null)
    const collectionConcilationAnalyticsToken = useRef(null)

    const [accountData, setAccountData] = useState({
        revenueReconcilation: {},
        isRevenueReconcilationLoading: false,
        isRevenueReconcilationLoaded: false,
        revenueReconcilationConf: "",


        collectionConcilation: {},
        isCollectionConcilationLoading: false,
        isCollectionConcilationLoaded: false,
        collectionConcilationConf: "",


        isError: false,
        errorMessage: "",
        startDay: moment().subtract(6, "day").format("DD-MM-YYYY"),
        days: 7,
        isTable: false,

    })

    const clearMessage = () => {
        setAccountData(p => {
            return { ...p, isError: false, errorMessage: "" }
        })
    }


    const onUpdateDate = (date, days) => {
        setAccountData(p => {
            return {
                ...p,
                startDay: date,
                days: days
            }
        })
    }


    const loadRevenueReconcilation = useCallback(async (hotelId, token, startDay, days, isTable, conf) => {
        if (conf === `${hotelId}${startDay}${days}${isTable}`) {
            return
        }
        if (revenueReconcilationAnalyticsToken.current && revenueReconcilationAnalyticsToken.current.token) {
            revenueReconcilationAnalyticsToken.current.cancel()
        }
        revenueReconcilationAnalyticsToken.current = axios.CancelToken.source()
        setAccountData(p => { return { ...p, isRevenueReconcilationLoading: true, isRevenueReconcilationLoaded: false, revenueReconcilation: {} } })
        try {
            const data = await GetAnalytics(Constant.getRevenueReconcilationRoute, hotelId, token, startDay, days, isTable, revenueReconcilationAnalyticsToken.current.token)
            if (!data.status) {
                setAccountData(p => {
                    return {
                        ...p,
                        isRevenueReconcilationLoading: false,
                        isRevenueReconcilationLoaded: false,
                        isError: true,
                        errorMessage: data.message,
                    }
                })
                return
            }
            setAccountData(p => {
                return {
                    ...p,
                    isRevenueReconcilationLoading: false,
                    isRevenueReconcilationLoaded: true,
                    isError: true,
                    errorMessage: "Revenue Reconcilation Successfully.",
                    revenueReconcilation: JSON.parse(data.data),
                    revenueReconcilationConf: `${hotelId}${startDay}${days}${isTable}`
                }
            })
        } catch (err) {
            console.log(err)
        }
    }, [])

    const loadCollectionConcilation = useCallback(async (hotelId, token, startDay, days, isTable, conf) => {
        if (conf === `${hotelId}${startDay}${days}${isTable}`) {
            return
        }
        if (collectionConcilationAnalyticsToken.current && collectionConcilationAnalyticsToken.current.token) {
            collectionConcilationAnalyticsToken.current.cancel()
        }
        collectionConcilationAnalyticsToken.current = axios.CancelToken.source()
        setAccountData(p => { return { ...p, isCollectionConcilationLoading: true, collectionConcilation: {} } })
        const data = await GetAnalytics(Constant.getCollectionConcilationRoute, hotelId, token, startDay, days, isTable, collectionConcilationAnalyticsToken.current.token)
        if (!data.status) {
            setAccountData(p => {
                return {
                    ...p,
                    isCollectionConcilationLoading: false,
                    isCollectionConcilationLoaded: false,
                    isError: true,
                    errorMessage: data.message,
                }
            })
            return
        }
        setAccountData(p => {
            return {
                ...p,
                isCollectionConcilationLoading: false,
                isCollectionConcilationLoaded: true,
                isError: true,
                errorMessage: "Collection Concilation Loaded Successfully.",
                collectionConcilation: JSON.parse(data.data),
                collectionConcilationConf: `${hotelId}${startDay}${days}${isTable}`
            }
        })
    }, [])




    useEffect(() => {
        if (hotelContext.selctedHotelId !== undefined && selRoute === allRoutes[23]) {
            loadRevenueReconcilation(hotelContext.selctedHotelId, authContext.authToken, accountData.startDay, accountData.days, accountData.isTable, accountData.revenueReconcilationConf)
        }
    }, [
        authContext.authToken,
        hotelContext.selctedHotelId,
        accountData.days,
        accountData.startDay,
        accountData.isTable,
        loadRevenueReconcilation,
        selRoute,
        accountData.revenueReconcilationConf
    ])

    useEffect(() => {
        if (hotelContext.selctedHotelId !== undefined && selRoute === allRoutes[24]) {
            loadCollectionConcilation(hotelContext.selctedHotelId, authContext.authToken, accountData.startDay, accountData.days, accountData.isTable,accountData.collectionConcilationConf)
        }
    }, [
        authContext.authToken,
        hotelContext.selctedHotelId,
        accountData.days,
        accountData.startDay,
        accountData.isTable,
        loadCollectionConcilation,
        selRoute,
        accountData.collectionConcilationConf
    ])



    const value = {
        ...accountData,
        clearMessage,
        onUpdateDate,
    }

    return (
        <AnalyticsContext.Provider value={value}>
            {children}
        </AnalyticsContext.Provider>
    )

}
export default AnalyticsContextProvider