import { CircularProgress, Container } from "@mui/material";

const LoadingBookings = () => {
  return (
    <Container
      sx={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: "100%",
        width: "100%",
        flexGrow: 1,
      }}
    >
     <CircularProgress />
    </Container>
  );
};

export default LoadingBookings