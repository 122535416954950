import { Checkbox, Stack, TextField, Typography } from "@mui/material";

const UpdateRecurringStatus = ({ value, label, onValueChange, isEnabled }) => {
  return (
    <Stack
      sx={{
        flexGrow: 1,
        justifyContent: "space-between",
        alignItems: "center",
        flexDirection: "row",
        margin: "2px 15px",
        maxWidth: "45%",
      }}
    >
      <Typography variant="h1" fontSize="18px">
        {label}
      </Typography>

      {typeof value == "boolean" ? (
        <Checkbox
          checked={value}
          disabled={!isEnabled}
          onChange={(e) => {
            onValueChange(e.target.checked);
          }}
        />
      ) : (
        <TextField
          value={value}
          onChange={(e) => {
            let value = e.target.value
            onValueChange(value === "" && Number(value) < 0 ? 0 : Number(value).toString() )
          }}
          disabled={!isEnabled}
          sx={{
            width:"100px"
          }}
          type="numeric"
        />
      )}
    </Stack>
  );
};

export default UpdateRecurringStatus;
